import React, { useState, useEffect, useRef } from "react";
import firebase from "firebase/compat/app";
import GoogleMapReact from "google-map-react";
import SideMenu from "./SideMenu";
import ActivateRouteMenu from "./ActivateRouteMenu";
import Geocoder from "react-native-geocoding";
import { generateBusinessId } from "../../Tools/generateBusinessId";
import AddEmployees from "./AddEmployees";
import AddServiceLocations from "./AddServiceLocations";
import ActivateRoute from "./ActivateRoute";
import { toast } from "react-toastify";
import { getProfilePhoto } from "../../GlobalComponents/getProfilePhoto";
import ChangePlanner from "./ChangePlanner";
import dayjs from "dayjs";
import StopDetailsCard from "./StopDetailsCard";
import { MdSync } from "react-icons/md";

Geocoder.init(process.env.REACT_APP_GOOGLE_MAPS_API_TOKEN);

const Popover = ({
  stopCount,
  handleSelectSubMarker,
  multiStopMarkerData,
  selectedSubMarker,
  handleAddServiceLocationToDay,
  editMode,
  markers,
  refresh,
  hiddenMarkers,
  handleEditModeOnHoverMultistop,
  handleOnHoverExit,
}) => {
  const [assignedMarkers, setAssignedMarkers] = useState([]);

  useEffect(() => {
    let matchesAssignedMarkers = [];
    let hiddenMarkersCount = 0;

    for (let index = 0; index < markers.length; index++) {
      const marker = markers[index];

      // Check if the current marker is hidden

      if (
        hiddenMarkers.some(
          (hiddenMarker) => hiddenMarker.markerId === marker.markerId
        )
      ) {
        // If the hidden marker matches the serviceLocationId, increment the hiddenMarkersCount

        if (
          marker.serviceLocationId ===
            multiStopMarkerData.value.serviceLocationId &&
          marker.subscriptionStopId ===
            multiStopMarkerData.value.subscriptionStopId
        ) {
          hiddenMarkersCount++;
        }
        continue; // Skip this iteration if markerId is in hiddenMarkers
      }

      // If the marker is not hidden and matches the serviceLocationId and the day is not "none"
      if (
        marker.serviceLocationId ===
          multiStopMarkerData.value.serviceLocationId &&
        marker.day !== "none" &&
        marker.subscriptionStopId ===
          multiStopMarkerData.value.subscriptionStopId
      ) {
        matchesAssignedMarkers.push(marker);
      }
    }

    // Calculate empty slots considering the hidden markers
    const emptySlots =
      stopCount - matchesAssignedMarkers.length - hiddenMarkersCount;

    // Fill the matchesAssignedMarkers array with empty objects to represent empty slots
    for (let i = 0; i < emptySlots; i++) {
      matchesAssignedMarkers.push({});
    }

    // Log and set the new array

    setAssignedMarkers(matchesAssignedMarkers);
  }, [refresh, markers, hiddenMarkers, multiStopMarkerData, stopCount]);

  return (
    <div
      style={{
        position: "absolute",
        bottom: "10px",
        left: "50%",
        width: 180,
        minHeight: 60,
        transform: "translateX(-18%)",
        backgroundColor: "white",
        padding: "15px",
        borderRadius: "4px",
        zIndex: 1000,
        boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.5)",
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        flexWrap: "wrap",
      }}
    >
      {assignedMarkers.map((value, index) => (
        <div
          onMouseOver={() =>
            editMode
              ? handleEditModeOnHoverMultistop(
                  multiStopMarkerData.value,
                  index,
                  assignedMarkers
                )
              : console.log()
          }
          onMouseLeave={() => (editMode ? handleOnHoverExit() : console.log())}
          onClick={(e) => {
            e.stopPropagation();
            if (!editMode) {
              // issue is multiStopMarkerData.value is the initial marker data which is why its always the value of the first stop if value.day doesnt exist
              let undefinedMarkerValue = {
                location: multiStopMarkerData.value.location,
                name: multiStopMarkerData.value.name.replace(/  +/g, " "),
                address: multiStopMarkerData.value.address,
                serviceLocationId: multiStopMarkerData.value.serviceLocationId,
                routeId: multiStopMarkerData.value.routeId,
                customerId: multiStopMarkerData.value.customerId,
                assigned: false,
                day: "none",
                employeePhoto: "none",
                employeeUid: "none",
                employeeName: "none",
                stopCount:
                  multiStopMarkerData.value.recurringStopType
                    .stopIntervalPerWeek,
                recurringStopType: multiStopMarkerData.value.recurringStopType,
                markerId: multiStopMarkerData.value.markerId,
                edited: false,
                hidden: false,
                subscriptionStopId:
                  multiStopMarkerData.value.subscriptionStopId,
                subscriptionId: multiStopMarkerData.value.recurringStopType
                  ? multiStopMarkerData.value.recurringStopType
                      .connectedSubscriptionId
                  : undefined,
              };

              handleSelectSubMarker(
                index,
                value.day ? value : undefinedMarkerValue
              );
            } else {
              handleAddServiceLocationToDay(
                value.day ? value : multiStopMarkerData.value,
                e
              );
            }
          }}
          onContextMenu={(e) => {
            e.preventDefault();
            e.stopPropagation();

            if (editMode) {
              handleAddServiceLocationToDay(
                value.day ? value : multiStopMarkerData.value,
                { nativeEvent: { which: 3 } }
              );
            }
          }}
          key={index}
          style={{
            width: 40,
            height: 40,
            backgroundColor: "grey",
            borderRadius: "50%",
            display: "flex",
            border: "4px solid",
            justifyContent: "center",
            alignItems: "center",
            color: "white",
            margin: 4,
            borderColor:
              selectedSubMarker === index
                ? "red"
                : value.day === "Monday"
                ? "#976DD0"
                : value.day === "Tuesday"
                ? "#FFBA5C"
                : value.day === "Wednesday"
                ? "#77D353"
                : value.day === "Thursday"
                ? "#F95F62"
                : value.day === "Friday"
                ? "#235789"
                : value.day === "Saturday"
                ? "#ED7D3A"
                : value.day === "Sunday"
                ? "#4F46E5"
                : "black",
          }}
        >
          {value.day && (
            <div>
              {value.employeeName
                ? value.employeeName
                    .match(/(\b\S)?/g)
                    .join("")
                    .match(/(^\S|\S$)?/g)
                    .join("")
                    .toUpperCase()
                : "Unavailable"}
            </div>
          )}
        </div>
      ))}
      <div
        style={{
          position: "absolute",
          bottom: "-10px",
          left: "50%",
          transform: "translateX(-50%)",
          width: 0,
          height: 0,
          borderLeft: "10px solid transparent",
          borderRight: "10px solid transparent",
          borderTop: "10px solid white",
          zIndex: 2,
        }}
      />
      <div
        style={{
          position: "absolute",
          bottom: "-8px",
          left: "50%",
          transform: "translateX(-50%)",
          width: 0,
          height: 0,
          borderLeft: "10px solid transparent",
          borderRight: "10px solid transparent",
          borderTop: "10px solid rgba(0, 0, 0, 0.1)",
          zIndex: 1,
          filter: "blur(4px)",
        }}
      />
    </div>
  );
};

const MarkerDots = ({ value, markers, refresh, hiddenMarkers }) => {
  const [assignedMarkers, setAssignedMarkers] = useState([]);

  useEffect(() => {
    let matchesAssignedMarkers = [];
    let hiddenCount = 0;
    const stopCount = value.stopCount;

    for (let index = 0; index < markers.length; index++) {
      const marker = markers[index];

      // Check if the marker is hidden and matches the serviceLocationId
      if (
        hiddenMarkers.some((a) => a.markerId === marker.markerId) &&
        marker.serviceLocationId === value.serviceLocationId &&
        marker.subscriptionStopId === value.subscriptionStopId
      ) {
        hiddenCount++; // Increment hiddenCount for each relevant hidden marker
        continue; // Skip further processing for this marker
      }

      // Process non-hidden markers that match the serviceLocationId and have a valid day
      if (
        marker.serviceLocationId === value.serviceLocationId &&
        marker.day !== "none" &&
        marker.subscriptionStopId === value.subscriptionStopId
      ) {
        matchesAssignedMarkers.push(marker);
      }
    }

    // Adjust stopCount by subtracting the number of hidden markers
    const adjustedStopCount = stopCount - hiddenCount;
    // Calculate empty slots based on the adjusted stop count
    const emptySlots = adjustedStopCount - matchesAssignedMarkers.length;

    for (let i = 0; i < emptySlots; i++) {
      matchesAssignedMarkers.push({});
    }

    setAssignedMarkers(matchesAssignedMarkers);
  }, [refresh, markers, hiddenMarkers, value]);

  return (
    <>
      {assignedMarkers.map((value, index) => (
        <span
          key={index}
          style={{
            width: "6px",
            height: "6px",
            backgroundColor:
              value.day === "Monday"
                ? "#976DD0"
                : value.day === "Tuesday"
                ? "#FFBA5C"
                : value.day === "Wednesday"
                ? "#77D353"
                : value.day === "Thursday"
                ? "#F95F62"
                : value.day === "Friday"
                ? "#235789"
                : value.day === "Saturday"
                ? "#ED7D3A"
                : value.day === "Sunday"
                ? "#4F46E5"
                : "grey",
            borderRadius: "50%",
            display: "inline-block",
            margin: 1,
          }}
        ></span>
      ))}
    </>
  );
};

const RouteBuilderEdit = (props) => {
  const db = firebase.firestore();
  const selectedBusiness = props.selectedBusiness;
  const [selectedRoute, setSelectedRoute] = useState(props.selectedRoute);
  const [refresh, setRefresh] = useState(false);
  const [employeesInRoute, setEmployeesInRoute] = useState([]);
  const [serviceLocationsInRoute, setServiceLocationsInRoute] = useState([]);
  const markers = useRef();
  const [editMode, setEditMode] = useState(false);
  const [editModeDay, setEditModeDay] = useState("");
  const [editModeEmployee, setEditModeDayEmployee] = useState({});
  const [routeStartDate, setRouteStartDate] = useState(
    dayjs().format("MM/DD/YYYY")
  );
  const [mapsObject, setMapsObject] = useState();
  const [mapObject, setMapObject] = useState();
  const [mapCenterCords, setMapCenterCords] = useState();
  const [hasUnactivatedChanges, setHasUnactivatedChanges] = useState(false);
  const [deletedMarkers, setDeletedMarkers] = useState([]);
  const deletedMarkersRef = useRef([]);
  const [mapWidth, setMapWidth] = useState("0px");
  const [mapHeight, setMapHeight] = useState("0px");
  const [savingsChangesDisplay, setSavingChangesDisplay] = useState();
  const [employeePhotos, setEmployeePhotos] = useState([]);
  const [routeIsAlreadyActive, setRouteIsAlreadyActive] = useState();
  const [displayChangesMenu, setDisplayChangesMenu] = useState(false);
  const [uneditedMarkersData, setUneditedMarkersData] = useState([]);
  const [changesData, setChangesData] = useState([]);
  const [moveData, setMoveData] = useState();
  const [multiStopMarkerData, setMultiStopMarkerData] = useState({});
  const [selectedSubMarker, setSelectedSubMarker] = useState();
  const [multiStopMarkerDisplay, setMultiStopMarkerDisplay] = useState(false);
  const [hiddenMarkers, setHiddenMarkers] = useState([]);
  const [defaultZoom, setDefaultZoom] = useState();
  const firstRun = useRef();
  const makingChange = useRef();
  const [loadingRoute, setLoadingRoute] = useState(false);
  const addToDayCooldown = useRef(false);
  const [globalBiWeeklyStartDate, setGlobalBiWeeklyStartDate] =
    useState("Bi-Weekly | A");
  const actionQueue = useRef([]);
  const isProcessingQueue = useRef(false);
  const debounceTimeout = useRef(null);
  const alreadySaved = useRef(false);
  const editedMarkers = useRef([]);
  const [dayViewData, setDayViewData] = useState({});
  const [dayViewDisplay, setDayViewDisplay] = useState(false);
  const editModeOrder = useRef([]);

  // gonna need to store these changes in the route db that way this state can be set if they leave routebuilder and come back.
  // for adding and removing data into the database when saving changes to a day add in the info for the stop being removed and the stop being added.
  // then also check to see if a change for the stop is already in the db, for example you edit a stop on a monday to a tuesday and hit save. Then go back to that tuesday and edit it to wed, it should change the existing
  // info in the db to monday => wed instead of having two sets of changes monday => tue and then tue => wed

  // I think if I store unedited markers in the db instead of locally that will also solve a lot of the weird bugs im running into.

  // Just wipe unedited markers on every activation like doing changes.

  useEffect(() => {
    if (props.navigationChanged !== "Route Builder") {
      return;
      // this dont work ofc because component is unmounted before this is called, gonna need a diff approach.
      if (hasUnactivatedChanges || !routeIsAlreadyActive) {
        const activate = window.confirm(
          "Would you like to activate your changes before leaving?"
        );
        if (activate) {
          console.log("Activating changes...");
        }
      }
    }
  }, [props.navigationChanged, hasUnactivatedChanges, routeIsAlreadyActive]);

  const unscheduleEmployeeAll = async (employeeUid) => {
    // need to set all markers that belong to the employee to unassigned like I do in the handleAddServiceLocationToDay function
    // then once that is done, just call handleSaveEditMode

    const batch = db.batch();

    for (const value of markers.current) {
      if (value.employeeUid === employeeUid && value.assigned) {
        // check if pending change for this stopId, if true dont allow marker to be deleted.
        console.log(value.markerId);
        const changeExists = changesData.some(
          (a) => a.subscriptionStopId === value.subscriptionStopId
        );

        if (changeExists) {
          toast.warning(
            "There is a pending change for this stop so it cannot be unassigned. Cancel the change to continue."
          );

          continue;
        }

        // need to find marker by marker ID, check to see if multiple markers for same service location, if yes set the value being edited to straight up delete the marker, if no then just set values to unassigned.
        // edit markers value straight up.

        // only 1 marker
        // just set values to unassigned state
        console.log("11111");
        let currentMarkers = markers.current;
        const indexOfMarker = currentMarkers.findIndex(
          (a) => a.markerId === value.markerId
        );

        currentMarkers[indexOfMarker].day = "none";
        currentMarkers[indexOfMarker].assigned = false;
        currentMarkers[indexOfMarker].employeeUid = "none";
        currentMarkers[indexOfMarker].employeePhoto = "none";
        if (editedMarkers.current.some((a) => a.markerId === value.markerId)) {
          // remove from assigned markers
          const indexOfAssignedMarker = editedMarkers.current.findIndex(
            (a) => a.markerId === value.markerId
          );
          editedMarkers.current.splice(indexOfAssignedMarker, 1);
          currentMarkers[indexOfMarker].edited = false;
        } else {
          currentMarkers[indexOfMarker].edited = true;
          editedMarkers.current.push(value);
        }

        await updateMarkers(currentMarkers);
        // remove stop from both employee and service location
      }
    }

    setRefresh(Math.random(0, 2));

    const employeeRoutesQuery = await db
      .collection("Businesses")
      .doc(selectedBusiness)
      .collection("Routes")
      .doc(selectedRoute.routeId)
      .collection("Employees")
      .doc(employeeUid)
      .collection("Routes")
      .get();

    for (const employeeRoute of employeeRoutesQuery.docs) {
      if (employeeRoute.id === "Pending") continue;
      batch.update(employeeRoute.ref, { route: [] });
    }

    const serviceLocationDoc = await db
      .collection("Businesses")
      .doc(selectedBusiness)
      .collection("Routes")
      .doc(selectedRoute.routeId)
      .collection("ServiceLocations")
      .get();

    for (const serviceLocation of serviceLocationDoc.docs) {
      const serviceLocationData = serviceLocation.data();
      const serviceLocationRoutes = serviceLocationData.routes;

      const filteredServiceLocationRoutes = serviceLocationRoutes.filter(
        (a) => a.employeeUid !== employeeUid
      );

      batch.update(serviceLocation.ref, {
        routes: filteredServiceLocationRoutes,
        assigned: filteredServiceLocationRoutes.length === 0 ? false : true,
      });
    }

    await batch.commit();
    if (selectedRoute.active) {
      await db
        .collection("Businesses")
        .doc(selectedBusiness)
        .collection("Routes")
        .doc(selectedRoute.routeId)
        .update({ unactivatedChanges: true });

      setHasUnactivatedChanges(true);
    }

    // go back

    handleGoBackDayView();
  };

  const handleSelectSubMarker = (index, value) => {
    if (index === selectedSubMarker) {
      setSelectedSubMarker();
      props.setHoverDisplayData({});
      props.setHoverDisplay(false);
      return;
    }
    setSelectedSubMarker(index);
    if (!editMode) {
      props.setHoverDisplayData({ value: value });
      props.setHoverDisplay(true);
    }
  };

  useEffect(() => {
    props.setServiceLocationsInRouteHome(serviceLocationsInRoute);
  }, [serviceLocationsInRoute]);

  useEffect(() => {
    const storedZoomValue = localStorage.getItem("mapZoom");
    if (storedZoomValue !== null) {
      const zoom = parseInt(storedZoomValue, 10);
      // Use the zoom value as needed
      setDefaultZoom(zoom);
    } else {
      setDefaultZoom(11);
    }
  }, []);

  useEffect(() => {
    const py6Element = document.getElementsByClassName("py-6")[0];
    setMapWidth(document.getElementsByClassName("py-6")[0].offsetWidth + "px");
    setLoadingRoute(true);
    markers.current = [];
    makingChange.current = false;

    const businessesRef = db.collection("Businesses").doc(selectedBusiness);
    const routeRef = businessesRef
      .collection("Routes")
      .doc(selectedRoute.routeId);
    const serviceLocationsRef = routeRef.collection("ServiceLocations");

    // Fetch all customers related to the selected business
    const allCustomersPromise = db
      .collection("Customers")
      .where("businessIds", "array-contains", selectedBusiness)
      .get()
      .then((allCustomersQuery) => {
        return Promise.all(
          allCustomersQuery.docs.map(async (customerDoc) => {
            const serviceLocationsQuery = await db
              .collection("Customers")
              .doc(customerDoc.id)
              .collection("ServiceLocations")
              .where("businessId", "==", selectedBusiness)
              .get();
            return serviceLocationsQuery.docs.map((serviceLocationDoc) => {
              return serviceLocationDoc.data();
            });
          })
        );
      });

    const promise1 = routeRef.get();
    const promise2 = businessesRef.get();
    const promise3 = serviceLocationsRef.get();
    const promise4 = allCustomersPromise; // Add the new promise here

    firstRun.current = true;
    Promise.all([promise1, promise2, promise3, promise4]).then(
      async ([
        routeSnapshot,
        businessSnapshot,
        serviceLocationsSnapshot,
        allCustomerServiceLocations,
      ]) => {
        const routeData = routeSnapshot.data();
        const businessData = businessSnapshot.data();
        console.log(routeData);
        setGlobalBiWeeklyStartDate(
          routeData.globalBiWeeklyStartDate || "Bi-Weekly | A"
        );

        setRouteIsAlreadyActive(routeData.active);
        setHasUnactivatedChanges(routeData.unactivatedChanges);

        // Geocode business service area zip code
        Geocoder.from(businessData.serviceAreaZipcode)
          .then((json) => {
            var location = json.results[0].geometry.location;
            setMapCenterCords(location);
          })
          .catch((error) => console.warn(error));

        let arrOfServiceLocations = [];

        serviceLocationsSnapshot.docs.forEach((serviceLocationDoc) => {
          arrOfServiceLocations.push(serviceLocationDoc.data());
        });

        const serviceLocationIdsSet = new Set(
          arrOfServiceLocations.map((loc) => loc.serviceLocationId)
        );
        let batch = db.batch();
        let batchCount = 0;

        const handleBatchWrite = async () => {
          batchCount++;

          if (batchCount >= 480) {
            await batch.commit();

            batch = db.batch();
            batchCount = 0;
          }
        };

        const handleAddLocationToRoute = async (
          selectedServiceLocation,
          subscription
        ) => {
          if (!selectedServiceLocation) {
            console.log("No service location selected");
            return;
          }

          if (!subscription) {
            console.log("No subscription selected");
            return;
          }

          try {
            //setLoading(true);

            const routeServiceLocationSnapshot = await db
              .collection("Businesses")
              .doc(selectedBusiness)
              .collection("Routes")
              .doc(selectedRoute.routeId)
              .collection("ServiceLocations")
              .doc(
                selectedServiceLocation.serviceLocationId +
                  subscription.subscriptionStopId
              )
              .get();

            if (routeServiceLocationSnapshot.exists) {
              console.log("Service location already exists in route");
              return;
            }

            const geocodeResult = await Geocoder.from(
              selectedServiceLocation.address
            );
            const locationCoordinates =
              geocodeResult.results[0].geometry.location;

            const stopTemplateSnapshot = await db
              .collection("Businesses")
              .doc(selectedBusiness)
              .collection("RecurringStopTemplates")
              .doc(subscription.connectedStopTemplateId)
              .get();

            let stopTemplateData = stopTemplateSnapshot.data();

            // add the custom name to stopName here

            stopTemplateData.stopName = subscription.subscriptionTitle;

            const serviceLocationIdWithSub = `${selectedServiceLocation.serviceLocationId}${subscription.subscriptionStopId}`;

            const serviceLocationRef = db
              .collection("Businesses")
              .doc(selectedBusiness)
              .collection("Routes")
              .doc(selectedRoute.routeId)
              .collection("ServiceLocations")
              .doc(serviceLocationIdWithSub);

            batch.set(serviceLocationRef, {
              assigned: false,
              recurringStopType: stopTemplateData,
              coordinates: locationCoordinates,
              serviceLocationId: selectedServiceLocation.serviceLocationId,
              customerId: selectedServiceLocation.customerId,
              routes: [],
              name: selectedServiceLocation.name,
              address: selectedServiceLocation.address,
              subscriptionStopId: subscription.subscriptionStopId,
            });

            await handleBatchWrite();

            return {
              assigned: false,
              recurringStopType: stopTemplateData,
              coordinates: locationCoordinates,
              serviceLocationId: selectedServiceLocation.serviceLocationId,
              customerId: selectedServiceLocation.customerId,
              routes: [],
              name: selectedServiceLocation.name,
              address: selectedServiceLocation.address,
              subscriptionStopId: subscription.subscriptionStopId,
            };
          } catch (error) {
            console.error("Error adding service location to route:", error);
            return;
          } finally {
            //setLoading(false);
          }
        };

        const allPromises = [];

        for (const customerServiceLocations of allCustomerServiceLocations) {
          for (const serviceLocation of customerServiceLocations) {
            // Handle multiple subscriptions in parallel
            const promises = serviceLocation.subscriptions.map(async (sub) => {
              const newServiceLocation = await handleAddLocationToRoute(
                serviceLocation,
                sub
              );
              if (newServiceLocation) {
                arrOfServiceLocations.push(newServiceLocation);
              }
            });
            allPromises.push(...promises); // Add all promises to the main array
          }
        }

        // Use Promise.all to wait for all operations to complete
        await Promise.all(allPromises);
        if (batchCount > 0) {
          await batch.commit();
          batchCount = 0;
        }

        setServiceLocationsInRoute(arrOfServiceLocations);
        getAllPoolAddresses(arrOfServiceLocations);
      }
    );

    const sub = businessesRef
      .collection("Routes")
      .doc(selectedRoute.routeId)
      .onSnapshot((snapshot) => {
        const newChangesData = snapshot.data().changes;
        setChangesData(newChangesData ? newChangesData : []);

        if (!firstRun.current && !makingChange.current) {
          console.log("shouldnt run on first");
          handleReloadData();
        }
      });

    return () => sub();
  }, []);

  const handleReloadData = () => {
    db.collection("Businesses")
      .doc(selectedBusiness)
      .collection("Routes")
      .doc(selectedRoute.routeId)
      .collection("ServiceLocations")
      .get()
      .then((snapshot) => {
        let arrOfServiceLocations = [];

        snapshot.docs.forEach((serviceLocationDoc, index) => {
          const serviceLocationData = serviceLocationDoc.data();

          arrOfServiceLocations.push(serviceLocationData);
        });
        setTimeout(() => {
          setServiceLocationsInRoute(arrOfServiceLocations);
          getAllPoolAddresses(arrOfServiceLocations);
        }, 1000);
      });
  };

  window.addEventListener("resize", (event) => {
    setMapWidth(document.getElementsByClassName("py-6")[0].offsetWidth + "px");
  });

  const getSubscriptionName = async (stopTemplateId) => {
    const subscription = await db
      .collection("Businesses")
      .doc(selectedBusiness)
      .collection("Subscriptions")
      .where("connectedStopTemplateId", "==", stopTemplateId)
      .get();

    return subscription.docs[0].data().subscriptionTitle;
  };

  const getAllPoolAddresses = async (serviceLocations) => {
    const pausedStops = serviceLocations.map(async (value) => {
      console.log(value);
      const serviceLocationDoc = await db
        .collection("Customers")
        .doc(value.customerId)
        .collection("ServiceLocations")
        .doc(value.serviceLocationId)
        .get();
      const subscriptions = serviceLocationDoc.data().subscriptions;
      const pausedSubscription = subscriptions.find(
        (sub) =>
          sub.connectedStopTemplateId ===
            value.recurringStopType.stopTemplateId && sub.paused
      );
      return pausedSubscription ? value : null;
    });

    const allPausedStops = await Promise.all(pausedStops);
    const pausedStopsArr = allPausedStops.filter((item) => item !== null);
    console.log(pausedStopsArr);

    const addArrPromises = serviceLocations.map(async (serviceLocationData) => {
      const location = serviceLocationData.coordinates;

      // Prepare the recurringStopType subscriptionTitle promises
      const stopNamePromise = getSubscriptionName(
        serviceLocationData.recurringStopType?.stopTemplateId
      ).then((subscriptionTitle) => {
        if (serviceLocationData.recurringStopType) {
          serviceLocationData.recurringStopType.stopName = subscriptionTitle;
        }
      });

      if (serviceLocationData.assigned) {
        // Prepare route processing promises
        const routePromises = serviceLocationData.routes.map(async (route) => {
          const employee = employeePhotos.find(
            (a) => a.uid === route.employeeUid
          );

          await stopNamePromise;

          return {
            location,
            name: serviceLocationData.name.replace(/  +/g, " "),
            address: serviceLocationData.address,
            serviceLocationId: serviceLocationData.serviceLocationId,
            routeId: selectedRoute.routeId,
            customerId: serviceLocationData.customerId,
            assigned: serviceLocationData.assigned,
            day: route.day ? route.day : "none",
            employeePhoto: employee ? employee.profilePhoto : "",
            employeeUid: route.employeeUid || "none",
            employeeName: route.employeeName,
            recurringStopType: serviceLocationData.recurringStopType,
            stopServiceInterval:
              serviceLocationData.recurringStopType.stopServiceInterval,
            markerId: route.markerId,
            stopId: route.stopId || generateBusinessId(),
            stopCount:
              serviceLocationData.recurringStopType?.stopIntervalPerWeek,
            edited: false,
            hidden: false,
            subscriptionStopId: serviceLocationData.subscriptionStopId,
            subscriptionId:
              serviceLocationData.recurringStopType?.connectedSubscriptionId,
          };
        });

        return Promise.all(routePromises);
      } else {
        const markerId = generateBusinessId();

        await stopNamePromise;

        return {
          location,
          name: serviceLocationData.name.replace(/  +/g, " "),
          address: serviceLocationData.address,
          serviceLocationId: serviceLocationData.serviceLocationId,
          routeId: selectedRoute.routeId,
          customerId: serviceLocationData.customerId,
          assigned: serviceLocationData.assigned,
          day: "none",
          employeePhoto: "none",
          employeeUid: "none",
          employeeName: "none",
          stopCount: serviceLocationData.recurringStopType?.stopIntervalPerWeek,
          recurringStopType: serviceLocationData.recurringStopType,
          stopServiceInterval:
            serviceLocationData.recurringStopType.stopServiceInterval,
          markerId,
          edited: false,
          hidden: false,
          subscriptionStopId: serviceLocationData.subscriptionStopId,
          subscriptionId:
            serviceLocationData.recurringStopType?.connectedSubscriptionId,
        };
      }
    });

    const addArrNested = await Promise.all(addArrPromises);
    const addArr = addArrNested.flat();

    if (firstRun.current && markers.current.length > 0) {
      firstRun.current = false;
      setUneditedMarkersData(markers.current);
    }
    // filtered by if has paused subscription
    markers.current = addArr.filter(
      (a) =>
        !pausedStopsArr.some(
          (b) => b.subscriptionStopId === a.subscriptionStopId
        )
    );
    setLoadingRoute(false);
    setRefresh(Math.random(0, 2));
    setSavingChangesDisplay(false);
  };

  const handleApiLoaded = (map, maps) => {
    if (mapsObject === undefined && mapObject === undefined) {
      setMapsObject(maps);
      setMapObject(map);
    }
  };

  const checkRouteNumber = (marker) => {
    if (markers.current.includes(marker) && marker.assigned) {
      const orderNumber = markers.current.indexOf(marker);
      return orderNumber + 1;
    } else {
      return "X";
    }
  };

  const handleEditMode = (day, employee) => {
    if (editMode) {
      handleCancelEditMode();
    } else {
      setEditModeDay(day);
      setEditModeDayEmployee(employee);
      setEditMode(true);
    }
  };

  const handleSaveEditMode = async () => {
    setSavingChangesDisplay(true);

    editedMarkers.current = [];

    const addedStops = [];

    if (alreadySaved.current === true) return;

    alreadySaved.current = true;

    // check to see if isProcessingQueue.current is true, if it is, wait for it to finish before continuing

    while (isProcessingQueue.current) {
      await new Promise((resolve) => setTimeout(resolve, 1000));
    }

    const batch = db.batch();

    for (const value of markers.current) {
      if (
        value.assigned &&
        value.day === editModeDay &&
        value.employeeUid === editModeEmployee.uid &&
        value.edited
      ) {
        console.log("value", value);
        const serviceLocationRef = db
          .collection("Businesses")
          .doc(selectedBusiness)
          .collection("Routes")
          .doc(selectedRoute.routeId)
          .collection("ServiceLocations")
          .doc(value.serviceLocationId + value.subscriptionStopId);

        // need to use stopId to find original data for the from value
        const employeeRef = db
          .collection("Businesses")
          .doc(selectedBusiness)
          .collection("Routes")
          .doc(selectedRoute.routeId)
          .collection("Employees")
          .doc(editModeEmployee.uid)
          .collection("Routes")
          .doc(editModeDay);

        // need to get the route data, then add the value, then sort by editModeOrder.current, then just update route object in firestore

        addedStops.push(employeeRef);

        batch.set(
          employeeRef,
          {
            route: firebase.firestore.FieldValue.arrayUnion(value),
          },
          { merge: true }
        );

        batch.update(serviceLocationRef, {
          assigned: value.assigned,
          routes: firebase.firestore.FieldValue.arrayUnion({
            day: value.day,
            employeePhoto: value.employeePhoto,
            employeeUid: value.employeeUid,
            recurringStopType: value.recurringStopType,
            employeeName: value.employeeName,
            markerId: value.markerId,
            stopId: value.stopId,
            subscriptionStopId: value.subscriptionStopId,
          }),
        });
      } else if (!value.assigned && value.day === "none" && value.edited) {
        console.log("value", value);
        const employeeRef = db
          .collection("Businesses")
          .doc(selectedBusiness)
          .collection("Routes")
          .doc(selectedRoute.routeId)
          .collection("Employees")
          .doc(editModeEmployee.uid)
          .collection("Routes")
          .doc(editModeDay);

        const serviceLocationRef = db
          .collection("Businesses")
          .doc(selectedBusiness)
          .collection("Routes")
          .doc(selectedRoute.routeId)
          .collection("ServiceLocations")
          .doc(value.serviceLocationId + value.subscriptionStopId);

        const employeeSnapshot = await employeeRef.get();

        if (employeeSnapshot.exists) {
          let employeeData = employeeSnapshot.data();

          const indexOfCurrentRoute = employeeData.route.findIndex(
            (a) => a.markerId === value.markerId
          );

          employeeData.route.splice(indexOfCurrentRoute, 1);

          employeeRef.update({ route: employeeData.route });
        }

        const serviceLocationSnapshot = await serviceLocationRef.get();
        let serviceLocationData = serviceLocationSnapshot.data();
        const indexOfCurrentServiceLocationRoute =
          serviceLocationData.routes.findIndex(
            (a) => a.markerId === value.markerId
          );
        serviceLocationData.routes.splice(
          indexOfCurrentServiceLocationRoute,
          1
        );
        batch.update(serviceLocationRef, {
          routes: serviceLocationData.routes,
          assigned: false,
        });
      }
    }

    const employeeRef = db
      .collection("Businesses")
      .doc(selectedBusiness)
      .collection("Routes")
      .doc(selectedRoute.routeId)
      .collection("Employees")
      .doc(editModeEmployee.uid)
      .collection("Routes")
      .doc(editModeDay);

    const employeeSnapshot = await employeeRef.get();

    let employeeData = employeeSnapshot.data();

    if (deletedMarkersRef.current) {
      for (let index = 0; index < deletedMarkersRef.current.length; index++) {
        const deletedMarker = deletedMarkersRef.current[index];
        console.log("deletedMarker", deletedMarker);

        const serviceLocationRef = db
          .collection("Businesses")
          .doc(selectedBusiness)
          .collection("Routes")
          .doc(selectedRoute.routeId)
          .collection("ServiceLocations")
          .doc(
            deletedMarker.serviceLocationId + deletedMarker.subscriptionStopId
          );

        if (employeeSnapshot.exists) {
          employeeData.route = employeeData.route.filter(
            (a) =>
              a.serviceLocationId !== deletedMarker.serviceLocationId &&
              a.subscriptionStopId !== deletedMarker.subscriptionStopId
          );

          batch.update(employeeRef, { route: employeeData.route });
        }

        const serviceLocationSnapshot = await serviceLocationRef.get();
        let serviceLocationData = serviceLocationSnapshot.data();

        let newRoutes = serviceLocationData.routes.filter(
          (a) => a.stopId !== deletedMarker.stopId
        );
        batch.update(serviceLocationRef, {
          routes: newRoutes,
        });
      }
    }

    await batch.commit();

    // using editModeOrder I need to get all the edited markers and their employee and then update the order of route in the employee doc for that day by sorting it by editModeOrder.current
    console.log(editModeOrder.current);
    for (const addedStop of addedStops) {
      const addedStopSnapshot = await addedStop.get();
      const addedStopData = addedStopSnapshot.data();
      console.log(addedStopData);
      const sortedRoute = addedStopData.route.sort((a, b) => {
        return (
          editModeOrder.current.indexOf(a.markerId) -
          editModeOrder.current.indexOf(b.markerId)
        );
      });

      await addedStop.update({ route: sortedRoute });
    }

    if (selectedRoute.active) {
      setHasUnactivatedChanges(true);
      await db
        .collection("Businesses")
        .doc(selectedBusiness)
        .collection("Routes")
        .doc(selectedRoute.routeId)
        .update({ unactivatedChanges: true });
    }
    setEditModeDay("");
    setEditModeDayEmployee({});
    deletedMarkersRef.current = [];
    alreadySaved.current = false;
    handleReloadData();
    setEditMode(false);
    editModeOrder.current = [];
  };

  const processQueue = async () => {
    if (isProcessingQueue.current || actionQueue.current.length === 0) return;

    isProcessingQueue.current = true;
    const { value, e } = actionQueue.current.shift();

    await handleAddServiceLocationToDay(value, e);

    isProcessingQueue.current = false;
    processQueue();
  };

  const debounceInterval = 100;

  const enqueueAction = (value, e) => {
    clearTimeout(debounceTimeout.current);
    debounceTimeout.current = setTimeout(() => {
      // check to make sure the action is not already in the queue
      const actionExists = actionQueue.current.some(
        (a) =>
          a.value.markerId === value.markerId || a.value.stopId === value.stopId
      );
      if (actionExists) {
        return;
      }
      actionQueue.current.push({ value, e });
      processQueue();
    }, debounceInterval);
  };

  // THE ISSUE IS THAT FOR SOME REASON I HAVE EXTRA STOPS GOING INTO MARKERS.CURRENT WHEN YOU SPAM ADD AND REMOVE MARKERS.

  function getNextOccurrenceOfDay(day) {
    const daysOfWeek = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    const targetDayIndex = daysOfWeek.indexOf(day);
    if (targetDayIndex === -1) {
      throw new Error("Invalid day provided");
    }

    const today = new Date();
    const currentDayIndex = today.getDay();
    const daysUntilNext = (targetDayIndex - currentDayIndex + 7) % 7 || 7;

    const nextDate = new Date();
    nextDate.setDate(today.getDate() + daysUntilNext);

    return `${
      nextDate.getMonth() + 1
    }/${nextDate.getDate()}/${nextDate.getFullYear()}`;
  }

  const updateMarkers = async (markersUpdate) => {
    return new Promise((resolve) => {
      // Perform updates to markers.current
      markers.current = markersUpdate;

      // Small timeout to ensure any updates are propagated
      setTimeout(() => {
        resolve();
      }, 0); // Minimal delay to flush synchronous tasks
    });
  };

  const handleAddServiceLocationToDay = async (value, e) => {
    // so the issue is if you remove and readd a stop in a single edit mode session it just adds the stop as a new stop even tho the stop already existed.

    // need to check if stop already exists in the day, if it does then just remove it from the day and add it back in.

    // the tricky part is the stopId between the existing stop and the new stop being added will be different.

    // I may have to check to see if a stop already exists with the same markerId / employeeUid / subscriptionId and if it does then just remove it from the day and add it back in.

    // need a cooldown because you can spam the hell out of this and fuck it up

    if (e.nativeEvent.which === 3) {
      if (editMode && value.assigned) {
        console.log(value);
        editModeOrder.current = editModeOrder.current.filter(
          (a) => a !== value.markerId
        );
        // check if pending change for this stopId, if true dont allow marker to be deleted.

        const changeExists = changesData.some(
          (a) => a.subscriptionStopId === value.subscriptionStopId
        );

        if (changeExists) {
          return toast.warning(
            "There is a pending change for this stop so it cannot be unassigned. Cancel the change to continue."
          );
        }

        if (
          markers.current
            .filter((a) => a.subscriptionStopId === value.subscriptionStopId)
            .findIndex(
              (a) =>
                a.day === editModeDay && a.employeeUid === editModeEmployee.uid
            ) !== -1
        ) {
          // need to find marker by marker ID, check to see if multiple markers for same service location, if yes set the value being edited to straight up delete the marker, if no then just set values to unassigned.
          // edit markers value straight up.

          if (
            markers.current.filter(
              (a) =>
                a.serviceLocationId === value.serviceLocationId &&
                a.subscriptionStopId === value.subscriptionStopId
            ).length > 1
          ) {
            // more than one marker
            // remove marker entirely from markers

            // check if the marker is for the current edit day

            if (value.day !== editModeDay) return;

            console.log("removing");

            editedMarkers.current.push(value);

            let currentMarkers = markers.current;

            const indexOfMarker = currentMarkers.findIndex(
              (a) => a.markerId === value.markerId
            );

            currentMarkers.splice(indexOfMarker, 1);

            // just make a deleted markers array
            let currentDeletedMarkers = deletedMarkersRef.current;
            if (
              !currentDeletedMarkers.some((a) => a.markerId === value.markerId)
            ) {
              currentDeletedMarkers.push(value);

              deletedMarkersRef.current = currentDeletedMarkers;
            }

            await updateMarkers(currentMarkers);
            setRefresh(Math.random(0, 8));
          } else {
            // only 1 marker
            // just set values to unassigned state

            let currentMarkers = markers.current;
            const indexOfMarker = currentMarkers.findIndex(
              (a) => a.markerId === value.markerId
            );

            currentMarkers[indexOfMarker].day = "none";
            currentMarkers[indexOfMarker].assigned = false;
            currentMarkers[indexOfMarker].employeeUid = "none";
            currentMarkers[indexOfMarker].employeePhoto = "none";
            if (
              editedMarkers.current.some((a) => a.markerId === value.markerId)
            ) {
              // remove from assigned markers
              const indexOfAssignedMarker = editedMarkers.current.findIndex(
                (a) => a.markerId === value.markerId
              );
              editedMarkers.current.splice(indexOfAssignedMarker, 1);
              currentMarkers[indexOfMarker].edited = false;
            } else {
              currentMarkers[indexOfMarker].edited = true;
              editedMarkers.current.push(value);
            }

            await updateMarkers(currentMarkers);
            setRefresh(Math.random(0, 2));
          }
        }
      }
    } else {
      console.log("HERERERE");
      console.log(value);
      console.log(value.markerId);
      editModeOrder.current.push(value.markerId);
      console.log(editModeOrder.current);
      if (editMode && !value.assigned) {
        // checking to see if sub is paused

        // remove from deleted markers if it exists in there

        const alreadyExistingActiveStop = uneditedMarkersData.some(
          (a) =>
            a.markerId === value.markerId &&
            a.subscriptionId === value.subscriptionId &&
            a.day === editModeDay &&
            a.employeeUid === editModeEmployee.uid
        );

        if (alreadyExistingActiveStop) {
          let currentMarkers = markers.current;

          const indexOfCurrentMarker = currentMarkers.findIndex(
            (a) => a.markerId === value.markerId
          );

          // I need to try and find the original stopId here

          currentMarkers[indexOfCurrentMarker].day = editModeDay;
          currentMarkers[indexOfCurrentMarker].assigned = true;
          currentMarkers[indexOfCurrentMarker].employeePhoto =
            await handleProfilePhoto(editModeEmployee.uid);
          currentMarkers[indexOfCurrentMarker].employeeUid =
            editModeEmployee.uid;
          currentMarkers[indexOfCurrentMarker].employeeName =
            editModeEmployee.name;
          if (
            editedMarkers.current.some((a) => a.markerId === value.markerId)
          ) {
            // remove from unassigned markers
            const indexOfUnassignedMarker = editedMarkers.current.findIndex(
              (a) => a.markerId === value.markerId
            );
            editedMarkers.current.splice(indexOfUnassignedMarker, 1);
            currentMarkers[indexOfCurrentMarker].edited = false;
          } else {
            currentMarkers[indexOfCurrentMarker].edited = true;

            editedMarkers.current.push(value);
          }

          let currentDeletedMarkers = deletedMarkersRef.current;

          const indexOfDeletedMarker = currentDeletedMarkers.findIndex(
            (a) => a.markerId === value.markerId
          );

          if (indexOfDeletedMarker !== -1) {
            currentDeletedMarkers.splice(indexOfDeletedMarker, 1);
            deletedMarkersRef.current = currentDeletedMarkers;
          }

          await updateMarkers(currentMarkers);
          setRefresh(Math.random(0, 2));

          return;
        }

        const customerDoc = await db
          .collection("Customers")
          .doc(value.customerId)
          .collection("ServiceLocations")
          .doc(value.serviceLocationId)
          .get();

        const paused = customerDoc
          .data()
          .subscriptions.find(
            (a) => a.subscriptionStopId === value.subscriptionStopId
          );

        if (paused?.paused) {
          return toast.warning(
            "The subscription on this service location is paused, resume the subscription to assign this stop!"
          );
        }

        let currentDeletedMarkers = deletedMarkersRef.current;

        const indexOfDeletedMarker = currentDeletedMarkers.findIndex(
          (a) => a.markerId === value.markerId
        );

        if (indexOfDeletedMarker !== -1) {
          currentDeletedMarkers.splice(indexOfDeletedMarker, 1);
          deletedMarkersRef.current = currentDeletedMarkers;
        }

        const stopTemplateId = value.recurringStopType.stopTemplateId;

        const stopTemplateDoc = await db
          .collection("Businesses")
          .doc(selectedBusiness)
          .collection("RecurringStopTemplates")
          .doc(stopTemplateId)
          .get();

        const timesPerWeek = stopTemplateDoc.data().stopIntervalPerWeek;

        // need to find how many stops already exist for the marker

        let currentExistingStopsForServiceLocation = markers.current.filter(
          (a) =>
            a.serviceLocationId === value.serviceLocationId &&
            a.assigned &&
            a.recurringStopType.connectedSubscriptionId ===
              value.recurringStopType.connectedSubscriptionId &&
            a.subscriptionStopId === value.subscriptionStopId
        );

        if (currentExistingStopsForServiceLocation.length >= timesPerWeek) {
          return toast.warning(
            `The subscription for this service location only allows ${timesPerWeek} stops per week.`
          );
        }

        value.employeeUid = editModeEmployee.uid;
        value.assigned = true;
        value.day = editModeDay;
        value.employeePhoto = editModeEmployee.photo;

        let currentMarkers = markers.current;

        const indexOfCurrentMarker = currentMarkers.findIndex(
          (a) => a.markerId === value.markerId
        );

        // I need to try and find the original stopId here

        console.log(currentMarkers[indexOfCurrentMarker]);

        currentMarkers[indexOfCurrentMarker].day = editModeDay;
        currentMarkers[indexOfCurrentMarker].assigned = true;
        currentMarkers[indexOfCurrentMarker].employeePhoto =
          await handleProfilePhoto(editModeEmployee.uid);
        currentMarkers[indexOfCurrentMarker].employeeUid = editModeEmployee.uid;
        currentMarkers[indexOfCurrentMarker].employeeName =
          editModeEmployee.name;

        if (editedMarkers.current.some((a) => a.markerId === value.markerId)) {
          const indexOfUnassignedMarker = editedMarkers.current.findIndex(
            (a) => a.markerId === value.markerId
          );
          editedMarkers.current.splice(indexOfUnassignedMarker, 1);
          currentMarkers[indexOfCurrentMarker].edited = false;
        } else {
          currentMarkers[indexOfCurrentMarker].edited = true;

          editedMarkers.current.push(value);
        }
        currentMarkers[indexOfCurrentMarker].stopId = generateBusinessId();

        await updateMarkers(currentMarkers);
        setRefresh(Math.random(0, 2));
      } else if (editMode && value.assigned) {
        // check to see if different day of the week
        // if different day, add as a second stop.

        // create a new marker entirely inside of markers.

        // check to make sure not already a marker for the location for the current subscription on the same day

        // need to check if subscription is paused on service location

        const customerDoc = await db
          .collection("Customers")
          .doc(value.customerId)
          .collection("ServiceLocations")
          .doc(value.serviceLocationId)
          .get();

        const paused = customerDoc.data().subscriptions.paused;

        if (paused) {
          return toast.warning(
            "The subscription on this service location is paused, resume the subscription to assign this stop!"
          );
        }

        const stopTemplateId = value.recurringStopType.stopTemplateId;

        const stopTemplateDoc = await db
          .collection("Businesses")
          .doc(selectedBusiness)
          .collection("RecurringStopTemplates")
          .doc(stopTemplateId)
          .get();

        const timesPerWeek = stopTemplateDoc.data().stopIntervalPerWeek;

        // need to find how many stops already exist for the marker

        let currentExistingStopsForServiceLocation = markers.current.filter(
          (a) =>
            a.serviceLocationId === value.serviceLocationId &&
            a.assigned &&
            a.recurringStopType.connectedSubscriptionId ===
              value.recurringStopType.connectedSubscriptionId &&
            value.subscriptionStopId === a.subscriptionStopId
        );

        if (currentExistingStopsForServiceLocation.length >= timesPerWeek) {
          return toast.warning(
            `The subscription for this service location only allows ${timesPerWeek} stops per week.`
          );
        }

        let currentMarkers = markers.current;

        const matchingMarkers = currentMarkers.filter(
          (a) =>
            a.serviceLocationId === value.serviceLocationId &&
            a.subscriptionId === value.subscriptionId &&
            a.subscriptionStopId === value.subscriptionStopId
        );

        console.log("adding");

        console.log(editedMarkers.current);

        console.log(matchingMarkers);

        if (matchingMarkers.length > 0) {
          if (!matchingMarkers.some((a) => a.day === editModeDay)) {
            let currentDeletedMarkers = deletedMarkersRef.current;

            const indexOfDeletedMarker = currentDeletedMarkers.findIndex(
              (a) => a.subscriptionStopId === value.subscriptionStopId
            );
            console.log(indexOfDeletedMarker);
            if (indexOfDeletedMarker !== -1) {
              currentDeletedMarkers.splice(indexOfDeletedMarker, 1);
              deletedMarkersRef.current = currentDeletedMarkers;
            }

            if (
              editedMarkers.current.some(
                (a) => a.subscriptionStopId === value.subscriptionStopId
              )
            ) {
              let editedMarker = editedMarkers.current.find(
                (a) => a.subscriptionStopId === value.subscriptionStopId
              );

              console.log("HEREREREE");

              editedMarker.edited = true;

              currentMarkers.push(editedMarker);
              // remove from edited markers

              const indexOfEditedMarker = editedMarkers.current.findIndex(
                (a) => a.subscriptionStopId === value.subscriptionStopId
              );

              if (indexOfEditedMarker !== -1) {
                editedMarkers.current.splice(indexOfEditedMarker, 1);
              }
            } else {
              const newValue = {
                location: value.location,
                name: value.name.replace(/  +/g, " "),
                address: value.address,
                serviceLocationId: value.serviceLocationId,
                routeId: selectedRoute.routeId,
                customerId: value.customerId,
                assigned: true,
                day: editModeDay,
                employeePhoto: await handleProfilePhoto(editModeEmployee.uid),
                employeeUid: editModeEmployee.uid,
                employeeName: editModeEmployee.name,
                recurringStopType: value.recurringStopType,
                markerId: generateBusinessId(),
                stopId: generateBusinessId(),
                edited: true,
                subscriptionId: value.recurringStopType
                  ? value.recurringStopType.connectedSubscriptionId
                  : undefined,
                subscriptionStopId: value.subscriptionStopId,
              };
              currentMarkers.push(newValue);
              editedMarkers.current.push(newValue);
            }

            await updateMarkers(currentMarkers);

            setRefresh(Math.random(0, 2));
          } else {
            toast.warn(
              "Recurring stop already scheduled on this date for this service location and subscription."
            );
          }
        } else {
          let currentMarkers = markers.current;

          const newValue = {
            location: value.location,
            name: value.name.replace(/  +/g, " "),
            address: value.address,
            serviceLocationId: value.serviceLocationId,
            routeId: selectedRoute.routeId,
            customerId: value.customerId,
            assigned: true,
            day: editModeDay,
            employeePhoto: await handleProfilePhoto(editModeEmployee.uid),
            employeeUid: editModeEmployee.uid,
            employeeName: editModeEmployee.name,
            recurringStopType: value.recurringStopType,
            markerId: generateBusinessId(),
            edited: true,
            stopId: generateBusinessId(),
            subscriptionId: value.recurringStopType
              ? value.recurringStopType.connectedSubscriptionId
              : undefined,
            subscriptionStopId: value.subscriptionStopId,
          };

          currentMarkers.push(newValue);
          editedMarkers.current.push(newValue);

          await updateMarkers(currentMarkers);

          setRefresh(Math.random(0, 2));
        }
      }
      // if already assigned and edit mode, setup multistop

      // cancelling needs to not wipe marker, but set back to previous state
    }
  };

  const handleCancelEditMode = () => {
    // set markers back to unedited value.
    // set serviceLocationsOrder.current
    handleReloadData();

    setEditModeDay("");
    setEditMode(false);
  };

  function getAdjustedLongitude(markers, currentMarker, index) {
    // Filter to find all markers at the same location
    const similarMarkers = markers.filter(
      (item) =>
        item.location.lat === currentMarker.location.lat &&
        item.location.lng === currentMarker.location.lng
    );
    // Find the index of the current marker in the list of similar markers
    const currentIndex = similarMarkers.findIndex(
      (item) => item.markerId === currentMarker.markerId
    );
    // Adjust longitude if there are overlaps
    return similarMarkers.length > 1
      ? currentMarker.location.lng + 0.00003 * (currentIndex + 1)
      : currentMarker.location.lng;
  }

  const handleOnHover = (value, index, position) => {
    const stopCount = value.value.stopCount;

    if (stopCount == 1) {
      if (editMode) {
        return;
      }
      if (
        props.hoverDisplayData.value &&
        props.hoverDisplayData.value.markerId === value.value.markerId
      ) {
        props.setHoverDisplayData({});
        props.setHoverDisplay(false);
        setMultiStopMarkerDisplay(false);
        setSelectedSubMarker();
      } else {
        props.setHoverDisplayData(value);
        props.setHoverDisplay(true);
        setMultiStopMarkerDisplay(false);
        setSelectedSubMarker();
      }
    } else {
      if (
        multiStopMarkerData.value &&
        multiStopMarkerData.value.markerId === value.value.markerId
      ) {
        setMultiStopMarkerData({});
        setMultiStopMarkerDisplay(false);
        setSelectedSubMarker();
      } else {
        setMultiStopMarkerData(value);
        props.setHoverDisplay(false);
        setMultiStopMarkerDisplay(true);
        props.setHoverDisplayData({});
        setSelectedSubMarker();
      }
    }
  };

  const handleEditModeOnHover = (value, index) => {
    const stopCount = value.value.stopCount;
    if (stopCount === 1) {
      if (!props.hoverDisplayData.value && multiStopMarkerDisplay === false) {
        props.setHoverDisplayData(value);
        props.setHoverDisplay(true);
        setMultiStopMarkerDisplay(false);
        setSelectedSubMarker();
      }
    }
  };

  const handleEditModeOnHoverMultistop = (value, index, assignedMarkers) => {
    if (!props.hoverDisplayData.value) {
      if (Object.keys(assignedMarkers[index]).length > 0) {
        props.setHoverDisplayData({ value: value });
        props.setHoverDisplay(true);
        setSelectedSubMarker();
      }
    }
  };

  const handleOnHoverExit = () => {
    props.setHoverDisplayData({});
    props.setHoverDisplay(false);
  };

  const handleMoveClick = (value) => {
    setMoveData(value);
    props.setHoverDisplayData({});
    props.setHoverDisplay(false);
    setDisplayChangesMenu(true);
  };

  const handleToggleHiddenDay = (day, boolean, employee) => {
    setHiddenMarkers((prevHiddenMarkers) => {
      if (boolean) {
        return prevHiddenMarkers.filter((a) => {
          for (let index = 0; index < markers.current.length; index++) {
            let marker = markers.current[index];
            if (marker.day === day && marker.employeeUid === employee) {
              if (a.markerId === marker.markerId) {
                return false; // Filter out the marker
              }
            }
          }
          return true; // Keep other markers
        });
      } else {
        for (let index = 0; index < markers.current.length; index++) {
          let marker = markers.current[index];
          if (marker.day === day && marker.employeeUid === employee) {
            prevHiddenMarkers.push(marker);
          }
        }
        return [...prevHiddenMarkers]; // Create a new array with the added markers
      }
    });

    setRefresh(Math.random(0, 2));
  };

  const handleToggleHiddenEmployee = (boolean, employee) => {
    setHiddenMarkers((prevHiddenMarkers) => {
      if (boolean) {
        return prevHiddenMarkers.filter((a) => {
          for (let index = 0; index < markers.current.length; index++) {
            let marker = markers.current[index];
            if (marker.employeeUid === employee) {
              if (a.markerId === marker.markerId) {
                return false; // Filter out the marker
              }
            }
          }
          return true; // Keep other markers
        });
      } else {
        for (let index = 0; index < markers.current.length; index++) {
          let marker = markers.current[index];
          if (marker.employeeUid === employee) {
            prevHiddenMarkers.push(marker);
          }
        }
        return [...prevHiddenMarkers]; // Create a new array with the added markers
      }
    });

    setRefresh(Math.random(0, 2));
  };

  const handleDeleteEmployee = (value) => {
    let totalCount = 0;
    // check to make sure the employee has no stops.
    if (totalCount === 0) {
      db.collection("Businesses")
        .doc(selectedBusiness)
        .collection("Routes")
        .doc(selectedRoute.routeId)
        .collection("Employees")
        .doc(value.uid)
        .delete();
    } else {
      alert(
        "Cannot delete an employee with stops! Please remove all stops and try again!"
      );
    }
  };

  const handleProfilePhoto = async (employeeUid) => {
    const employee = {
      uid: employeeUid,
      businessId: selectedBusiness,
    };
    const profilePhoto = await getProfilePhoto(employee);
    return profilePhoto;
  };

  const handleCancelChange = async (change) => {
    const currentRouteDoc = await db
      .collection("Businesses")
      .doc(selectedBusiness)
      .collection("Routes")
      .doc(selectedRoute.routeId)
      .get();

    const data = currentRouteDoc.data();

    let currentChanges = data.changes;

    let newChanges = currentChanges.filter(
      (a) => a.changeId !== change.changeId
    );

    await db
      .collection("Businesses")
      .doc(selectedBusiness)
      .collection("Routes")
      .doc(selectedRoute.routeId)
      .update({ changes: newChanges });
  };

  const handleZoomValue = (zoom) => {
    // Convert the zoom value to a string and store it in local storage
    localStorage.setItem("mapZoom", zoom.toString());
  };

  const handleViewDay = (data) => {
    setDayViewDisplay(true);
    console.log(data);
    setDayViewData(data);
  };

  const handleGoBackDayView = () => {
    setDayViewDisplay(false);
    setDayViewData({});
  };

  // I activated changes for a route with multistops
  // then I went and removed 2 stops from 2 different multistops and added to another day
  // then when I went to activate changes, the change planner says wed to wed for some reason
  // could have something to do with uneditedMarkersData maybe since thats only reset locally on activate changes
  // current route open is for that

  return (
    <div
      style={{
        height: "88%",
        pointerEvents: savingsChangesDisplay ? "none" : "initial",
      }}
    >
      <div>
        <AddEmployees
          handleDeleteEmployee={handleDeleteEmployee}
          open={props.employeesDisplay}
          setOpen={props.setEmployeesDisplay}
          selectedBusiness={selectedBusiness}
          selectedRoute={selectedRoute}
          employeesInRoute={employeesInRoute}
        />

        <AddServiceLocations
          serviceLocationsInRoute={serviceLocationsInRoute}
          setServiceLocationsInRoute={setServiceLocationsInRoute}
          handleReloadData={handleReloadData}
          open={props.addCustomersDisplay}
          setOpen={props.setAddCustomersDisplay}
          selectedBusiness={selectedBusiness}
          selectedRoute={selectedRoute}
          employeesInRoute={employeesInRoute}
          routeStartDate={routeStartDate}
          routeIsAlreadyActive={routeIsAlreadyActive}
          setHasUnactivatedChanges={setHasUnactivatedChanges}
          hasUnactivatedChanges={hasUnactivatedChanges}
          handleCloseRouteClick={props.handleCloseRouteClick}
          handleActivateRouteClick={props.handleActivateRouteClick}
          markers={markers.current}
        />

        <ActivateRoute
          setOpen={props.setActivateRouteDisplay}
          open={props.activateRouteDisplay}
          serviceLocationsInRoute={serviceLocationsInRoute}
          setActivateRouteDisplay={props.setActivateRouteDisplay}
          selectedBusiness={selectedBusiness}
          selectedRoute={selectedRoute}
          routeStartDate={routeStartDate}
          handleCloseRouteClick={props.handleCloseRouteClick}
          setRouteStartDate={setRouteStartDate}
          routeIsAlreadyActive={routeIsAlreadyActive}
          globalBiWeeklyStartDate={globalBiWeeklyStartDate}
        />

        {false && (
          <ActivateRouteMenu
            serviceLocationsInRoute={serviceLocationsInRoute}
            setActivateRouteDisplay={props.setActivateRouteDisplay}
            selectedBusiness={selectedBusiness}
            selectedRoute={selectedRoute}
            routeStartDate={routeStartDate}
            handleCloseRouteClick={props.handleCloseRouteClick}
          />
        )}
        <SideMenu
          setEmployeePhotos={setEmployeePhotos}
          selectedBusiness={selectedBusiness}
          selectedRoute={selectedRoute}
          setEmployeesInRoute={setEmployeesInRoute}
          employeesInRoute={employeesInRoute}
          serviceLocationsInRoute={serviceLocationsInRoute}
          setServiceLocationsInRoute={setServiceLocationsInRoute}
          handleEditMode={handleEditMode}
          editModeDay={editModeDay}
          editMode={editMode}
          handleToggleHiddenDay={handleToggleHiddenDay}
          setRouteStartDate={setRouteStartDate}
          handleReloadData={handleReloadData}
          handleAddCustomersClick={props.handleAddCustomersClick}
          handleActivateRouteClick={props.handleActivateRouteClick}
          handleEmployeesClick={props.handleEmployeesClick}
          handleCloseActiveRouteClick={props.handleCloseActiveRouteClick}
          handleCloseRouteClick={props.handleCloseRouteClick}
          searchTerm={props.searchTerm}
          setSearchTerm={props.setSearchTerm}
          handleRouteSave={props.handleRouteSave}
          hasUnactivatedChanges={hasUnactivatedChanges}
          routeStartDate={routeStartDate}
          setHasUnactivatedChanges={setHasUnactivatedChanges}
          handleCancelEditMode={handleCancelEditMode}
          routeIsAlreadyActive={routeIsAlreadyActive}
          setDisplayChangesMenu={setDisplayChangesMenu}
          markers={markers.current}
          refresh={refresh}
          handleToggleHiddenEmployee={handleToggleHiddenEmployee}
          setAddCustomersDisplay={props.setAddCustomersDisplay}
          globalBiWeeklyStartDate={globalBiWeeklyStartDate}
          setGlobalBiWeeklyStartDate={setGlobalBiWeeklyStartDate}
          unscheduleEmployeeAll={unscheduleEmployeeAll}
          dayViewData={dayViewData}
          handleViewDay={handleViewDay}
          handleGoBackDayView={handleGoBackDayView}
          dayViewDisplay={dayViewDisplay}
        />
        {editMode && (
          <div
            style={{
              width: "28%",
              height: "14%",
              position: "absolute",
              zIndex: 1000,
              margin: 20,
              right: 0,
              bottom: 0,
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <div
              onClick={() => handleCancelEditMode()}
              style={{
                marginRight: "2%",
                backgroundColor: "red",
                color: "white",
                height: 36,
                width: "32%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: 2,
                cursor: "pointer",
              }}
            >
              Cancel
            </div>
            <div
              onClick={() => handleSaveEditMode()}
              style={{
                marginRight: "2%",
                backgroundColor: "#00A6FF",
                color: "white",
                height: 36,
                width: "32%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: 2,
                cursor: "pointer",
                marginLeft: "2%",
              }}
            >
              Save
            </div>
            <div
              style={{
                width: 60,
                height: 60,
                border: "1px solid #E5E7EB",
                borderRadius: 50,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "#00A6FF",
                color: "white",
                marginLeft: "6%",
              }}
            >
              {
                markers.current.filter(
                  (a) =>
                    a.day === editModeDay &&
                    a.employeeUid === editModeEmployee.uid
                ).length
              }
            </div>
          </div>
        )}
        <div>
          {defaultZoom && mapCenterCords && (
            <GoogleMapReact
              bootstrapURLKeys={{
                key: process.env.REACT_APP_GOOGLE_MAPS_API_TOKEN,
                libraries: ["places"],
              }}
              options={{
                fullscreenControl: false,
                zoomControl: true,
                clickableIcons: false,
                disableDoubleClickZoom: true,
              }}
              defaultCenter={{
                lat: mapCenterCords.lat,
                lng: mapCenterCords.lng,
              }}
              defaultZoom={defaultZoom}
              onChange={({ center, zoom }) => {
                // Here you can access the new zoom value
                handleZoomValue(zoom);
              }}
              onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
              yesIWantToUseGoogleMapApiInternals
              style={{
                position: "absolute",
                right: 0,
                top: 0,
                width: mapWidth,
                height: "100vh",
              }}
            >
              {Object.values(
                markers.current
                  .filter(
                    (
                      (uniqueIds) => (marker) =>
                        props.searchTerm.length === 0
                          ? !uniqueIds.has(
                              marker.serviceLocationId +
                                "_" +
                                marker.subscriptionStopId
                            ) &&
                            uniqueIds.add(
                              marker.serviceLocationId +
                                "_" +
                                marker.subscriptionStopId
                            )
                          : marker.name
                              .toLowerCase()
                              .includes(props.searchTerm.toLowerCase()) &&
                            !uniqueIds.has(
                              marker.serviceLocationId +
                                "_" +
                                marker.subscriptionStopId
                            ) &&
                            uniqueIds.add(
                              marker.serviceLocationId +
                                "_" +
                                marker.subscriptionStopId
                            )
                    )(new Set())
                  )
                  .reduce((acc, marker) => {
                    const coordKey = `${marker.location.lat}_${marker.location.lng}`;
                    if (!acc[coordKey]) {
                      acc[coordKey] = {
                        lat: marker.location.lat,
                        lng: marker.location.lng,
                        markers: [],
                        markerId: marker.markerId,
                      };
                    }
                    acc[coordKey].markers.push(marker);

                    return acc;
                  }, {})
              ).map((groupedMarkers, index) => (
                <div
                  key={index}
                  lat={groupedMarkers.lat}
                  lng={groupedMarkers.lng}
                  style={{ display: "flex" }}
                >
                  {groupedMarkers.markers.map((value, index) => (
                    <div
                      key={value.markerId}
                      onClick={(e) => handleOnHover({ value: value }, index)}
                      onMouseDown={(e) => {
                        value.stopCount == 1
                          ? enqueueAction(value, e)
                          : console.log();
                      }}
                      onMouseOver={() =>
                        value.stopCount == 1 && editMode
                          ? handleEditModeOnHover({ value: value }, index)
                          : console.log()
                      }
                      onMouseLeave={() =>
                        value.stopCount == 1 && editMode
                          ? handleOnHoverExit()
                          : console.log()
                      }
                      style={{
                        display:
                          value.stopCount > 1
                            ? "flex"
                            : hiddenMarkers.some(
                                (a) => a.markerId === value.markerId
                              )
                            ? "none"
                            : "flex",
                        width: 40,
                        height: 40,
                        border: "4px solid rgb(41, 186, 230)",
                        boxShadow:
                          props.hoverDisplayData.value &&
                          props.hoverDisplayData.value.markerId ===
                            value.markerId &&
                          !editMode
                            ? "0 0 8px 2px #014efe"
                            : "none",
                        borderColor:
                          value.stopCount > 1
                            ? "black"
                            : props.hoverDisplayData.value &&
                              props.hoverDisplayData.value.markerId ===
                                value.markerId &&
                              !editMode
                            ? "blue"
                            : props.hoverDisplayData.value &&
                              props.hoverDisplayData.value[0] &&
                              props.hoverDisplayData.value[0].markerId ===
                                value.markerId &&
                              !editMode
                            ? "red"
                            : value.day === "Monday"
                            ? "#976DD0"
                            : value.day === "Tuesday"
                            ? "#FFBA5C"
                            : value.day === "Wednesday"
                            ? "#77D353"
                            : value.day === "Thursday"
                            ? "#F95F62"
                            : value.day === "Friday"
                            ? "#235789"
                            : value.day === "Saturday"
                            ? "#ED7D3A"
                            : value.day === "Sunday"
                            ? "#4F46E5"
                            : "black",

                        borderRadius: 50,
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {multiStopMarkerDisplay &&
                        multiStopMarkerData.value.markerId ===
                          value.markerId && (
                          <Popover
                            refresh={refresh}
                            handleSelectSubMarker={handleSelectSubMarker}
                            stopCount={value.stopCount}
                            editMode={editMode}
                            multiStopMarkerData={multiStopMarkerData}
                            selectedSubMarker={selectedSubMarker}
                            markers={markers.current}
                            hiddenMarkers={hiddenMarkers}
                            handleEditModeOnHoverMultistop={
                              handleEditModeOnHoverMultistop
                            }
                            handleOnHoverExit={handleOnHoverExit}
                            handleAddServiceLocationToDay={enqueueAction}
                          />
                        )}

                      <div
                        style={{
                          display: "flex",
                          width: 32,
                          height: 32,
                          borderRadius: 50,
                          alignItems: "center",
                          justifyContent: "center",
                          backgroundColor:
                            value.stopCount > 1
                              ? "white"
                              : value.assigned
                              ? "gray"
                              : "gray",
                          color: "white",
                          fontSize: 10,
                        }}
                      >
                        {value.stopCount == 1 && value.assigned && (
                          <div>
                            {value.employeeName
                              ? value.employeeName
                                  .match(/(\b\S)?/g)
                                  .join("")
                                  .match(/(^\S|\S$)?/g)
                                  .join("")
                                  .toUpperCase()
                              : "Unavailable"}
                          </div>
                        )}

                        {value.stopCount > 1 && (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              width: `${(50 * value.stopCount) / 3}px`, // adjust width based on stopCount
                              padding: 2,
                              flexWrap: "wrap",
                            }}
                          >
                            <MarkerDots
                              refresh={refresh}
                              markers={markers.current}
                              value={value}
                              hiddenMarkers={hiddenMarkers}
                            />
                          </div>
                        )}
                        {value.stopCount == 1 && (
                          <div>
                            {editMode && !value.assigned ? (
                              checkRouteNumber(value)
                            ) : (
                              <div
                                style={{
                                  display: value.assigned ? "none" : "initial",
                                }}
                              >
                                <div
                                  style={{
                                    marginTop: 4,
                                    display: value.assigned
                                      ? "initial"
                                      : "none",
                                  }}
                                >
                                  {"# " + index}
                                </div>
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              ))}
            </GoogleMapReact>
          )}
          {props.hoverDisplay && (
            <div
              style={{
                width: "200px",
                borderRadius: "0.5rem",
                backgroundColor: "transparent",
                height: "auto",
                position: "absolute",
                zIndex: 1000,
                margin: 20,
                marginTop: "2%",
                right: 40,
                top: 200,
              }}
              class="routeBuilderEditSideMenuScroll"
            >
              <StopDetailsCard
                handleMoveClick={handleMoveClick}
                value={props.hoverDisplayData.value}
                changesData={changesData}
                handleCancelChange={handleCancelChange}
                selectedRoute={selectedRoute}
              />
            </div>
          )}
        </div>
      </div>
      {savingsChangesDisplay && (
        <div
          style={{
            position: "absolute",
            left: "54%",
            top: "44%",
            backgroundColor: "white",
            height: 60,
            width: 200,
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
            border: "1px solid black",
            borderRadius: 5,
          }}
        >
          <div>Saving changes...</div>
        </div>
      )}
      <div
        onClick={handleReloadData}
        style={{
          position: "absolute",
          right: "2%",
          top: "10%",
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
          cursor: "pointer",
          backgroundColor: "white",
          padding: 8,
        }}
      >
        <div style={{ fontSize: 12, marginRight: 4 }}>Perform Sync</div>
        <MdSync />
      </div>

      {loadingRoute && (
        <div
          style={{
            position: "absolute",
            right: 0,
            top: 0,
            width: mapWidth,
            height: "100vh",
            zIndex: 99999,
            backgroundColor: "rgba(0,0,0,0.5)",
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
          }}
        >
          <div
            style={{
              marginRight: 4,
              color: "white",
              backgroundColor: "rgba(0,0,0,0.6)",
              borderRadius: 10,
              padding: 20,
            }}
          >
            <div style={{ fontSize: 36 }}>Loading Route</div>
          </div>
        </div>
      )}

      <ChangePlanner
        open={displayChangesMenu}
        setOpen={setDisplayChangesMenu}
        selectedBusiness={selectedBusiness}
        selectedRoute={selectedRoute}
        serviceLocationsInRoute={serviceLocationsInRoute}
        routeStartDate={routeStartDate}
        routeIsAlreadyActive={routeIsAlreadyActive}
        setHasUnactivatedChanges={setHasUnactivatedChanges}
        moveData={moveData}
        employeesInRoute={employeesInRoute}
        makingChange={makingChange}
      />
    </div>
  );
};

export default RouteBuilderEdit;
