import React, { useState } from "react";
import { TbPhotoOff } from "react-icons/tb";
import ViewImage from "../GlobalComponents/ViewImage";

const JobImagesNew = (props) => {
  const pics = props.jobData.pics || [];
  const uploadedImages = [];
  const [viewImage, setViewImage] = useState(false);
  const [media, setMedia] = useState([]);

  const handleImageClick = (index) => {
    setMedia(pics[index]);
    setViewImage(true);
  };

  return (
    <div
      style={{
        width: "100%",
        alignItems: "flex-start",
        backgroundColor: "white",
        paddingTop: 0,
        height: "72%",
      }}
    >
      {/* Scrollable Pictures Section */}
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          alignItems: "center",
          width: "100%",
          overflowY: "auto",
        }}
      >
        {pics.map((value, index) => (
          <img
            key={index}
            onClick={() => handleImageClick(index)}
            src={value}
            alt={`Picture ${index}`}
            style={{
              width: 100,
              height: 100,
              marginBottom: "20px",
              marginRight: "20px",
              borderRadius: "10px",
              objectFit: "cover",
              cursor: "pointer",
            }}
          />
        ))}

        {/* Placeholder when no pictures are available */}
        {pics.length === 0 && (
          <div
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <TbPhotoOff size={180} />
            <p style={{ color: "black", fontSize: "18px", marginTop: "10px" }}>
              You have not added any pictures
            </p>
          </div>
        )}
      </div>
      <ViewImage media={media} open={viewImage} setOpen={setViewImage} />
    </div>
  );
};

export default JobImagesNew;
