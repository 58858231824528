import React, { useState, useEffect } from "react";
import firebase from "firebase/compat/app";
import { BiTrash } from "react-icons/bi";
import { FaCheckCircle, FaRegCheckCircle } from "react-icons/fa";

export default function Notifications(props) {
  const selectedBusiness = props.businessId;
  const db = firebase.firestore();
  const [notifications, setNotifications] = useState([]);
  const searchTerm = props.searchTerm;

  // Fetch notifications and include the document id
  useEffect(() => {
    console.log(selectedBusiness);
    const unsubscribe = db
      .collection("Businesses")
      .doc(selectedBusiness)
      .collection("Notifications")
      .onSnapshot((snapshot) => {
        const notificationsArray = snapshot.docs.map((doc) => ({
          ...doc.data(),
        }));
        setNotifications(notificationsArray);
      });

    return () => unsubscribe();
  }, [selectedBusiness, db]);

  // Function to clear all notifications from Firestore
  const clearAllNotifications = async () => {
    try {
      const notificationsRef = db
        .collection("Businesses")
        .doc(selectedBusiness)
        .collection("Notifications");

      const snapshot = await notificationsRef.get();
      snapshot.forEach((doc) => {
        doc.ref.delete();
      });
    } catch (error) {
      console.error("Error clearing notifications: ", error);
    }
  };

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="sm:flex sm:items-center mt-8">
        <div className="sm:flex-auto">
          <h1 className="text-xl font-semibold text-gray-900">Notifications</h1>
        </div>
        <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
          <button
            onClick={clearAllNotifications}
            type="button"
            className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
          >
            Clear All
          </button>
        </div>
      </div>
      <div className="mt-8 flex flex-col">
        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                    >
                      Notification
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Date
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Time
                    </th>
                    <th
                      scope="col"
                      className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                    >
                      <span className="sr-only"></span>
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {notifications
                    .sort((a, b) => {
                      return (
                        new Date(b.date + " " + b.time) -
                        new Date(a.date + " " + a.time)
                      );
                    })
                    .map((notification) => (
                      <tr key={notification.id}>
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                          <div
                            style={{
                              maxWidth: "120%",
                              overflow: "hidden",
                              whiteSpace: "normal",
                            }}
                          >
                            {notification.body}
                          </div>
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {notification.date}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {notification.time}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          <BiTrash
                            onClick={() =>
                              db
                                .collection("Businesses")
                                .doc(selectedBusiness)
                                .collection("Notifications")
                                .doc(notification.id)
                                .delete()
                            }
                            className="text-red-500 cursor-pointer text-xl ml-auto mr-6"
                          />
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
