/* This example requires Tailwind CSS v2.0+ */

import React, { useEffect, useState, useRef } from "react";
import firebase from "firebase/compat/app";
import { BiUser } from "react-icons/bi";
import { ImProfile } from "react-icons/im";
import { MdSendToMobile } from "react-icons/md";
import CustomerProfile from "./CustomerProfile";
import CustomersButton from "./CustomersButton";
import AddCustomer from "./AddCustomer";
import ImportCustomers from "./ImportCustomers";
import { toast } from "react-toastify";
import Select from "./Select";
import { Tooltip } from "react-tooltip";

export default function Customers(props) {
  const selectedBusiness = props.businessId;
  const [allCustomers, setAllCustomers] = useState([]);
  const [addCustomerDisplay, setAddCustomerDisplay] = useState(false);
  const [importCustomersDisplay, setImportCustomersDisplay] = useState(false);
  const [mainDisplay, setMainDisplay] = useState(true);
  const [selectedCustomerData, setSelectedCustomerData] = useState();
  const [disablePagination, setDisablePagination] = useState(false);
  const [customerCount, setCustomerCount] = useState();
  const [filterOption, setFilterOption] = useState("None");
  const isMounted = useRef(false);
  const [currentPage, setCurrentPage] = useState(1);

  // Instead of two separate stacks, we now cache page cursors in an object.
  // pageCursors[page] will hold the "lastVisible" document for page (page - 1)
  // For page 1, we set its cursor to null.
  const [pageCursors, setPageCursors] = useState({ 1: null });

  const db = firebase.firestore();

  // Compute total pages (assuming 20 items per page)
  const totalPages = customerCount ? Math.ceil(customerCount / 20) : 1;

  // We'll store the current subscription so we can detach it when needed
  let currentSubscription = useRef(null);

  // This helper performs a realtime query for the given page.
  // For page 1, no cursor is used; for subsequent pages, we use pageCursors[page].
  // Modify goToPage function while keeping the original structure
  // Modify goToPage function to always get the correct data for any page
  const goToPage = async (page) => {
    if (page === currentPage) return;
    stopSubscription();

    try {
      // Calculate how many documents to skip to get to the requested page
      const itemsPerPage = 20;
      const skipCount = (page - 1) * itemsPerPage;

      // If we need to fetch page > 1, we need to get the document right before our page starts
      if (skipCount > 0) {
        // Query to get exactly the document at position (skipCount - 1)
        const cursorQuery = db
          .collection("Customers")
          .where("businessIds", "array-contains", selectedBusiness)
          .orderBy("customerLastName")
          .limit(skipCount);

        const snapshot = await cursorQuery.get();

        if (snapshot.docs.length > 0) {
          // The last document is our cursor
          const cursorDoc = snapshot.docs[snapshot.docs.length - 1];

          // Store this cursor temporarily (we'll use it immediately)
          const tempCursor = cursorDoc;

          // Now create a new query starting after this cursor to get page data
          const pageQuery = db
            .collection("Customers")
            .where("businessIds", "array-contains", selectedBusiness)
            .orderBy("customerLastName")
            .startAfter(tempCursor)
            .limit(itemsPerPage);

          // Set up real-time listener for the page data
          const unsubscribe = pageQuery.onSnapshot((pageSnapshot) => {
            if (!pageSnapshot.empty) {
              const customers = pageSnapshot.docs.map((doc) => doc.data());
              setAllCustomers(customers);

              // Store cursors for the current page and next page
              const lastVisibleDoc =
                pageSnapshot.docs[pageSnapshot.docs.length - 1];
              setPageCursors((prev) => {
                const newCursors = { ...prev };
                newCursors[page] = tempCursor; // Cursor to START this page
                newCursors[page + 1] = lastVisibleDoc; // Cursor to START next page
                return newCursors;
              });
            } else {
              setAllCustomers([]);
            }
          });

          currentSubscription.current = unsubscribe;
        } else {
          // Not enough documents to reach this page - go to page 1
          const fallbackQuery = db
            .collection("Customers")
            .where("businessIds", "array-contains", selectedBusiness)
            .orderBy("customerLastName")
            .limit(itemsPerPage);

          const unsubscribe = fallbackQuery.onSnapshot((snapshot) => {
            const customers = snapshot.docs.map((doc) => doc.data());
            setAllCustomers(customers);

            // Store cursor for page 2
            if (snapshot.docs.length > 0) {
              const lastVisibleDoc = snapshot.docs[snapshot.docs.length - 1];
              setPageCursors((prev) => ({ ...prev, 2: lastVisibleDoc }));
            }
          });

          currentSubscription.current = unsubscribe;
          page = 1; // Reset to page 1
        }
      } else {
        // For page 1, no cursor needed
        const pageQuery = db
          .collection("Customers")
          .where("businessIds", "array-contains", selectedBusiness)
          .orderBy("customerLastName")
          .limit(itemsPerPage);

        const unsubscribe = pageQuery.onSnapshot((snapshot) => {
          const customers = snapshot.docs.map((doc) => doc.data());
          setAllCustomers(customers);

          // Store cursor for page 2
          if (snapshot.docs.length > 0) {
            const lastVisibleDoc = snapshot.docs[snapshot.docs.length - 1];
            setPageCursors((prev) => ({ ...prev, 2: lastVisibleDoc }));
          }
        });

        currentSubscription.current = unsubscribe;
      }

      setCurrentPage(page);
    } catch (error) {
      console.error("Error fetching page:", error);
      // Fallback to page 1 on error
      const query = db
        .collection("Customers")
        .where("businessIds", "array-contains", selectedBusiness)
        .orderBy("customerLastName")
        .limit(20);

      const unsubscribe = query.onSnapshot((snapshot) => {
        const customers = snapshot.docs.map((doc) => doc.data());
        setAllCustomers(customers);
      });

      currentSubscription.current = unsubscribe;
      setCurrentPage(1);
    }
  };

  // Keep the original subscribeCustomers function for Next/Back buttons
  // The handleNext and handleBack functions remain unchanged

  // Keep the original subscribeCustomers function but add better handling for missing cursors
  const subscribeCustomers = (page) => {
    console.log(page);
    console.log(pageCursors[page]);

    let query = db
      .collection("Customers")
      .where("businessIds", "array-contains", selectedBusiness)
      .orderBy("customerLastName")
      .limit(20);

    if (page > 1 && pageCursors[page]) {
      console.log("Using cursor for page", page);
      query = query.startAfter(pageCursors[page]);
    } else if (page > 1) {
      console.log("No cursor for page", page, "- will show first page data");
    }

    // Attach the realtime listener
    const unsubscribe = query.onSnapshot((snapshot) => {
      if (!snapshot.empty) {
        const customers = snapshot.docs.map((doc) => doc.data());
        setAllCustomers(customers);
        const lastVisibleDoc = snapshot.docs[snapshot.docs.length - 1];
        // Cache the cursor for the next page if not already cached
        setPageCursors((prev) => {
          if (!prev[page + 1]) {
            return { ...prev, [page + 1]: lastVisibleDoc };
          }
          return prev;
        });
      } else {
        // No results - show empty list
        setAllCustomers([]);
      }
    });
    return unsubscribe;
  };

  // Detach current subscription, if any.
  const stopSubscription = () => {
    if (currentSubscription.current) {
      currentSubscription.current();
      currentSubscription.current = null;
    }
  };

  // Initial load using a realtime listener (page 1)
  useEffect(() => {
    isMounted.current = true;
    if (isMounted.current) {
      // Load page 1 (cursor is null)
      currentSubscription.current = subscribeCustomers(1);
      // Also get the business doc to set customerCount
      db.collection("Businesses")
        .doc(selectedBusiness)
        .get()
        .then((businessDoc) => {
          setCustomerCount(businessDoc.data().customerCount);
        });
    }

    if (props.preSelectedCustomer) {
      setMainDisplay(false);
      setSelectedCustomerData(props.preSelectedCustomer);
    }

    return () => {
      isMounted.current = false;
      stopSubscription();
    };
  }, [selectedBusiness]);

  const handleNext = () => {
    stopSubscription();
    const nextPage = currentPage + 1;
    currentSubscription.current = subscribeCustomers(nextPage);
    setCurrentPage(nextPage);
  };

  const handleBack = () => {
    stopSubscription();
    const prevPage = currentPage - 1;
    currentSubscription.current = subscribeCustomers(prevPage);
    setCurrentPage(prevPage);
  };

  const searchCustomers = (term) => {
    const searchTerm = term.toLowerCase().trim();

    // Clear the current list when searching
    setAllCustomers([]);

    const queries = [
      db
        .collection("Customers")
        .where("businessIds", "array-contains", selectedBusiness)
        .orderBy("customerFirstName")
        .startAt(searchTerm.toLowerCase())
        .endAt(searchTerm + "\uf8ff")
        .limit(20),

      db
        .collection("Customers")
        .where("businessIds", "array-contains", selectedBusiness)
        .orderBy("customerFullName")
        .startAt(searchTerm.toLowerCase())
        .endAt(searchTerm + "\uf8ff")
        .limit(20),

      db
        .collection("Customers")
        .where("businessIds", "array-contains", selectedBusiness)
        .orderBy("customerLastName")
        .startAt(searchTerm.toLowerCase())
        .endAt(searchTerm + "\uf8ff")
        .limit(20),

      db
        .collection("Customers")
        .where("businessIds", "array-contains", selectedBusiness)
        .orderBy("customerHomeAddress")
        .startAt(searchTerm.toLowerCase())
        .endAt(searchTerm + "\uf8ff")
        .limit(20),

      db
        .collection("Customers")
        .where("businessIds", "array-contains", selectedBusiness)
        .orderBy("customerPhoneNumber")
        .startAt(searchTerm.toLowerCase())
        .endAt(searchTerm + "\uf8ff")
        .limit(20),
    ];

    // Run all queries and replace the customer list with search results
    const unsubscribeList = queries.map((query) =>
      query.onSnapshot((snapshot) => {
        const newCustomers = snapshot.docs.map((doc) => doc.data());

        setAllCustomers((prevCustomers) => {
          // Remove pagination results and show only search results
          return [
            ...new Map(
              [...newCustomers, ...prevCustomers].map((customer) => [
                customer.customerPhoneNumber, // Assuming phone numbers are unique
                customer,
              ])
            ).values(),
          ];
        });
      })
    );

    return () => unsubscribeList.forEach((unsubscribe) => unsubscribe());
  };

  const handleSearch = (term) => {
    if (term.trim() !== "") {
      setDisablePagination(true);
      stopSubscription();
      setCurrentPage(1); // Reset to page 1 when searching
      currentSubscription.current = searchCustomers(term);
    } else {
      stopSubscription();
      setAllCustomers([]); // Clear search results before resetting pagination
      currentSubscription.current = subscribeCustomers(1);
      setDisablePagination(false);
      setCurrentPage(1);
    }
  };
  useEffect(() => {
    handleSearch(props.searchTerm);
  }, [props.searchTerm]);

  const handleCustomerClick = (data) => {
    setSelectedCustomerData(data);
    setMainDisplay(false);
    props.handleAddToBreadcrumb({
      name: (data.customerFirstName + " " + data.customerLastName).replace(
        /(^\w{1})|(\s+\w{1})/g,
        (letter) => letter.toUpperCase()
      ),
      type: "customer",
      current: true,
      value: data,
    });
  };

  const handleMainButtonClick = (menuOption) => {
    if (menuOption === "New Customer") {
      setAddCustomerDisplay(true);
    } else if (menuOption === "Import Customers") {
      setImportCustomersDisplay(true);
    } else if (menuOption === "Invite All Customers") {
      firebase
        .auth()
        .currentUser.getIdToken()
        .then((token) => {
          fetch(
            "https://us-central1-symbri-production.cloudfunctions.net/inviteAllCustomers",
            {
              method: "POST",
              body: JSON.stringify({ token: token }),
            }
          );
          toast.success("All customers will be sent invites!");
        });
    }
  };

  const handleResendInvite = (data) => {
    console.log("+1" + data.customerPhoneNumber);
    firebase
      .auth()
      .currentUser.getIdToken()
      .then(async (token) => {
        const res = await fetch(
          "https://us-central1-symbri-production.cloudfunctions.net/sendCustomerGreeting",
          {
            method: "POST",
            body: JSON.stringify({
              customerPhoneNumber: "+1" + data.customerPhoneNumber,
              customerEmailAddress: data.customerEmailAddress,
              customerFirstName: data.customerFirstName,
              selectedBusiness: selectedBusiness,
              token: token,
              inviteCode: data.inviteCode,
            }),
          }
        );

        if (res.status === 200) {
          toast.success(
            "A text message has been sent to the customer inviting them!"
          );
        } else {
          toast.error("There was an error sending the invite!");
        }
      });
  };

  return mainDisplay ? (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-xl font-semibold text-gray-900">
            Customers {customerCount}
          </h1>
          <p className="mt-2 text-sm text-gray-700"></p>
        </div>

        <div className="mt-6 flex flex-col-reverse items-center justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-reverse sm:space-y-0 sm:space-x-3 md:mt-0 md:flex-row md:space-x-3">
          <div className="w-[200px] mb-1">
            <Select
              setValue={setFilterOption}
              options={["None", "No App", "Has App", "Cellphone", "Landline"]}
              value={filterOption}
            />
          </div>
          <CustomersButton
            handleMainButtonClick={handleMainButtonClick}
            businessId={selectedBusiness}
          />
        </div>
      </div>
      <div className="mt-8 flex flex-col">
        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                    >
                      Name
                    </th>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                    ></th>
                    <th className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                          alignItems: "center",
                        }}
                      >
                        <button
                          disabled={currentPage === 1 || disablePagination}
                          onClick={handleBack}
                          type="button"
                          className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto mr-4"
                        >
                          Back
                        </button>
                        {/* Pagination numbers */}
                        <div className="flex items-center space-x-2">
                          {(() => {
                            const visiblePageCount = 5;
                            let startPage =
                              Math.floor((currentPage - 1) / visiblePageCount) *
                                visiblePageCount +
                              1;
                            let endPage = Math.min(
                              totalPages,
                              startPage + visiblePageCount - 1
                            );

                            // Shift the block only if there are more pages beyond the current block
                            if (
                              currentPage === endPage &&
                              endPage < totalPages
                            ) {
                              startPage = currentPage;
                              endPage = Math.min(
                                totalPages,
                                startPage + visiblePageCount - 1
                              );
                            }

                            // Prevent shifting if total pages is within the currently displayed range
                            if (totalPages <= endPage) {
                              startPage = Math.max(
                                1,
                                totalPages - visiblePageCount + 1
                              );
                              endPage = totalPages;
                            }

                            return Array.from(
                              { length: endPage - startPage + 1 },
                              (_, index) => {
                                const page = startPage + index;
                                return (
                                  <button
                                    key={page}
                                    onClick={() => goToPage(page)}
                                    className={`px-3 py-1 border ${
                                      currentPage === page
                                        ? "bg-indigo-600 text-white"
                                        : "bg-white text-indigo-600"
                                    }`}
                                  >
                                    {page}
                                  </button>
                                );
                              }
                            );
                          })()}
                        </div>
                        <button
                          disabled={disablePagination}
                          onClick={handleNext}
                          type="button"
                          className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto ml-4"
                        >
                          Next
                        </button>
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {allCustomers
                    .filter((person) => {
                      if (filterOption === "None") {
                        return person;
                      } else if (filterOption === "No App") {
                        return person.pending;
                      } else if (filterOption === "Has App") {
                        return !person.pending;
                      } else if (filterOption === "Cellphone") {
                        return person.customerPhoneNumber;
                      } else if (filterOption === "Landline") {
                        return person.customerLandline;
                      }
                    })
                    .sort((a, b) =>
                      a.customerLastName.localeCompare(b.customerLastName)
                    )
                    .map((person) => (
                      <tr
                        key={
                          person.customerFirstName +
                          " " +
                          person.customerLastName
                        }
                      >
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6">
                          <div className="flex items-center">
                            <div className="flex">
                              <div className="font-medium text-gray-900 font-bold">
                                {person.customerLastName.replace(
                                  /(^\w{1})|(\s+\w{1})/g,
                                  (letter) => letter.toUpperCase()
                                )}
                              </div>
                              <div className="font-medium text-gray-900 ml-1">
                                {person.customerFirstName.replace(
                                  /(^\w{1})|(\s+\w{1})/g,
                                  (letter) => letter.toUpperCase()
                                )}
                              </div>
                            </div>
                          </div>
                        </td>
                        <td className="flex justify-center items-center h-20">
                          {person.pending && (
                            <div
                              onClick={() =>
                                person.customerLandline
                                  ? null
                                  : handleResendInvite(person)
                              }
                              data-tooltip-id="resend-invite-tooltip"
                              data-tooltip-content={
                                person.customerLandline
                                  ? "We are unable to send invites to landlines!"
                                  : "Send app invite to this customer!"
                              }
                              data-tooltip-place="top"
                              className={
                                person.customerLandline
                                  ? "mr-8 ml-auto cursor-pointer text-gray-500"
                                  : "cursor-pointer ml-auto mr-8 text-[#04A6FF]"
                              }
                            >
                              Invite To App
                            </div>
                          )}
                          {!person.pending && (
                            <div
                              data-tooltip-id="already-invited-tooltip"
                              data-tooltip-content="This customer already has the app installed!"
                              data-tooltip-place="top"
                              className="mr-8 ml-auto cursor-pointer text-gray-500"
                            >
                              Invite To App
                            </div>
                          )}
                          <div
                            onClick={() => handleCustomerClick(person)}
                            className="cursor-pointer mr-8"
                          >
                            View
                          </div>
                        </td>
                        <Tooltip id="already-invited-tooltip" />
                        <Tooltip id="resend-invite-tooltip" />
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <AddCustomer
        businessId={selectedBusiness}
        open={addCustomerDisplay}
        setOpen={setAddCustomerDisplay}
        userPermissions={props.userPermissions}
      />
      <ImportCustomers
        businessId={selectedBusiness}
        open={importCustomersDisplay}
        setOpen={setImportCustomersDisplay}
      />
    </div>
  ) : (
    <CustomerProfile
      handleAddToBreadcrumb={props.handleAddToBreadcrumb}
      businessId={selectedBusiness}
      selectedCustomerData={selectedCustomerData}
    />
  );
}
