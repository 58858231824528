import React, { useState, useEffect, useRef, useCallback } from "react";
import firebase from "firebase/compat/app";
import GoogleMapReact from "google-map-react";
import SideMenu from "./SideMenu";
import Geocoder from "react-native-geocoding";
import { generateBusinessId } from "../../Tools/generateBusinessId";
import dayjs from "dayjs";
import RecheduleRecurringStop from "../../GlobalComponents/RescheduleRecurringStop";
import RescheduleOneTimeStop from "../../GlobalComponents/RescheduleOneTimeStop";
import ViewDetailsRec from "./ViewDetailsRec";
import ViewDetailsOneTime from "./ViewDetailsOneTime";
import ScheduleOneTimeStop from "../../GlobalComponents/ScheduleOneTimeStop";
import StopDetailsCard from "./StopDetailsCard";
import { MdSync } from "react-icons/md";
import { toast } from "react-toastify";
import RescheduleWholeRoute from "../../GlobalComponents/RescheduleWholeRoute";
import ViewReport from "../../Customers/ViewReport";
import ViewJobReport from "../../Jobs/ViewJobReport";
import ViewJob from "../../Jobs/ViewJob";
import AddStop from "./AddStop";

Geocoder.init(process.env.REACT_APP_GOOGLE_MAPS_API_TOKEN);

const Popover = ({
  stopCount,
  handleSelectSubMarker,
  multiStopMarkerData,
  selectedSubMarker,
  markers,
  refresh,
  stopsBeingViewed,
  getCountInRoute,
  handleSelectSubMarkerClick,
}) => {
  const [assignedMarkers, setAssignedMarkers] = useState([]);
  const hiddenMarkers = [];

  useEffect(() => {
    let matchesAssignedMarkers = [];

    for (let index = 0; index < markers.length; index++) {
      const marker = markers[index];

      // If the marker is not hidden and matches the serviceLocationId and the day is not "none"
      if (
        marker.serviceLocationId ===
        multiStopMarkerData.value[0].serviceLocationId
      ) {
        matchesAssignedMarkers.push(marker);
      }
    }

    // Log and set the new array
    console.log(matchesAssignedMarkers);
    setAssignedMarkers(matchesAssignedMarkers);
  }, [refresh, markers, multiStopMarkerData, stopCount]);

  return (
    <div
      style={{
        position: "absolute",
        bottom: "10px",
        left: "50%",
        width: 180,
        minHeight: 60,
        transform: "translateX(-50%)",
        backgroundColor: "white",
        padding: "15px",
        borderRadius: "4px",
        zIndex: 1000,
        boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.5)",
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        flexWrap: "wrap",
      }}
    >
      {assignedMarkers
        .filter((a) => {
          // if stopsBeingViewed is defined, filter by it
          if (stopsBeingViewed) {
            return stopsBeingViewed.includes(
              a.tempStopId ? a.tempStopId : a.stopId
            );
          } else {
            return true;
          }
        })
        .map((value, index) => (
          <div
            onClick={(e) => {
              e.stopPropagation();

              handleSelectSubMarkerClick(index, value);
            }}
            onMouseEnter={() => handleSelectSubMarker(index, value, true)}
            onMouseLeave={() => handleSelectSubMarker(index, value, true, true)}
            key={index}
            style={{
              width: 40,
              height: 40,
              backgroundColor: "grey",
              borderRadius: "50%",
              display: "flex",
              border: "4px solid",
              justifyContent: "center",
              alignItems: "center",
              color: "white",
              margin: 4,
              boxShadow:
                selectedSubMarker === index ? "0 0 8px 2px #014efe" : "none",
              borderColor:
                selectedSubMarker === index
                  ? "blue"
                  : value.day === "Monday"
                  ? "#976DD0"
                  : value.day === "Tuesday"
                  ? "#FFBA5C"
                  : value.day === "Wednesday"
                  ? "#77D353"
                  : value.day === "Thursday"
                  ? "#F95F62"
                  : value.day === "Friday"
                  ? "#235789"
                  : value.day === "Saturday"
                  ? "#ED7D3A"
                  : value.day === "Sunday"
                  ? "#4F46E5"
                  : "black",
            }}
          >
            {value.day !== "none" && (
              <div>
                {stopsBeingViewed?.includes(
                  value.tempStopId ? value.tempStopId : value.stopId
                )
                  ? getCountInRoute(value)
                  : value.employeeName
                      .match(/(\b\S)?/g)
                      .join("")
                      .match(/(^\S|\S$)?/g)
                      .join("")
                      .toUpperCase()}
              </div>
            )}
          </div>
        ))}
      <div
        style={{
          position: "absolute",
          bottom: "-10px",
          left: "50%",
          transform: "translateX(-50%)",
          width: 0,
          height: 0,
          borderLeft: "10px solid transparent",
          borderRight: "10px solid transparent",
          borderTop: "10px solid white",
          zIndex: 2,
        }}
      />
      <div
        style={{
          position: "absolute",
          bottom: "-8px",
          left: "50%",
          transform: "translateX(-50%)",
          width: 0,
          height: 0,
          borderLeft: "10px solid transparent",
          borderRight: "10px solid transparent",
          borderTop: "10px solid rgba(0, 0, 0, 0.1)",
          zIndex: 1,
          filter: "blur(4px)",
        }}
      />
    </div>
  );
};

const MarkerDots = React.memo(
  ({ value, markers, refresh, stopsBeingViewed }) => {
    const [assignedMarkers, setAssignedMarkers] = useState([]);
    useEffect(() => {
      let matchesAssignedMarkers = [];

      for (let index = 0; index < markers.length; index++) {
        const marker = markers[index];

        if (marker.serviceLocationId === value.serviceLocationId) {
          matchesAssignedMarkers.push(marker);
        }
      }

      setAssignedMarkers(matchesAssignedMarkers);
    }, [markers, value]);

    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexWrap: "wrap",
          maxWidth: 30,
        }}
      >
        {assignedMarkers
          .filter((a) => {
            // if stopsBeingViewed is defined, filter by it
            if (stopsBeingViewed) {
              return stopsBeingViewed.includes(
                a.tempStopId ? a.tempStopId : a.stopId
              );
            } else {
              return true;
            }
          })
          .map((value, index) => (
            <span
              key={index}
              style={{
                width: "6px",
                height: "6px",
                backgroundColor:
                  value.day === "Monday"
                    ? "#4F46E5"
                    : value.day === "Tuesday"
                    ? "#FFBA5C"
                    : value.day === "Wednesday"
                    ? "#77D353"
                    : value.day === "Thursday"
                    ? "#F95F62"
                    : value.day === "Friday"
                    ? "#235789"
                    : value.day === "Saturday"
                    ? "#ED7D3A"
                    : value.day === "Sunday"
                    ? "#4F46E5"
                    : "grey",
                borderRadius: "50%",
                display: "inline-block",
                margin: 1,
              }}
            ></span>
          ))}
      </div>
    );
  }
);

const ActiveRoute = (props) => {
  const db = firebase.firestore();
  const selectedBusiness = props.businessId;
  const [selectedRoute, setSelectedRoute] = useState({});
  const [refresh, setRefresh] = useState(false);
  const [serviceLocationsInRoute, setServiceLocationsInRoute] = useState([]);
  const markers = useRef();
  const [hoverDisplay, setHoverDisplay] = useState(false);
  const [hoverDisplayData, setHoverDisplayData] = useState({});
  const [routeStartDate, setRouteStartDate] = useState(new Date());
  const [mapsObject, setMapsObject] = useState();
  const [mapObject, setMapObject] = useState();
  const [mapCenterCords, setMapCenterCords] = useState();
  const serviceLocationsOrder = useRef();
  const [hasUnactivatedChanges, setHasUnactivatedChanges] = useState(false);
  const [unscheduledMarkers, setUnscheduledMarkers] = useState([]);
  const [rescheduledMarkers, setRescheduledMarkers] = useState([]);
  const [selectedUnscheduledStop, setSelectedUnscheduledStop] = useState({});
  const [reloadUnscheduledStops, setReloadUnscheduledStops] = useState(false);
  const [mapWidth, setMapWidth] = useState("0px");
  const [rescheduleDisplay, setRescheduleDisplay] = useState(false);
  const [oneTimeRescheduleDisplay, setOneTimeRescheduleDisplay] =
    useState(false);
  const [rescheduleData, setRescheduleData] = useState();
  const [rescheduledStops, setRescheduledStops] = useState([]);
  const [viewDetailsOneTimeDisplay, setViewDetailsOneTimeDisplay] =
    useState(false);
  const [viewDetailsRecDisplay, setViewDetailsRecDisplay] = useState(false);
  const [viewDetailsData, setViewDetailsData] = useState();
  const [selectedUnscheduledStops, setSelectedUnscheduledStops] = useState([]);
  const [scheduleOneTimeStopDisplay, setScheduleOneTimeStopDisplay] =
    useState(false);
  const [selectedMarkerIndex, setSelectedMarkerIndex] = useState();
  const routeOrder = useRef();
  const [dayViewDisplay, setDayViewDisplay] = useState(false);
  const [dayViewData, setDayViewData] = useState({});
  const [dayData, setDayData] = useState([]);
  const orderListener = useRef();
  const [allEmployees, setAllEmployees] = useState([]);
  const [lastReloadValue, setLastReloadValue] = useState(null);
  const initialLoad = useRef();
  const [customRouteOrder, setCustomerRouteOrder] = useState([]);
  const [markersDataForProp, setMarkersDataForProp] = useState([]);
  const [forceEmployeeCardsReload, setForceEmployeeCardsReload] =
    useState(false);
  const [selectedSubMarker, setSelectedSubMarker] = useState();
  const [multiStopMarkerDisplay, setMultiStopMarkerDisplay] = useState(false);
  const [multiStopMarkerData, setMultiStopMarkerData] = useState({});
  const [defaultZoom, setDefaultZoom] = useState();
  const [routeHasProcessingChanges, setRouteHasProcessingChanges] =
    useState(false);
  const lastReloadValueRef = useRef();
  const [hiddenEmployees, setHiddenEmployees] = useState([]);
  const [rescheduleWholeRouteDisplay, setRescheduleWholeRouteDisplay] =
    useState(false);
  const [moveRoutePools, setMoveRoutePools] = useState([]);
  const [reportData, setReportData] = useState();
  const [viewRecReport, setViewRecReport] = useState(false);
  const [reportCustomerData, setReportCustomerData] = useState();
  const [reportServicLocation, setReportServicLocation] = useState();
  const [viewJobReport, setViewJobReport] = useState(false);
  const [jobReportData, setJobReportData] = useState();
  const [jobData, setJobData] = useState();
  const [stopsBeingViewed, setStopsBeingViewed] = useState();
  const [displayViewJob, setDisplayViewJob] = useState(false);
  const [viewJobData, setViewJobData] = useState();
  const [selectedDayViewCard, setSelectedDayViewCard] = useState();
  const viewDayListener = useRef();
  const [showAddStop, setShowAddStop] = useState(false);
  const [addStopMode, setAddStopMode] = useState("unscheduled");
  const [selectedDayViewEmployee, setSelectedDayViewEmployee] = useState();

  useEffect(() => {
    routeOrder.current = [];

    setMapWidth(document.getElementsByClassName("py-6")[0].offsetWidth + "px");
    markers.current = [];
    setMarkersDataForProp([]);
    let listener = null;

    db.collection("Businesses")
      .doc(selectedBusiness)
      .collection("Routes")
      .where("active", "==", true)
      .get()
      .then((querySnapshot) => {
        // setup a listener that specifically waits for the reloaad field to change
        if (!querySnapshot.empty) {
          const routeData = querySnapshot.docs[0].data();
          lastReloadValueRef.current = routeData.reload;
          setLastReloadValue(routeData.reload);

          setSelectedRoute(routeData);
        }
      });

    listener = db
      .collection("Businesses")
      .doc(selectedBusiness)
      .onSnapshot((documentSnapshot) => {
        const bData = documentSnapshot.data();
        setRouteHasProcessingChanges(bData.routeChangesPending ? true : false);
      });

    db.collection("Businesses")
      .doc(selectedBusiness)
      .get()
      .then((documentSnapshot) => {
        const bData = documentSnapshot.data();
        Geocoder.from(bData.serviceAreaZipcode)
          .then((json) => {
            var location = json.results[0].geometry.location;
            setMapCenterCords(location);
          })
          .catch((error) => console.warn(error));
      });

    return () => {
      if (listener) listener();
      if (orderListener.current) orderListener.current();
      if (viewDayListener.current) viewDayListener.current();
    };
  }, []);

  const handleSelectSubMarker = useCallback(
    (index, value, hover, exit) => {
      console.log(selectedSubMarker);
      if (exit && !selectedSubMarker && selectedSubMarker !== 0) {
        console.log("11111111");
        setSelectedSubMarker();
        setHoverDisplayData({});
        setHoverDisplay(false);
        return;
      }

      if (index === selectedSubMarker && !exit && !hover) {
        console.log("22222222");
        setSelectedSubMarker();
        setHoverDisplayData({});
        setHoverDisplay(false);
        return;
      }

      if (!selectedSubMarker && selectedSubMarker !== 0) {
        console.log("44444444");
        setSelectedMarkerIndex();
        setHoverDisplayData({ value: value });
        setHoverDisplay(true);
      }

      if (!hover) {
        console.log("33333333");
        setSelectedSubMarker(index);
      }
    },
    [selectedSubMarker]
  );

  useEffect(() => {
    const storedZoomValue = localStorage.getItem("mapZoom");
    if (storedZoomValue !== null) {
      const zoom = parseInt(storedZoomValue, 10);
      // Use the zoom value as needed
      setDefaultZoom(zoom);
    } else {
      setDefaultZoom(11);
    }
  }, []);

  const handleZoomValue = (zoom) => {
    // Convert the zoom value to a string and store it in local storage
    localStorage.setItem("mapZoom", zoom.toString());
  };

  const checkProcessingChanges = async () => {
    const documentSnapshot = await db
      .collection("Businesses")
      .doc(selectedBusiness)
      .get();
    const bData = documentSnapshot.data();
    return bData.routeChangesPending ? true : false;
  };

  useEffect(() => {
    initialLoad.current = true;

    const unsubscribe = db
      .collection("Businesses")
      .doc(selectedBusiness)
      .collection("Routes")
      .where("active", "==", true)
      .onSnapshot(async (querySnapshot) => {
        console.log("SNAPSHOT 1 FIRING");
        if (querySnapshot.empty) return;

        const docData = querySnapshot.docs[0].data();
        const newReloadValue = docData.reload;

        console.log(`newReloadValue: ${newReloadValue}`);

        const hasProcessingChanges = await checkProcessingChanges();

        if (
          (!hasProcessingChanges &&
            newReloadValue !== lastReloadValueRef.current) ||
          (initialLoad.current && !hasProcessingChanges)
        ) {
          initialLoad.current = false;
          setSelectedRoute(docData);
          setLastReloadValue(newReloadValue);
          lastReloadValueRef.current = newReloadValue;

          serviceLocationsOrder.current = [];
          setHoverDisplayData({});
          setHoverDisplay(false);
          setSelectedMarkerIndex(null);
          setUnscheduledMarkers([]);
          setRescheduledMarkers([]);
          markers.current = [];
          setMarkersDataForProp([]);
          setMultiStopMarkerData({});
          setMultiStopMarkerDisplay(false);
          setSelectedSubMarker();

          try {
            const employeesSnapshot = await db
              .collection("Businesses")
              .doc(selectedBusiness)
              .collection("Routes")
              .doc(docData.routeId)
              .collection("Employees")
              .get();

            const arrOfEmployees = employeesSnapshot.docs.map((doc) =>
              doc.data()
            );
            setAllEmployees(arrOfEmployees);

            const serviceLocationPromises = arrOfEmployees.map(
              async (employee) => {
                const uid = employee.uid;
                const weekday = dayjs(routeStartDate).format("dddd");

                const [stopsSnap, movedStopsSnap, jobStopsDocSnap] =
                  await Promise.all([
                    db
                      .collection("Businesses")
                      .doc(selectedBusiness)
                      .collection("Workers")
                      .doc(uid)
                      .collection("Route")
                      .doc(weekday)
                      .collection("Stops")
                      .get(),
                    db
                      .collection("Businesses")
                      .doc(selectedBusiness)
                      .collection("Workers")
                      .doc(uid)
                      .collection("Route")
                      .doc(weekday)
                      .collection("MovedStops")
                      .get(),
                    db
                      .collection("Businesses")
                      .doc(selectedBusiness)
                      .collection("Workers")
                      .doc(uid)
                      .collection("Route")
                      .doc("JobStops")
                      .get(),
                  ]);

                const arrOfHiddenStops = movedStopsSnap.docs
                  .filter(
                    (doc) =>
                      dayjs(routeStartDate).format("MM/DD/YYYY") ===
                      doc.data().originalDate
                  )
                  .map((doc) => doc.data().stopId);

                const serviceLocations = stopsSnap.docs
                  .filter((doc) => {
                    const stopId = doc.data().tempStopId || doc.data().stopId;
                    return !arrOfHiddenStops.includes(stopId);
                  })
                  .map((doc) => doc.data());

                if (jobStopsDocSnap.exists) {
                  const jobStopsData = jobStopsDocSnap.data();
                  if (jobStopsData.route) {
                    const jobServiceLocations = jobStopsData.route.filter(
                      (stop) => {
                        const stopId = stop.tempStopId || stop.stopId;
                        return !arrOfHiddenStops.includes(stopId);
                      }
                    );
                    serviceLocations.push(...jobServiceLocations);
                  }
                }

                return serviceLocations;
              }
            );

            const serviceLocationsArrays = await Promise.all(
              serviceLocationPromises
            );
            const arrOfServiceLocations = serviceLocationsArrays.flat();

            const [unscheduled, rescheduled] = await Promise.all([
              handleGetUnscheduled(arrOfEmployees),
              handleGetRescheduled(arrOfEmployees),
            ]);

            setServiceLocationsInRoute(arrOfServiceLocations);

            setUnscheduledMarkers(unscheduled);

            console.log(unscheduled);

            getAllPoolAddresses(arrOfServiceLocations, unscheduled);
          } catch (error) {
            console.error("Error during data fetching:", error);
          }
        }
      });

    // Clean up the listener when the component unmounts
    return () => unsubscribe();
  }, [selectedBusiness, routeStartDate]);

  const handleGetRescheduled = async (arrOfEmployees) => {
    const businessRef = db.collection("Businesses").doc(selectedBusiness);

    let currentRescheduledMarkers = [];

    const employeePromises = arrOfEmployees.map(async (employee) => {
      const workerRef = businessRef.collection("Workers").doc(employee.uid);
      const routesSnapshot = await workerRef.collection("Route").get();
      const routePromises = routesSnapshot.docs.map(async (routeDoc) => {
        const dayName = routeDoc.id;
        const stopsSnapshot = await workerRef
          .collection("Route")
          .doc(dayName)
          .collection("MovedStops")
          .get();

        stopsSnapshot.docs.forEach(async (stopDoc) => {
          let stop = stopDoc.data();
          // check if the stop.date is older than 7 days if it is skip
          if (dayjs().diff(dayjs(stop.originalDate), "days") > 7) {
            return;
          }
          stop.employeeUid = employee.uid;
          stop.businessId = selectedBusiness;
          const customersData = await db
            .collection("Businesses")
            .doc(stop.businessId)
            .collection("Workers")
            .doc(stop.employeeUid)
            .collection("Route")
            .doc(dayjs(stop.originalDate).format("dddd"))
            .collection("Stops")
            .doc(stop.stopId)
            .get();

          if (customersData.exists) {
            const stopData = customersData.data();
            stop.customerName = stopData.customerData?.name;
            currentRescheduledMarkers.push(stop);
          }
        });
      });
      await Promise.all(routePromises);
    });

    await Promise.all(employeePromises);

    const jobsSnapshot = await db
      .collection("Businesses")
      .doc(selectedBusiness)
      .collection("Jobs")
      .get();

    console.log(currentRescheduledMarkers);
    setRescheduledMarkers(currentRescheduledMarkers);
  };

  const handleGetUnscheduled = async (arrOfEmployees) => {
    const businessRef = db.collection("Businesses").doc(selectedBusiness);

    let currentUnscheduledMarkers = new Map();

    const employeePromises = arrOfEmployees.map(async (employee) => {
      const workerRef = businessRef.collection("Workers").doc(employee.uid);
      const routesSnapshot = await workerRef.collection("Route").get();
      const routePromises = routesSnapshot.docs.map(async (routeDoc) => {
        const dayName = routeDoc.id;
        const stopsSnapshot = await workerRef
          .collection("Route")
          .doc(dayName)
          .collection("Stops")
          .get();
        stopsSnapshot.docs.forEach((stopDoc) => {
          const stop = stopDoc.data();
          if (
            stop.unscheduledDates &&
            stop.unscheduledDates[0] &&
            stop.stopId &&
            !currentUnscheduledMarkers.has(
              stop.tempStopId ? stop.tempStopId : stop.stopId
            )
          ) {
            if (
              stop.rescheduledDates &&
              !stop.rescheduledDates.some((a) =>
                stop.unscheduledDates.includes(a)
              )
            ) {
              if (stop.customerData) {
                currentUnscheduledMarkers.set(stop.stopId, stop);
              }
            }
          }
        });
      });
      await Promise.all(routePromises);
    });

    await Promise.all(employeePromises);

    const jobsSnapshot = await db
      .collection("Businesses")
      .doc(selectedBusiness)
      .collection("Jobs")
      .get();

    jobsSnapshot.docs.forEach((doc) => {
      const data = doc.data();
      if (data.unscheduledStops && data.status !== "complete") {
        data.unscheduledStops.forEach((stop) => {
          if (
            !currentUnscheduledMarkers.has(
              stop.tempStopId ? stop.tempStopId : stop.stopId
            )
          ) {
            console.log(stop);
            currentUnscheduledMarkers.set(
              stop.tempStopId ? stop.tempStopId : stop.stopId,
              stop
            );
          }
        });
      }
    });
    const unscheduledMarkers = Array.from(currentUnscheduledMarkers.values());

    console.log(unscheduledMarkers);

    setUnscheduledMarkers(unscheduledMarkers);

    return unscheduledMarkers;
  };

  const handleReloadEntireRoute = (unscheduledStop) => {
    serviceLocationsOrder.current = [];
    setHoverDisplayData({});
    setHoverDisplay(false);
    setSelectedMarkerIndex();

    setUnscheduledMarkers([]);
    setRescheduledMarkers([]);
    markers.current = [];
    setMarkersDataForProp([]);

    db.collection("Businesses")
      .doc(selectedBusiness)
      .collection("Routes")
      .doc(selectedRoute.routeId)
      .collection("Employees")
      .get()
      .then((snapshot) => {
        let arrOfServiceLocations = [];

        let arrOfEmployees = [];

        snapshot.docs.forEach((value) => {
          const data = value.data();
          arrOfEmployees.push(data);
        });
        setAllEmployees(arrOfEmployees);

        let promises = snapshot.docs.map(async (doc) => {
          const docData = doc.data();
          return db
            .collection("Businesses")
            .doc(selectedBusiness)
            .collection("Workers")
            .doc(docData.uid)
            .collection("Route")
            .doc(dayjs(routeStartDate).format("dddd"))
            .collection("Stops")
            .get()
            .then(async (snap) => {
              let arrOfHiddenStops = [];

              return db
                .collection("Businesses")
                .doc(selectedBusiness)
                .collection("Workers")
                .doc(docData.uid)
                .collection("Route")
                .doc(dayjs(routeStartDate).format("dddd"))
                .collection("MovedStops")
                .get()
                .then(async (snapshotQuery) => {
                  if (snapshotQuery && snapshotQuery.docs) {
                    snapshotQuery.docs.forEach((value) => {
                      if (
                        dayjs(routeStartDate).format("MM/DD/YYYY") ===
                        value.data().originalDate
                      ) {
                        arrOfHiddenStops.push(value.data().stopId);
                      }
                    });
                  }

                  if (!snap.empty) {
                    snap.docs.forEach((value, index) => {
                      if (
                        !arrOfHiddenStops.includes(
                          value.data().tempStopId
                            ? value.data().tempStopId
                            : value.data().stopId
                        )
                      ) {
                        arrOfServiceLocations.push(value.data());
                      }
                    });
                    return db
                      .collection("Businesses")
                      .doc(selectedBusiness)
                      .collection("Workers")
                      .doc(docData.uid)
                      .collection("Route")
                      .doc("JobStops")
                      .get()
                      .then((documentSnapshot) => {
                        if (documentSnapshot.exists) {
                          const jobStopsData = documentSnapshot.data();
                          if (jobStopsData.route) {
                            jobStopsData.route.forEach((value, index) => {
                              if (
                                !arrOfHiddenStops.includes(
                                  value.tempStopId
                                    ? value.tempStopId
                                    : value.stopId
                                )
                              ) {
                                arrOfServiceLocations.push(value);
                              }
                            });
                          }
                        }
                      });
                  } else {
                    return db
                      .collection("Businesses")
                      .doc(selectedBusiness)
                      .collection("Workers")
                      .doc(docData.uid)
                      .collection("Route")
                      .doc("JobStops")
                      .get()
                      .then((documentSnapshot) => {
                        if (documentSnapshot.exists) {
                          const jobStopsData = documentSnapshot.data();
                          jobStopsData.route.forEach((value, index) => {
                            if (
                              !arrOfHiddenStops.includes(
                                value.tempStopId
                                  ? value.tempStopId
                                  : value.stopId
                              )
                            ) {
                              arrOfServiceLocations.push(value);
                            }
                          });
                        }
                      });
                  }
                });
            });
        });

        Promise.all(promises)
          .then(async () => {
            const unscheduled = await handleGetUnscheduled(arrOfEmployees);
            await handleGetRescheduled(arrOfEmployees);
            setServiceLocationsInRoute(arrOfServiceLocations);
            setForceEmployeeCardsReload(!forceEmployeeCardsReload);
            console.log("function call 22222222");
            getAllPoolAddresses(arrOfServiceLocations, unscheduled);
          })
          .catch((err) => console.error(err)); // Handle errors if any
      });
  };

  function getAdjustedLongitude(
    markers,
    props,
    routeStartDate,
    selectedUnscheduledStops,
    stopsBeingViewed
  ) {
    // Helper to determine if a marker is part of a multistop
    const isMultistop = (marker) =>
      markers
        .filter(
          (a) => a.serviceLocationId === marker.serviceLocationId && !a.hidden
        )
        .filter((a) =>
          props.searchTerm.length === 0
            ? true
            : a.address.toLowerCase().includes(props.searchTerm.toLowerCase())
        )
        .filter((a) =>
          !a.assigned
            ? selectedUnscheduledStops.some(
                (b) =>
                  (b.tempStopId ? b.tempStopId : b.stopId) ===
                  (a.tempStopId ? a.tempStopId : a.stopId)
              )
            : true
        )
        .filter((a) => {
          // if stopsBeingViewed is defined, filter by it
          if (stopsBeingViewed) {
            return stopsBeingViewed.includes(
              a.tempStopId ? a.tempStopId : a.stopId
            );
          } else {
            return true;
          }
        })
        .filter((a) =>
          a.unscheduledDates
            ? !a.unscheduledDates.includes(
                dayjs(routeStartDate).format("MM/DD/YYYY")
              )
            : true
        )
        .filter((a) =>
          a.tempStopId
            ? a.date === dayjs(routeStartDate).format("MM/DD/YYYY")
            : true
        ).length > 1;

    // Create a map to group markers by their latitude and longitude
    const groupedMarkers = markers.reduce((acc, marker) => {
      const key = `${marker.location.lat},${marker.location.lng}`;
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(marker);
      return acc;
    }, {});

    // Adjust the longitude for markers in the same group or handle multistops
    const adjustedMarkers = [];
    Object.values(groupedMarkers).forEach((group) => {
      if (group.some(isMultistop)) {
        // If any marker in the group is a multistop, add only the first marker as-is
        adjustedMarkers.push(group[0]);
      } else {
        // Adjust longitude for non-multistop markers
        group.forEach((marker, index) => {
          const adjustedMarker = {
            ...marker,
            location: {
              ...marker.location,
              lng: marker.location.lng + 0.004 * index, // Adjust for spacing
            },
          };
          adjustedMarkers.push(adjustedMarker);
        });
      }
    });

    return adjustedMarkers;
  }

  const getAllPoolAddresses = async (serviceLocations, unscheduled) => {
    console.log("GETTING POOL ADDRESSES");
    let addArr = [];

    // Handle routeOrder initialization
    if (routeOrder.current.length === 0) {
      const orderPromises = serviceLocations.map((serviceLocation) => {
        return new Promise((resolve) => {
          if (serviceLocation.dayOfTheWeek) {
            const currentDay = dayjs(routeStartDate).format("dddd");
            const currentDate = dayjs(routeStartDate).format("MM/DD/YYYY");
            const isFrequencyOnce = serviceLocation.frequency === "once";
            const dayMatch = isFrequencyOnce
              ? serviceLocation.stopDate === currentDate
              : serviceLocation.dayOfTheWeek === currentDay;

            if (dayMatch) {
              resolve(serviceLocation.tempStopId || serviceLocation.stopId);
            } else {
              resolve(null);
            }
          } else if (
            serviceLocation.frequency === "once" &&
            serviceLocation.stopObject &&
            serviceLocation.stopObject.stopDate ===
              dayjs(routeStartDate).format("MM/DD/YYYY")
          ) {
            resolve(serviceLocation.stopId);
          } else {
            resolve(null);
          }
        });
      });

      const orderResults = await Promise.all(orderPromises);
      routeOrder.current = orderResults.filter((id) => id !== null);
    }

    // Sorting serviceLocations based on routeOrder
    const sortedServiceLocations = [...serviceLocations].sort((a, b) => {
      const aId = a.tempStopId || a.stopId;
      const bId = b.tempStopId || b.stopId;
      return routeOrder.current.indexOf(aId) - routeOrder.current.indexOf(bId);
    });

    // Process sortedServiceLocations
    const serviceLocationPromises = sortedServiceLocations.map((value) => {
      return new Promise((resolve) => {
        let location = value.location || value.coordinates;
        const serviceLocationData = value;
        const markerId = generateBusinessId();

        const currentDay = dayjs(routeStartDate).format("dddd");
        const currentDate = dayjs(routeStartDate).format("MM/DD/YYYY");
        const isFrequencyOnce = value.frequency === "once";
        const dayMatch = value.dayOfTheWeek
          ? isFrequencyOnce
            ? value.stopDate === currentDate
            : value.dayOfTheWeek === currentDay
          : false;

        if (value.dayOfTheWeek && dayMatch) {
          const showStop = shouldShowStop(
            value.biWeeklyStartDate,
            currentDate,
            value.dayOfTheWeek
          );

          const markerData = {
            location: location,
            name: serviceLocationData.name,
            address: serviceLocationData.address,
            serviceLocationId: serviceLocationData.serviceLocationId,
            routeId: selectedRoute.routeId,
            customerId: serviceLocationData.customerId || value.customerId,
            assigned: true,
            day: value.dayOfTheWeek || "none",
            dayOfTheWeek: value.dayOfTheWeek || "none",
            originalDate: value.originalDate,
            originalEmployee: value.originalEmployee,
            employeePhoto: value.contractor
              ? value.contractor.profilePhoto
              : "none",
            employeeUid: value.contractor ? value.contractor.id : "none",
            employeeName: value.contractor.name,
            recurringStopType:
              serviceLocationData.recurringStopType || value.type,
            markerId: markerId,
            type: "recStop",
            stopId: value.stopId,
            customerName: value.customerData.name,
            customerData: value.customerData,
            stopObject: value.stopObject,
            completedDates: value.completedDates,
            stopWorkerDescription: value.stopWorkerDescription,
            stopCustomerDescription: value.stopCustomerDescription,
            stopTemplateId: value.stopTemplateId,
            stopNickname: value.stopNickname,
            contractor: value.contractor,
            stopCount: value.tempStopId ? 3 : 1, // Adjusted based on context
            unscheduledDates: value.unscheduledDates || [],
            rescheduledDates: value.rescheduledDates || [],
            tempStopId: value.tempStopId || undefined,
            date: value.date,
            hidden: hiddenEmployees.includes(value.contractor.id),
            biWeeklyStartDate: value.biWeeklyStartDate,
            showStop: showStop,
          };

          resolve(markerData);
        } else if (
          value.frequency === "once" &&
          value.stopObject &&
          value.stopObject.stopDate === currentDate
        ) {
          const markerData = {
            location: location,
            name: value.name,
            address: value.address,
            serviceLocationId: value.serviceLocationId,
            routeId: selectedRoute.routeId,
            customerId: value.customerId,
            assigned: true,
            originalDate: value.originalDate || "none",
            originalEmployee: value.originalEmployee || "none",
            day: value.stopObject.dayOfTheWeek || "none",
            dayOfTheWeek: value.stopObject.dayOfTheWeek || "none",
            employeePhoto:
              value.contractor.photo || value.contractor.profilePhoto || "none",
            employeeUid: value.contractor.uid || value.contractor.id || "none",
            employeeName: value.contractor.name,
            recurringStopType: value.recurringStopType,
            markerId: markerId,
            type: "stop",
            stopId: value.stopId,
            stopCount: 1,
            customerName: value.customerData.name,
            customerData: value.customerData,
            stopObject: value.stopObject,
            stopTemplateId: value.stopTemplateId,
            tempStopId: value.tempStopId || undefined,
            date: value.date || undefined,
            hidden: hiddenEmployees.includes(value.contractor.id),
            showStop: true,
          };

          resolve(markerData);
        } else {
          resolve(null);
        }
      });
    });

    const serviceLocationResults = await Promise.all(serviceLocationPromises);
    addArr.push(...serviceLocationResults.filter((item) => item !== null));

    // Process unscheduled markers
    const unscheduledPromises = unscheduled.map((unscheduledMarker) => {
      return new Promise((resolve) => {
        const markerId = generateBusinessId();
        const markerData = {
          location:
            unscheduledMarker.stopServiceLocationCoordinates ||
            unscheduledMarker.location,
          name:
            unscheduledMarker.title ||
            unscheduledMarker.stopTitle ||
            unscheduledMarker.name,
          address: unscheduledMarker.stopServiceLocation
            ? unscheduledMarker.stopServiceLocation.address
            : unscheduledMarker.address,
          serviceLocationId: unscheduledMarker.stopServiceLocation
            ? unscheduledMarker.stopServiceLocation.serviceLocationId
            : unscheduledMarker.serviceLocationId,
          routeId: selectedRoute.routeId,
          customerId: unscheduledMarker.stopServiceLocation
            ? unscheduledMarker.stopServiceLocation.customerId
            : unscheduledMarker.customerData.customerId,
          assigned: false,
          day: "none",
          dayOfTheWeek: "none",
          employeePhoto: "none",
          employeeUid: "none",
          employeeName: "none",
          originalDate: unscheduledMarker.originalDate || "none",
          originalEmployee: unscheduledMarker.originalEmployee || "none",
          unscheduledDates: unscheduledMarker.unscheduledDates || [],
          rescheduledDates: unscheduledMarker.rescheduledDates || [],
          type: unscheduledMarker.routeId ? "recStop" : "stop",
          markerId: markerId,
          stopId: unscheduledMarker.tempStopId || unscheduledMarker.stopId,
          customerName: unscheduledMarker.customer
            ? unscheduledMarker.customer.customerName ||
              unscheduledMarker.customer.name
            : unscheduledMarker.customerData.name,
          unassignedStopObject: unscheduledMarker,
          stopServiceLocation: unscheduledMarker.stopServiceLocation,
          stopTemplateId: unscheduledMarker.stopTemplateId,
          jobId: unscheduledMarker.jobId || "none",
          hidden: false,
          showStop: true,
        };

        resolve(markerData);
      });
    });

    const unscheduledResults = await Promise.all(unscheduledPromises);
    addArr.push(...unscheduledResults);

    // Update markers and state
    console.log(addArr)
    markers.current = addArr;
    setMarkersDataForProp(addArr);
    setRefresh((prev) => !prev);
  };

  const handleApiLoaded = (map, maps) => {
    if (mapsObject === undefined && mapObject === undefined) {
      setMapsObject(maps);
      setMapObject(map);
    }
  };
  const handleOnHover = (value, index) => {
    if (value.multiStop) {
      console.log("0009090099090");
      console.log(multiStopMarkerData);
      console.log(value.value);
      if (
        multiStopMarkerData?.value?.some((marker) =>
          value?.value?.some(
            (stop) =>
              marker.stopId ===
              (stop.tempStopId ? stop.tempStopId : stop.stopId)
          )
        )
      ) {
        setMultiStopMarkerData({});
        setMultiStopMarkerDisplay(false);
        setSelectedSubMarker();
      } else {
        console.log("93458348590340");
        setMultiStopMarkerData(value);
        setHoverDisplay(false);
        setMultiStopMarkerDisplay(true);
        setHoverDisplayData({});
        setSelectedSubMarker();
      }
    } else {
      if (
        hoverDisplayData.value &&
        hoverDisplayData.value.markerId === value.value.markerId &&
        selectedMarkerIndex === index
      ) {
        console.log("000000");
        setHoverDisplayData({});
        setHoverDisplay(false);
        setSelectedMarkerIndex();
        setSelectedSubMarker();
        if (dayViewDisplay) {
          // get the stops being viewed

          setSelectedDayViewCard(null);
        }
      } else {
        console.log("111111");
        setHoverDisplayData(value);
        setHoverDisplay(true);
        setSelectedMarkerIndex(index);
        setSelectedSubMarker();
        // check if day view is open
        if (dayViewDisplay) {
          // get the stops being viewed

          console.log(dayData);

          let stop = dayData.find(
            (a) =>
              (a.tempStopId ? a.tempStopId : a.stopId) ===
              (value.value.tempStopId
                ? value.value.tempStopId
                : value.value.stopId)
          );

          if (!stop) {
            // gotta check the other value layout I guess find the current viewed employee in dayData
            stop = dayData[dayViewData.employee.uid].find(
              (a) =>
                (a.tempStopId ? a.tempStopId : a.stopId) ===
                (value.value.tempStopId
                  ? value.value.tempStopId
                  : value.value.stopId)
            );
          }

          setSelectedDayViewCard(stop);
        }
      }
    }
  };

  const handleOnHoverNew = (value, index) => {
    if (value.multiStop) return;

    if (
      selectedMarkerIndex ||
      selectedMarkerIndex === 0 ||
      selectedSubMarker ||
      selectedSubMarker === 0
    ) {
      return;
    }

    if (value === "exit") {
      setHoverDisplayData({});
      setHoverDisplay(false);
      setSelectedMarkerIndex();
      setSelectedSubMarker();
      return;
    }

    if (
      hoverDisplayData.value &&
      hoverDisplayData.value.markerId === value.value.markerId
    ) {
      setHoverDisplayData({});
      setHoverDisplay(false);
      setSelectedMarkerIndex();
      setSelectedSubMarker();
    } else {
      setHoverDisplayData(value);
      setHoverDisplay(true);
      setSelectedSubMarker();
    }
  };

  const handleToggleHiddenDay = (day, boolean, employee) => {
    if (boolean) {
      if (hiddenEmployees.includes(employee)) {
        setHiddenEmployees((prev) => prev.filter((a) => a !== employee));
      }
      for (let index = 0; index < markers.current.length; index++) {
        let marker = markers.current[index];

        if (marker.day === day && marker.employeeUid === employee) {
          markers.current[index].hidden = false;
          setRefresh(!refresh);
        }
      }
    } else {
      if (!hiddenEmployees.includes(employee)) {
        setHiddenEmployees((prev) => [...prev, employee]);
      }
      for (let index = 0; index < markers.current.length; index++) {
        let marker = markers.current[index];

        if (marker.day === day && marker.employeeUid === employee) {
          markers.current[index].hidden = true;
          setRefresh(!refresh);
        }
      }
    }
  };

  const handleAddUnscheduledStopToDb = (selectedEmployee, value, newDate) => {
    // need to do dates

    const stopId = generateBusinessId();

    const stopData = {
      dayOfTheWeek: dayjs(newDate).format("dddd"),
      frequency: "once",
      startOn: dayjs(newDate).format("MM/DD/YYYY"),
      stopAfter: "no end",
      name: value.stopTitle || value.unassignedStopObject.stopTitle,
      address: value.stopServiceLocation.address,
      stopId: stopId,
      routeId: selectedRoute.routeId,
      serviceLocationId: value.stopServiceLocation.serviceLocationId,
      completedDates: [],
      contractor: {
        id: selectedEmployee.uid,
        name: selectedEmployee.name,
        profilePhoto: selectedEmployee.photo,
      },
      customerData: {
        name: value.unassignedStopObject
          ? value.unassignedStopObject.customer.customerName
          : value.customer.name || value.customer.customerName,
        customerId: value.unassignedStopObject
          ? value.unassignedStopObject.customer.customerId
          : value.customer.customerId,
        businessId: value.stopServiceLocation.businessId,
      },
      businessId: value.stopServiceLocation.businessId,
      stopNickname: value.stopTitle || value.unassignedStopObject.stopTitle,
      location: value.stopServiceLocationCoordinates || value.location,
      stopTemplateId: "none",
      stopCustomerDescription:
        value.stopTitle || value.unassignedStopObject.stopTitle,
      stopWorkerDescription:
        value.stopDescription || value.unassignedStopObject.stopDescription,
      stopDate: dayjs(newDate).format("MM/DD/YYYY"),
      type: "Job Stop",
      messageId: "none",
      jobId: value.jobId,
    };

    db.collection("Businesses")
      .doc(value.stopServiceLocation.businessId)
      .get()
      .then((documentSnapshot) => {
        const businessData = documentSnapshot.data();

        db.collection("Businesses")
          .doc(value.stopServiceLocation.businessId)
          .collection("Jobs")
          .doc(value.jobId)
          .get()
          .then((documentSnapshot) => {
            const jobData = documentSnapshot.data();
            firebase
              .auth()
              .currentUser.getIdToken()
              .then((token) => {
                fetch(
                  "https://us-central1-symbri-production.cloudfunctions.net/sendCustomerNotification",
                  {
                    method: "POST",
                    body: JSON.stringify({
                      token: token,
                      notificationData: {
                        hasApp: {
                          title: "Job stop has been scheduled!",
                          body:
                            "The stop " +
                            (value.stopTitle ||
                              value.unassignedStopObject.stopTitle) +
                            " under the job " +
                            jobData.jobTitle +
                            " has been scheduled for " +
                            dayjs(newDate).format("MM/DD/YYYY") +
                            ".",
                        },
                        noApp: {
                          title: "Job stop been scheduled!",
                          body:
                            "The stop " +
                            value.stopTitle +
                            " under the job " +
                            jobData.jobTitle +
                            " has been scheduled. Please download the app and login into view, when the stop is scheduled for, the the stop details, and message " +
                            businessData.name +
                            " with any questions.",
                        },
                      },
                      customerId: value.unassignedStopObject
                        ? value.unassignedStopObject.customer.customerId
                        : value.customer.customerId,
                    }),
                  }
                );
              });
          });
      });

    db.collection("Customers")
      .doc(
        value.unassignedStopObject
          ? value.unassignedStopObject.customer.customerId
          : value.customer.customerId
      )
      .collection("ServiceLocations")
      .doc(value.stopServiceLocation.serviceLocationId)
      .update({
        routes: firebase.firestore.FieldValue.arrayUnion(stopData),
      });

    const routeData = {
      frequency: "once",
      name: value.stopTitle || value.unassignedStopObject.stopTitle,
      address: value.stopServiceLocation.address,
      stopObject: stopData,
      routeId: stopId,
      jobId: value.jobId,
      serviceLocationId: value.stopServiceLocation.serviceLocationId,
      type: "stop",
      completed: false,
      stopId: stopId,
      contractor: selectedEmployee,
      location: value.stopServiceLocationCoordinates || value.location,
      customerData: {
        name: value.unassignedStopObject
          ? value.unassignedStopObject.customer.customerName
          : value.customer.name || value.customer.customerName,
        customerId: value.unassignedStopObject
          ? value.unassignedStopObject.customer.customerId
          : value.customer.customerId,
        businessId: value.stopServiceLocation.businessId,
      },
    };

    db.collection("Businesses")
      .doc(value.stopServiceLocation.businessId)
      .collection("Workers")
      .doc(selectedEmployee.uid)
      .collection("Route")
      .doc("JobStops")
      .set(
        {
          route: firebase.firestore.FieldValue.arrayUnion(routeData),
          checkDistanceTime: true,
        },
        { merge: true }
      );

    db.collection("Businesses")
      .doc(value.stopServiceLocation.businessId)
      .collection("Jobs")
      .doc(value.jobId)
      .update({
        stops: firebase.firestore.FieldValue.arrayRemove(value),
        unscheduledStops: firebase.firestore.FieldValue.arrayRemove(value),
      })
      .then(() => {
        db.collection("Businesses")
          .doc(value.stopServiceLocation.businessId)
          .collection("Jobs")
          .doc(value.jobId)
          .update({
            stops: firebase.firestore.FieldValue.arrayUnion(stopData),
            status: "scheduled",
          })
          .then(() => {
            setScheduleOneTimeStopDisplay(false);
            handleReloadEntireRoute();
          });
      });
  };

  const getCountInRoute = (data) => {
    // Determine if the stop is part of a multistop by checking markers with the same serviceLocationId
    const serviceMarkers = markers.current.filter(
      (a) => a.serviceLocationId === data.serviceLocationId && !a.hidden
    );
    const markersToCheck = markers.current
      .filter(
        (a) => a.serviceLocationId === data.serviceLocationId && !a.hidden
      )
      .filter((a) =>
        props.searchTerm.length === 0
          ? true
          : a.address.toLowerCase().includes(props.searchTerm.toLowerCase())
      )
      .filter((a) =>
        !a.assigned
          ? selectedUnscheduledStops.some(
              (b) =>
                (b.tempStopId ? b.tempStopId : b.stopId) ===
                (a.tempStopId ? a.tempStopId : a.stopId)
            )
          : true
      )
      .filter((a) =>
        a.unscheduledDates
          ? !a.unscheduledDates.includes(
              dayjs(routeStartDate).format("MM/DD/YYYY")
            )
          : true
      )
      .filter((a) =>
        a.tempStopId
          ? a.date === dayjs(routeStartDate).format("MM/DD/YYYY")
          : true
      )
      .filter((a) => a.showStop)
      .filter((a) => {
        // if stopsBeingViewed is defined, filter by it
        if (stopsBeingViewed) {
          return stopsBeingViewed.includes(
            a.tempStopId ? a.tempStopId : a.stopId
          );
        } else {
          return true;
        }
      });

    // first need to check if multistop marker

    let matchesAssignedMarkers = [];

    for (let index = 0; index < markersToCheck.length; index++) {
      const marker = markersToCheck[index];

      // If the marker is not hidden and matches the serviceLocationId and the day is not "none"
      if (marker.serviceLocationId === data.serviceLocationId) {
        matchesAssignedMarkers.push(marker);
      }
    }
    const isMultiStop = matchesAssignedMarkers.length > 1;

    if (!isMultiStop) {
      // For a single stop, use getAdjustedLongitude and the full chain of filters.

      const orderedMarkers = getAdjustedLongitude(
        markers.current,
        props,
        routeStartDate,
        selectedUnscheduledStops,
        stopsBeingViewed
      )
        .filter((value) =>
          stopsBeingViewed
            ? selectedUnscheduledStops.some(
                (b) =>
                  (b.tempStopId ? b.tempStopId : b.stopId) ===
                  (value.tempStopId ? value.tempStopId : value.stopId)
              ) ||
              stopsBeingViewed.includes(
                value.tempStopId ? value.tempStopId : value.stopId
              )
            : true
        )
        .filter((a) =>
          props.searchTerm.length === 0
            ? true
            : a.customerName
                .toLowerCase()
                .includes(props.searchTerm.toLowerCase())
        )
        .filter((a) =>
          !a.assigned
            ? selectedUnscheduledStops.some(
                (b) =>
                  (b.tempStopId ? b.tempStopId : b.stopId) ===
                  (a.tempStopId ? a.tempStopId : a.stopId)
              )
            : true
        )
        .filter((a) =>
          a.assigned && a.unscheduledDates
            ? !a.unscheduledDates.includes(
                dayjs(routeStartDate).format("MM/DD/YYYY")
              )
            : true
        )
        .filter((a) =>
          a.tempStopId
            ? a.date === dayjs(routeStartDate).format("MM/DD/YYYY")
            : true
        )
        .filter((value) => {
          // Only return a marker if its associated group has exactly one visible stop
          if (
            markers.current
              .filter(
                (a) =>
                  a.serviceLocationId === value.serviceLocationId && !a.hidden
              )
              .filter((a) =>
                props.searchTerm.length === 0
                  ? true
                  : a.address
                      .toLowerCase()
                      .includes(props.searchTerm.toLowerCase())
              )
              .filter((a) =>
                !a.assigned
                  ? selectedUnscheduledStops.some(
                      (b) =>
                        (b.tempStopId ? b.tempStopId : b.stopId) ===
                        (a.tempStopId ? a.tempStopId : a.stopId)
                    )
                  : true
              )
              .filter((a) =>
                a.unscheduledDates
                  ? !a.unscheduledDates.includes(
                      dayjs(routeStartDate).format("MM/DD/YYYY")
                    )
                  : true
              )
              .filter((a) =>
                a.tempStopId
                  ? a.date === dayjs(routeStartDate).format("MM/DD/YYYY")
                  : true
              ).length === 1
          ) {
            return value.showStop;
          }
          return true;
        });
      // sort by custom route order
      const index = orderedMarkers
        .sort(
          (a, b) =>
            customRouteOrder.indexOf(a.stopId) -
            customRouteOrder.indexOf(b.stopId)
        )
        .findIndex(
          (a) =>
            (a.tempStopId ? a.tempStopId : a.stopId) ===
            (data.tempStopId ? data.tempStopId : data.stopId)
        );

      return index + 1; // Return 1-indexed count
    } else {
      // For multistop, replicate the filtering chain used in your handleDayViewCardClick multistop section.
      // Note: We now call getAdjustedLongitude first to ensure the same ordering as in the single-stop branch.
      console.log(data);
      const markersToCheck = markers.current.filter((a) => {
        // if stopsBeingViewed is defined, filter by it
        if (stopsBeingViewed) {
          return stopsBeingViewed.includes(
            a.tempStopId ? a.tempStopId : a.stopId
          );
        } else {
          return true;
        }
      });
      let index = -1;
      for (let index = 0; index < markersToCheck.length; index++) {
        const marker = markersToCheck[index];

        // If the marker is not hidden and matches the serviceLocationId and stopid or tempStopId match
        if (
          marker.serviceLocationId === data.serviceLocationId &&
          (marker.tempStopId ? marker.tempStopId : marker.stopId) ===
            (data.tempStopId ? data.tempStopId : data.stopId)
        ) {
          const markerIndex = index;
          console.log(markerIndex);
          return markerIndex + 1; // Return 1-indexed count
        }
      }

      return index; // Return 1-indexed count
    }
  };

  const handleAddUnscheduledStopToDay = (value, allEmployees) => {
    setSelectedUnscheduledStop(value);

    // need a way to select the contractor you want to add to
    // then use selected date as the date
    // just do a popup with a list of workers to choose from maybe?
  };

  window.addEventListener("resize", (event) => {
    setMapWidth(document.getElementsByClassName("py-6")[0].offsetWidth + "px");
  });

  const handleOpenReschedule = (stopData) => {
    if (stopData.stopObject) {
      setViewDetailsOneTimeDisplay(false);
      setViewDetailsRecDisplay(false);
      setOneTimeRescheduleDisplay(true);
      setRescheduleData(stopData);
      setHoverDisplayData({});
      setHoverDisplay(false);
    } else {
      setViewDetailsOneTimeDisplay(false);
      setViewDetailsRecDisplay(false);
      setRescheduleDisplay(true);
      setRescheduleData(stopData);
      setHoverDisplayData({});
      setHoverDisplay(false);
    }
  };

  const handleOpenSchedule = (stopData) => {
    setRescheduleData(stopData);
    setViewDetailsOneTimeDisplay(false);
    setScheduleOneTimeStopDisplay(true);
  };

  const handleViewDetails = (data) => {
    if (data.unassignedStopObject) {
      console.log(data.unassignedStopObject);
      setViewDetailsData(data.unassignedStopObject);
    } else {
      setViewDetailsData(data);
    }

    setHoverDisplayData({});
    setHoverDisplay(false);
    setSelectedMarkerIndex();

    if (data.type === "Reoccurring Stop" || data.type === "recStop") {
      setViewDetailsOneTimeDisplay(false);
      setViewDetailsRecDisplay(true);
    } else if (data.type === "stop") {
      setViewDetailsOneTimeDisplay(true);
      setViewDetailsRecDisplay(false);
    } else if (data.stopServiceLocation) {
      setViewDetailsOneTimeDisplay(true);
      setViewDetailsRecDisplay(false);
    }
  };

  const handleUpdateRouteOrder = (newOrder) => {
    routeOrder.current = newOrder;
  };

  const handleCompleteRecTimeStop = async (stop, date, workerUid) => {
    await db
      .collection("Businesses")
      .doc(selectedBusiness)
      .collection("Workers")
      .doc(workerUid)
      .collection("Route")
      .doc(dayjs(date).format("dddd"))
      .collection("Stops")
      .doc(stop.tempStopId ? stop.tempStopId : stop.stopId)
      .update({
        completedDates: firebase.firestore.FieldValue.arrayUnion(
          dayjs(date).format("MM/DD/YYYY")
        ),
      });

    const customerServiceLocationDoc = await db
      .collection("Customers")
      .doc(stop.customerData.customerId)
      .collection("ServiceLocations")
      .doc(stop.serviceLocationId)
      .get();

    const customerServiceLocationData = customerServiceLocationDoc.data();

    let routes = customerServiceLocationData.routes;

    let indexOfRoute = routes.findIndex((a) =>
      a.tempStopId
        ? a.tempStopId
        : a.stopId === stop.tempStopId
        ? stop.tempStopId
        : stop.stopId
    );

    routes[indexOfRoute].completedDates.push(dayjs(date).format("MM/DD/YYYY"));

    await db
      .collection("Customers")
      .doc(stop.customerData.customerId)
      .collection("ServiceLocations")
      .doc(stop.serviceLocationId)
      .update({
        routes: routes,
      });

    setViewDetailsRecDisplay(false);
    toast.success("Stop Completed");
    handleReloadEntireRoute();
  };

  const handleCompleteOneTimeStop = (stop, date, workerUid) => {
    db.collection("Businesses")
      .doc(selectedBusiness)
      .collection("Workers")
      .doc(workerUid)
      .collection("Route")
      .doc("JobStops")
      .get()
      .then((jobStopsDocumentSnapshot) => {
        const jobStopsDoc = jobStopsDocumentSnapshot.data();
        let stops = jobStopsDoc.route;

        let indexOfStops = stops.findIndex(
          (a) => a.stopObject.stopId === stop.stopObject.stopId
        );

        stops[indexOfStops].stopObject.completedDates.push(
          dayjs(date).format("MM/DD/YYYY")
        );

        stops[indexOfStops].completed = true;

        db.collection("Businesses")
          .doc(selectedBusiness)
          .collection("Workers")
          .doc(workerUid)
          .collection("Route")
          .doc("JobStops")
          .update({ route: stops })
          .then(async () => {
            const jobDoc = await db
              .collection("Businesses")
              .doc(selectedBusiness)
              .collection("Jobs")
              .doc(stop.stopObject.jobId)
              .get();

            const jobData = jobDoc.data();

            let stops = jobData.stops;
            let indexOfStops = stops.findIndex(
              (a) => a.stopId === stop.stopObject.stopId
            );
            stops[indexOfStops].completedDates.push(
              dayjs(date).format("MM/DD/YYYY")
            );

            db.collection("Businesses")
              .doc(selectedBusiness)
              .collection("Jobs")
              .doc(stop.stopObject.jobId)
              .update({
                stops: stops,
                completedDates: firebase.firestore.FieldValue.arrayUnion(
                  dayjs(date).format("MM/DD/YYYY")
                ),
              })
              .then(() => {
                setViewDetailsOneTimeDisplay(false);
                handleReloadEntireRoute();
              })
              .catch((e) => console.log(e));
          })
          .catch((e) => console.log(e));
      })
      .catch((e) => console.log(e));
  };

  const handleViewDay = (data, employee, completedStops) => {
    if (orderListener.current) {
      orderListener.current();
    }

    if (viewDayListener.current) {
      viewDayListener.current();
    }

    setSelectedDayViewEmployee(employee);

    orderListener.current = db
      .collection("Businesses")
      .doc(selectedBusiness)
      .collection("Workers")
      .doc(employee.uid)
      .collection("Route")
      .doc(dayjs(routeStartDate).format("dddd"))
      .onSnapshot((workerRouteDocumentSnapshot) => {
        if (workerRouteDocumentSnapshot.exists) {
          const workerRouteDoc = workerRouteDocumentSnapshot.data();
          if (
            workerRouteDoc.customRouteOrders &&
            workerRouteDoc.customRouteOrders.some(
              (a) => a.date === dayjs(routeStartDate).format("MM/DD/YYYY")
            )
          ) {
            const order =
              workerRouteDoc.customRouteOrders[
                workerRouteDoc.customRouteOrders.findIndex(
                  (a) => a.date === dayjs(routeStartDate).format("MM/DD/YYYY")
                )
              ].order;

            handleUpdateRouteOrder(order);
            setCustomerRouteOrder(order);
          } else {
            let order = [];
            const defaultRouteOrder = workerRouteDoc.defaultRouteOrder || [];

            // check to see if defaultRouteOrder missing any stops in data

            if (defaultRouteOrder.length !== data.length) {
              // add the missing stops to default route order

              data.forEach((stop) => {
                if (
                  !defaultRouteOrder.includes(stop.tempStopId || stop.stopId)
                ) {
                  defaultRouteOrder.push(stop.tempStopId || stop.stopId);
                }
              });

              db.collection("Businesses")
                .doc(selectedBusiness)
                .collection("Workers")
                .doc(employee.uid)
                .collection("Route")
                .doc(dayjs(routeStartDate).format("dddd"))
                .update({
                  defaultRouteOrder: defaultRouteOrder,
                });
            }

            // update the defaultRouteOrder in the database

            // sort by default order
            console.log("using default order");

            setCustomerRouteOrder(defaultRouteOrder);
          }

          setDayViewData({
            data: data,
            employee: employee,
            completedStops: completedStops,
          });
          setDayViewDisplay(true);
          const stopsViewed = data.map((a) =>
            a.tempStopId ? a.tempStopId : a.stopId
          );

          setStopsBeingViewed(stopsViewed);
        } else {
          setDayViewData({
            data: data,
            employee: employee,
            completedStops: completedStops,
          });
          setDayViewDisplay(true);
          const stopsViewed = data.map((a) =>
            a.tempStopId ? a.tempStopId : a.stopId
          );

          setStopsBeingViewed(stopsViewed);
        }
      });
  };

  const shouldShowStop = (biWeeklyStartDate, viewedDate, stopDay) => {
    if (biWeeklyStartDate === "none" || !biWeeklyStartDate) return true;
    const daysOfWeek = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];

    // Helper to parse a date string (mm/dd/yyyy) into a Date object
    const parseDate = (dateString) => {
      const [month, day, year] = dateString.split("/").map(Number);
      return new Date(year, month - 1, day);
    };

    // Parse input dates
    const startDate = parseDate(biWeeklyStartDate);
    const currentDate = parseDate(viewedDate);

    // Validate stopDay and get its index
    const stopDayIndex = daysOfWeek.indexOf(stopDay);
    if (stopDayIndex === -1)
      throw new Error("Invalid day of the week provided");

    // Check if the current date is before the biWeeklyStartDate
    if (currentDate < startDate) return false;

    // Ensure the viewed date matches the stop day
    if (currentDate.getDay() !== stopDayIndex) return false;

    // Calculate the difference in weeks between startDate and currentDate
    const timeDifference = currentDate - startDate;
    const daysDifference = timeDifference / (1000 * 60 * 60 * 24);
    const weeksDifference = Math.floor(daysDifference / 7);

    // Return true if the difference in weeks is even (biweekly schedule)
    return weeksDifference % 2 === 0;
  };

  const handleOpenReport = async (data) => {
    try {
      const customerReportsQuery = await db
        .collection("Customers")
        .doc(data.customerData.customerId)
        .collection("PoolReports")
        .where("businessId", "==", selectedBusiness)
        .where("stopId", "==", data.stopId)
        .where(
          "formattedReportDate",
          "==",
          dayjs(routeStartDate).format("MM/DD/YYYY")
        )
        .get();

      if (!customerReportsQuery.empty) {
        // get customer data

        const customerData = await db
          .collection("Customers")
          .doc(data.customerData.customerId)
          .get();

        setReportCustomerData(customerData.data());

        const serviceLocationData = await db
          .collection("Customers")
          .doc(data.customerData.customerId)
          .collection("ServiceLocations")
          .doc(data.serviceLocationId)
          .get();

        setReportServicLocation(serviceLocationData.data());

        setReportData(customerReportsQuery.docs[0].data());
        setViewRecReport(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleOpenReportJob = async (data) => {
    try {
      if (data.customerData?.customerId) {
        const querySnapshot = await db
          .collection("Customers")
          .doc(data.customerData.customerId)
          .collection("PoolReports")
          .where("businessId", "==", selectedBusiness)
          .where(
            "jobStopId",
            "==",
            data.stopObject?.tempStopId
              ? data.stopObject?.tempStopId
              : data.stopObject?.stopId
          )
          .get();

        const reports = querySnapshot.docs.map((doc) => doc.data());

        const exactReport = reports[0];

        if (exactReport) {
          // get job data

          const jobData = await db
            .collection("Businesses")
            .doc(selectedBusiness)
            .collection("Jobs")
            .doc(data.stopObject?.jobId)
            .get();

          setJobData(jobData.data());

          setJobReportData(exactReport);
          setViewJobReport(true);
        }
      } else if (data.jobStopId) {
        const querySnapshot = await db
          .collection("Customers")
          .doc(data.customerId)
          .collection("PoolReports")
          .where("businessId", "==", selectedBusiness)
          .where("jobStopId", "==", data.jobStopId)
          .get();

        const reports = querySnapshot.docs.map((doc) => doc.data());

        const exactReport = reports[0];

        if (exactReport) {
          // get job data

          const jobData = await db
            .collection("Businesses")
            .doc(selectedBusiness)
            .collection("Jobs")
            .doc(data.reportJobId)
            .get();

          setJobData(jobData.data());

          setJobReportData(exactReport);
          setViewJobReport(true);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleViewJob = async (data) => {
    const jobDoc = await db
      .collection("Businesses")
      .doc(selectedBusiness)
      .collection("Jobs")
      .doc(data.stopObject.jobId)
      .get();

    const jobData = jobDoc.data();

    setViewJobData(jobData);
    setDisplayViewJob(true);
    // close view one time stop
    setViewDetailsOneTimeDisplay(false);
  };

  const handleDayViewCardClick = (data) => {
    console.log(data);
    setSelectedDayViewCard(data);
    if (!data) {
      // unselect everything
      setHoverDisplayData({});
      setHoverDisplay(false);
      setSelectedMarkerIndex();
      setSelectedSubMarker();
      return;
    }

    const markersToCheck = markers.current
      .filter(
        (a) => a.serviceLocationId === data.serviceLocationId && !a.hidden
      )
      .filter((a) =>
        props.searchTerm.length === 0
          ? true
          : a.address.toLowerCase().includes(props.searchTerm.toLowerCase())
      )
      .filter((a) =>
        !a.assigned
          ? selectedUnscheduledStops.some(
              (b) =>
                (b.tempStopId ? b.tempStopId : b.stopId) ===
                (a.tempStopId ? a.tempStopId : a.stopId)
            )
          : true
      )
      .filter((a) =>
        a.unscheduledDates
          ? !a.unscheduledDates.includes(
              dayjs(routeStartDate).format("MM/DD/YYYY")
            )
          : true
      )
      .filter((a) =>
        a.tempStopId
          ? a.date === dayjs(routeStartDate).format("MM/DD/YYYY")
          : true
      )
      .filter((a) => a.showStop)
      .filter((a) => {
        // if stopsBeingViewed is defined, filter by it
        if (stopsBeingViewed) {
          return stopsBeingViewed.includes(
            a.tempStopId ? a.tempStopId : a.stopId
          );
        } else {
          return true;
        }
      });

    // first need to check if multistop marker

    let matchesAssignedMarkers = [];

    for (let index = 0; index < markersToCheck.length; index++) {
      const marker = markersToCheck[index];

      // If the marker is not hidden and matches the serviceLocationId and the day is not "none"
      if (marker.serviceLocationId === data.serviceLocationId) {
        matchesAssignedMarkers.push(marker);
      }
    }

    const isMultiStop = matchesAssignedMarkers.length > 1;

    const markerIndex = getAdjustedLongitude(
      markers.current,
      props,
      routeStartDate,
      selectedUnscheduledStops,
      stopsBeingViewed
    )
      .filter((value) =>
        stopsBeingViewed
          ? selectedUnscheduledStops.some(
              (b) =>
                (b.tempStopId ? b.tempStopId : b.stopId) ===
                (value.tempStopId ? value.tempStopId : value.stopId)
            ) ||
            stopsBeingViewed.includes(
              value.tempStopId ? value.tempStopId : value.stopId
            )
          : true
      )
      .filter((a) =>
        props.searchTerm.length === 0
          ? true
          : a.customerName
              .toLowerCase()
              .includes(props.searchTerm.toLowerCase())
      )
      .filter((a) =>
        !a.assigned
          ? selectedUnscheduledStops.some(
              (b) =>
                (b.tempStopId ? b.tempStopId : b.stopId) ===
                (a.tempStopId ? a.tempStopId : a.stopId)
            )
          : true
      )
      .filter((a) =>
        a.assigned && a.unscheduledDates
          ? !a.unscheduledDates.includes(
              dayjs(routeStartDate).format("MM/DD/YYYY")
            )
          : true
      )
      .filter((a) =>
        a.tempStopId
          ? a.date === dayjs(routeStartDate).format("MM/DD/YYYY")
          : true
      )
      .filter((value) => {
        if (
          markers.current
            .filter(
              (a) =>
                a.serviceLocationId === value.serviceLocationId && !a.hidden
            )
            .filter((a) =>
              props.searchTerm.length === 0
                ? true
                : a.address
                    .toLowerCase()
                    .includes(props.searchTerm.toLowerCase())
            )
            .filter((a) =>
              !a.assigned
                ? selectedUnscheduledStops.some(
                    (b) =>
                      (b.tempStopId ? b.tempStopId : b.stopId) ===
                      (a.tempStopId ? a.tempStopId : a.stopId)
                  )
                : true
            )
            .filter((a) =>
              a.unscheduledDates
                ? !a.unscheduledDates.includes(
                    dayjs(routeStartDate).format("MM/DD/YYYY")
                  )
                : true
            )
            .filter((a) =>
              a.tempStopId
                ? a.date === dayjs(routeStartDate).format("MM/DD/YYYY")
                : true
            ).length === 1
        ) {
          return value.showStop;
        } else {
          return true;
        }
      })
      .findIndex(
        (a) =>
          (a.tempStopId ? a.tempStopId : a.stopId) ===
          (data.tempStopId ? data.tempStopId : data.stopId)
      );

    if (markerIndex !== -1 && !isMultiStop) {
      const value = getAdjustedLongitude(
        markers.current,
        props,
        routeStartDate,
        selectedUnscheduledStops,
        stopsBeingViewed
      )
        .filter((value) =>
          stopsBeingViewed
            ? selectedUnscheduledStops.some(
                (b) =>
                  (b.tempStopId ? b.tempStopId : b.stopId) ===
                  (value.tempStopId ? value.tempStopId : value.stopId)
              ) ||
              stopsBeingViewed.includes(
                value.tempStopId ? value.tempStopId : value.stopId
              )
            : true
        )
        .filter((a) =>
          props.searchTerm.length === 0
            ? true
            : a.customerName
                .toLowerCase()
                .includes(props.searchTerm.toLowerCase())
        )
        .filter((a) =>
          !a.assigned
            ? selectedUnscheduledStops.some(
                (b) =>
                  (b.tempStopId ? b.tempStopId : b.stopId) ===
                  (a.tempStopId ? a.tempStopId : a.stopId)
              )
            : true
        )
        .filter((a) =>
          a.assigned && a.unscheduledDates
            ? !a.unscheduledDates.includes(
                dayjs(routeStartDate).format("MM/DD/YYYY")
              )
            : true
        )
        .filter((a) =>
          a.tempStopId
            ? a.date === dayjs(routeStartDate).format("MM/DD/YYYY")
            : true
        )
        .filter((value) => {
          if (
            markers.current
              .filter(
                (a) =>
                  a.serviceLocationId === value.serviceLocationId && !a.hidden
              )
              .filter((a) =>
                props.searchTerm.length === 0
                  ? true
                  : a.address
                      .toLowerCase()
                      .includes(props.searchTerm.toLowerCase())
              )
              .filter((a) =>
                !a.assigned
                  ? selectedUnscheduledStops.some(
                      (b) =>
                        (b.tempStopId ? b.tempStopId : b.stopId) ===
                        (a.tempStopId ? a.tempStopId : a.stopId)
                    )
                  : true
              )
              .filter((a) =>
                a.unscheduledDates
                  ? !a.unscheduledDates.includes(
                      dayjs(routeStartDate).format("MM/DD/YYYY")
                    )
                  : true
              )
              .filter((a) =>
                a.tempStopId
                  ? a.date === dayjs(routeStartDate).format("MM/DD/YYYY")
                  : true
              ).length === 1
          ) {
            return value.showStop;
          } else {
            return true;
          }
        })[markerIndex];
      console.log(markerIndex);

      handleOnHover(
        markers.current
          .filter(
            (a) => a.serviceLocationId === value.serviceLocationId && !a.hidden
          )
          .filter((a) => {
            if (stopsBeingViewed) {
              return stopsBeingViewed.includes(
                a.tempStopId ? a.tempStopId : a.stopId
              );
            } else {
              return true;
            }
          })
          .filter((a) =>
            props.searchTerm.length === 0
              ? true
              : a.address.toLowerCase().includes(props.searchTerm.toLowerCase())
          )
          .filter((a) =>
            !a.assigned
              ? selectedUnscheduledStops.some(
                  (b) =>
                    (b.tempStopId ? b.tempStopId : b.stopId) ===
                    (a.tempStopId ? a.tempStopId : a.stopId)
                )
              : true
          )
          .filter((a) =>
            a.unscheduledDates
              ? !a.unscheduledDates.includes(
                  dayjs(routeStartDate).format("MM/DD/YYYY")
                )
              : true
          )
          .filter((a) =>
            a.tempStopId
              ? a.date === dayjs(routeStartDate).format("MM/DD/YYYY")
              : true
          )
          .filter((a) => a.showStop).length > 1
          ? {
              multiStop: true,
              value: markers.current
                .filter(
                  (a) =>
                    a.serviceLocationId === value.serviceLocationId && !a.hidden
                )
                .filter((a) => {
                  if (stopsBeingViewed) {
                    return stopsBeingViewed.includes(
                      a.tempStopId ? a.tempStopId : a.stopId
                    );
                  } else {
                    return true;
                  }
                })
                .filter((a) =>
                  props.searchTerm.length === 0
                    ? true
                    : a.address
                        .toLowerCase()
                        .includes(props.searchTerm.toLowerCase())
                )
                .filter((a) =>
                  !a.assigned
                    ? selectedUnscheduledStops.some(
                        (b) =>
                          (b.tempStopId ? b.tempStopId : b.stopId) ===
                          (a.tempStopId ? a.tempStopId : a.stopId)
                      )
                    : true
                )
                .filter((a) =>
                  a.unscheduledDates
                    ? !a.unscheduledDates.includes(
                        dayjs(routeStartDate).format("MM/DD/YYYY")
                      )
                    : true
                )
                .filter((a) =>
                  a.tempStopId
                    ? a.date === dayjs(routeStartDate).format("MM/DD/YYYY")
                    : true
                )
                .filter((a) => a.showStop),
            }
          : {
              multiStop: false,
              value: value,
            },
        markerIndex
      );
    } else {
      // submarker
      console.log("submarker");
      console.log(data);

      const markersToCheck = markers.current
        .filter(
          (a) => a.serviceLocationId === data.serviceLocationId && !a.hidden
        )
        .filter((a) =>
          props.searchTerm.length === 0
            ? true
            : a.address.toLowerCase().includes(props.searchTerm.toLowerCase())
        )
        .filter((a) =>
          !a.assigned
            ? selectedUnscheduledStops.some(
                (b) =>
                  (b.tempStopId ? b.tempStopId : b.stopId) ===
                  (a.tempStopId ? a.tempStopId : a.stopId)
              )
            : true
        )
        .filter((a) =>
          a.unscheduledDates
            ? !a.unscheduledDates.includes(
                dayjs(routeStartDate).format("MM/DD/YYYY")
              )
            : true
        )
        .filter((a) =>
          a.tempStopId
            ? a.date === dayjs(routeStartDate).format("MM/DD/YYYY")
            : true
        )
        .filter((a) => a.showStop)
        .filter((a) => {
          // if stopsBeingViewed is defined, filter by it
          if (stopsBeingViewed) {
            return stopsBeingViewed.includes(
              a.tempStopId ? a.tempStopId : a.stopId
            );
          } else {
            return true;
          }
        });

      for (let index = 0; index < markersToCheck.length; index++) {
        const marker = markersToCheck[index];

        // If the marker is not hidden and matches the serviceLocationId and stopid or tempStopId match
        if (
          marker.serviceLocationId === data.serviceLocationId &&
          (marker.tempStopId ? marker.tempStopId : marker.stopId) ===
            (data.tempStopId ? data.tempStopId : data.stopId)
        ) {
          const value = marker;
          const markerIndex = index;

          handleOnHover(
            {
              multiStop: true,
              value: [value],
            },
            markerIndex
          );

          setTimeout(() => {
            handleSelectSubMarkerClick(markerIndex, value);
            // remove selected main marker
            setSelectedMarkerIndex();
          }, 500);
        } else {
          console.log("no match");
          console.log(marker);
        }
      }

      // Log and set the new array
    }
  };

  const handleSelectSubMarkerClick = (index, value) => {
    console.log(selectedSubMarker);
    console.log(index);
    if (index === selectedSubMarker) {
      setSelectedSubMarker();
      setHoverDisplayData({});
      setHoverDisplay(false);
      return;
    }
    setSelectedSubMarker(index);

    setHoverDisplayData({ value: value });
    setHoverDisplay(true);
    console.log(dayViewDisplay);
    if (dayViewDisplay) {
      // get the stops being viewed
      console.log(dayData);

      let stop = dayData.find(
        (a) =>
          (a.tempStopId ? a.tempStopId : a.stopId) ===
          (value.tempStopId ? value.tempStopId : value.stopId)
      );

      console.log(stop);

      if (!stop) {
        // gotta check the other value layout; find the current viewed employee in dayData
        stop = dayData[dayViewData.employee.uid].find(
          (a) =>
            (a.tempStopId ? a.tempStopId : a.stopId) ===
            (value.tempStopId ? value.tempStopId : value.stopId)
        );
      }

      setSelectedDayViewCard(stop);
      // using the stop in the daydata, I want to highlight the stop card on the side menu
    }
  };

  const fetchStopsForEmployee = async (employee, formattedDate) => {
    // Compute the weekday from the formatted date.
    const weekday = dayjs(formattedDate, "MM/DD/YYYY").format("dddd");

    const workerRef = db
      .collection("Businesses")
      .doc(selectedBusiness)
      .collection("Workers")
      .doc(employee.uid);

    const [stopsSnap, movedStopsSnap, jobStopsDocSnap] = await Promise.all([
      workerRef.collection("Route").doc(weekday).collection("Stops").get(),
      workerRef.collection("Route").doc(weekday).collection("MovedStops").get(),
      workerRef.collection("Route").doc("JobStops").get(),
    ]);

    const hiddenStopIds = movedStopsSnap.docs
      .filter((doc) => formattedDate === doc.data().originalDate)
      .map((doc) => doc.data().stopId);

    let stops = stopsSnap.docs
      .map((doc) => doc.data())
      .filter((data) => {
        const id = data.tempStopId || data.stopId;
        return !hiddenStopIds.includes(id);
      });

    stops = stops.filter((stop) => {
      if (!stop.customerData) return false;
      return shouldShowStop(
        stop.biWeeklyStartDate,
        formattedDate,
        stop.dayOfTheWeek
      );
    });

    if (jobStopsDocSnap.exists) {
      const jobStopsData = jobStopsDocSnap.data();
      if (jobStopsData.route) {
        const jobStops = jobStopsData.route.filter((stop) => {
          const id = stop.tempStopId || stop.stopId;
          const notHidden = !hiddenStopIds.includes(id);
          if (!notHidden) return false;
          return stop.stopObject.stopDate === formattedDate;
        });
        stops.push(...jobStops);
      }
    }

    return stops;
  };

  const updateDayData = async (newDate) => {
    // Format the date as needed (e.g., "MM/DD/YYYY")
    const formattedDate = dayjs(newDate).format("MM/DD/YYYY");

    // If no employee is selected in day view, we can bail out early.
    if (!dayViewData?.employee) return;

    // Fetch the stops for the selected employee & date.
    const updatedStops = await fetchStopsForEmployee(
      dayViewData.employee,
      formattedDate
    );

    console.log(updatedStops);

    // Update the dayData state
    setDayData(updatedStops);

    // Derive and update stopsBeingViewed state (e.g., an array of stop IDs)
    const viewedStopIds = updatedStops.map(
      (stop) => stop.tempStopId || stop.stopId
    );
    setStopsBeingViewed(viewedStopIds);

    // Also update dayViewData so that the side menu reflects the new stops.
    // You can also compute "completedStops" if your stops have a completedDates field.
    const updatedCompletedStops = updatedStops.filter(
      (stop) =>
        stop.completedDates && stop.completedDates.includes(formattedDate)
    );
    console.log(updatedStops);
    console.log(updatedCompletedStops);
    setDayViewData({
      employee: dayViewData.employee, // persist the same employee
      data: updatedStops,
      completedStops: updatedCompletedStops,
    });
  };

  // Then, in a useEffect that watches for changes in the selected date:
  useEffect(() => {
    // Only update if an employee is selected for day view
    if (dayViewData && dayViewData.employee) {
      updateDayData(routeStartDate);
    }
  }, [routeStartDate]);

  return (
    <div
      style={{
        height: "88%",
      }}
    >
      <div>
        <SideMenu
          handleOpenReschedule={handleOpenReschedule}
          selectedBusiness={selectedBusiness}
          selectedRoute={selectedRoute}
          serviceLocationsInRoute={serviceLocationsInRoute}
          setServiceLocationsInRoute={setServiceLocationsInRoute}
          handleToggleHiddenDay={handleToggleHiddenDay}
          setRouteStartDate={setRouteStartDate}
          hasUnactivatedChanges={hasUnactivatedChanges}
          routeStartDate={routeStartDate}
          setHasUnactivatedChanges={setHasUnactivatedChanges}
          setUnscheduledMarkers={setUnscheduledMarkers}
          setSelectedUnscheduledStop={setSelectedUnscheduledStop}
          selectedUnscheduledStop={selectedUnscheduledStop}
          handleAddUnscheduledStopToDay={handleAddUnscheduledStopToDay}
          reloadUnscheduledStops={reloadUnscheduledStops}
          rescheduledStops={rescheduledStops}
          handleViewDetails={handleViewDetails}
          setSelectedUnscheduledStops={setSelectedUnscheduledStops}
          selectedUnscheduledStops={selectedUnscheduledStops}
          setRefresh={setRefresh}
          customRouteOrder={customRouteOrder}
          handleUpdateRouteOrder={handleUpdateRouteOrder}
          unscheduledMarkers={unscheduledMarkers}
          handleViewDay={handleViewDay}
          setDayViewData={setDayViewData}
          setDayViewDisplay={setDayViewDisplay}
          dayViewDisplay={dayViewDisplay}
          dayViewData={dayViewData}
          setDayData={setDayData}
          dayData={dayData}
          forceEmployeeCardsReload={forceEmployeeCardsReload}
          allEmployees={allEmployees}
          markersDataForProp={markersDataForProp}
          rescheduledMarkers={rescheduledMarkers}
          hiddenEmployees={hiddenEmployees}
          setRescheduleWholeRouteDisplay={setRescheduleWholeRouteDisplay}
          moveRoutePools={moveRoutePools}
          setMoveRoutePools={setMoveRoutePools}
          rescheduleWholeRouteDisplay={rescheduleWholeRouteDisplay}
          rescheduleDisplay={rescheduleDisplay}
          oneTimeRescheduleDisplay={oneTimeRescheduleDisplay}
          scheduleOneTimeStopDisplay={scheduleOneTimeStopDisplay}
          viewDetailsRecDisplay={viewDetailsRecDisplay}
          viewDetailsOneTimeDisplay={viewDetailsOneTimeDisplay}
          setStopsBeingViewed={setStopsBeingViewed}
          handleOpenReportJob={handleOpenReportJob}
          handleOpenReport={handleOpenReport}
          setSelectedDayViewCard={setSelectedDayViewCard}
          selectedDayViewCard={selectedDayViewCard}
          handleDayViewCardClick={handleDayViewCardClick}
          stopsBeingViewed={stopsBeingViewed}
          showAddStop={showAddStop}
          setShowAddStop={setShowAddStop}
          addStopMode={addStopMode}
          setAddStopMode={setAddStopMode}
        />

        <div>
          {defaultZoom && mapCenterCords && (
            <GoogleMapReact
              bootstrapURLKeys={{
                key: process.env.REACT_APP_GOOGLE_MAPS_API_TOKEN,
                libraries: ["places"],
              }}
              options={{
                fullscreenControl: false,
                zoomControl: true,
                clickableIcons: false,
              }}
              defaultCenter={{
                lat: mapCenterCords.lat,
                lng: mapCenterCords.lng,
              }}
              defaultZoom={defaultZoom}
              onClick={(e) => {
                // Only clear if the click isn’t on a marker element
                // (Markers should call e.stopPropagation() on their click events)
                setSelectedSubMarker(null);
                setHoverDisplay(false);
                setHoverDisplayData({});
                setSelectedMarkerIndex(null);
                setMultiStopMarkerDisplay(false);
                handleDayViewCardClick(null);
              }}
              onChange={({ center, zoom }) => {
                // Here you can access the new zoom value
                handleZoomValue(zoom);
              }}
              onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
              yesIWantToUseGoogleMapApiInternals
              style={{
                position: "absolute",
                right: 0,
                top: 0,
                width: mapWidth,
                height: "100vh",
              }}
            >
              {getAdjustedLongitude(
                markers.current,
                props,
                routeStartDate,
                selectedUnscheduledStops,
                stopsBeingViewed
              )
                .filter((value) =>
                  stopsBeingViewed
                    ? selectedUnscheduledStops.some(
                        (b) =>
                          (b.tempStopId ? b.tempStopId : b.stopId) ===
                          (value.tempStopId ? value.tempStopId : value.stopId)
                      ) ||
                      stopsBeingViewed.includes(
                        value.tempStopId ? value.tempStopId : value.stopId
                      )
                    : true
                )
                .filter((a) =>
                  props.searchTerm.length === 0
                    ? true
                    : a.customerName
                        .toLowerCase()
                        .includes(props.searchTerm.toLowerCase())
                )
                .filter((a) =>
                  !a.assigned
                    ? selectedUnscheduledStops.some(
                        (b) =>
                          (b.tempStopId ? b.tempStopId : b.stopId) ===
                          (a.tempStopId ? a.tempStopId : a.stopId)
                      )
                    : true
                )
                .filter((a) =>
                  a.assigned && a.unscheduledDates
                    ? !a.unscheduledDates.includes(
                        dayjs(routeStartDate).format("MM/DD/YYYY")
                      )
                    : true
                )
                .filter((a) =>
                  a.tempStopId
                    ? a.date === dayjs(routeStartDate).format("MM/DD/YYYY")
                    : true
                )
                .filter((value) => {
                  if (
                    markers.current
                      .filter(
                        (a) =>
                          a.serviceLocationId === value.serviceLocationId &&
                          !a.hidden
                      )
                      .filter((a) => {
                        if (stopsBeingViewed) {
                          return stopsBeingViewed.includes(
                            a.tempStopId ? a.tempStopId : a.stopId
                          );
                        } else {
                          return true;
                        }
                      })
                      .filter((a) =>
                        props.searchTerm.length === 0
                          ? true
                          : a.address
                              .toLowerCase()
                              .includes(props.searchTerm.toLowerCase())
                      )
                      .filter((a) =>
                        !a.assigned
                          ? selectedUnscheduledStops.some(
                              (b) =>
                                (b.tempStopId ? b.tempStopId : b.stopId) ===
                                (a.tempStopId ? a.tempStopId : a.stopId)
                            )
                          : true
                      )
                      .filter((a) =>
                        a.unscheduledDates
                          ? !a.unscheduledDates.includes(
                              dayjs(routeStartDate).format("MM/DD/YYYY")
                            )
                          : true
                      )
                      .filter((a) =>
                        a.tempStopId
                          ? a.date ===
                            dayjs(routeStartDate).format("MM/DD/YYYY")
                          : true
                      ).length === 1
                  ) {
                    return value.showStop;
                  } else {
                    return true;
                  }
                })
                .map((value, index) => (
                  <div
                    lat={value.location.lat}
                    lng={value.location.lng}
                    onMouseEnter={() =>
                      handleOnHoverNew(
                        markers.current
                          .filter(
                            (a) =>
                              a.serviceLocationId === value.serviceLocationId &&
                              !a.hidden
                          )
                          .filter((a) => {
                            if (stopsBeingViewed) {
                              return stopsBeingViewed.includes(
                                a.tempStopId ? a.tempStopId : a.stopId
                              );
                            } else {
                              return true;
                            }
                          })
                          .filter((a) =>
                            props.searchTerm.length === 0
                              ? true
                              : a.address
                                  .toLowerCase()
                                  .includes(props.searchTerm.toLowerCase())
                          )
                          .filter((a) =>
                            !a.assigned
                              ? selectedUnscheduledStops.some(
                                  (b) =>
                                    (b.tempStopId ? b.tempStopId : b.stopId) ===
                                    (a.tempStopId ? a.tempStopId : a.stopId)
                                )
                              : true
                          )
                          .filter((a) =>
                            a.unscheduledDates
                              ? !a.unscheduledDates.includes(
                                  dayjs(routeStartDate).format("MM/DD/YYYY")
                                )
                              : true
                          )
                          .filter((a) =>
                            a.tempStopId
                              ? a.date ===
                                dayjs(routeStartDate).format("MM/DD/YYYY")
                              : true
                          )
                          .filter((a) => a.showStop).length > 1
                          ? {
                              multiStop: true,
                              value: markers.current
                                .filter(
                                  (a) =>
                                    a.serviceLocationId ===
                                      value.serviceLocationId && !a.hidden
                                )
                                .filter((a) => {
                                  if (stopsBeingViewed) {
                                    return stopsBeingViewed.includes(
                                      a.tempStopId ? a.tempStopId : a.stopId
                                    );
                                  } else {
                                    return true;
                                  }
                                })
                                .filter((a) =>
                                  props.searchTerm.length === 0
                                    ? true
                                    : a.address
                                        .toLowerCase()
                                        .includes(
                                          props.searchTerm.toLowerCase()
                                        )
                                )
                                .filter((a) =>
                                  !a.assigned
                                    ? selectedUnscheduledStops.some(
                                        (b) =>
                                          (b.tempStopId
                                            ? b.tempStopId
                                            : b.stopId) ===
                                          (a.tempStopId
                                            ? a.tempStopId
                                            : a.stopId)
                                      )
                                    : true
                                )
                                .filter((a) =>
                                  a.unscheduledDates
                                    ? !a.unscheduledDates.includes(
                                        dayjs(routeStartDate).format(
                                          "MM/DD/YYYY"
                                        )
                                      )
                                    : true
                                )
                                .filter((a) =>
                                  a.tempStopId
                                    ? a.date ===
                                      dayjs(routeStartDate).format("MM/DD/YYYY")
                                    : true
                                )
                                .filter((a) => a.showStop),
                            }
                          : {
                              multiStop: false,
                              value: markers.current.find(
                                (a) => a.markerId === value.markerId
                              ),
                            },
                        index
                      )
                    }
                    onMouseLeave={() => handleOnHoverNew("exit")}
                    key={value.tempStopId ? value.tempStopId : value.stopId}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleOnHover(
                        markers.current
                          .filter(
                            (a) =>
                              a.serviceLocationId === value.serviceLocationId &&
                              !a.hidden
                          )
                          .filter((a) => {
                            if (stopsBeingViewed) {
                              return stopsBeingViewed.includes(
                                a.tempStopId ? a.tempStopId : a.stopId
                              );
                            } else {
                              return true;
                            }
                          })
                          .filter((a) =>
                            props.searchTerm.length === 0
                              ? true
                              : a.address
                                  .toLowerCase()
                                  .includes(props.searchTerm.toLowerCase())
                          )
                          .filter((a) =>
                            !a.assigned
                              ? selectedUnscheduledStops.some(
                                  (b) =>
                                    (b.tempStopId ? b.tempStopId : b.stopId) ===
                                    (a.tempStopId ? a.tempStopId : a.stopId)
                                )
                              : true
                          )
                          .filter((a) =>
                            a.unscheduledDates
                              ? !a.unscheduledDates.includes(
                                  dayjs(routeStartDate).format("MM/DD/YYYY")
                                )
                              : true
                          )
                          .filter((a) =>
                            a.tempStopId
                              ? a.date ===
                                dayjs(routeStartDate).format("MM/DD/YYYY")
                              : true
                          )
                          .filter((a) => a.showStop).length > 1
                          ? {
                              multiStop: true,
                              value: markers.current
                                .filter(
                                  (a) =>
                                    a.serviceLocationId ===
                                      value.serviceLocationId && !a.hidden
                                )
                                .filter((a) => {
                                  if (stopsBeingViewed) {
                                    return stopsBeingViewed.includes(
                                      a.tempStopId ? a.tempStopId : a.stopId
                                    );
                                  } else {
                                    return true;
                                  }
                                })
                                .filter((a) =>
                                  props.searchTerm.length === 0
                                    ? true
                                    : a.address
                                        .toLowerCase()
                                        .includes(
                                          props.searchTerm.toLowerCase()
                                        )
                                )
                                .filter((a) =>
                                  !a.assigned
                                    ? selectedUnscheduledStops.some(
                                        (b) =>
                                          (b.tempStopId
                                            ? b.tempStopId
                                            : b.stopId) ===
                                          (a.tempStopId
                                            ? a.tempStopId
                                            : a.stopId)
                                      )
                                    : true
                                )
                                .filter((a) =>
                                  a.unscheduledDates
                                    ? !a.unscheduledDates.includes(
                                        dayjs(routeStartDate).format(
                                          "MM/DD/YYYY"
                                        )
                                      )
                                    : true
                                )
                                .filter((a) =>
                                  a.tempStopId
                                    ? a.date ===
                                      dayjs(routeStartDate).format("MM/DD/YYYY")
                                    : true
                                )
                                .filter((a) => a.showStop),
                            }
                          : {
                              multiStop: false,
                              value: markers.current.find(
                                (a) => a.markerId === value.markerId
                              ),
                            },
                        index
                      );
                    }}
                    style={{
                      display: value.hidden ? "none" : "flex",
                      width: 40,
                      height: 40,
                      marginLeft: -20,
                      border: "4px solid rgb(41, 186, 230)",
                      boxShadow:
                        selectedMarkerIndex === index
                          ? "0 0 8px 2px #014efe"
                          : "none",
                      borderColor:
                        selectedMarkerIndex === index
                          ? "blue"
                          : markers.current
                              .filter(
                                (a) =>
                                  a.serviceLocationId ===
                                    value.serviceLocationId && !a.hidden
                              )
                              .filter((a) => {
                                if (stopsBeingViewed) {
                                  return stopsBeingViewed.includes(
                                    a.tempStopId ? a.tempStopId : a.stopId
                                  );
                                } else {
                                  return true;
                                }
                              })
                              .filter((a) =>
                                props.searchTerm.length === 0
                                  ? true
                                  : a.address
                                      .toLowerCase()
                                      .includes(props.searchTerm.toLowerCase())
                              )
                              .filter((a) =>
                                !a.assigned
                                  ? selectedUnscheduledStops.some(
                                      (b) =>
                                        (b.tempStopId
                                          ? b.tempStopId
                                          : b.stopId) ===
                                        (a.tempStopId ? a.tempStopId : a.stopId)
                                    )
                                  : true
                              )

                              .filter((a) =>
                                a.unscheduledDates
                                  ? !a.unscheduledDates.includes(
                                      dayjs(routeStartDate).format("MM/DD/YYYY")
                                    )
                                  : true
                              )
                              .filter((a) =>
                                a.tempStopId
                                  ? a.date ===
                                    dayjs(routeStartDate).format("MM/DD/YYYY")
                                  : true
                              )
                              .filter((a) => a.showStop).length > 1
                          ? "black"
                          : value.day === "Monday"
                          ? "#976DD0"
                          : value.day === "Tuesday"
                          ? "#FFBA5C"
                          : value.day === "Wednesday"
                          ? "#77D353"
                          : value.day === "Thursday"
                          ? "#F95F62"
                          : value.day === "Friday"
                          ? "#235789"
                          : value.day === "Saturday"
                          ? "#ED7D3A"
                          : value.day === "Sunday"
                          ? "blue"
                          : "black",

                      borderRadius: 50,
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    {multiStopMarkerDisplay &&
                      multiStopMarkerData.value[0] &&
                      multiStopMarkerData.value.some(
                        (a) => a.serviceLocationId === value.serviceLocationId
                      ) && (
                        <Popover
                          refresh={refresh}
                          handleSelectSubMarker={handleSelectSubMarker}
                          stopCount={value.stopCount}
                          multiStopMarkerData={multiStopMarkerData}
                          selectedSubMarker={selectedSubMarker}
                          stopsBeingViewed={stopsBeingViewed}
                          getCountInRoute={getCountInRoute}
                          handleSelectSubMarkerClick={
                            handleSelectSubMarkerClick
                          }
                          markers={markers.current
                            .filter(
                              (a) =>
                                a.serviceLocationId ===
                                  value.serviceLocationId && !a.hidden
                            )
                            .filter((a) => {
                              if (stopsBeingViewed) {
                                return stopsBeingViewed.includes(
                                  a.tempStopId ? a.tempStopId : a.stopId
                                );
                              } else {
                                return true;
                              }
                            })
                            .filter((a) =>
                              props.searchTerm.length === 0
                                ? true
                                : a.address
                                    .toLowerCase()
                                    .includes(props.searchTerm.toLowerCase())
                            )
                            .filter((a) =>
                              !a.assigned
                                ? selectedUnscheduledStops.some(
                                    (b) =>
                                      (b.tempStopId
                                        ? b.tempStopId
                                        : b.stopId) ===
                                      (a.tempStopId ? a.tempStopId : a.stopId)
                                  )
                                : true
                            )
                            .filter((a) =>
                              a.unscheduledDates
                                ? !a.unscheduledDates.includes(
                                    dayjs(routeStartDate).format("MM/DD/YYYY")
                                  )
                                : true
                            )
                            .filter((a) =>
                              a.tempStopId
                                ? a.date ===
                                  dayjs(routeStartDate).format("MM/DD/YYYY")
                                : true
                            )
                            .filter((a) => a.showStop)}
                          //hiddenMarkers={hiddenMarkers}
                        />
                      )}
                    <div
                      style={{
                        display: "flex",
                        width: 32,
                        height: 32,
                        borderRadius: 50,
                        alignItems: "center",
                        justifyContent: "center",
                        backgroundColor:
                          markers.current
                            .filter(
                              (a) =>
                                a.serviceLocationId ===
                                  value.serviceLocationId && !a.hidden
                            )
                            .filter((a) => {
                              if (stopsBeingViewed) {
                                return stopsBeingViewed.includes(
                                  a.tempStopId ? a.tempStopId : a.stopId
                                );
                              } else {
                                return true;
                              }
                            })
                            .filter((a) =>
                              props.searchTerm.length === 0
                                ? true
                                : a.address
                                    .toLowerCase()
                                    .includes(props.searchTerm.toLowerCase())
                            )
                            .filter((a) =>
                              !a.assigned
                                ? selectedUnscheduledStops.some(
                                    (b) =>
                                      (b.tempStopId
                                        ? b.tempStopId
                                        : b.stopId) ===
                                      (a.tempStopId ? a.tempStopId : a.stopId)
                                  )
                                : true
                            )
                            .filter((a) =>
                              a.unscheduledDates
                                ? !a.unscheduledDates.includes(
                                    dayjs(routeStartDate).format("MM/DD/YYYY")
                                  )
                                : true
                            )
                            .filter((a) =>
                              a.tempStopId
                                ? a.date ===
                                  dayjs(routeStartDate).format("MM/DD/YYYY")
                                : true
                            )
                            .filter((a) => a.showStop).length > 1
                            ? "white"
                            : value.assigned
                            ? "gray"
                            : "gray",
                        color: "white",
                        fontSize: 10,
                      }}
                    >
                      {markers.current
                        .filter(
                          (a) =>
                            a.serviceLocationId === value.serviceLocationId &&
                            !a.hidden
                        )
                        .filter((a) => {
                          if (stopsBeingViewed) {
                            return stopsBeingViewed.includes(
                              a.tempStopId ? a.tempStopId : a.stopId
                            );
                          } else {
                            return true;
                          }
                        })
                        .filter((a) =>
                          props.searchTerm.length === 0
                            ? true
                            : a.address
                                .toLowerCase()
                                .includes(props.searchTerm.toLowerCase())
                        )
                        .filter((a) =>
                          !a.assigned
                            ? selectedUnscheduledStops.some(
                                (b) =>
                                  (b.tempStopId ? b.tempStopId : b.stopId) ===
                                  (a.tempStopId ? a.tempStopId : a.stopId)
                              )
                            : true
                        )
                        .filter((a) =>
                          a.unscheduledDates
                            ? !a.unscheduledDates.includes(
                                dayjs(routeStartDate).format("MM/DD/YYYY")
                              )
                            : true
                        )
                        .filter((a) =>
                          a.tempStopId
                            ? a.date ===
                              dayjs(routeStartDate).format("MM/DD/YYYY")
                            : true
                        )
                        .filter((a) => a.showStop).length <= 1 &&
                        value.assigned && (
                          <div>
                            {stopsBeingViewed?.includes(
                              value.tempStopId ? value.tempStopId : value.stopId
                            )
                              ? getCountInRoute(value)
                              : value.employeeName
                                  .match(/(\b\S)?/g)
                                  .join("")
                                  .match(/(^\S|\S$)?/g)
                                  .join("")
                                  .toUpperCase()}
                          </div>
                        )}

                      {markers.current
                        .filter(
                          (a) =>
                            a.serviceLocationId === value.serviceLocationId &&
                            !a.hidden
                        )
                        // filter by stopsBeingViewed
                        .filter((a) => {
                          if (stopsBeingViewed) {
                            return stopsBeingViewed.includes(
                              a.tempStopId ? a.tempStopId : a.stopId
                            );
                          } else {
                            return true;
                          }
                        })
                        .filter((a) =>
                          props.searchTerm.length === 0
                            ? true
                            : a.address
                                .toLowerCase()
                                .includes(props.searchTerm.toLowerCase())
                        )
                        .filter((a) =>
                          !a.assigned
                            ? selectedUnscheduledStops.some(
                                (b) =>
                                  (b.tempStopId ? b.tempStopId : b.stopId) ===
                                  (a.tempStopId ? a.tempStopId : a.stopId)
                              )
                            : true
                        )
                        .filter((a) =>
                          a.unscheduledDates
                            ? !a.unscheduledDates.includes(
                                dayjs(routeStartDate).format("MM/DD/YYYY")
                              )
                            : true
                        )
                        .filter((a) =>
                          a.tempStopId
                            ? a.date ===
                              dayjs(routeStartDate).format("MM/DD/YYYY")
                            : true
                        )
                        .filter((a) => a.showStop).length > 1 && (
                        <div>
                          <MarkerDots
                            refresh={refresh}
                            stopsBeingViewed={stopsBeingViewed}
                            markers={markers.current
                              .filter(
                                (a) =>
                                  a.serviceLocationId ===
                                    value.serviceLocationId && !a.hidden
                              )
                              .filter((a) => {
                                if (stopsBeingViewed) {
                                  return stopsBeingViewed.includes(
                                    a.tempStopId ? a.tempStopId : a.stopId
                                  );
                                } else {
                                  return true;
                                }
                              })
                              .filter((a) =>
                                props.searchTerm.length === 0
                                  ? true
                                  : a.address
                                      .toLowerCase()
                                      .includes(props.searchTerm.toLowerCase())
                              )
                              .filter((a) =>
                                !a.assigned
                                  ? selectedUnscheduledStops.some(
                                      (b) =>
                                        (b.tempStopId
                                          ? b.tempStopId
                                          : b.stopId) ===
                                        (a.tempStopId ? a.tempStopId : a.stopId)
                                    )
                                  : true
                              )
                              .filter((a) =>
                                a.unscheduledDates
                                  ? !a.unscheduledDates.includes(
                                      dayjs(routeStartDate).format("MM/DD/YYYY")
                                    )
                                  : true
                              )
                              .filter((a) =>
                                a.tempStopId
                                  ? a.date ===
                                    dayjs(routeStartDate).format("MM/DD/YYYY")
                                  : true
                              )
                              .filter((a) => a.showStop)}
                            value={value}
                            //hiddenMarkers={hiddenMarkers}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                ))}
            </GoogleMapReact>
          )}
          {hoverDisplay && (
            <div
              style={{
                width: "200px",
                border: "none",
                borderRadius: 5,
                backgroundColor: "transparent",
                height: "auto",
                position: "absolute",
                zIndex: 1000,
                margin: 20,
                marginTop: "2%",
                right: 20,
                top: 80,
              }}
              class="routeBuilderEditSideMenuScroll"
            >
              {hoverDisplayData.multiStop && (
                <div>
                  {hoverDisplayData.value.map((value) => (
                    <StopDetailsCard
                      selectedDate={routeStartDate}
                      value={value}
                      handleViewDetails={handleViewDetails}
                      handleViewDay={handleViewDay}
                      dayData={dayData}
                      handleOpenReschedule={handleOpenReschedule}
                      originalDate={dayjs(routeStartDate).format("MM/DD/YYYY")}
                      handleReloadEntireRoute={handleReloadEntireRoute}
                      setHoverDisplay={setHoverDisplay}
                    />
                  ))}
                </div>
              )}
              {!hoverDisplayData.multiStop && (
                <StopDetailsCard
                  selectedDate={routeStartDate}
                  value={hoverDisplayData.value}
                  handleViewDetails={handleViewDetails}
                  handleViewDay={handleViewDay}
                  dayData={dayData}
                  handleOpenReschedule={handleOpenReschedule}
                  originalDate={dayjs(routeStartDate).format("MM/DD/YYYY")}
                  handleReloadEntireRoute={handleReloadEntireRoute}
                  setHoverDisplay={setHoverDisplay}
                />
              )}
            </div>
          )}
        </div>
        <div
          onClick={handleReloadEntireRoute}
          style={{
            position: "absolute",
            right: "2%",
            top: "10%",
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
            cursor: "pointer",
            backgroundColor: "white",
            padding: 8,
          }}
        >
          <div style={{ fontSize: 12, marginRight: 4 }}>Perform Sync</div>
          <MdSync />
        </div>

        {routeHasProcessingChanges && (
          <div
            style={{
              position: "absolute",
              right: 0,
              top: 0,
              width: mapWidth,
              height: "100vh",
              zIndex: 99999,
              backgroundColor: "rgba(0,0,0,0.5)",
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
            }}
          >
            <div
              style={{
                marginRight: 4,
                color: "white",
                backgroundColor: "rgba(0,0,0,0.6)",
                borderRadius: 10,
                padding: 20,
              }}
            >
              <div style={{ fontSize: 36 }}>Route is currently pending</div>
              <div style={{ fontSize: 14, textAlign: "center" }}>
                You recently activated a new route which is processing,
              </div>
              <div style={{ fontSize: 14, textAlign: "center" }}>
                this can take up to 2 minutes.
              </div>
            </div>
          </div>
        )}
      </div>

      <RecheduleRecurringStop
        rescheduleData={rescheduleData}
        setOpen={setRescheduleDisplay}
        open={rescheduleDisplay}
        selectedBusiness={selectedBusiness}
        routeId={selectedRoute.routeId}
        originalDate={dayjs(routeStartDate).format("MM/DD/YYYY")}
        setRouteStartDate={setRouteStartDate}
        handleReloadEntireRoute={handleReloadEntireRoute}
        routeStartDate={routeStartDate}
        setDayViewDisplay={setDayViewDisplay}
      />

      <RescheduleWholeRoute
        rescheduleData={rescheduleData}
        setOpen={setRescheduleWholeRouteDisplay}
        open={rescheduleWholeRouteDisplay}
        selectedBusiness={selectedBusiness}
        routeId={selectedRoute.routeId}
        originalDate={dayjs(routeStartDate).format("MM/DD/YYYY")}
        setRouteStartDate={setRouteStartDate}
        handleReloadEntireRoute={handleReloadEntireRoute}
        moveRoutePools={moveRoutePools}
        routeStartDate={routeStartDate}
        setDayViewDisplay={setDayViewDisplay}
      />

      <RescheduleOneTimeStop
        rescheduleData={rescheduleData}
        setOpen={setOneTimeRescheduleDisplay}
        open={oneTimeRescheduleDisplay}
        selectedBusiness={selectedBusiness}
        routeId={selectedRoute.routeId}
        originalDate={dayjs(routeStartDate).format("MM/DD/YYYY")}
        setRouteStartDate={setRouteStartDate}
        setDayViewDisplay={setDayViewDisplay}
        handleReloadEntireRoute={handleReloadEntireRoute}
      />

      <ScheduleOneTimeStop
        rescheduleData={rescheduleData}
        setOpen={setScheduleOneTimeStopDisplay}
        open={scheduleOneTimeStopDisplay}
        selectedBusiness={selectedBusiness}
        routeId={selectedRoute.routeId}
        originalDate={dayjs(routeStartDate).format("MM/DD/YYYY")}
        setRouteStartDate={setRouteStartDate}
        handleAddUnscheduledStopToDb={handleAddUnscheduledStopToDb}
      />

      <ViewDetailsRec
        setOpen={setViewDetailsRecDisplay}
        open={viewDetailsRecDisplay}
        viewDetailsData={viewDetailsData}
        stopDate={dayjs(
          viewDetailsData?.date ||
            viewDetailsData?.originalDate ||
            dayjs(routeStartDate).format("MM/DD/YYYY")
        ).format("MM/DD/YYYY")}
        handleOpenReschedule={handleOpenReschedule}
        handleCompleteRecTimeStop={handleCompleteRecTimeStop}
        setDayData={setDayData}
        dayData={dayData}
        handleViewDay={handleViewDay}
        selectedDate={routeStartDate}
        handleOpenReport={handleOpenReport}
      />

      <ViewDetailsOneTime
        setOpen={setViewDetailsOneTimeDisplay}
        open={viewDetailsOneTimeDisplay}
        viewDetailsData={viewDetailsData}
        stopDate={dayjs(routeStartDate).format("MM/DD/YYYY")}
        handleOpenReschedule={handleOpenReschedule}
        handleOpenSchedule={handleOpenSchedule}
        handleCompleteOneTimeStop={handleCompleteOneTimeStop}
        setDayData={setDayData}
        dayData={dayData}
        handleViewDay={handleViewDay}
        handleOpenReportJob={handleOpenReportJob}
        handleViewJob={handleViewJob}
      />

      <ViewReport
        selectedReport={reportData}
        open={viewRecReport}
        setOpen={setViewRecReport}
        selectedCustomer={reportCustomerData}
        serviceLocation={reportServicLocation}
      />

      <ViewJobReport
        open={viewJobReport}
        setOpen={setViewJobReport}
        selectedJob={jobData}
        selectedReport={jobReportData}
      />

      <ViewJob
        selectedJob={viewJobData}
        selectedBusiness={selectedBusiness}
        setOpen={setDisplayViewJob}
        open={displayViewJob}
        setViewReportOpen={setViewJobReport}
        handleViewReport={handleOpenReportJob}
      />

      <AddStop
        selectedBusiness={selectedBusiness}
        open={showAddStop}
        setOpen={setShowAddStop}
        mode={addStopMode}
        dayData={dayData}
        selectedDayViewEmployee={selectedDayViewEmployee}
      />
    </div>
  );
};

export default ActiveRoute;
