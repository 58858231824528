import React, { useState, useEffect, useRef } from "react";
import firebase from "firebase/compat/app";
import { generateBusinessId } from "../Tools/generateBusinessId";
import NewTasks from "./NewTasks";
import { FiCheckCircle, FiTrash } from "react-icons/fi";
import { toast } from "react-toastify";
import TaskProfile from "./TaskProfile";
import dayjs from "dayjs";
import TasksButton from "./TasksButton";
import Categories from "./Categories";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/solid";
import AssignedButton from "./AssignedButton";
import SelectEmployee from "./SelectEmployee";
import SelectAssigned from "./SelectAssigned";
import Folders from "./Folders";
import Geocoder from "react-native-geocoding";
import { FaArchive, FaWrench } from "react-icons/fa";

export default function Tasks(props) {
  const selectedBusiness = props.businessId;
  const db = firebase.firestore();
  const searchTerm = props.searchTerm;
  const userPermissions = props.userPermissions;
  const [tasks, setTasks] = useState([]);
  const [createTaskDisplay, setCreateTaskDisplay] = useState(false);
  const [serviceRequestData, setServiceRequestData] = useState();
  const [serviceRequestDisplay, setServiceRequestDisplay] = useState(false);
  const [categoriesDisplay, setCategoriesDisplay] = useState(false);
  const [sortBy, setSortBy] = useState("name");
  const [allCategories, setAllCategories] = useState([]);
  const [selectedFolder, setSelectedFolder] = useState("new");
  const [allFolders, setAllFolders] = useState([]);
  const [createFoldersDisplay, setCreateFoldersDisplay] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState({
    title: "all",
    color: "none",
  });
  const [viewMode, setViewMode] = useState("active");

  useEffect(() => {
    db.collection("Businesses")
      .doc(selectedBusiness)
      .collection("TaskCategories")
      .doc("Categories")
      .onSnapshot((snapshot) => {
        const data = snapshot.data();
        setAllCategories(data.categories);
      });

    db.collection("Businesses")
      .doc(selectedBusiness)
      .collection("TaskCategories")
      .doc("Folders")
      .onSnapshot((snapshot) => {
        const data = snapshot.data();
        setAllFolders(data.folders);
      });

    db.collection("Businesses")
      .doc(selectedBusiness)
      .collection("Tasks")
      .onSnapshot((tasksDocumentSnapshot) => {
        let tasksArr = [];
        for (
          let index = 0;
          index < tasksDocumentSnapshot.docs.length;
          index++
        ) {
          const taskDoc = tasksDocumentSnapshot.docs[index].data();
          tasksArr.push(taskDoc);
        }
        setTasks(tasksArr);
      });
  }, []);

  const handleCompleteTask = async (task) => {
    if (!userPermissions.includes("Edit Issues")) {
      toast.warn("You do not have permission to complete this action.");
      return;
    }
    db.collection("Businesses")
      .doc(selectedBusiness)
      .collection("Tasks")
      .doc(task.taskId)
      .update({ taskStatus: "complete" });

    const notificationId = generateBusinessId();

    await db
      .collection("Businesses")
      .doc(props.businessId)
      .collection("Notifications")
      .doc(notificationId)
      .set({
        id: notificationId,
        body: `Issue ${task.taskName} has been marked as complete.`,
        date: dayjs().format("MM/DD/YYYY"),
        time: dayjs().format("hh:mm A"),
      });

    toast.success("Task marked as complete!");
  };

  const handleDeleteTask = (task) => {
    if (!userPermissions.includes("Edit Issues")) {
      toast.warn("You do not have permission to complete this action.");
      return;
    }
    db.collection("Businesses")
      .doc(selectedBusiness)
      .collection("Tasks")
      .doc(task.taskId)
      .delete();

    toast.success("Task deleted!");
  };

  const handleConvertTaskToJob = (
    task,
    selectedCategory,
    quickCreate,
    stopName,
    stopDescription,
    stopDate,
    selectedEmployee
  ) => {
    if (!userPermissions.includes("Edit Issues")) {
      toast.warn("You do not have permission to complete this action.");
      return;
    }
    const messageIdWithContractor = generateBusinessId();
    const newJobId = generateBusinessId();

    db.collection("Businesses")
      .doc(selectedBusiness)
      .get()
      .then((documentSnapshot) => {
        const data = documentSnapshot.data();
        const businessName = data.name;

        db.collection("Customers")
          .doc(task.customer.customerId)
          .get()
          .then((customersDoccumentSnapshot) => {
            const customerDoc = customersDoccumentSnapshot.data();
            db.collection("Customers")
              .doc(task.customer.customerId)
              .collection("ServiceLocations")
              .doc(task.customer.serviceLocationId)
              .get()
              .then(async (serviceLocationDoccumentSnapshot) => {
                const serviceLocationDoc =
                  serviceLocationDoccumentSnapshot.data();

                const notificationId = generateBusinessId();

                await db
                  .collection("Businesses")
                  .doc(props.businessId)
                  .collection("Notifications")
                  .doc(notificationId)
                  .set({
                    id: notificationId,
                    body: `Issue ${task.taskName} has been converted to a job.`,
                    date: dayjs().format("MM/DD/YYYY"),
                    time: dayjs().format("hh:mm A"),
                  });

                db.collection("Businesses")
                  .doc(selectedBusiness)
                  .collection("Jobs")
                  .doc(newJobId)
                  .set({
                    status: "new",
                    jobTitle: task.taskName,
                    jobDescription: task.taskName,
                    customerId: task.customer.customerId,
                    customerData: customerDoc,
                    serviceLocation: serviceLocationDoc,
                    jobId: newJobId,
                    messageId: messageIdWithContractor,
                    createdDate: dayjs().format("MM/DD/YYYY"),
                    notes: [],
                    stops: [],
                    estimates: [],
                    invoices: [],
                    pics: task.media,
                    messages: [],
                    businessName: businessName,
                    category: selectedCategory || {
                      title: "none",
                      color: "none",
                    },
                    businessId: selectedBusiness,
                    createdByEmployeeName: task.taskCreatedBy,
                  })
                  .then(async () => {
                    if (quickCreate.name === "Scheduled Stop") {
                      const newStopId = generateBusinessId();

                      db.collection("Businesses")
                        .doc(selectedBusiness)
                        .collection("Routes")
                        .where("active", "==", true)
                        .get()
                        .then(async (routeQuery) => {
                          const routeId = routeQuery.docs[0].id;

                          Geocoder.from(serviceLocationDoc.address)
                            .then(async (json) => {
                              console.log(json.results[0].geometry.location);
                              const stopData = {
                                dayOfTheWeek: dayjs(stopDate).format("dddd"),
                                frequency: "once",
                                startOn: dayjs(stopDate).format("MM/DD/YYYY"),
                                stopAfter: "no end",
                                name: stopName,
                                address: serviceLocationDoc.address,
                                stopId: newStopId,
                                routeId: routeId,
                                serviceLocationId:
                                  serviceLocationDoc.serviceLocationId,
                                completedDates: [],
                                contractor: {
                                  id: selectedEmployee.uid,
                                  name: selectedEmployee.name,
                                  profilePhoto: selectedEmployee.profilePhoto,
                                },
                                customerData: {
                                  name:
                                    customerDoc.customerFirstName +
                                    " " +
                                    customerDoc.customerLastName,
                                  customerId: customerDoc.customerId,
                                  businessId: selectedBusiness,
                                },
                                businessId: selectedBusiness,
                                stopNickname: stopName,
                                location: json.results[0].geometry.location,
                                stopTemplateId: "none",
                                stopCustomerDescription: stopName,
                                stopWorkerDescription: stopDescription,
                                stopDate: dayjs(stopDate).format("MM/DD/YYYY"),
                                type: "Job Stop",
                                messageId: "none",
                                jobId: newJobId,
                              };

                              console.log(stopData);

                              const routeData = {
                                frequency: "once",
                                name: stopName,
                                address: serviceLocationDoc.address,
                                stopObject: stopData,
                                routeId: stopData.routeId, // change
                                jobId: newJobId,
                                serviceLocationId:
                                  serviceLocationDoc.serviceLocationId,
                                type: "stop",
                                completed: false,
                                stopId: stopData.stopId,
                                contractor: selectedEmployee,
                                location: json.results[0].geometry.location,
                                customerData: {
                                  name:
                                    customerDoc.customerFirstName +
                                    " " +
                                    customerDoc.customerLastName,
                                  customerId: customerDoc.customerId,
                                  businessId: selectedBusiness,
                                },
                              };

                              db.collection("Businesses")
                                .doc(selectedBusiness)
                                .collection("Workers")
                                .doc(selectedEmployee.uid)
                                .collection("Route")
                                .doc("JobStops")
                                .set(
                                  {
                                    route:
                                      firebase.firestore.FieldValue.arrayUnion(
                                        routeData
                                      ),
                                    checkDistanceTime: true,
                                  },
                                  { merge: true }
                                );

                              await db
                                .collection("Businesses")
                                .doc(selectedBusiness)
                                .collection("Workers")
                                .doc(selectedEmployee.uid)
                                .update({ lastUpdated: dayjs().unix() });

                              db.collection("Businesses")
                                .doc(selectedBusiness)
                                .collection("Jobs")
                                .doc(newJobId)
                                .update({
                                  stops:
                                    firebase.firestore.FieldValue.arrayUnion(
                                      stopData
                                    ),
                                  status: "scheduled",
                                })
                                .then(() => {
                                  db.collection("Businesses")
                                    .doc(selectedBusiness)
                                    .get()
                                    .then((documentSnapshot) => {
                                      const businessData =
                                        documentSnapshot.data();

                                      firebase
                                        .auth()
                                        .currentUser.getIdToken()
                                        .then(async (token) => {
                                          fetch(
                                            "https://us-central1-symbri-production.cloudfunctions.net/sendCustomerNotification",
                                            {
                                              method: "POST",
                                              body: JSON.stringify({
                                                token: token,
                                                notificationData: {
                                                  hasApp: {
                                                    body:
                                                      "A new stop named " +
                                                      stopName +
                                                      " for the job " +
                                                      task.taskName +
                                                      " has been created!.",
                                                    title: "Job stop created!",
                                                  },
                                                  noApp: {
                                                    body:
                                                      "A new stop named " +
                                                      stopName +
                                                      " for the job " +
                                                      task.taskName +
                                                      " has been created!. Please download the app and login into view the the stop details and message " +
                                                      businessData.name +
                                                      " with any questions.",
                                                    title: "Job stop created!",
                                                  },
                                                },
                                                customerId:
                                                  customerDoc.customerId,
                                              }),
                                            }
                                          );
                                          await db
                                            .collection("Businesses")
                                            .doc(selectedBusiness)
                                            .collection("Routes")
                                            .doc(routeId)
                                            .update({
                                              reload:
                                                !routeQuery.docs[0].data()
                                                  .reload,
                                            });
                                          props.setJobRouteData(newJobId);
                                          handleDeleteTask(task);
                                          props.handleChangeNavigation("Jobs");
                                          setServiceRequestDisplay(false);
                                          toast.success(
                                            "Job created from service request!"
                                          );
                                        });
                                    });
                                });
                            })
                            .catch((e) => console.log(e));
                        });
                    } else if (quickCreate.name === "Unscheduled Stop") {
                      const stopObject = {
                        stopDate: "none",
                        stopTitle: stopName,
                        stopDescription: stopDescription,
                        stopTech: "none",
                        scheduledJob: false,
                        jobId: newJobId,
                        stopId: generateBusinessId(),
                        customer: {
                          customerName:
                            customerDoc.customerFirstName +
                            " " +
                            customerDoc.customerLastName,
                          customerId: customerDoc.customerId,
                        },
                        stopServiceLocation: serviceLocationDoc,
                        stopServiceLocationCoordinates: await Geocoder.from(
                          serviceLocationDoc.address
                        ).then((json) => {
                          return json.results[0].geometry.location;
                        }),
                      };

                      // replace this shit with new notification

                      db.collection("Businesses")
                        .doc(selectedBusiness)
                        .collection("Jobs")
                        .doc(newJobId)
                        .update({
                          unscheduledStops:
                            firebase.firestore.FieldValue.arrayUnion(
                              stopObject
                            ),
                        })
                        .then(() => {
                          db.collection("Businesses")
                            .doc(selectedBusiness)
                            .get()
                            .then((documentSnapshot) => {
                              const businessData = documentSnapshot.data();

                              firebase
                                .auth()
                                .currentUser.getIdToken()
                                .then(async (token) => {
                                  fetch(
                                    "https://us-central1-symbri-production.cloudfunctions.net/sendCustomerNotification",
                                    {
                                      method: "POST",
                                      body: JSON.stringify({
                                        token: token,
                                        notificationData: {
                                          hasApp: {
                                            body:
                                              "A new stop named " +
                                              stopName +
                                              " for the job " +
                                              task.taskName +
                                              " has been created but is unscheduled.",
                                            title:
                                              "Unscheduled job stop created!",
                                          },
                                          noApp: {
                                            body:
                                              "A new stop named " +
                                              stopName +
                                              " for the job " +
                                              task.taskName +
                                              " has been created but is unscheduled. Please download the app and login into view the the stop details and message " +
                                              businessData.name +
                                              " with any questions.",
                                            title:
                                              "Unscheduled job stop created!",
                                          },
                                        },
                                        customerId: customerDoc.customerId,
                                      }),
                                    }
                                  );
                                  db.collection("Businesses")
                                    .doc(selectedBusiness)
                                    .collection("Routes")
                                    .where("active", "==", true)
                                    .get()
                                    .then(async (routeQuery) => {
                                      await db
                                        .collection("Businesses")
                                        .doc(selectedBusiness)
                                        .collection("Routes")
                                        .doc(routeQuery.docs[0].id)
                                        .update({
                                          reload:
                                            !routeQuery.docs[0].data().reload,
                                        });
                                      props.setJobRouteData(newJobId);
                                      handleDeleteTask(task);
                                      props.handleChangeNavigation("Jobs");

                                      setServiceRequestDisplay(false);
                                      toast.success(
                                        "Job created from service request!"
                                      );
                                    });
                                });
                            });
                        });
                    } else {
                      props.setJobRouteData(newJobId);
                      handleDeleteTask(task);
                      props.handleChangeNavigation("Jobs");
                      setServiceRequestDisplay(false);
                      toast.success("Job created from service request!");
                    }
                  });
              });
          });
      });
  };

  const handleServiceRequestClick = (task) => {
    setServiceRequestData(task);
    setServiceRequestDisplay(true);
  };

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="sm:flex sm:items-center">
        <div className="flex flex-row items-center">
          <h1 className="text-xl font-semibold text-gray-900">Issue Tracker</h1>
        </div>
        <div className="mt-4 sm:mt-0 ml-auto flex flex-row justify-center items-center">
          <div className="mr-4 mb-2">
            <SelectAssigned
              businessId={selectedBusiness}
              setSelectedFolder={setSelectedFolder}
              allFolders={allFolders}
              header={true}
            />
          </div>
          {viewMode === "active" && (
            <div
              onClick={() => setViewMode("archived")}
              className="mr-4 bg-[#6366F1] text-white p-[6.6px] rounded-md pl-3 pr-3 font-medium flex items-center cursor-pointer"
            >
              <FaArchive />
              <div className="ml-2">Archived Issues</div>
            </div>
          )}
          {viewMode === "archived" && (
            <div
              onClick={() => setViewMode("active")}
              className="mr-4 bg-[#6366F1] text-white p-[6.6px] rounded-md pl-3 pr-3 font-medium flex items-center cursor-pointer"
            >
              <FaWrench />
              <div className="ml-2">Active Issues</div>
            </div>
          )}
          <TasksButton
            setCreateFoldersDisplay={setCreateFoldersDisplay}
            setCreateTaskDisplay={setCreateTaskDisplay}
            setCategoriesDisplay={setCategoriesDisplay}
          />
        </div>
      </div>
      <div className="mt-8 flex flex-col">
        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 flex"
                    >
                      Name
                      {sortBy === "name" && (
                        <ChevronDownIcon className="h-5 w-5 text-black cursor-pointer" />
                      )}
                      {sortBy !== "name" && (
                        <ChevronUpIcon
                          onClick={() => setSortBy("name")}
                          className="h-5 w-5 text-black cursor-pointer"
                        />
                      )}
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      <div className="flex">
                        Customer
                        {sortBy === "customer" && (
                          <ChevronDownIcon className="h-5 w-5 text-black cursor-pointer" />
                        )}
                        {sortBy !== "customer" && (
                          <ChevronUpIcon
                            onClick={() => setSortBy("customer")}
                            className="h-5 w-5 text-black cursor-pointer"
                          />
                        )}
                      </div>
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      <div className="flex">
                        Created By
                        {sortBy === "createdBy" && (
                          <ChevronDownIcon className="h-5 w-5 text-black cursor-pointer" />
                        )}
                        {sortBy !== "createdBy" && (
                          <ChevronUpIcon
                            onClick={() => setSortBy("createdBy")}
                            className="h-5 w-5 text-black cursor-pointer"
                          />
                        )}
                      </div>
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      <div className="flex">
                        Created Date
                        {sortBy === "createdDate" && (
                          <ChevronDownIcon className="h-5 w-5 text-black cursor-pointer" />
                        )}
                        {sortBy !== "createdDate" && (
                          <ChevronUpIcon
                            onClick={() => setSortBy("createdDate")}
                            className="h-5 w-5 text-black cursor-pointer"
                          />
                        )}
                      </div>
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      <div className="flex">
                        <select
                          onChange={(change) =>
                            setSelectedCategory(JSON.parse(change.target.value))
                          }
                          style={{ appearance: "none" }}
                        >
                          <option
                            value={JSON.stringify({
                              title: "all",
                              color: "none",
                            })}
                          >
                            Issue Categories
                          </option>
                          {allCategories.map((category) => (
                            <option value={JSON.stringify(category)}>
                              {category.title}
                            </option>
                          ))}
                        </select>{" "}
                        {sortBy === "category" && (
                          <ChevronDownIcon className="h-5 w-5 text-black cursor-pointer" />
                        )}
                        {sortBy !== "category" && (
                          <ChevronUpIcon
                            onClick={() => setSortBy("category")}
                            className="h-5 w-5 text-black cursor-pointer"
                          />
                        )}
                      </div>
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      <div className="flex">
                        Status
                        {sortBy === "status" && (
                          <ChevronDownIcon className="h-5 w-5 text-black cursor-pointer" />
                        )}
                        {sortBy !== "status" && (
                          <ChevronUpIcon
                            onClick={() => setSortBy("status")}
                            className="h-5 w-5 text-black cursor-pointer"
                          />
                        )}
                      </div>
                    </th>

                    <th
                      scope="col"
                      className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                    >
                      <span className="sr-only">Edit</span>
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {tasks
                    .filter((a) =>
                      selectedFolder.toLowerCase() === "all"
                        ? true
                        : a.folder === selectedFolder.toLowerCase()
                    )
                    .filter((a) =>
                      searchTerm !== ""
                        ? a.taskName
                            .toLowerCase()
                            .includes(searchTerm.toLowerCase())
                        : true
                    )
                    .filter((a) =>
                      selectedCategory.title !== "all"
                        ? a.category
                          ? a.category.title === selectedCategory.title
                          : false
                        : true
                    )
                    .filter((a) =>
                      viewMode === "active"
                        ? a.taskStatus !== "archived"
                        : a.taskStatus === "archived"
                    )
                    .sort((a, b) => {
                      if (sortBy === "customer") {
                        return a.customer.name.localeCompare(b.customer.name);
                      } else if (sortBy === "name") {
                        return a.taskName.localeCompare(b.taskName);
                      } else if (sortBy === "createdDate") {
                        const dateA = dayjs(a.taskCreatedDate);

                        const dateB = dayjs(b.taskCreatedDate);
                        if (dateA < dateB) {
                          return 1;
                        }
                        if (dateA > dateB) {
                          return -1;
                        }
                        return 0;
                      } else if (sortBy === "category") {
                        return (
                          a.category ? a.category.title : "None"
                        ).localeCompare(b.category ? b.category.title : "None");
                      } else if (sortBy === "status") {
                        return a.taskStatus.localeCompare(b.taskStatus);
                      } else if (sortBy === "createdBy") {
                        return a.taskCreatedBy.localeCompare(b.taskCreatedBy);
                      } else {
                        return 0;
                      }
                    })
                    .map((task) => (
                      <tr key={task.taskId}>
                        <td
                          className="whitespace-nowrap overflow-hidden text-ellipsis py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6"
                          style={{ maxWidth: "250px" }}
                        >
                          {task.taskName.replace(
                            /(^\w{1})|(\s+\w{1})/g,
                            (letter) => letter.toUpperCase()
                          )}
                        </td>

                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {task.customer !== "none"
                            ? task.customer.name.replace(
                                /(^\w{1})|(\s+\w{1})/g,
                                (letter) => letter.toUpperCase()
                              )
                            : "none"}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {task.taskCreatedBy.replace(
                            /(^\w{1})|(\s+\w{1})/g,
                            (letter) => letter.toUpperCase()
                          )}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {task.taskCreatedDate.replace(
                            /(^\w{1})|(\s+\w{1})/g,
                            (letter) => letter.toUpperCase()
                          )}
                        </td>
                        <td
                          style={{
                            color: task.category
                              ? task.category.color
                              : "#6C7381",
                          }}
                          className="whitespace-nowrap px-3 py-4 text-sm"
                        >
                          {task.category
                            ? task.category.title.replace(
                                /(^\w{1})|(\s+\w{1})/g,
                                (letter) => letter.toUpperCase()
                              )
                            : "None"}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {task.taskStatus.replace(
                            /(^\w{1})|(\s+\w{1})/g,
                            (letter) => letter.toUpperCase()
                          )}
                        </td>

                        <td className="relative whitespace-nowrap py-4 ml-auto pr-4 text-right text-sm font-medium sm:pr-6">
                          <div style={{ display: "flex" }}>
                            <div
                              onClick={() => handleServiceRequestClick(task)}
                              style={{ marginLeft: "30%" }}
                              className="text-indigo-600 hover:text-indigo-900 cursor-pointer"
                            >
                              View
                            </div>

                            <div
                              onClick={() => handleCompleteTask(task)}
                              style={{ marginLeft: "12%" }}
                              className="text-indigo-600 hover:text-indigo-900 cursor-pointer"
                            >
                              <FiCheckCircle size={20} />
                            </div>
                            <div
                              onClick={() => handleDeleteTask(task)}
                              style={{ marginLeft: "12%" }}
                              className="text-indigo-600 hover:text-indigo-900 cursor-pointer"
                            >
                              <FiTrash size={20} />
                            </div>
                          </div>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <NewTasks
        selectedBusiness={selectedBusiness}
        setOpen={setCreateTaskDisplay}
        open={createTaskDisplay}
      />
      <Categories
        selectedBusiness={selectedBusiness}
        setOpen={setCategoriesDisplay}
        open={categoriesDisplay}
        tasks={tasks}
      />
      <Folders
        selectedBusiness={selectedBusiness}
        setOpen={setCreateFoldersDisplay}
        open={createFoldersDisplay}
        tasks={tasks}
      />
      <TaskProfile
        selectedBusiness={selectedBusiness}
        userPermissions={userPermissions}
        setOpen={setServiceRequestDisplay}
        open={serviceRequestDisplay}
        serviceRequestData={serviceRequestData}
        handleConvertTaskToJob={handleConvertTaskToJob}
      />
    </div>
  );
}
