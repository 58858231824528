import { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import { CheckCircleIcon } from "@heroicons/react/solid";
import { generateBusinessId } from "../Tools/generateBusinessId";
import firebase from "firebase/compat/app";
import { toast } from "react-toastify";
import dayjs from "dayjs";
import ViewImage from "../GlobalComponents/ViewImage";

export default function ViewReport(props) {
  const [open, setOpen] = useState(false);
  const db = firebase.firestore();
  const selectedBusiness = props.selectedBusiness;
  const selectedReport = props.selectedReport;
  const selectedCustomer = props.selectedCustomer;
  const [reportPhotos, setReportPhotos] = useState([]);
  const [measurements, setMeasurements] = useState([]);
  const [chemicals, setChemicals] = useState([]);
  const [tasks, setTasks] = useState([]);
  const [media, setMedia] = useState([]);
  const [viewImage, setViewImage] = useState(false);
  const [techNotes, setTechNotes] = useState("");

  useEffect(() => {
    setOpen(props.open);
    console.log(selectedReport);
    if (
      selectedReport &&
      selectedReport.reportObjects &&
      selectedReport.reportObjects.some((a) => a.type === "picsVids")
    ) {
      setReportPhotos(
        selectedReport.reportObjects.find((a) => a.type === "picsVids").value
      );
    }
    if (
      selectedReport &&
      selectedReport.reportObjects &&
      selectedReport.reportObjects.some((a) => a.type === "readings")
    ) {
      setMeasurements(
        selectedReport.reportObjects.find((a) => a.type === "readings").value
      );
    }

    if (
      selectedReport &&
      selectedReport.reportObjects &&
      selectedReport.reportObjects.some((a) => a.type === "tasks")
    ) {
      setTasks(
        selectedReport.reportObjects.find((a) => a.type === "tasks").value
      );
    }

    if (
      selectedReport &&
      selectedReport.reportObjects &&
      selectedReport.reportObjects.some((a) => a.type === "chemicalsAdded")
    ) {
      setChemicals(
        selectedReport.reportObjects.find((a) => a.type === "chemicalsAdded")
          .value
      );
    }
    if (
      selectedReport &&
      selectedReport.reportObjects &&
      selectedReport.reportObjects.some((a) => a.type === "techNotes")
    ) {
      setTechNotes(
        selectedReport.reportObjects.find((a) => a.type === "techNotes").value
      );
    }
  }, [props.open]);

  const handleClose = () => {
    setOpen(false);
    props.setOpen(false);
  };

  const handleViewImage = (media) => {
    setMedia(media);
    setViewImage(true);
  };

  return selectedReport ? (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={handleClose}>
        <div className="fixed inset-0" />

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-300 sm:duration-500"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-300 sm:duration-500"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                  <div className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
                    {selectedReport && (
                      <div className="h-0 flex-1 overflow-y-auto">
                        <div className="bg-indigo-700 py-6 px-4 sm:px-6">
                          <div className="flex items-center justify-between">
                            <Dialog.Title className="text-lg font-medium text-white">
                              {" "}
                              {selectedReport.subscriptionTitle}{" "}
                            </Dialog.Title>
                            <div className="ml-3 flex h-7 items-center">
                              <button
                                type="button"
                                className="rounded-md bg-indigo-700 text-indigo-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                                onClick={handleClose}
                              >
                                <span className="sr-only">Close panel</span>
                                <XIcon className="h-6 w-6" aria-hidden="true" />
                              </button>
                            </div>
                          </div>
                          <div className="mt-1">
                            <p className="text-sm text-indigo-300">
                              {selectedCustomer.customerFirstName}{" "}
                              {selectedCustomer.customerLastName}
                              {" | "}
                              {props.serviceLocation.address}
                            </p>
                          </div>
                        </div>
                        <div className="flex flex-1 flex-col justify-between">
                          <div className="divide-y divide-gray-200 px-4 sm:px-6">
                            <div className="space-y-6 pt-6 pb-5">
                              <div>
                                <label
                                  htmlFor="project-name"
                                  className="block text-sm font-medium text-gray-900"
                                >
                                  {" "}
                                  {selectedReport.reportEmployee.name}{" "}
                                </label>
                                <div className="mt-1 flex">
                                  <div className="text-gray-500">
                                    {dayjs(
                                      selectedReport.reportDate.toDate()
                                    ).format("dddd")}
                                  </div>
                                  <div className="ml-4 text-gray-500">
                                    {dayjs(
                                      selectedReport.reportDate.toDate()
                                    ).format("h:mmA")}
                                  </div>
                                  <div className="ml-4 text-gray-500">
                                    {dayjs(
                                      selectedReport.reportDate.toDate()
                                    ).format("MM/DD/YYYY")}
                                  </div>
                                </div>
                              </div>
                              <div>
                                <label
                                  htmlFor="project-name"
                                  className="block text-sm font-medium text-gray-900"
                                >
                                  {" "}
                                  Photos{" "}
                                </label>
                                <div className="mt-1 flex overflow-y-scroll">
                                  {reportPhotos.map((photo) => (
                                    <img
                                      onClick={() => handleViewImage(photo)}
                                      key={photo}
                                      src={photo}
                                      style={{
                                        minHeight: 100,
                                        minWidth: 100,
                                        maxHeight: 100,
                                        maxWidth: 100,
                                        margin: 2,
                                        borderRadius: 5,
                                        cursor: "pointer",
                                      }}
                                    />
                                  ))}
                                </div>
                              </div>
                              <div>
                                <label
                                  htmlFor="project-name"
                                  className="block text-sm font-medium text-gray-900"
                                >
                                  {" "}
                                  Measurements{" "}
                                </label>
                                <div className="mt-1">
                                  {measurements
                                    .filter(
                                      (a) => a.value.toString().length > 0
                                    )
                                    .map((measurement) => (
                                      <div className="flex">
                                        <div className="text-gray-500">
                                          {measurement.name}
                                        </div>
                                        <div className="ml-auto text-gray-500">
                                          {measurement.value}{" "}
                                          {measurement.name === "Ph" ||
                                          measurement.name === "Alkalinity"
                                            ? ""
                                            : "ppm"}
                                        </div>
                                      </div>
                                    ))}
                                </div>
                              </div>
                              <div>
                                <label
                                  htmlFor="project-name"
                                  className="block text-sm font-medium text-gray-900"
                                >
                                  {" "}
                                  Chemicals Added{" "}
                                </label>
                                <div className="mt-1">
                                  {chemicals
                                    .filter(
                                      (a) => a.value.toString().length > 0
                                    )
                                    .map((chemical) => (
                                      <div className="flex">
                                        <div className="text-gray-500">
                                          {chemical.chemicalData.name}
                                        </div>
                                        <div className="ml-auto text-gray-500">
                                          {chemical.value}
                                        </div>
                                      </div>
                                    ))}
                                </div>
                              </div>
                              <div>
                                <label
                                  htmlFor="project-name"
                                  className="block text-sm font-medium text-gray-900"
                                >
                                  {" "}
                                  Tasks Completed{" "}
                                </label>
                                <div className="mt-1">
                                  {tasks
                                    .filter((a) => a.completed)
                                    .map((task) => (
                                      <div className="flex">
                                        <div className="text-gray-500">
                                          {task.taskData.name}
                                        </div>
                                        <div className="ml-auto text-gray-500">
                                          <CheckCircleIcon className="h-6 w-6" />
                                        </div>
                                      </div>
                                    ))}
                                </div>
                              </div>
                              <div>
                                <label
                                  htmlFor="project-name"
                                  className="block text-sm font-medium text-gray-900"
                                >
                                  {" "}
                                  Tech Notes
                                </label>
                                <div className="mt-1">
                                  <div className="text-gray-500">
                                    {techNotes}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}

                    <div className="flex flex-shrink-0 justify-end px-4 py-4">
                      <button
                        onClick={() => handleClose(false)}
                        type="submit"
                        className="ml-4 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      >
                        Close
                      </button>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
        <ViewImage media={media} open={viewImage} setOpen={setViewImage} />
      </Dialog>
    </Transition.Root>
  ) : (
    <div></div>
  );
}
