import React, { useState, useEffect, useRef } from "react";
import firebase from "firebase/compat/app";
import dayjs from "dayjs";
import ViewReport from "./ViewReport";

export default function ServiceLocationSubscriptionView({
  selectedSubscription,
  selectedCustomer,
  businessId,
  serviceLocation,
}) {
  const db = firebase.firestore();
  const [reports, setReports] = useState([]);
  const [selectedReport, setSelectedReport] = useState();
  const [viewReportOpen, setViewReportOpen] = useState(false);
  const [emailReportsEnabled, setEmailReportsEnabled] = useState(false);

  useEffect(() => {
    db.collection("Customers")
      .doc(selectedCustomer.customerId)
      .collection("PoolReports")
      .where("businessId", "==", businessId)
      .where("subscriptionId", "==", selectedSubscription.subscriptionId)
      .get()
      .then((querySnapshot) => {
        setReports(querySnapshot.docs.map((doc) => doc.data()));
      });
  }, []);

  useEffect(() => {
    if (serviceLocation) {
      setEmailReportsEnabled(serviceLocation.emailReports);
    }
  }, [serviceLocation]);

  const handleViewReport = (report) => {
    setSelectedReport(report);
    setViewReportOpen(true);
  };

  const toggleEmailReports = () => {
    const isEnabled = !emailReportsEnabled;
    setEmailReportsEnabled(isEnabled);
    db.collection("Customers")
      .doc(selectedCustomer.customerId)
      .collection("ServiceLocations")
      .doc(serviceLocation.serviceLocationId)
      .update({ emailReports: isEnabled });
  };

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-xl font-semibold text-gray-900">
            {selectedSubscription.subscriptionTitle}
          </h1>
        </div>

        <div className="ml-4">
          <label className="inline-flex items-center">
            <span className="mr-2 text-sm">Email Reports</span>
            <input
              type="checkbox"
              className="form-checkbox"
              checked={emailReportsEnabled}
              onChange={toggleEmailReports}
            />
          </label>
        </div>
      </div>
      <div className="mt-8 flex flex-col">
        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                    >
                      History
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    ></th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    ></th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    ></th>
                    <th
                      scope="col"
                      className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                    ></th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {reports
                    .sort(
                      (a, b) => b.reportDate.toDate() - a.reportDate.toDate()
                    )
                    .map((report) => (
                      <tr key={report.reportId}>
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                          {report.reportEmployee.name?.replace(
                            /(^\w{1})|(\s+\w{1})/g,
                            (letter) => letter.toUpperCase()
                          )}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {dayjs(report.reportDate.toDate()).format("dddd")}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {dayjs(report.reportDate.toDate()).format("h:mmA")}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {dayjs(report.reportDate.toDate()).format(
                            "MM/DD/YYYY"
                          )}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {"Complete"}
                        </td>
                        <td
                          style={{ marginLeft: "50%" }}
                          className="whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6 flex items-center"
                        >
                          <div
                            onClick={() => handleViewReport(report)}
                            className="text-indigo-600 hover:text-indigo-900 cursor-pointer"
                          >
                            View
                          </div>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <ViewReport
        selectedReport={selectedReport}
        open={viewReportOpen}
        setOpen={setViewReportOpen}
        selectedCustomer={selectedCustomer}
        serviceLocation={serviceLocation}
      />
    </div>
  );
}
