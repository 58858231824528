import { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import { generateBusinessId } from "../Tools/generateBusinessId";
import firebase from "firebase/compat/app";
import SelectCustomerSearch from "../Jobs/SelectCustomerSearch";
import SelectServiceLocation from "../Jobs/SelectServiceLocation";
import SelectCategory from "../Jobs/SelectCategory";
import SelectEmployee from "./SelectEmployee";
import SelectAssigned from "./SelectAssigned";
import { toast } from "react-toastify";
import dayjs from "dayjs";

export default function NewTasks(props) {
  const [open, setOpen] = useState(false);
  const db = firebase.firestore();
  const selectedBusiness = props.selectedBusiness;
  const [newTaskName, setNewTaskName] = useState("");
  const [serviceLocations, setServiceLocations] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState();
  const [selectedServiceLocation, setSelectedServiceLocation] = useState();
  const [allCategories, setAllCategories] = useState([]);
  const [newTaskDescription, setNewTaskDescription] = useState("");
  const [allFolders, setAllFolders] = useState([]);
  const [selectedFolder, setSelectedFolder] = useState("new");
  const [selectedCategory, setSelectedCategory] = useState({
    title: "None",
    color: "black",
  });

  useEffect(() => {
    setOpen(props.open);
    if (props.open) {
      db.collection("Businesses")
        .doc(selectedBusiness)
        .collection("TaskCategories")
        .doc("Categories")
        .get()
        .then((snapshot) => {
          const data = snapshot.data();
          setAllCategories(data.categories);
        });

      db.collection("Businesses")
        .doc(selectedBusiness)
        .collection("TaskCategories")
        .doc("Folders")
        .get()
        .then((snapshot) => {
          const data = snapshot.data();
          console.log(data.folders);
          setAllFolders(data.folders);
        });

      db.collection("Customers")
        .where("businessIds", "array-contains", selectedBusiness)
        .get()
        .then((snapshot) => {
          let customersArray = [];
          for (let index = 0; index < snapshot.docs.length; index++) {
            const customerDoc = snapshot.docs[index].data();
            customersArray.push(customerDoc);
          }
          setCustomers(customersArray);
        });
    }
  }, [props.open]);

  useEffect(() => {
    if (selectedCustomer) {
      db.collection("Customers")
        .doc(selectedCustomer.customerId)
        .collection("ServiceLocations")
        .where("businessId", "==", selectedCustomer.businessIds[0])
        .get()
        .then((snapshot) => {
          let serviceLocationsArray = [];
          for (let index = 0; index < snapshot.docs.length; index++) {
            const serviceLocationDoc = snapshot.docs[index].data();
            serviceLocationsArray.push(serviceLocationDoc);
          }
          setServiceLocations(serviceLocationsArray);
          console.log(serviceLocationsArray);
        });
    }
  }, [selectedCustomer]);

  const handleClose = () => {
    setOpen(false);
    // reset all data to default
    setNewTaskName("");
    setNewTaskDescription("");
    setSelectedCustomer();
    setSelectedServiceLocation();
    setSelectedCategory({ title: "None", color: "black" });
    setSelectedFolder("new");
    props.setOpen(false);
  };

  const handleCreateTask = async () => {
    const newTaskId = generateBusinessId();

    if (newTaskName === "" || newTaskDescription === "") {
      toast.warning("Name and description are required!");
      return;
    }

    const userDoc = await db
      .collection("Users")
      .doc(firebase.auth().currentUser.uid)
      .get();

    const docData = userDoc.data();
    const usersName = docData.name;

    await db
      .collection("Businesses")
      .doc(selectedBusiness)
      .collection("Tasks")
      .doc(newTaskId)
      .set({
        taskId: newTaskId,
        taskName: newTaskName,
        taskDescription: newTaskDescription,
        taskStatus: "incomplete",
        taskCreatedDate: new Date().toDateString(),
        category: selectedCategory,
        taskCreatedBy: usersName,
        businessId: selectedBusiness,
        folder: selectedFolder.toLowerCase(),
        media: [],
        customer: selectedCustomer
          ? {
              customerId: selectedCustomer.customerId,
              serviceLocationId: selectedServiceLocation.serviceLocationId,
              name:
                selectedCustomer.customerFirstName +
                " " +
                selectedCustomer.customerLastName,
            }
          : "none",
      });

    // send business notification

    const notificationId = generateBusinessId();

    await db
      .collection("Businesses")
      .doc(selectedBusiness)
      .collection("Notifications")
      .doc(notificationId)
      .set({
        id: notificationId,
        body: `The Task ${newTaskName} has been created by ${usersName}`,
        date: dayjs().format("MM/DD/YYYY"),
        time: dayjs().format("hh:mm A"),
      });

    handleClose();
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={handleClose}>
        <div className="fixed inset-0" />

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-300 sm:duration-500"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-300 sm:duration-500"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                  <div className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
                    <div className="h-0 flex-1 overflow-y-auto">
                      <div className="bg-indigo-700 py-6 px-4 sm:px-6">
                        <div className="flex items-center justify-between">
                          <Dialog.Title className="text-lg font-medium text-white">
                            {" "}
                            New Issue{" "}
                          </Dialog.Title>
                          <div className="ml-3 flex h-7 items-center">
                            <button
                              type="button"
                              className="rounded-md bg-indigo-700 text-indigo-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                              onClick={handleClose}
                            >
                              <span className="sr-only">Close panel</span>
                              <XIcon className="h-6 w-6" aria-hidden="true" />
                            </button>
                          </div>
                        </div>
                        <div className="mt-1">
                          <p className="text-sm text-indigo-300">
                            Get started by filling in the information below to
                            create your new issue.
                          </p>
                        </div>
                      </div>
                      <div className="flex flex-1 flex-col justify-between">
                        <div className="divide-y divide-gray-200 px-4 sm:px-6">
                          <div className="space-y-6 pt-6 pb-5">
                            <div>
                              <label
                                htmlFor="project-name"
                                className="block text-sm font-medium text-gray-900"
                              >
                                {" "}
                                Issue Name{" "}
                              </label>
                              <div className="mt-1">
                                <input
                                  onChange={(change) =>
                                    setNewTaskName(change.target.value)
                                  }
                                  type="text"
                                  className="block w-full rounded-md border-gray-300 border-2 h-10 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                />
                              </div>
                            </div>
                            <div>
                              <label
                                htmlFor="project-name"
                                className="block text-sm font-medium text-gray-900"
                              >
                                {" "}
                                Issue Description{" "}
                              </label>
                              <div className="mt-1">
                                <textarea
                                  onChange={(change) =>
                                    setNewTaskDescription(change.target.value)
                                  }
                                  rows="1"
                                  className="block w-full rounded-md border-gray-300 border-2 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm resize-none"
                                  style={{ height: "80px", overflowY: "auto" }}
                                ></textarea>
                              </div>
                            </div>
                            <div>
                              <label
                                htmlFor="description"
                                className="block text-sm font-medium text-gray-900"
                              >
                                {" "}
                                Customer{" "}
                              </label>
                              <div className="mt-1">
                                <SelectCustomerSearch
                                  businessId={props.businessId}
                                  setSelectedCustomer={setSelectedCustomer}
                                  customers={customers}
                                />
                              </div>
                            </div>
                            {selectedCustomer && (
                              <div>
                                <label
                                  htmlFor="description"
                                  className="block text-sm font-medium text-gray-900"
                                >
                                  {" "}
                                  Service Location{" "}
                                </label>
                                <div className="mt-1">
                                  <SelectServiceLocation
                                    businessId={props.businessId}
                                    setSelectedServiceLocation={
                                      setSelectedServiceLocation
                                    }
                                    serviceLocations={serviceLocations}
                                  />
                                </div>
                              </div>
                            )}

                            <div>
                              <label
                                htmlFor="description"
                                className="block text-sm font-medium text-gray-900"
                              >
                                {" "}
                                Category{" "}
                              </label>
                              <div className="mt-1">
                                <SelectCategory
                                  allCategories={allCategories}
                                  setSelectedCategory={setSelectedCategory}
                                />
                              </div>
                            </div>

                            <div>
                              <label
                                htmlFor="description"
                                className="block text-sm font-medium text-gray-900"
                              >
                                {" "}
                                Assign To{" "}
                              </label>
                              <div className="mt-1">
                                <SelectAssigned
                                  setSelectedFolder={setSelectedFolder}
                                  allFolders={allFolders}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-shrink-0 justify-end px-4 py-4">
                      <button
                        type="button"
                        className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                        onClick={() => handleClose(false)}
                      >
                        Cancel
                      </button>
                      <button
                        onClick={() => handleCreateTask()}
                        type="submit"
                        className="ml-4 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      >
                        Add
                      </button>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
