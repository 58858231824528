import { useEffect, useState } from "react";
import SubscriptionDetailsTabs from "./SubscriptionDetailsTabs";
import firebase from "firebase/compat/app";
import dayjs from "dayjs";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/solid";
import * as XLSX from "xlsx";

export default function SubscriptionDetails(props) {
  const db = firebase.firestore();
  const subscription = props.subscription;
  const [selectedTab, setSelectedTab] = useState("Customers");
  const [customers, setCustomers] = useState([]);
  const [fromDate, setFromDate] = useState(
    dayjs().subtract(31, "days").format("YYYY-MM-DD")
  );
  const [toDate, setToDate] = useState(dayjs().format("YYYY-MM-DD"));
  const [sortBy, setSortBy] = useState("name");

  const [sortConfig, setSortConfig] = useState({
    field: "owner",
    direction: "asc",
  });

  const stats = [
    {
      name: "Monthly Earnings",
      stat: "$" + customers.length * subscription.subscriptionPrice,
    },
    { name: "Total Customers", stat: customers.length },
    { name: "Avg. Stop Time", stat: "8 Minutes" },
  ];

  const handleSort = (field) => {
    setSortConfig((prev) => {
      if (prev.field === field) {
        return { field, direction: prev.direction === "asc" ? "desc" : "asc" };
      } else {
        return { field, direction: "asc" };
      }
    });
  };

  const calculateMetrics = async (data) => {
    try {
      const startDate = new Date(fromDate);
      const endDate = new Date(toDate + "T23:59:59.999");

      const reportsRef = await db
        .collection("Customers")
        .doc(data.customerId)
        .collection("PoolReports")
        .where("businessId", "==", data.businessId)
        .where("subscriptionId", "==", subscription.subscriptionId)
        .where("reportDate", ">=", startDate)
        .where("reportDate", "<=", endDate)
        .get();

      const reports = reportsRef.docs.map((doc) => doc.data());
      const serviceLocationReports = reports.filter(
        (report) => report.reportServiceLocationId === data.serviceLocationId
      );

      // Identify all the months in [fromDate, toDate].
      const lastMonths = (() => {
        const from = dayjs(fromDate).startOf("month");
        const to = dayjs(toDate).startOf("month");
        const months = [];
        let current = from;

        while (current.isBefore(to) || current.isSame(to, "month")) {
          months.push(current.format("MMMM"));
          current = current.add(1, "month");
        }
        return months;
      })();

      // For each month, find chemicals and sum up dose/cost
      const monthsData = await Promise.all(
        lastMonths.map(async (month) => {
          // Filter reports for this month
          const monthReports = serviceLocationReports.filter(
            (report) =>
              dayjs(report.reportDate.seconds * 1000).format("MMMM") === month
          );

          // Find the "chemicalsAdded" object in each report
          const chemicals = monthReports
            .map((report) =>
              report.reportObjects.find((obj) => obj.type === "chemicalsAdded")
            )
            .filter(Boolean);

          const chemicalObjects = chemicals.flat();

          // Calculate both cost and dose for each chemical
          const chemicalEntries = await Promise.all(
            chemicalObjects.map(async (chemical) => {
              const chemsPromises = chemical.value.map(async (chem) => {
                const chemicalId = chem.chemicalData.chemicalId;

                const chemicalRef = await db
                  .collection("Businesses")
                  .doc(data.businessId)
                  .collection("Chemicals")
                  .doc(chemicalId)
                  .get();

                const chemicalData = chemicalRef.data();

                // `chem.value` is the numeric dose
                const dose = parseFloat(chem.value) || 0;
                const cost =
                  parseFloat(
                    chemicalData.chemicalPricePerDose.replace("$", "")
                  ) * dose || 0;

                return {
                  chemicalName: chemicalData.chemicalName,
                  dose,
                  cost,
                };
              });

              return Promise.all(chemsPromises);
            })
          );

          // Flatten
          const flatChemicalEntries = chemicalEntries.flat();

          // Aggregate per chemical
          const chemicalsArray = flatChemicalEntries.reduce((acc, item) => {
            const existing = acc.find(
              (c) => c.chemicalName === item.chemicalName
            );
            if (existing) {
              existing.dose += item.dose;
              existing.cost += item.cost;
            } else {
              acc.push({
                chemicalName: item.chemicalName,
                dose: item.dose,
                cost: item.cost,
              });
            }
            return acc;
          }, []);

          return {
            name: month,
            chemicals: chemicalsArray,
          };
        })
      );

      // Sum up total cost across all months
      const totalCosts = monthsData.reduce((acc, month) => {
        const monthTotal = month.chemicals.reduce(
          (sum, c) => sum + (isNaN(c.cost) ? 0 : c.cost),
          0
        );
        return acc + monthTotal;
      }, 0);

      // Sum up total doses across all months
      const totalDoses = monthsData.reduce((acc, month) => {
        const monthTotal = month.chemicals.reduce(
          (sum, c) => sum + (isNaN(c.dose) ? 0 : c.dose),
          0
        );
        return acc + monthTotal;
      }, 0);

      return {
        monthsData,
        totalCosts,
        totalDoses,
        allReports: serviceLocationReports,
      };
    } catch (error) {
      console.error("Error in calculateMetrics:", error);
      return { monthsData: [], totalCosts: 0, totalDoses: 0, allReports: [] };
    }
  };

  useEffect(() => {
    const fetchCustomers = async () => {
      try {
        const customerSnapshot = await db
          .collection("Customers")
          .where("businessIds", "array-contains", props.selectedBusiness)
          .get();

        const customersArray = [];
        const serviceLocationPromises = [];

        for (const doc of customerSnapshot.docs) {
          const customerDoc = doc.data();

          const serviceLocationPromise = db
            .collection("Customers")
            .doc(customerDoc.customerId)
            .collection("ServiceLocations")
            .where("businessId", "==", props.selectedBusiness)
            .get()
            .then(async (serviceLocationSnapshot) => {
              const locationPromises = serviceLocationSnapshot.docs.map(
                async (serviceDoc) => {
                  const serviceLocationDoc = serviceDoc.data();

                  const foundSubscription =
                    serviceLocationDoc.subscriptions.find(
                      (a) => a.subscriptionId === subscription.subscriptionId
                    );

                  if (foundSubscription) {
                    const cost = await calculateMetrics(serviceLocationDoc);
                    // so I need to search all reports for the service location, then get all report.reportEmployee.name values and combine them into a string then use that value as the employee value

                    const employeeCounts = cost.allReports.reduce(
                      (acc, report) => {
                        const name = report.reportEmployee.name;
                        acc[name] = (acc[name] || 0) + 1;
                        return acc;
                      },
                      {}
                    );

                    // Next, convert the object to an array of strings in the desired format and join them with ' - '
                    const allEmployees = Object.entries(employeeCounts)
                      .map(
                        ([name, count]) =>
                          `${name.replace(/(^\w{1})|(\s+\w{1})/g, (letter) =>
                            letter.toUpperCase()
                          )} (${count})`
                      )
                      .join(" - ");

                    console.log(allEmployees);

                    customersArray.push({
                      serviceLocationId: serviceLocationDoc.serviceLocationId,
                      serviceLocationOwnerName: `${customerDoc.customerFirstName} ${customerDoc.customerLastName}`,
                      serviceLocationAddress: serviceLocationDoc.address,
                      serviceLocationName: serviceLocationDoc.name,
                      subscription: foundSubscription,
                      cost,
                      allEmployees,
                    });
                  }
                }
              );

              await Promise.all(locationPromises);
            });

          serviceLocationPromises.push(serviceLocationPromise);
        }

        await Promise.all(serviceLocationPromises);

        console.log(customersArray);
        setCustomers(customersArray);
      } catch (error) {
        console.error("Error fetching customers: ", error);
      }
    };

    fetchCustomers();
  }, [props.selectedBusiness, fromDate, toDate]);

  const autoFitColumns = (ws, data) => {
    // Start each column with a default width of 10
    const colWidths = data[0].map(() => 10);
    data.forEach((row) => {
      row.forEach((val, idx) => {
        const cellValue = val ? val.toString() : "";
        colWidths[idx] = Math.max(colWidths[idx], cellValue.length);
      });
    });
    ws["!cols"] = colWidths.map((w) => ({ wch: w }));
  };

  const exportToCsv = () => {
    // 1) Calculate global totals for each chemical
    const globalChemicalTotals = {};
    customers.forEach((customer) => {
      const { monthsData } = customer.cost;
      monthsData.forEach((month) => {
        month.chemicals.forEach((chem) => {
          if (!globalChemicalTotals[chem.chemicalName]) {
            globalChemicalTotals[chem.chemicalName] = 0;
          }
          globalChemicalTotals[chem.chemicalName] += chem.dose;
        });
      });
    });

    // 2) Filter out chemicals with a total dose of 0
    const chemicalNames = Object.keys(globalChemicalTotals).filter(
      (chemName) => globalChemicalTotals[chemName] !== 0
    );

    // -- HEADER ROW SETUP --
    // Now we have columns:
    // "Last Name", "First Name", "Address", "Monthly Subscription Fee", "Employee", "Nickname"
    // Then for each chemical, we create TWO columns but merge them so the chemical name spans both.
    const headerRow = [
      "Last Name",
      "First Name",
      "Address",
      "Monthly Subscription Fee",
      "Employee", // <-- New column
      "Nickname",
    ];

    // We'll track merges in an array
    const merges = [];

    // Each chemical is allocated TWO columns for data (dose, cost).
    // The chemical columns now start at index 6.
    chemicalNames.forEach((chemName, index) => {
      // For the Nth chemical, the start column is 6 + (2 * index)
      const startCol = 6 + 2 * index;

      // Put the chemical name in the first cell, leave second cell empty
      headerRow[startCol] = chemName;
      headerRow[startCol + 1] = "";

      // Merge them so it looks like one big cell in the header
      merges.push({
        s: { r: 0, c: startCol }, // start cell
        e: { r: 0, c: startCol + 1 }, // end cell
      });
    });

    // Build the 2D array for the worksheet
    const wsData = [];
    wsData.push(headerRow);

    // Sort customers by last name (A to Z)
    const sortedCustomers = [...customers].sort((a, b) => {
      const aNames = a.serviceLocationOwnerName.split(" ");
      const aLastName =
        aNames.length > 1 ? aNames[aNames.length - 1] : aNames[0];

      const bNames = b.serviceLocationOwnerName.split(" ");
      const bLastName =
        bNames.length > 1 ? bNames[bNames.length - 1] : bNames[0];

      return aLastName.localeCompare(bLastName);
    });

    // -- DATA ROWS --
    // Each row has the base 6 columns, then 2 columns per chemical: (dose, cost).
    sortedCustomers.forEach((customer) => {
      const { monthsData } = customer.cost;
      const chemicalTotalsDose = {};
      const chemicalTotalsCost = {};

      monthsData.forEach((month) => {
        month.chemicals.forEach((chem) => {
          if (!chemicalTotalsDose[chem.chemicalName]) {
            chemicalTotalsDose[chem.chemicalName] = 0;
          }
          if (!chemicalTotalsCost[chem.chemicalName]) {
            chemicalTotalsCost[chem.chemicalName] = 0;
          }
          chemicalTotalsDose[chem.chemicalName] += chem.dose;
          chemicalTotalsCost[chem.chemicalName] += chem.cost;
        });
      });

      // Split the full name into last and first names
      const names = customer.serviceLocationOwnerName.split(" ");
      const lastName = names.length > 1 ? names[names.length - 1] : names[0];
      const firstName =
        names.length > 1 ? names.slice(0, names.length - 1).join(" ") : "";

      // Base columns: last name, first name, address, monthly subscription fee, employee, nickname
      const dataRow = [
        lastName.replace(/(^\w{1})|(\s+\w{1})/g, (letter) =>
          letter.toUpperCase()
        ),
        firstName.replace(/(^\w{1})|(\s+\w{1})/g, (letter) =>
          letter.toUpperCase()
        ),
        customer.serviceLocationAddress.replace(",", ""),
        "$" + customer.subscription.subscriptionPrice, // Monthly Subscription Fee
        customer.allEmployees, // <-- New column data
        customer.serviceLocationName.replace(/(^\w{1})|(\s+\w{1})/g, (letter) =>
          letter.toUpperCase()
        ),
      ];

      // For each chemical, add two columns: dose, cost
      chemicalNames.forEach((chemName) => {
        const doseVal = chemicalTotalsDose[chemName] || 0;
        const costVal = chemicalTotalsCost[chemName] || 0;
        dataRow.push(doseVal.toFixed(2)); // left half = dose
        dataRow.push(`$${costVal.toFixed(2)}`); // right half = cost
      });

      wsData.push(dataRow);
    });

    // -- CREATE WORKBOOK --
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.aoa_to_sheet(wsData);

    // Apply merges (so each chemical name spans two columns in the header)
    ws["!merges"] = merges;

    // Auto-fit columns
    autoFitColumns(ws, wsData);

    // Optionally set row heights, alignment, etc.
    ws["!rows"] = wsData.map((_, idx) => {
      if (idx === 0) {
        // Header row
        return { hpt: 20 };
      } else {
        // Data rows
        return { hpt: 16 };
      }
    });

    // Center the chemical header text in the merged cells
    merges.forEach((m) => {
      const topLeftCell = XLSX.utils.encode_cell({ r: m.s.r, c: m.s.c });
      const cell = ws[topLeftCell];
      if (cell) {
        if (!cell.s) cell.s = {};
        if (!cell.s.alignment) cell.s.alignment = {};
        cell.s.alignment = { horizontal: "center", vertical: "center" };
      }
    });

    // Append the worksheet
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

    // Generate file name and download
    const fileName = `${subscription.subscriptionTitle}_${fromDate}_to_${toDate}.xlsx`;
    XLSX.writeFile(wb, fileName);
  };

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-xl font-semibold text-gray-900">
            Customers Enrolled: {customers.length}
          </h1>
          <div className="flex flex-wrap items-end gap-4 mb-4 mt-6">
            <div>
              <label className="block mb-1">Chemicals From:</label>
              <input
                type="date"
                value={fromDate}
                onChange={(e) => setFromDate(e.target.value)}
                className="border border-gray-300 rounded px-2 py-1"
              />
            </div>
            <div>
              <label className="block mb-1">Chemicals To:</label>
              <input
                type="date"
                value={toDate}
                onChange={(e) => setToDate(e.target.value)}
                className="border border-gray-300 rounded px-2 py-1"
              />
            </div>
          </div>
        </div>
        <div className="mt-4 sm:mt-0 sm:ml-16 flex">
          <SubscriptionDetailsTabs setSelectedTab={setSelectedTab} />
          {selectedTab === "Customers" && (
            <button
              onClick={exportToCsv}
              className="inline-flex items-center px-4 py-2 border border-transparent 
                 text-sm font-medium rounded-md shadow-sm text-white 
                 bg-indigo-600 hover:bg-indigo-700 focus:outline-none ml-2"
            >
              Export
            </button>
          )}
        </div>
      </div>
      <div className="mt-8 flex flex-col">
        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
              {selectedTab === "Metrics" && (
                <div style={{ padding: 20, backgroundColor: "#F9FAFB" }}>
                  <h3 className="text-lg font-medium leading-6 text-gray-900">
                    Last 30 days
                  </h3>
                  <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-3">
                    {stats.map((item) => (
                      <div
                        key={item.name}
                        className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6"
                      >
                        <dt className="truncate text-sm font-medium text-gray-500">
                          {item.name}
                        </dt>
                        <dd className="mt-1 text-3xl font-semibold tracking-tight text-gray-900">
                          {item.stat}
                        </dd>
                      </div>
                    ))}
                  </dl>
                </div>
              )}

              {selectedTab === "Customers" && (
                <table className="min-w-full divide-y divide-gray-300">
                  <thead className="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                      >
                        <div className="flex items-center">
                          Owner
                          <button
                            onClick={() => handleSort("owner")}
                            className="ml-1"
                          >
                            {sortConfig.field === "owner" ? (
                              sortConfig.direction === "asc" ? (
                                <ChevronUpIcon className="h-5 w-5 text-black" />
                              ) : (
                                <ChevronDownIcon className="h-5 w-5 text-black" />
                              )
                            ) : (
                              <ChevronUpIcon className="h-5 w-5 text-black" />
                            )}
                          </button>
                        </div>
                      </th>
                      <th
                        scope="col"
                        className="px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        <div className="flex items-center">
                          Address
                          <button
                            onClick={() => handleSort("address")}
                            className="ml-1"
                          >
                            {sortConfig.field === "address" ? (
                              sortConfig.direction === "asc" ? (
                                <ChevronUpIcon className="h-5 w-5 text-black" />
                              ) : (
                                <ChevronDownIcon className="h-5 w-5 text-black" />
                              )
                            ) : (
                              <ChevronUpIcon className="h-5 w-5 text-black" />
                            )}
                          </button>
                        </div>
                      </th>
                      <th
                        scope="col"
                        className="px-1 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        <div className="flex items-center">
                          Subscription Price
                          <button
                            onClick={() => handleSort("subPrice")}
                            className="ml-1"
                          >
                            {sortConfig.field === "subPrice" ? (
                              sortConfig.direction === "asc" ? (
                                <ChevronUpIcon className="h-5 w-5 text-black" />
                              ) : (
                                <ChevronDownIcon className="h-5 w-5 text-black" />
                              )
                            ) : (
                              <ChevronUpIcon className="h-5 w-5 text-black" />
                            )}
                          </button>
                        </div>
                      </th>
                      <th
                        scope="col"
                        className="py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        <div className="flex items-center">
                          Chemical Cost
                          <button
                            onClick={() => handleSort("chemCost")}
                            className="ml-1"
                          >
                            {sortConfig.field === "chemCost" ? (
                              sortConfig.direction === "asc" ? (
                                <ChevronUpIcon className="h-5 w-5 text-black" />
                              ) : (
                                <ChevronDownIcon className="h-5 w-5 text-black" />
                              )
                            ) : (
                              <ChevronUpIcon className="h-5 w-5 text-black" />
                            )}
                          </button>
                        </div>
                      </th>
                    </tr>
                  </thead>

                  <tbody className="divide-y divide-gray-200 bg-white">
                    {customers
                      .sort((a, b) => {
                        let compare = 0;
                        if (sortConfig.field === "owner") {
                          compare = a.serviceLocationOwnerName.localeCompare(
                            b.serviceLocationOwnerName
                          );
                        } else if (sortConfig.field === "address") {
                          compare = a.serviceLocationAddress.localeCompare(
                            b.serviceLocationAddress
                          );
                        } else if (sortConfig.field === "subPrice") {
                          compare =
                            a.subscription.subscriptionPrice -
                            b.subscription.subscriptionPrice;
                        } else if (sortConfig.field === "chemCost") {
                          compare = a.cost.totalCosts - b.cost.totalCosts;
                        }
                        return sortConfig.direction === "asc"
                          ? compare
                          : -compare;
                      })
                      .map((customer) => (
                        <tr key={customer.serviceLocationId}>
                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                            {customer.serviceLocationOwnerName.replace(
                              /(^\w{1})|(\s+\w{1})/g,
                              (letter) => letter.toUpperCase()
                            )}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            {customer.serviceLocationAddress.substring(0, 30) +
                              "..."}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            ${customer.subscription.subscriptionPrice}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            ${customer.cost.totalCosts.toFixed(2)}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
