import { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import { CheckCircleIcon } from "@heroicons/react/solid";
import { generateBusinessId } from "../Tools/generateBusinessId";
import firebase from "firebase/compat/app";
import { toast } from "react-toastify";
import dayjs from "dayjs";
import ViewImage from "../GlobalComponents/ViewImage";

export default function ViewJobReport(props) {
  const [open, setOpen] = useState(false);
  const db = firebase.firestore();
  const selectedBusiness = props.selectedBusiness;
  const selectedReport = props.selectedReport;
  const selectedJob = props.selectedJob;
  const selectedCustomer = props.selectedCustomer;
  const [reportPhotos, setReportPhotos] = useState([]);
  const [measurements, setMeasurements] = useState([]);
  const [chemicals, setChemicals] = useState([]);
  const [tasks, setTasks] = useState([]);
  const [techNotes, setTechNotes] = useState("");
  const [customerMessages, setCustomerMessages] = useState("");
  const [media, setMedia] = useState([]);
  const [viewImage, setViewImage] = useState(false);

  useEffect(() => {
    setOpen(props.open);
    console.log(selectedReport);
    console.log(selectedJob);

    setReportPhotos([]);
    setTechNotes("");
    setCustomerMessages("");

    if (
      selectedReport &&
      selectedReport.reportObjects &&
      selectedReport.reportObjects.some((a) => a.type === "picsVids")
    ) {
      setReportPhotos(
        selectedReport.reportObjects.find((a) => a.type === "picsVids").value
      );
    }

    if (
      selectedReport &&
      selectedReport.reportObjects &&
      selectedReport.reportObjects.some((a) => a.type === "tasks")
    ) {
      setTasks(
        selectedReport.reportObjects.find((a) => a.type === "tasks").value
      );
    }

    if (
      selectedReport &&
      selectedReport.reportObjects &&
      selectedReport.reportObjects.some((a) => a.type === "customerMessage")
    ) {
      setCustomerMessages(
        selectedReport.reportObjects.find((a) => a.type === "customerMessage")
          .value
      );
    }

    if (
      selectedReport &&
      selectedReport.reportObjects &&
      selectedReport.reportObjects.some((a) => a.type === "techNotes")
    ) {
      setTechNotes(
        selectedReport.reportObjects.find((a) => a.type === "techNotes").value
      );
    }
  }, [props.open]);

  const handleClose = () => {
    setOpen(false);
    props.setOpen(false);
  };

  const handleViewImage = (media) => {
    setMedia(media);
    setViewImage(true);
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={handleClose}>
        <div className="fixed inset-0" />

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-300 sm:duration-500"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-300 sm:duration-500"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                  <div className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
                    {selectedReport && (
                      <div className="h-0 flex-1 overflow-y-auto">
                        <div className="bg-black py-6 px-4 sm:px-6">
                          <div className="flex items-center justify-between">
                            <Dialog.Title className="text-lg font-medium text-white">
                              {" "}
                              {selectedJob.jobTitle}{" "}
                            </Dialog.Title>
                            <div className="ml-3 flex h-7 items-center">
                              <button
                                type="button"
                                className="rounded-md bg-white text-black hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                                onClick={handleClose}
                              >
                                <span className="sr-only">Close panel</span>
                                <XIcon className="h-6 w-6" aria-hidden="true" />
                              </button>
                            </div>
                          </div>
                          <div className="mt-1">
                            <p className="text-sm text-white">
                              {selectedJob.customerData.customerHomeAddress}
                            </p>
                          </div>
                        </div>
                        <div className="flex flex-1 flex-col justify-between">
                          <div className="divide-y divide-gray-200 px-4 sm:px-6">
                            <div className="space-y-6 pt-6 pb-5">
                              <div>
                                <label
                                  htmlFor="project-name"
                                  className="block text-sm font-medium text-gray-900"
                                >
                                  {selectedReport.reportEmployee.name}
                                </label>
                                <div className="mt-1 flex">
                                  <div className="text-gray-500">
                                    {dayjs(
                                      selectedReport.reportDate.toDate()
                                    ).format("dddd")}
                                  </div>
                                  <div className="ml-4 text-gray-500">
                                    {dayjs(
                                      selectedReport.reportDate.toDate()
                                    ).format("h:mmA")}
                                  </div>
                                  <div className="ml-4 text-gray-500">
                                    {dayjs(
                                      selectedReport.reportDate.toDate()
                                    ).format("MM/DD/YYYY")}
                                  </div>
                                </div>
                              </div>
                              <div>
                                <label
                                  htmlFor="project-name"
                                  className="block text-sm font-medium text-gray-900"
                                >
                                  {" "}
                                  Photos{" "}
                                </label>
                                <div className="mt-1 flex overflow-y-scroll">
                                  {reportPhotos.map((photo) => (
                                    <img
                                      onClick={() => handleViewImage(photo)}
                                      key={photo}
                                      src={photo}
                                      style={{
                                        minHeight: 100,
                                        minWidth: 100,
                                        maxHeight: 100,
                                        maxWidth: 100,
                                        margin: 2,
                                        borderRadius: 5,
                                        cursor: "pointer",
                                      }}
                                    />
                                  ))}
                                </div>
                              </div>

                              <div>
                                <label
                                  htmlFor="project-name"
                                  className="block text-sm font-medium text-gray-900"
                                >
                                  {" "}
                                  Tech Notes{" "}
                                </label>
                                <div className="mt-1">
                                  <div className="text-black">{techNotes}</div>
                                </div>
                              </div>
                              <div>
                                <label
                                  htmlFor="project-name"
                                  className="block text-sm font-medium text-gray-900"
                                >
                                  {" "}
                                  Customer Message{" "}
                                </label>
                                <div className="mt-1">
                                  <div className="text-black">
                                    {customerMessages}
                                  </div>
                                </div>
                              </div>

                              {selectedReport.reportObjects?.find(
                                ({ type }) => type === "chemicalsAdded"
                              ) && (
                                <div
                                  style={{
                                    borderBottom: "1.4px solid #D9D9D9",
                                    marginTop: "4%",
                                    borderRadius: 10,
                                  }}
                                ></div>
                              )}

                              <div>
                                {selectedReport.reportObjects?.find(
                                  ({ type }) => type === "chemicalsAdded"
                                ) && (
                                  <p
                                    style={{
                                      fontSize: 18,
                                      fontWeight: 600,
                                      marginTop: 22,
                                      marginLeft: 4,
                                    }}
                                  >
                                    Chemicals Added
                                  </p>
                                )}

                                {selectedReport.reportObjects
                                  ?.find(
                                    ({ type }) => type === "chemicalsAdded"
                                  )
                                  ?.value?.filter((a) => a.value?.length > 0)
                                  .map((chemical, index) => (
                                    <div
                                      key={index}
                                      style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        marginLeft: 4,
                                        marginTop: 18,
                                      }}
                                    >
                                      <div
                                        style={{
                                          display: "flex",
                                          flexDirection: "row",
                                          alignItems: "center",
                                          width: "100%",
                                        }}
                                      >
                                        <p
                                          style={{
                                            fontSize: 16,
                                            fontWeight: 500,
                                            margin: 0,
                                          }}
                                        >
                                          {chemical.chemicalData?.name}
                                        </p>
                                        <p
                                          style={{
                                            fontSize: 14,
                                            marginLeft: "auto",
                                            marginRight: "4%",
                                          }}
                                        >
                                          {chemical.value}
                                        </p>
                                      </div>
                                    </div>
                                  ))}
                              </div>

                              {selectedReport.reportObjects?.filter(
                                (a) => a.type === "readings"
                              )[0] && (
                                <div
                                  style={{
                                    borderBottom: "1.4px solid #D9D9D9",
                                    marginTop: "6%",
                                    borderRadius: 10,
                                  }}
                                ></div>
                              )}

                              <div>
                                {selectedReport.reportObjects?.filter(
                                  (a) => a.type === "readings"
                                )[0] && (
                                  <p
                                    style={{
                                      fontSize: 18,
                                      fontWeight: 600,
                                      marginTop: 32,
                                      marginLeft: 4,
                                    }}
                                  >
                                    Readings
                                  </p>
                                )}

                                {selectedReport.reportObjects
                                  ?.filter((a) => a.type === "readings")[0]
                                  ?.value?.filter((a) => a.value?.length > 0)
                                  .map((reading, index) => (
                                    <div
                                      sdfsdsdf={console.log(
                                        selectedReport.reportObjects?.filter(
                                          (a) => a.type === "readings"
                                        )
                                      )}
                                      key={index}
                                      style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        marginLeft: 4,
                                        marginTop: 18,
                                      }}
                                    >
                                      <div
                                        style={{
                                          display: "flex",
                                          flexDirection: "row",
                                          alignItems: "center",
                                          width: "100%",
                                        }}
                                      >
                                        <p
                                          style={{
                                            fontSize: 16,
                                            fontWeight: 500,
                                            margin: 0,
                                          }}
                                        >
                                          {reading.readingData?.name}
                                        </p>
                                        <p
                                          style={{
                                            fontSize: 14,
                                            marginLeft: "auto",
                                            marginRight: "4%",
                                          }}
                                        >
                                          {reading.value}
                                        </p>
                                      </div>
                                    </div>
                                  ))}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}

                    <div className="flex flex-shrink-0 justify-end px-4 py-4">
                      <button
                        onClick={() => handleClose(false)}
                        type="submit"
                        className="ml-4 inline-flex justify-center rounded-md border border-transparent bg-black py-2 px-4 text-sm font-medium text-white shadow-sm"
                      >
                        Close
                      </button>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
          <ViewImage media={media} open={viewImage} setOpen={setViewImage} />
        </div>
      </Dialog>
    </Transition.Root>
  );
}
