import { useEffect, useState } from "react";
import { IoLocationSharp } from "react-icons/io5";
import ServiceLocationSubscriptionCard from "./ServiceLocationSubscriptionCard";
import firebase from "firebase/compat/app";
import { confirmAlert } from "react-confirm-alert";
import { toast } from "react-toastify";
// New modal component to handle pause options with improved styling
function PauseSubscriptionModal({
  onClose,
  activeSubscription,
  handleNowPause,
  handleFuturePause,
}) {
  const [pauseOption, setPauseOption] = useState("now");
  const [selectedDate, setSelectedDate] = useState("");

  return (
    <div className="bg-white rounded-lg shadow-lg p-6 max-w-md mx-auto">
      <h1 className="text-2xl font-bold mb-4">Please Confirm</h1>
      <p className="text-gray-700 mb-4">
        Are you sure you want to pause this subscription? It will remove all
        currently scheduled recurring stops for this service location.
      </p>
      <div className="mb-4">
        <div className="flex items-center mb-2">
          <input
            type="radio"
            id="pauseNow"
            value="now"
            checked={pauseOption === "now"}
            onChange={() => setPauseOption("now")}
            className="mr-2"
          />
          <label htmlFor="pauseNow" className="text-gray-800">
            Pause Now
          </label>
        </div>
        <div className="flex items-center">
          <input
            type="radio"
            id="pauseFuture"
            value="future"
            checked={pauseOption === "future"}
            onChange={() => setPauseOption("future")}
            className="mr-2"
          />
          <label htmlFor="pauseFuture" className="text-gray-800">
            Pause in Future
          </label>
        </div>
      </div>
      {pauseOption === "future" && (
        <div className="mb-4">
          <label className="block text-gray-800 mb-2">Select Date:</label>
          <input
            type="date"
            value={selectedDate}
            onChange={(e) => setSelectedDate(e.target.value)}
            className="w-full border border-gray-300 rounded px-3 py-2"
          />
        </div>
      )}
      <div className="flex justify-end space-x-4">
        <button
          onClick={() => {
            if (pauseOption === "now") {
              handleNowPause();
            } else {
              handleFuturePause(selectedDate);
            }
            onClose();
          }}
          className="bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700 transition"
        >
          Pause
        </button>
        <button
          onClick={onClose}
          className="bg-gray-300 text-gray-800 px-4 py-2 rounded hover:bg-gray-400 transition"
        >
          Cancel
        </button>
      </div>
    </div>
  );
}

export default function ServiceLocationSubscriptions(props) {
  const db = firebase.firestore();
  const [activeSubscription, setActiveSubscription] = useState();
  const [pausedSubscriptions, setPausedSubscriptions] = useState([]);
  const [reload, setReload] = useState(false);
  //scheduledSubscription: firebase.firestore.FieldValue.delete(),
  //pausedSubscriptions: pausedSubscriptions,

  const getSubscriptionName = async (stopTemplateId) => {
    const subscription = await db
      .collection("Businesses")
      .doc(props.businessId)
      .collection("Subscriptions")
      .where("connectedStopTemplateId", "==", stopTemplateId)
      .get();

    return subscription.docs[0].data().subscriptionTitle;
  };

  useEffect(() => {
    const unsubscribe = db
      .collection("Customers")
      .doc(props.serviceLocation.customerId)
      .collection("ServiceLocations")
      .doc(props.serviceLocation.serviceLocationId)
      .onSnapshot(async (snapshot) => {
        const data = snapshot.data();

        if (data?.subscriptions) {
          const resolvedSubscriptions = await Promise.all(
            data.subscriptions.map(async (subscription) => {
              const subscriptionTitle = await getSubscriptionName(
                subscription.connectedStopTemplateId
              );
              return { ...subscription, subscriptionTitle };
            })
          );

          setActiveSubscription(resolvedSubscriptions);
        }

        const pausedSubs = data.pausedSubscriptions || [];

        // do same thing for paused subscriptions

        const resolvedPausedSubscriptions = await Promise.all(
          pausedSubs.map(async (subscription) => {
            const subscriptionTitle = await getSubscriptionName(
              subscription.connectedStopTemplateId
            );
            return { ...subscription, subscriptionTitle };
          })
        );

        setPausedSubscriptions(resolvedPausedSubscriptions);
      });

    return () => unsubscribe();
  }, [
    props.serviceLocation.customerId,
    props.serviceLocation.serviceLocationId,
  ]);

  const handlePauseSubscription = (activeSubscription) => {
    confirmAlert({
      customUI: ({ onClose }) => (
        <div className="fixed inset-0 bg-gray-500 bg-opacity-50 flex items-center justify-center">
          <PauseSubscriptionModal
            onClose={onClose}
            activeSubscription={activeSubscription}
            handleNowPause={() => {
              firebase
                .auth()
                .currentUser.getIdToken()
                .then(async (token) => {
                  await fetch(
                    "https://us-central1-symbri-production.cloudfunctions.net/handlePauseSubscription",
                    {
                      method: "POST",
                      body: JSON.stringify({
                        token: token,
                        serviceLocationId:
                          props.serviceLocation.serviceLocationId,
                        customerId: props.serviceLocation.customerId,
                        subscriptionId: activeSubscription.subscriptionId,
                        subscriptionStopId:
                          activeSubscription.subscriptionStopId,
                      }),
                    }
                  );
                  setReload(!reload);
                });
            }}
            handleFuturePause={(selectedDate) => {
              console.log("Future pause date:", selectedDate);

              db.collection("Businesses")
                .doc(props.businessId)
                .collection("PendingSubPauses")
                .doc("PendingSubPauses")
                .set(
                  {
                    pendingPauses: firebase.firestore.FieldValue.arrayUnion({
                      serviceLocationId:
                        props.serviceLocation.serviceLocationId,
                      subscriptionId: activeSubscription.subscriptionId,
                      subscriptionStopId: activeSubscription.subscriptionStopId,
                      pauseDate: selectedDate,
                      uid: firebase.auth().currentUser.uid,
                      customerId: props.serviceLocation.customerId,
                    }),
                  },
                  { merge: true }
                );

              toast.success("Subscription pause scheduled successfully!");
            }}
          />
        </div>
      ),
    });
  };

  const handleDeleteSubscription = async (
    subscriptionId,
    subscriptionStopId
  ) => {
    const token = await firebase.auth().currentUser.getIdToken();

    await fetch(
      "https://us-central1-symbri-production.cloudfunctions.net/handleDeleteSubscription",
      {
        method: "POST",
        body: JSON.stringify({
          token: token,
          serviceLocationId: props.serviceLocation.serviceLocationId,
          customerId: props.serviceLocation.customerId,
          subscriptionId: subscriptionId,
          subscriptionStopId: subscriptionStopId,
        }),
      }
    );

    setReload(!reload);
  };

  return (
    <div className="px-4 sm:px-6 lg:px-0">
      <div className="mt-8 flex flex-col">
        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg border-gray-200 border-2">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr className="flex justify-between">
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 flex-shrink"
                    >
                      Subscriptions
                    </th>
                    <th
                      onClick={() => props.setAddSubscriptionDisplay(true)}
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-right text-[blue] text-sm font-semibold text-gray-900 sm:pl-6 flex-shrink cursor-pointer"
                    >
                      + Add Subscription
                    </th>
                  </tr>
                </thead>

                <tbody className="divide-y divide-gray-200 bg-white customer-profile-tbody">
                  {activeSubscription &&
                    activeSubscription.map((activeSubscription) => (
                      <ServiceLocationSubscriptionCard
                        businessId={props.businessId}
                        subscriptionDetails={activeSubscription}
                        paused={activeSubscription.paused}
                        serviceLocation={props.serviceLocation}
                        handlePauseSubscription={() =>
                          handlePauseSubscription(activeSubscription)
                        }
                        handleResumeSubscription={
                          props.handleResumeSubscription
                        }
                        handleReloadData={props.handleReloadData}
                        cancelScheduled={props.cancelScheduled}
                        handleDeleteSubscription={handleDeleteSubscription}
                        handleViewSubscription={props.handleViewSubscription}
                        reloadCards={reload}
                      />
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
