import React, { useState, useEffect, useRef } from "react";
import firebase from "firebase/compat/app";
import { generateBusinessId } from "../../Tools/generateBusinessId";
import RouteBuilderEdit from "./RouteBuilderEdit";
import { BsPencilSquare, BsSearch } from "react-icons/bs";
import dayjs from "dayjs";
import { confirmAlert } from "react-confirm-alert";
import NewRoute from "./NewRoute";
import EditRoute from "./EditRoute";
import { BiTrash } from "react-icons/bi";
import { toast } from "react-toastify";
import { FaRegCopy } from "react-icons/fa";

export default function RouteBuilderHome(props) {
  const db = firebase.firestore();
  const selectedBusiness = props.businessId;
  const [routes, setRoutes] = useState([]);
  const [selectedRoute, setSelectedRoute] = useState({});
  const [homeDisplay, setHomeDisplay] = useState(true);
  const [newRouteDisplay, setNewRouteDisplay] = useState(false);
  const [editRouteDisplay, setEditRouteDisplay] = useState(false);
  const [editRouteData, setEditRouteData] = useState(false);
  const [routeDisplay, setRouteDisplay] = useState(false);
  const [hoverDisplay, setHoverDisplay] = useState(false);
  const [hoverDisplayData, setHoverDisplayData] = useState({});
  const [refresh, setRefresh] = useState(0);
  const [addCustomersDisplay, setAddCustomersDisplay] = useState(false);
  const [employeesInRoute, setEmployeesInRoute] = useState([]);
  const [activateRouteDisplay, setActivateRouteDisplay] = useState(false);
  const [employeesDisplay, setEmployeesDisplay] = useState(false);
  const [employeeBackupData, setEmployeeBackupData] = useState([]);
  const [customerBackupData, setCustomerBackupData] = useState([]);
  const userPermissions = props.userPermissions;

  const [serviceLocationsInRouteHome, setServiceLocationsInRouteHome] =
    useState([]);
  const dateToCheck = useRef();
  const isMounted = useRef();

  useEffect(() => {
    isMounted.current = true;
    const databaseSnapshot = db
      .collection("Businesses")
      .doc(selectedBusiness)
      .collection("Routes")
      .onSnapshot((querySnapshot) => {
        let arrOfRoutes = [];
        querySnapshot.docs.forEach((value) => {
          arrOfRoutes.push(value.data());
          setRoutes(arrOfRoutes);
          setRefresh(Math.random(0, 2));
        });
      });

    return () => {
      isMounted.current = false;
      databaseSnapshot();
    };
  }, []);

  const handleCopyRoute = (value, event) => {
    event.stopPropagation();

    if (!userPermissions.includes("Edit Recurring Routes")) {
      toast.warn("You do not have permission to delete jobs.");
      return;
    }

    confirmAlert({
      title: "Please confirm!",
      message: "Are you sure you want to copy this route?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            firebase
              .auth()
              .currentUser.getIdToken()
              .then((token) => {
                fetch(
                  "https://us-central1-symbri-production.cloudfunctions.net/handleCopyRoute",
                  {
                    method: "POST",
                    body: JSON.stringify({
                      selectedBusiness: selectedBusiness,
                      value: value,
                      token: token,
                    }),
                  }
                );
              });
          },
        },
        {
          label: "No",
          onClick: () => console.log("Click No"),
        },
      ],
    });
  };

  const handleDeleteRoute = (value, event) => {
    event.stopPropagation();

    if (!userPermissions.includes("Edit Recurring Routes")) {
      toast.warn("You do not have permission to delete jobs.");
      return;
    }

    confirmAlert({
      title: "Please confirm!",
      message:
        "Are you sure you want to delete this route? It will remove all stops connected to this route.",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            let currentRoutes = routes;
            let indexOfRoute = currentRoutes.findIndex(
              (a) => a.routeId === value.routeId
            );

            currentRoutes.splice(indexOfRoute, 1);
            setRoutes(currentRoutes);
            setRefresh(Math.random(0, 2));
            firebase
              .auth()
              .currentUser.getIdToken()
              .then((token) => {
                fetch(
                  "https://us-central1-symbri-production.cloudfunctions.net/handleDeleteRoute",
                  {
                    method: "POST",
                    body: JSON.stringify({
                      selectedBusiness: selectedBusiness,
                      value: value,
                      token: token,
                    }),
                  }
                );
              });
          },
        },
        {
          label: "No",
          onClick: () => console.log("Click No"),
        },
      ],
    });
  };

  const handleRouteClick = (value) => {
    //need to get each route object in each employee
    //need to get each service location in each customer
    //{documentId: documentId, routeObject: routeObject, employeeDoc: employeeDoc}
    //{documentId: documentId, serviceLocation: serviceLocation, customerId: customerId }
    //store these objects in an array
    //then on cancel, just loop through that array and restore each document.

    if (!userPermissions.includes("Edit Recurring Routes")) {
      toast.warn("You do not have permission to delete jobs.");
      return;
    }

    props.handleAddToBreadcrumb({
      name: value.routeName,
      type: "route",
      current: true,
    });
    if (value.active) {
      let arrOfEmpBackups = [];
      let arrOfCustomerBackups = [];
      db.collection("Businesses")
        .doc(selectedBusiness)
        .collection("Routes")
        .doc(value.routeId)
        .collection("Employees")
        .get()
        .then((snapshot) => {
          snapshot.docs.forEach((employeeDoc) => {
            const employeeData = employeeDoc.data();
            db.collection("Businesses")
              .doc(selectedBusiness)
              .collection("Routes")
              .doc(value.routeId)
              .collection("Employees")
              .doc(employeeData.uid)
              .collection("Routes")
              .get()
              .then((snapshot) => {
                if (snapshot.docs.length === 1) {
                  let employeeBackup = {};
                  employeeBackup.employeeDoc = employeeData;
                  employeeBackup.documentId = "none";
                  employeeBackup.type = "employee";
                  employeeBackup.routeObject = "none";
                  arrOfEmpBackups.push(employeeBackup);
                  setEmployeeBackupData(arrOfEmpBackups);
                } else {
                  snapshot.docs.forEach((routeDoc) => {
                    if (routeDoc.id !== "Pending") {
                      let employeeBackup = {};
                      employeeBackup.employeeDoc = employeeData;
                      const routeData = routeDoc.data();
                      employeeBackup.documentId = routeDoc.id;
                      employeeBackup.type = "employee";
                      employeeBackup.routeObject = routeData;
                      arrOfEmpBackups.push(employeeBackup);
                      setEmployeeBackupData(arrOfEmpBackups);
                    }
                  });
                }
              });
          });
        });

      db.collection("Businesses")
        .doc(selectedBusiness)
        .collection("Routes")
        .doc(value.routeId)
        .collection("ServiceLocations")
        .get()
        .then((snapshot) => {
          snapshot.docs.forEach((serviceLocationDoc) => {
            let customerBackup = {};
            const serviceLocationData = serviceLocationDoc.data();
            customerBackup.documentId = serviceLocationDoc.id;
            customerBackup.serviceLocation = serviceLocationData;
            customerBackup.customerId = value.customerId;
            arrOfCustomerBackups.push(customerBackup);
            setCustomerBackupData(arrOfCustomerBackups);
          });
        });
    }
    setSelectedRoute(value);
    setHomeDisplay(false);
    setRouteDisplay(true);
  };

  const handleCloseRouteClick = () => {
    setSelectedRoute({});
    setRouteDisplay(false);
    setEmployeesDisplay(false);
    setAddCustomersDisplay(false);
    setActivateRouteDisplay(false);
    setHomeDisplay(true);
    props.resetBreadcrumbForRoute();
  };

  const handleCloseActiveRouteClick = () => {
    db.collection("Businesses")
      .doc(selectedBusiness)
      .collection("Routes")
      .doc(selectedRoute.routeId)
      .collection("Employees")
      .get()
      .then((snapshot) => {
        snapshot.docs.forEach((doc) => {
          if (
            employeeBackupData.findIndex((a) => a.employeeDoc.uid === doc.id) ==
              -1 &&
            employeeBackupData.findIndex((a) => a.documentId === "none") === -1
          ) {
            doc.ref.delete();
            db.collection("Businesses")
              .doc(selectedBusiness)
              .collection("Routes")
              .doc(selectedRoute.routeId)
              .collection("Employees")
              .doc(doc.ref.id)
              .collection("Routes")
              .get()
              .then((snapshot) => {
                snapshot.docs.forEach((doc) => {
                  doc.ref.delete();
                });
              });
          }
        });
      });

    customerBackupData.forEach((backupObj, index) => {
      db.collection("Businesses")
        .doc(selectedBusiness)
        .collection("Routes")
        .doc(selectedRoute.routeId)
        .collection("ServiceLocations")
        .doc(backupObj.documentId)
        .update({
          serviceLocation: backupObj.serviceLocation.serviceLocation,
        });

      if (customerBackupData.length - 1 === index) {
        employeeBackupData.forEach((backupObj, index) => {
          if (backupObj.documentId !== "none") {
            db.collection("Businesses")
              .doc(selectedBusiness)
              .collection("Routes")
              .doc(selectedRoute.routeId)
              .collection("Employees")
              .doc(backupObj.employeeDoc.uid)
              .collection("Routes")
              .get()
              .then((snapshot) => {
                let daysInsideBackup = [];
                snapshot.docs.forEach((doc) => {
                  backupObj.routeObject.route.forEach((value, index) => {
                    let day = value.day;
                    daysInsideBackup.push(day);
                    if (backupObj.routeObject.route.length - 1 === index) {
                      if (
                        daysInsideBackup.findIndex((a) => a === doc.id) === -1
                      ) {
                        doc.ref.delete();
                      }
                    }
                  });
                });
              });

            //Need to check if a new day has been added that is not in the backup when in edit mode in active route so when you run the cancel code it knows to get rid of that day
            db.collection("Businesses")
              .doc(selectedBusiness)
              .collection("Routes")
              .doc(selectedRoute.routeId)
              .collection("Employees")
              .doc(backupObj.employeeDoc.uid)
              .set(backupObj.employeeDoc)
              .then(() => {
                db.collection("Businesses")
                  .doc(selectedBusiness)
                  .collection("Routes")
                  .doc(selectedRoute.routeId)
                  .collection("Employees")
                  .doc(backupObj.employeeDoc.uid)
                  .collection("Routes")
                  .doc(backupObj.documentId)
                  .update({ route: backupObj.routeObject.route })
                  .catch((e) => console.log(e));
              });
          } else {
            db.collection("Businesses")
              .doc(selectedBusiness)
              .collection("Routes")
              .doc(selectedRoute.routeId)
              .collection("Employees")
              .doc(backupObj.employeeDoc.uid)
              .collection("Routes")
              .get()
              .then((snapshot) => {
                if (snapshot.docs.length !== 1) {
                  snapshot.docs.forEach((doc) => {
                    if (doc.id !== "Pending") {
                      doc.ref.delete();
                    }
                  });
                } else {
                  db.collection("Businesses")
                    .doc(selectedBusiness)
                    .collection("Routes")
                    .doc(selectedRoute.routeId)
                    .collection("Employees")
                    .doc(backupObj.employeeDoc.uid)
                    .set(backupObj.employeeDoc)
                    .then(() => {
                      db.collection("Businesses")
                        .doc(selectedBusiness)
                        .collection("Routes")
                        .doc(selectedRoute.routeId)
                        .collection("Employees")
                        .doc(backupObj.employeeDoc.uid)
                        .collection("Routes")
                        .doc("Pending")
                        .update({ pending: true })
                        .catch((e) => console.log(e));
                    });
                }
              });
          }
          if (employeeBackupData.length - 1 === index) {
            setTimeout(() => {
              setSelectedRoute({});
              setRouteDisplay(false);
              setEmployeesDisplay(false);
              setAddCustomersDisplay(false);
              setActivateRouteDisplay(false);
              setHomeDisplay(true);
              setCustomerBackupData([]);
              setEmployeeBackupData([]);
            }, 800);
          }
        });
      }
    });
  };

  const handleAddCustomersClick = () => {
    setAddCustomersDisplay(!addCustomersDisplay);
    setActivateRouteDisplay(false);
    setEmployeesDisplay(false);
    setHoverDisplay(false);
    setHoverDisplayData({});
  };

  const handleActivateRouteClick = () => {
    setAddCustomersDisplay(false);
    setActivateRouteDisplay(!activateRouteDisplay);
    setEmployeesDisplay(false);
  };

  const handleEmployeesClick = () => {
    setAddCustomersDisplay(false);
    setActivateRouteDisplay(false);
    setEmployeesDisplay(!employeesDisplay);
  };

  const sortRouteByDate = async (routeData) => {
    const routeFrequency = routeData.frequency;

    const selectedDay =
      routeData.dayOfTheWeek === "Monday"
        ? 8
        : routeData.dayOfTheWeek === "Tuesday"
        ? 9
        : routeData.dayOfTheWeek === "Wednesday"
        ? 10
        : routeData.dayOfTheWeek === "Thursday"
        ? 11
        : routeData.dayOfTheWeek === "Friday"
        ? 12
        : routeData.dayOfTheWeek === "Saturday"
        ? 13
        : routeData.dayOfTheWeek === "Sunday"
        ? 7
        : undefined;

    dateToCheck.current = dayjs(routeData.startOn).weekday(selectedDay);

    let datesArr = [];

    for (let index = 0; index < 200; index++) {
      if (routeFrequency === "weekly") {
        if (index !== 0) {
          dateToCheck.current = dayjs(dateToCheck.current).add(7, "days");
        }

        if (
          routeData.stopAfter !== "no end"
            ? dayjs(dateToCheck.current).isBefore(dayjs(routeData.stopAfter))
            : true
        ) {
          datesArr.push(dayjs(dateToCheck.current).format("MM/DD/YYYY"));
        }

        if (index === 199) {
          return datesArr;
        }
      }
      if (routeFrequency === "every 2 weeks") {
        if (index !== 0) {
          dateToCheck.current = dayjs(dateToCheck.current).add(14, "days");
        }
        if (
          routeData.stopAfter !== "no end"
            ? dayjs(dateToCheck.current).isBefore(dayjs(routeData.stopAfter))
            : true
        ) {
          datesArr.push(dayjs(dateToCheck.current).format("MM/DD/YYYY"));
        }
        if (index === 199) {
          return datesArr;
        }
      }
      if (routeFrequency === "every 3 weeks") {
        if (index !== 0) {
          dateToCheck.current = dayjs(dateToCheck.current).add(21, "days");
        }
        if (
          routeData.stopAfter !== "no end"
            ? dayjs(dateToCheck.current).isBefore(dayjs(routeData.stopAfter))
            : true
        ) {
          datesArr.push(dayjs(dateToCheck.current).format("MM/DD/YYYY"));
        }
        if (index === 199) {
          return datesArr;
        }
      }
      if (routeFrequency === "every 4 weeks") {
        if (index !== 0) {
          dateToCheck.current = dayjs(dateToCheck.current).add(28, "days");
        }
        if (
          routeData.stopAfter !== "no end"
            ? dayjs(dateToCheck.current).isBefore(dayjs(routeData.stopAfter))
            : true
        ) {
          datesArr.push(dayjs(dateToCheck.current).format("MM/DD/YYYY"));
        }
        if (index === 199) {
          return datesArr;
        }
      }
    }
  };

  const handleRouteSave = () => {
    db.collection("Businesses")
      .doc(selectedBusiness)
      .collection("Routes")
      .doc(selectedRoute.routeId)
      .update({
        active: true,
      })
      .then(() => {
        db.collection("Businesses")
          .doc(selectedBusiness)
          .collection("Routes")
          .where("active", "==", true)
          .get()
          .then((querySnapshot) => {
            querySnapshot.docs.forEach((doc) => {
              const docData = doc.data();
              if (docData.routeId !== selectedRoute.routeId) {
                doc.ref.update({
                  active: false,
                });
              }
            });
          });

        serviceLocationsInRouteHome.forEach((serviceLocation, index) => {
          if (serviceLocation.assigned) {
            const newStopId = generateBusinessId();
            const messageId = generateBusinessId();

            db.collection("Customers")
              .doc(serviceLocation.customerId)
              .get()
              .then((documentSnapshot) => {
                const customerData = documentSnapshot.data();

                db.collection("Customers")
                  .doc(serviceLocation.customerId)
                  .collection("ServiceLocations")
                  .doc(serviceLocation.serviceLocationId)
                  .get()
                  .then(async (documentSnapshot) => {
                    let serviceLocationData = documentSnapshot.data();

                    const routeData = {
                      dayOfTheWeek: serviceLocation.day,
                      frequency:
                        serviceLocation.recurringStopType.recurringSchedule,
                      startOn: selectedRoute.startOn,
                      stopAfter: "no end",
                      name: serviceLocation.name,
                      address: serviceLocation.address,
                      stopId: newStopId,
                      routeId: selectedRoute.routeId,
                      serviceLocationId: serviceLocation.serviceLocationId,
                      completed: false,
                      contractor: {
                        id: serviceLocation.employeeUid,
                        name: serviceLocation.employeeUid,
                        profilePhoto: serviceLocation.employeePhoto,
                      },
                      customerData: {
                        name:
                          customerData.customerFirstName +
                          " " +
                          customerData.customerLastName,
                        customerId: customerData.customerId,
                        businessIds: customerData.businessIds,
                      },
                      businessId: customerData.businessIds[0],
                      stopNickname: serviceLocation.recurringStopType.stopName,
                      stopTemplateId:
                        serviceLocation.recurringStopType.stopTemplateId,
                      stopCustomerDescription:
                        serviceLocation.recurringStopType.customerDescription,
                      stopWorkerDescription:
                        serviceLocation.recurringStopType.workerDescription,
                      type: "Reoccurring Stop",
                      messageId: messageId,
                    };

                    db.collection("Customers")
                      .doc(serviceLocation.customerId)
                      .collection("ServiceLocations")
                      .doc(serviceLocation.serviceLocationId)
                      .update({
                        routes:
                          firebase.firestore.FieldValue.arrayUnion(routeData),
                      })
                      .then(async () => {
                        const routeDates = await sortRouteByDate(routeData);

                        routeDates.forEach((value, index) => {
                          db.collection("Businesses")
                            .doc(customerData.businessIds[0])
                            .collection("Workers")
                            .doc(serviceLocation.employeeUid)
                            .collection("Routes")
                            .doc(value.replace(/\//g, "-"))
                            .set(
                              {
                                route:
                                  firebase.firestore.FieldValue.arrayUnion(
                                    routeData
                                  ),
                                date: value.replace(/\//g, "-"),
                                distanceTime: {},
                                checkDistanceTime: true,
                                routeOrder: [newStopId],
                              },
                              { merge: true }
                            );
                          if (routeDates.length - 1 === index) {
                            db.collection("Businesses")
                              .doc(customerData.businessIds[0])
                              .collection("Workers")
                              .doc(serviceLocation.employeeUid)
                              .collection("Routes")
                              .get()
                              .then((snapshot) => {
                                snapshot.docs.forEach(
                                  (documentSnapshot, index) => {
                                    if (documentSnapshot.id !== "Pending") {
                                      const docData = documentSnapshot.data();
                                      let currentRoute = docData.route;
                                      currentRoute.forEach(
                                        (routeValue, index) => {
                                          if (
                                            routeValue.stopTemplateId ===
                                              serviceLocation.recurringStopType
                                                .stopTemplateId &&
                                            routeValue.serviceLocationId ===
                                              serviceLocation.serviceLocationId &&
                                            dayjs(documentSnapshot.id).isAfter(
                                              dayjs(props.routeStartDate)
                                            )
                                          ) {
                                            currentRoute.splice(index, 1);
                                          }
                                        }
                                      );
                                      setTimeout(() => {
                                        db.collection("Businesses")
                                          .doc(customerData.businessIds[0])
                                          .collection("Workers")
                                          .doc(serviceLocation.employeeUid)
                                          .collection("Routes")
                                          .doc(documentSnapshot.id)
                                          .update({
                                            route: currentRoute,
                                          });
                                      }, 3000);
                                    }
                                  }
                                );
                              });
                          }
                        });
                      });
                  });
              });
          }
          if (serviceLocationsInRouteHome.length - 1 === index) {
            setTimeout(() => {
              handleCloseRouteClick();
            }, 2000);
          }
        });
      });
  };

  const handleEditClick = (route, event) => {
    event.stopPropagation();
    if (!userPermissions.includes("Edit Recurring Routes")) {
      toast.warn("You do not have permission to delete jobs.");
      return;
    }
    setEditRouteData(route);
    setEditRouteDisplay(true);
  };

  return homeDisplay ? (
    <div className="px-4 sm:px-6 lg:px-8">
      <NewRoute
        selectedBusiness={selectedBusiness}
        setOpen={setNewRouteDisplay}
        open={newRouteDisplay}
      />
      <EditRoute
        selectedBusiness={selectedBusiness}
        setOpen={setEditRouteDisplay}
        open={editRouteDisplay}
        editRouteData={editRouteData}
      />
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-xl font-semibold text-gray-900">Routes</h1>
        </div>
        <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
          <button
            onClick={() => setNewRouteDisplay(true)}
            type="button"
            className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
          >
            New Route
          </button>
        </div>
      </div>
      <div className="mt-8 flex flex-col">
        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                    >
                      Name
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {routes
                    .sort((a, b) => b.active - a.active)
                    .filter((obj) =>
                      obj.routeName
                        .toString()
                        .toLowerCase()
                        .includes(props.searchTerm.toLowerCase())
                    )
                    .map((route) => (
                      <tr
                        onClick={() => handleRouteClick(route)}
                        className="cursor-pointer"
                        key={route.routeId}
                      >
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                          {route.routeName.replace(
                            /(^\w{1})|(\s+\w{1})/g,
                            (letter) => letter.toUpperCase()
                          )}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "flex-end",
                              marginLeft: 200,
                            }}
                          >
                            <div style={{ marginRight: 10 }}>
                              {route.pendingAlreadyActive
                                ? "Pending"
                                : route.active
                                ? "Active"
                                : route.pending
                                ? "Pending"
                                : "Inactive"}
                            </div>
                            <div
                              style={{
                                width: 12,
                                height: 12,
                                backgroundColor: route.pendingAlreadyActive
                                  ? "orange"
                                  : route.active
                                  ? "green"
                                  : route.pending
                                  ? "orange"
                                  : "red",
                                borderRadius: 50,
                              }}
                            ></div>
                            <div
                              onClick={(event) => handleEditClick(route, event)}
                              style={{
                                marginLeft: "20px",
                                marginRight: "10px",
                              }}
                            >
                              <BsPencilSquare size={20} />
                            </div>
                            <div
                              onClick={(event) => handleCopyRoute(route, event)}
                              style={{
                                marginLeft: "10px",
                                marginRight: "10px",
                              }}
                            >
                              <FaRegCopy size={22} />
                            </div>
                            <div
                              onClick={(event) =>
                                handleDeleteRoute(route, event)
                              }
                              style={{
                                marginLeft: "10px",
                                marginRight: "10px",
                              }}
                            >
                              <BiTrash size={22} />
                            </div>
                          </div>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div
      style={{
        marginBottom: 50,
      }}
    >
      {false && (
        <div
          //hiding this for now incase I need to renable it or check something from it
          style={{
            height: 60,
            border: "1px solid black",
            display: "flex",
            alignItems: "center",
          }}
        >
          <div
            style={{
              fontSize: "1vw",
              marginLeft: "2%",
              borderRight: "1px solid black",
              paddingRight: "1.8%",
            }}
          >
            {selectedRoute.routeName}
          </div>
          <div
            onClick={() => handleAddCustomersClick()}
            style={{
              fontSize: "1vw",
              marginLeft: "2%",
              cursor: "pointer",
              borderBottom: !addCustomersDisplay ? "0px" : "1px solid black",
              fontWeight: !addCustomersDisplay ? "initial" : "500",
            }}
          >
            Service Locations
          </div>
          {!selectedRoute.active && (
            <div
              onClick={() => handleActivateRouteClick()}
              style={{
                fontSize: "1vw",
                marginLeft: "2%",
                cursor: "pointer",
                borderBottom: !activateRouteDisplay ? "0px" : "1px solid black",
                fontWeight: !activateRouteDisplay ? "initial" : "500",
              }}
            >
              Activate Route
            </div>
          )}
          <div
            onClick={() => handleEmployeesClick()}
            style={{
              fontSize: "1vw",
              marginLeft: "2%",
              cursor: "pointer",
              borderBottom: !employeesDisplay ? "0px" : "1px solid black",
              fontWeight: !employeesDisplay ? "initial" : "500",
            }}
          >
            Employees
          </div>
          <div
            style={{
              fontSize: "1vw",
              marginLeft: "2%",
              cursor: "pointer",
            }}
          >
            Schedule Conflicts {"(0)"}
          </div>
          <div id="editRouteBuilderSearchBar">
            <input
              id="adminInventorySubInventoryListSearchBarInput"
              value={props.searchTerm}
              onChange={(value) => props.setSearchTerm(value.target.value)}
            />
            <div id="adminInventorySubInventoryListSearchBarIcon">
              <BsSearch size={20} />
            </div>
          </div>
          {selectedRoute.active && (
            <div
              onClick={() => handleRouteSave()}
              style={{
                marginRight: "1%",
                backgroundColor: "#77D353",
                color: "white",
                height: 30,
                width: "8%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: 2,
                cursor: "pointer",
              }}
            >
              Save
            </div>
          )}
          <div
            onClick={() =>
              selectedRoute.active
                ? handleCloseActiveRouteClick()
                : handleCloseRouteClick()
            }
            style={{
              marginRight: "2%",
              backgroundColor: "red",
              color: "white",
              height: 30,
              width: "8%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: 2,
              cursor: "pointer",
            }}
          >
            Close
          </div>
        </div>
      )}
      <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
        <RouteBuilderEdit
          employeesDisplay={employeesDisplay}
          selectedRoute={selectedRoute}
          addCustomersDisplay={addCustomersDisplay}
          activateRouteDisplay={activateRouteDisplay}
          setActivateRouteDisplay={setActivateRouteDisplay}
          selectedBusiness={selectedBusiness}
          setEmployeesDisplay={setEmployeesDisplay}
          handleAddCustomersClick={handleAddCustomersClick}
          sideBarHidden={props.sideBarHidden}
          setAddCustomersDisplay={setAddCustomersDisplay}
          handleCloseRouteClick={handleCloseRouteClick}
          setServiceLocationsInRouteHome={setServiceLocationsInRouteHome}
          handleActivateRouteClick={handleActivateRouteClick}
          handleEmployeesClick={handleEmployeesClick}
          handleCloseActiveRouteClick={handleCloseActiveRouteClick}
          searchTerm={props.searchTerm}
          handleRouteSave={handleRouteSave}
          routeDisplay={routeDisplay}
          setEmployeesInRoute={setEmployeesInRoute}
          employeesInRoute={employeesInRoute}
          setHoverDisplay={setHoverDisplay}
          hoverDisplay={hoverDisplay}
          hoverDisplayData={hoverDisplayData}
          setHoverDisplayData={setHoverDisplayData}
          navigationChanged={props.navigationChanged}
        />
      </div>
    </div>
  );
}
