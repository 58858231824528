import React, { useEffect, useState } from "react";

const GmailConnect = ({ businessId }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [connected, setConnected] = useState(false);

  const handleConnectClick = () => {
    const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;
    const redirectUri = process.env.REACT_APP_GOOGLE_REDIRECT_URI; // Must match your Google Cloud Console setting
    const scope = encodeURIComponent(
      "https://www.googleapis.com/auth/gmail.send"
    );
    const responseType = "code";
    const accessType = "offline"; // Ensures you get a refresh token
    const includeGrantedScopes = "true";
    // Add the businessId as state
    const state = encodeURIComponent(businessId);

    const oauth2Url = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${clientId}&redirect_uri=${redirectUri}&response_type=${responseType}&scope=${scope}&access_type=${accessType}&include_granted_scopes=${includeGrantedScopes}&state=${state}`;

    // Redirect to Google OAuth2 consent screen
    window.location.href = oauth2Url;
  };

  useEffect(() => {
    // Check if the URL has a "code" parameter (after a successful Google OAuth2 redirect)
    const params = new URLSearchParams(window.location.search);
    const code = params.get("code");
    const returnedState = params.get("state"); // This will be your business id

    if (code && returnedState) {
      setLoading(true);
      // Send the authorization code and businessId (state) to your backend to exchange it for tokens
      fetch("/api/auth/google/callback", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ code, businessId: returnedState }),
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.json();
        })
        .then((data) => {
          // Process the response from your backend (store tokens, update user settings, etc.)
          setConnected(true);
          // Optionally clear the query parameters to avoid re-triggering this effect
          window.history.replaceState(
            {},
            document.title,
            window.location.pathname
          );
        })
        .catch((err) => {
          console.error(err);
          setError("Failed to connect your Gmail account.");
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, []);

  return (
    <div>
      {loading && <p>Connecting to Gmail...</p>}
      {error && <p style={{ color: "red" }}>{error}</p>}
      {connected ? (
        <p>Your Gmail account is connected!</p>
      ) : (
        <button onClick={handleConnectClick}>Connect Your Gmail</button>
      )}
    </div>
  );
};

export default GmailConnect;
