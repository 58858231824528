import React, { useState, useEffect, useRef } from "react";
import firebase from "firebase/compat/app";
import NewSubscriptionV2 from "./NewSubscriptionV2";
import EditSubscription from "./EditSubscription";
import SubscriptionDetails from "./SubscriptionDetails";
import EditSubscriptionV2 from "./EditSubscriptionV2";
import SubCard from "./SubCard";

export default function Subscriptions(props) {
  const selectedBusiness = props.businessId;
  const isMounted = useRef();
  const db = firebase.firestore();
  const [subscriptions, setSubscriptions] = useState([]);
  const [createSubscriptionDisplay, setCreateSubscriptionDisplay] =
    useState(false);
  const [selectedSubscription, setSelectedSubscription] = useState();
  const [editSubscriptionDisplay, setEditSubscriptionDisplay] = useState(false);
  const [detailsDisplay, setDetailsDisplay] = useState(false);

  useEffect(() => {
    if (props.reloadSubscriptions) {
      setDetailsDisplay(false);
      props.setReloadSubscriptions(false);
    }
  }, [props.reloadSubscriptions]);

  useEffect(() => {
    isMounted.current = true;
    const databaseSnapshot = db
      .collection("Businesses")
      .doc(selectedBusiness)
      .collection("Subscriptions")
      .onSnapshot((documentSnapshot) => {
        let subs = [];
        for (let index = 0; index < documentSnapshot.docs.length; index++) {
          const doc = documentSnapshot.docs[index].data();
          if (doc.pendingPauses) continue;
          subs.push(doc);
        }
        if (isMounted.current) {
          setSubscriptions(subs);
        }
      });

    return () => {
      isMounted.current = false;
      databaseSnapshot();
    };
  }, []);

  const handleViewSubscription = (value) => {
    setSelectedSubscription(value);
    setEditSubscriptionDisplay(true);
  };

  const handleViewSubscriptionDetails = (value) => {
    setSelectedSubscription(value);
    setDetailsDisplay(true);
    handleDetailsPageBreadcrumbs(value);
  };

  const handleDetailsPageBreadcrumbs = (value) => {
    props.handleAddToBreadcrumb({
      name: value.subscriptionTitle,
      type: "subscription",
      current: true,
      value: value,
    });
  };

  return detailsDisplay ? (
    <SubscriptionDetails
      selectedBusiness={selectedBusiness}
      subscription={selectedSubscription}
    />
  ) : (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-xl font-semibold text-gray-900">Subscriptions</h1>
        </div>
        <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
          <button
            onClick={() => setCreateSubscriptionDisplay(true)}
            type="button"
            className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
          >
            New Subscription
          </button>
        </div>
      </div>
      <div className="mt-8 flex flex-col">
        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 w-1/3"
                    >
                      Name
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Description
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Price
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Code
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Enrolled
                    </th>

                    <th
                      scope="col"
                      className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                    >
                      <span className="sr-only">Edit</span>
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {subscriptions
                    .filter((a) =>
                      a.subscriptionTitle
                        .toLowerCase()
                        .includes(props.searchTerm.toLowerCase())
                    )
                    .map((subscription) => (
                      <SubCard
                        key={subscription.subscriptionId}
                        handleViewSubscription={handleViewSubscription}
                        handleViewSubscriptionDetails={
                          handleViewSubscriptionDetails
                        }
                        subscription={subscription}
                        selectedBusiness={selectedBusiness}
                      />
                    ))}
                </tbody>
              </table>
              <NewSubscriptionV2
                selectedBusiness={selectedBusiness}
                setOpen={setCreateSubscriptionDisplay}
                open={createSubscriptionDisplay}
              />
              <EditSubscriptionV2
                selectedSubscription={selectedSubscription}
                selectedBusiness={selectedBusiness}
                setOpen={setEditSubscriptionDisplay}
                open={editSubscriptionDisplay}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
