import React, { useEffect, useState } from "react";
import CheckBox from "../../GlobalComponents/CheckBox";
import firebase from "firebase/compat/app";
import dayjs from "dayjs";
import { FiArrowRight } from "react-icons/fi";

const RescheduledStopCard = (props) => {
  const value = props.value;
  const db = firebase.firestore();
  const [rescheduledStopData, setRescheduledStopData] = useState(null);

  useEffect(() => {
    db.collection("Businesses")
      .doc(value.businessId)
      .collection("Workers")
      .doc(value.employeeUid)
      .collection("Route")
      .doc(dayjs(value.originalDate).format("dddd"))
      .collection("Stops")
      .doc(value.stopId)
      .get()
      .then((doc) => {
        console.log(doc.exists);
        if (doc.exists) {
          const stopData = doc.data();
          setRescheduledStopData(stopData);
        }
      });
  }, [value.stopId]);

  return !rescheduledStopData ? (
    <div></div>
  ) : (
    <div
      key={rescheduledStopData.serviceLocationId}
      style={{
        border: "1px solid #D9D9D9",
        height: 72,
        display: "flex",
        alignItems: "center",
        width: "88%",
        margin: "auto",
        marginTop: 12,
        borderRadius: 5,
      }}
    >
      <div style={{ marginLeft: "4%" }}>
        <img
          className="inline-block h-10 w-10 rounded-full"
          src={
            "https://firebasestorage.googleapis.com/v0/b/symbri.appspot.com/o/placeholder.jpeg?alt=media&token=0c577d79-ec85-40f7-a2a2-7962611740ef"
          }
        />
      </div>
      <div
        style={{ display: "flex", flexDirection: "column", marginLeft: "4%" }}
      >
        <div style={{ fontSize: "0.8rem", fontWeight: "600" }}>
          {(rescheduledStopData.customer
            ? rescheduledStopData.customer.customerName
              ? rescheduledStopData.customer.customerName
              : rescheduledStopData.customer.name
            : rescheduledStopData.customerData?.name
          )?.replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase())}
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            fontSize: "0.74rem",
          }}
        >
          {rescheduledStopData.stopTitle
            ? rescheduledStopData.stopTitle.substring(0, 28)
            : rescheduledStopData.stopNickname
            ? rescheduledStopData.stopNickname.substring(0, 28)
            : "No Title"}
          ...
        </div>
        <div className="flex">
          <div
            style={{
              fontSize: "0.74rem",
              marginRight: 5,
            }}
          >
            {value.originalDate}
          </div>
          <FiArrowRight />
          <div
            style={{
              fontSize: "0.74rem",
              marginLeft: 5,
            }}
          >
            {value.date}
          </div>
        </div>
      </div>
    </div>
  );
};

export default RescheduledStopCard;
