import { useState } from "react";
import EditJob from "../Jobs/ViewJob";
import ViewJobReport from "../Jobs/ViewJobReport";

export default function Jobs(props) {
  const [selectedJob, setSelectedJob] = useState();
  const [editJobDisplay, setEditJobDisplay] = useState(false);
  const jobs = props.jobs;
  const [viewReportOpen, setViewReportOpen] = useState(false);
  const [selectedReport, setSelectedReport] = useState();

  const handleJobClick = (value) => {
    setSelectedJob(value);
    setEditJobDisplay(true);
  };

  const handleViewReport = (report) => {
    console.log(report);
    setSelectedReport(report);
    setViewReportOpen(true);
  };

  return (
    <div className="px-4 sm:px-6 lg:px-0">
      <div className="mt-8 flex flex-col">
        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                    >
                      Jobs
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white customer-profile-tbody">
                  {jobs.map((job) => (
                    <tr key={job.jobId}>
                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6">
                        <div className="flex items-center">
                          <div className="h-10 w-10 flex-shrink-0">
                            <img
                              className="h-10 w-10 rounded-full"
                              src={
                                "https://firebasestorage.googleapis.com/v0/b/symbri-production.appspot.com/o/poolDefault.png?alt=media&token=c009a4d6-e644-4890-9dce-7e61c85c40c7"
                              }
                              alt=""
                            />
                          </div>
                          <div
                            className="ml-4"
                            style={{ maxWidth: 120, minWidth: 120 }}
                          >
                            <div className="font-medium text-gray-900">
                              {job.jobTitle.replace(
                                /(^\w{1})|(\s+\w{1})/g,
                                (letter) => letter.toUpperCase()
                              )}
                            </div>
                          </div>
                        </div>
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        <div className="text-gray-900 text-left mr-4">
                          {job.serviceLocation
                            ? job.serviceLocation.address
                                .replace(", USA", "")
                                .replace(/(^\w{1})|(\s+\w{1})/g, (letter) =>
                                  letter.toUpperCase()
                                )
                            : ""}
                        </div>
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        <div className="text-gray-900 text-left mr-4 ml-20">
                          {job.status.replace(
                            /(^\w{1})|(\s+\w{1})/g,
                            (letter) => letter.toUpperCase()
                          )}
                        </div>
                      </td>
                      <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                        <div
                          onClick={() => handleJobClick(job)}
                          className="text-indigo-600 hover:text-indigo-900 cursor-pointer"
                        >
                          View
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <EditJob
        selectedJob={selectedJob}
        selectedBusiness={props.businessId}
        setOpen={setEditJobDisplay}
        open={editJobDisplay}
        handleViewReport={handleViewReport}
      />
      <ViewJobReport
        open={viewReportOpen}
        setOpen={setViewReportOpen}
        selectedJob={selectedJob}
        selectedReport={selectedReport}
      />
    </div>
  );
}
