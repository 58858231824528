import { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import ViewImage from "../GlobalComponents/ViewImage";
import firebase from "firebase/compat/app";
import SelectCategory from "../Jobs/SelectCategory";
import SelectAssigned from "./SelectAssigned";
import { toast } from "react-toastify";
import SelectQuickCreate from "../Jobs/SelectQuickCreate";
import SelectEmployeeSearch from "../Jobs/SelectEmployeeSearch";
import CheckBox from "../GlobalComponents/CheckBox";

export default function TaskProfile(props) {
  const db = firebase.firestore();
  const [open, setOpen] = useState(false);
  const [viewImage, setViewImage] = useState(false);
  const [media, setMedia] = useState();
  const userPermissions = props.userPermissions;
  const [allCategories, setAllCategories] = useState([]);
  const [allFolders, setAllFolders] = useState([]);
  const [selectedFolder, setSelectedFolder] = useState("all");
  const [selectedCategory, setSelectedCategory] = useState({
    title: "none",
    color: "none",
  });
  const serviceRequestData = props.serviceRequestData;
  const [serviceLocationAddress, setServiceLocationAddress] = useState("");
  const [showJobConvert, setShowJobConvert] = useState(false);
  const [quickCreate, setQuickCreate] = useState({ name: "None" });
  const [stopName, setStopName] = useState("");
  const [stopDescription, setStopDescription] = useState("");
  const [stopDate, setStopDate] = useState("");
  const [selectedEmployee, setSelectedEmployee] = useState("");
  const [employees, setEmployees] = useState([]);
  const [showDescriptionInput, setShowDescriptionInput] = useState(false);

  useEffect(() => {
    setOpen(props.open);
    if (props.open && serviceRequestData) {
      setSelectedFolder(
        serviceRequestData.folder ? serviceRequestData.folder : "all"
      );

      setSelectedCategory(serviceRequestData.category);
      db.collection("Businesses")
        .doc(serviceRequestData.businessId)
        .collection("TaskCategories")
        .doc("Categories")
        .get()
        .then((snapshot) => {
          if (snapshot.exists) {
            const data = snapshot.data();
            setAllCategories(data.categories);
          }
        });

      db.collection("Businesses")
        .doc(serviceRequestData.businessId)
        .collection("TaskCategories")
        .doc("Folders")
        .get()
        .then((snapshot) => {
          const data = snapshot.data();
          setAllFolders(data.folders);
        });

      db.collection("Businesses")
        .doc(serviceRequestData.businessId)
        .collection("Workers")
        .where("businessId", "==", serviceRequestData.businessId)
        .get()
        .then((snapshot) => {
          let employeesArray = [];
          for (let index = 0; index < snapshot.docs.length; index++) {
            const employeeDoc = snapshot.docs[index].data();
            employeesArray.push(employeeDoc);
          }
          setEmployees(employeesArray);
        });

      if (serviceRequestData.customer !== "none") {
        getServiceLocationAddress(
          serviceRequestData.customer.customerId,
          serviceRequestData.customer.serviceLocationId
        );
      }
    }
  }, [props.open]);

  const handleClose = () => {
    setOpen(false);
    props.setOpen(false);
  };

  const getServiceLocationAddress = async (customerId, serviceLocationId) => {
    const serviceLocationDoc = await db
      .collection("Customers")
      .doc(customerId)
      .collection("ServiceLocations")
      .doc(serviceLocationId)
      .get();

    setServiceLocationAddress(serviceLocationDoc.data().address);
  };

  useEffect(() => {
    if (serviceRequestData) {
      try {
        if (!userPermissions.includes("Edit Issues")) {
          toast.warn("You do not have permission to complete this action.");
          return;
        }
        db.collection("Businesses")
          .doc(serviceRequestData.businessId)
          .collection("Tasks")
          .doc(serviceRequestData.taskId)
          .update({ category: selectedCategory });
      } catch (error) {
        //console.log(error);
      }
    }
  }, [selectedCategory]);

  useEffect(() => {
    if (serviceRequestData) {
      try {
        if (!userPermissions.includes("Edit Issues")) {
          toast.warn("You do not have permission to complete this action.");
          return;
        }
        db.collection("Businesses")
          .doc(serviceRequestData.businessId)
          .collection("Tasks")
          .doc(serviceRequestData.taskId)
          .update({ folder: selectedFolder.toLowerCase() });
      } catch (error) {
        //console.log(error);
      }
    }
  }, [selectedFolder]);

  const finalConvertCheck = () => {
    if (quickCreate.name === "None") {
      props.handleConvertTaskToJob(
        serviceRequestData,
        selectedCategory,
        quickCreate,
        stopName,
        stopDescription,
        stopDate,
        selectedEmployee
      );
    } else if (quickCreate.name === "Scheduled Stop") {
      // check to make sure fields are set
      if (stopName === "") {
        toast.warn("Please enter a stop name.");
        return;
      }
      if (showDescriptionInput && stopDescription === "") {
        toast.warn("Please enter a stop description.");
        return;
      }
      if (stopDate === "") {
        toast.warn("Please enter a stop date.");
        return;
      }
      if (selectedEmployee === "") {
        toast.warn("Please select an employee.");
        return;
      }
      props.handleConvertTaskToJob(
        serviceRequestData,
        selectedCategory,
        quickCreate,
        stopName,
        stopDescription,
        stopDate,
        selectedEmployee
      );
    } else if (quickCreate.name === "Unscheduled Stop") {
      // check to make sure fields are set
      if (stopName === "") {
        toast.warn("Please enter a stop name.");
        return;
      }
      if (showDescriptionInput && stopDescription === "") {
        toast.warn("Please enter a stop description.");
        return;
      }
      props.handleConvertTaskToJob(
        serviceRequestData,
        selectedCategory,
        quickCreate,
        stopName,
        stopDescription,
        stopDate,
        selectedEmployee
      );
    }
  };

  const handleArchive = async (status) => {
    await db
      .collection("Businesses")
      .doc(serviceRequestData.businessId)
      .collection("Tasks")
      .doc(serviceRequestData.taskId)
      .update({
        taskStatus: status ? "incomplete" : "archived",
      });
    handleClose();
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={handleClose}>
        <div className="fixed inset-0" />

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-300 sm:duration-500"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-300 sm:duration-500"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                  {!showJobConvert && serviceRequestData && (
                    <div className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
                      <div className="h-0 flex-1 overflow-y-auto">
                        <div className="bg-indigo-700 py-6 px-4 sm:px-6">
                          <div className="flex items-center justify-between">
                            <Dialog.Title className="text-lg font-medium text-white">
                              {" "}
                              Issue Tracker{" "}
                            </Dialog.Title>
                            <div className="ml-3 flex h-7 items-center">
                              <button
                                type="button"
                                className="rounded-md bg-indigo-700 text-indigo-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                                onClick={handleClose}
                              >
                                <span className="sr-only">Close panel</span>
                                <XIcon className="h-6 w-6" aria-hidden="true" />
                              </button>
                            </div>
                          </div>
                          <div className="mt-1">
                            <p className="text-sm text-indigo-300">
                              View the details of a issue here.
                            </p>
                          </div>
                        </div>
                        <div className="flex flex-1 flex-col justify-between">
                          <div className="divide-y divide-gray-200 px-4 sm:px-6">
                            <div className="space-y-6 pt-6 pb-5">
                              <div>
                                <label
                                  htmlFor="project-name"
                                  className="block text-sm font-medium text-gray-900"
                                >
                                  {" "}
                                  Issue Request Name{" "}
                                </label>
                                <div className="mt-1">
                                  <input
                                    value={serviceRequestData.taskName}
                                    disabled
                                    type="text"
                                    className="block w-full rounded-md border-gray-300 border-2 h-10 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                  />
                                </div>
                              </div>
                              <div>
                                <label
                                  htmlFor="project-name"
                                  className="block text-sm font-medium text-gray-900"
                                >
                                  {" "}
                                  Issue Description{" "}
                                </label>
                                <div className="mt-1">
                                  <textarea
                                    value={serviceRequestData.taskDescription}
                                    disabled
                                    rows="1"
                                    className="block w-full rounded-md border-gray-300 border-2 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm resize-none"
                                    style={{
                                      height: "80px",
                                      overflowY: "auto",
                                    }}
                                  ></textarea>
                                </div>
                              </div>
                              {serviceRequestData.customer !== "none" && (
                                <div>
                                  <label
                                    htmlFor="project-name"
                                    className="block text-sm font-medium text-gray-900"
                                  >
                                    {" "}
                                    Service Location{" "}
                                  </label>
                                  <div className="mt-1">
                                    <input
                                      value={serviceLocationAddress}
                                      disabled
                                      type="text"
                                      className="block w-full rounded-md border-gray-300 border-2 h-10 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                    />
                                  </div>
                                </div>
                              )}

                              <div>
                                <label
                                  htmlFor="description"
                                  className="block text-sm font-medium text-gray-900"
                                >
                                  {" "}
                                  Category{" "}
                                </label>
                                <div className="mt-1">
                                  <SelectCategory
                                    allCategories={allCategories}
                                    setSelectedCategory={setSelectedCategory}
                                    selectedCategory={selectedCategory}
                                  />
                                </div>
                              </div>

                              <div>
                                <label
                                  htmlFor="description"
                                  className="block text-sm font-medium text-gray-900"
                                >
                                  {" "}
                                  Assign To{" "}
                                </label>
                                <div className="mt-1">
                                  <SelectAssigned
                                    setSelectedFolder={setSelectedFolder}
                                    allFolders={allFolders}
                                    selectedFolder={selectedFolder}
                                  />
                                </div>
                              </div>
                              <div>
                                <label
                                  htmlFor="project-name"
                                  className="block text-sm font-medium text-gray-900"
                                >
                                  {" "}
                                  Media{" "}
                                </label>
                                <div className="mt-1">
                                  <div className="block w-full rounded-md border-gray-300 border-2 h-80 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm flex flex-wrap justify-center">
                                    {serviceRequestData.media.map((media) => (
                                      <img
                                        onClick={() => {
                                          setMedia(media);
                                          setViewImage(true);
                                        }}
                                        src={media}
                                        style={{
                                          height: 120,
                                          width: 120,
                                          margin: 4,
                                          cursor: "pointer",
                                        }}
                                      />
                                    ))}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-shrink-0 justify-end px-4 py-4">
                        <button
                          type="button"
                          className="rounded-md border border-gray-300 bg-black py-2 px-4 text-sm font-medium text-white shadow-sm mr-2"
                          onClick={() =>
                            serviceRequestData.taskStatus === "archived"
                              ? handleArchive(true)
                              : handleArchive(false)
                          }
                        >
                          {serviceRequestData.taskStatus === "archived"
                            ? "Unarchive"
                            : "Archive"}
                        </button>
                        <button
                          type="button"
                          className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                          onClick={() => handleClose(false)}
                        >
                          Cancel
                        </button>
                        {serviceRequestData.customer !== "none" && (
                          <button
                            onClick={() => setShowJobConvert(true)}
                            type="button"
                            className="ml-4 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                          >
                            Convert To Job
                          </button>
                        )}
                      </div>
                    </div>
                  )}

                  {showJobConvert && serviceRequestData && (
                    <div className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
                      <div className="h-0 flex-1 overflow-y-auto">
                        <div className="bg-indigo-700 py-6 px-4 sm:px-6">
                          <div className="flex items-center justify-between">
                            <Dialog.Title className="text-lg font-medium text-white">
                              {" "}
                              Convert Issue To Job{" "}
                            </Dialog.Title>
                            <div className="ml-3 flex h-7 items-center">
                              <button
                                type="button"
                                className="rounded-md bg-indigo-700 text-indigo-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                                onClick={handleClose}
                              >
                                <span className="sr-only">Close panel</span>
                                <XIcon className="h-6 w-6" aria-hidden="true" />
                              </button>
                            </div>
                          </div>
                          <div className="mt-1">
                            <p className="text-sm text-indigo-300">
                              Complete the form below to convert issue to job.
                            </p>
                          </div>
                        </div>
                        <div className="flex flex-1 flex-col justify-between">
                          <div className="divide-y divide-gray-200 px-4 sm:px-6">
                            <div className="space-y-6 pt-6 pb-5">
                              <div>
                                <label
                                  htmlFor="project-name"
                                  className="block text-sm font-medium text-gray-900"
                                >
                                  Quick Create Stop
                                </label>
                                <div className="mt-1">
                                  <SelectQuickCreate
                                    setQuickCreate={setQuickCreate}
                                    businessId={serviceRequestData.businessId}
                                  />
                                </div>
                              </div>
                              {quickCreate.name !== "None" && (
                                <div>
                                  <label
                                    htmlFor="description"
                                    className="block text-sm font-medium text-gray-900"
                                  >
                                    {" "}
                                    Stop Name{" "}
                                  </label>
                                  <div className="mt-1">
                                    <input
                                      onChange={(change) =>
                                        setStopName(change.target.value)
                                      }
                                      type="text"
                                      className="block w-full rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm border-gray-300 border-2 h-10"
                                    />
                                  </div>
                                </div>
                              )}
                              {quickCreate.name !== "None" && (
                                <div>
                                  <div className="flex items-center">
                                    <label
                                      htmlFor="description"
                                      className="block text-sm font-medium text-gray-900"
                                    >
                                      {" "}
                                      Stop Description{" "}
                                    </label>

                                    <div className="ml-auto">
                                      <CheckBox
                                        onChange={() =>
                                          setShowDescriptionInput(
                                            !showDescriptionInput
                                          )
                                        }
                                        checked={showDescriptionInput}
                                      />
                                    </div>
                                  </div>
                                  {showDescriptionInput && (
                                    <div className="mt-1">
                                      <input
                                        onChange={(change) =>
                                          setStopDescription(
                                            change.target.value
                                          )
                                        }
                                        type="text"
                                        className="block w-full rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm border-gray-300 border-2 h-10"
                                      />
                                    </div>
                                  )}
                                </div>
                              )}
                              {quickCreate.name === "Scheduled Stop" && (
                                <div>
                                  <label
                                    htmlFor="description"
                                    className="block text-sm font-medium text-gray-900"
                                  >
                                    {" "}
                                    Stop Date{" "}
                                  </label>
                                  <div className="mt-1">
                                    <input
                                      onChange={(change) =>
                                        setStopDate(change.target.value)
                                      }
                                      type="date"
                                      className="block w-full rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm border-gray-300 border-2 h-10"
                                    />
                                  </div>
                                </div>
                              )}
                              {quickCreate.name === "Scheduled Stop" && (
                                <div>
                                  <label
                                    htmlFor="description"
                                    className="block text-sm font-medium text-gray-900"
                                  >
                                    {" "}
                                    Employee{" "}
                                  </label>
                                  <div className="mt-1">
                                    <SelectEmployeeSearch
                                      businessId={serviceRequestData.businessId}
                                      setSelectedEmployee={setSelectedEmployee}
                                      employees={employees}
                                    />
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-shrink-0 justify-end px-4 py-4">
                        <button
                          type="button"
                          className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                          onClick={() =>
                            showJobConvert
                              ? setShowJobConvert(false)
                              : handleClose(false)
                          }
                        >
                          Cancel
                        </button>
                        {serviceRequestData.customer !== "none" && (
                          <button
                            onClick={() =>
                              !showJobConvert
                                ? setShowJobConvert(true)
                                : finalConvertCheck()
                            }
                            type="button"
                            className="ml-4 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                          >
                            Convert To Job
                          </button>
                        )}
                      </div>
                    </div>
                  )}
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
          <ViewImage media={media} open={viewImage} setOpen={setViewImage} />
        </div>
      </Dialog>
    </Transition.Root>
  );
}
