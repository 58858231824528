import { useEffect, useState } from "react";
import firebase from "firebase/compat/app";
import ServiceLocations from "./ServiceLocations";
import CustomerAddButton from "./CustomerAddButton";
import Jobs from "./Jobs";
import CustomerAddJob from "./CustomerAddJob";
import ServiceLocationProfile from "./ServiceLocationProfile";
import { BiUser } from "react-icons/bi";
import CustomerAddServiceLocation from "./CustomerAddServiceLocation";
import { toast } from "react-toastify";
import dayjs from "dayjs";
import { GoNote } from "react-icons/go";
import { generateBusinessId } from "../Tools/generateBusinessId";
import AutosizeInput from "react-input-autosize";

export default function CustomerProfile(props) {
  const selectedCustomerData = props.selectedCustomerData;
  const [serviceLocations, setServiceLocations] = useState([]);
  const [selectedServiceLocation, setSelectedServiceLocation] = useState();
  const [email, setEmail] = useState(selectedCustomerData.customerEmailAddress);
  const [jobs, setJobs] = useState([]);
  const [notes, setNotes] = useState(selectedCustomerData.notes || []);
  const [newNoteText, setNewNoteText] = useState("");
  const [addServiceLocationDisplay, setAddServiceLocationDisplay] =
    useState(false);
  const [serviceLocationProfileDisplay, setServiceLocationProfileDisplay] =
    useState(false);
  const [addJobDisplay, setAddJobDisplay] = useState(false);
  const db = firebase.firestore();
  const [firstName, setFirstName] = useState(
    selectedCustomerData.customerFirstName
  );

  const [lastName, setLastName] = useState(
    selectedCustomerData.customerLastName
  );

  const [customerNotificationSettings, setCustomerNotificationSettings] =
    useState(selectedCustomerData.notificationSettings || []);

  const [customerPhoneNumber, setCustomerPhoneNumber] = useState(
    selectedCustomerData.customerPhoneNumber
  );

  const [customerSecondaryPhoneNumber, setCustomerSecondaryPhoneNumber] =
    useState(selectedCustomerData.customerSecondaryPhoneNumber || "");

  const handleAddNote = () => {
    if (newNoteText === "" || newNoteText.trim().length === 0) {
      toast.warn("Note field cannot be blank!");
      return;
    }
    db.collection("Users")
      .doc(firebase.auth().currentUser.uid)
      .get()
      .then((documentSnapshot) => {
        const data = documentSnapshot.data();
        db.collection("Customers")
          .doc(selectedCustomerData.customerId)
          .update({
            notes: firebase.firestore.FieldValue.arrayUnion({
              noteBody: newNoteText,
              noteFrom: data.name,
              noteId: generateBusinessId(),
              noteWhen: new Date(),
            }),
          })
          .then(() => {
            let currentNotes = notes;
            currentNotes.push({
              noteBody: newNoteText,
              noteFrom: data.name,
              noteId: generateBusinessId(),
              noteWhen: new Date(),
            });
            setNotes(currentNotes);
            console.log(newNoteText);
            setNewNoteText("");
          });
      });
  };

  const allNotifications = [
    { id: 1, name: "Send a text message for all completed subscription stops" },
    { id: 2, name: "Send a text message for all completed job stops" },
    {
      id: 3,
      name: "Send a text message for all rescheduled subscription stops",
    },
    { id: 4, name: "Send a text message for all rescheduled job stops" },
    {
      id: 5,
      name: "Send a text message for all unscheduled subscription stops",
    },
    { id: 6, name: "Send a text message for all unscheduled job stops" },
  ];

  useEffect(() => {
    db.collection("Customers")
      .doc(selectedCustomerData.customerId)
      .collection("ServiceLocations")
      .where("customerId", "==", selectedCustomerData.customerId)
      .onSnapshot((snapshot) => {
        console.log(selectedCustomerData.customerId);
        let locationsArray = [];
        for (let index = 0; index < snapshot.docs.length; index++) {
          const docData = snapshot.docs[index].data();
          locationsArray.push(docData);
        }
        setServiceLocations(locationsArray);
        console.log(locationsArray);
      });
  }, []);

  useEffect(() => {
    const databaseSnapshot = db
      .collection("Businesses")
      .doc(props.businessId)
      .collection("Jobs")
      .where("customerId", "==", selectedCustomerData.customerId)
      .onSnapshot((snapshot) => {
        let jobsArray = [];
        for (let index = 0; index < snapshot.docs.length; index++) {
          const jobDoc = snapshot.docs[index].data();
          jobsArray.push(jobDoc);
        }
        setJobs(jobsArray);
      });
    return () => databaseSnapshot();
  }, []);

  const handleAddNewToCustomer = (type) => {
    console.log(type);
    if (type === "Service Location") {
      setAddServiceLocationDisplay(true);
    } else {
      setAddJobDisplay(true);
    }
  };

  const handleViewServiceLocationClick = (value) => {
    setSelectedServiceLocation(value);
    setServiceLocationProfileDisplay(true);
    props.handleAddToBreadcrumb({
      name: value.address.split(",")[0],
      type: "serviceLocation",
      current: true,
    });
  };

  const handleDeleteServiceLocation = async (serviceLocation) => {
    console.log(serviceLocation);
    const token = await firebase.auth().currentUser.getIdToken();
    const res = await fetch(
      "https://us-central1-symbri-production.cloudfunctions.net/handleDeleteServiceLocation",
      {
        method: "POST",
        body: JSON.stringify({
          serviceLocationId: serviceLocation.serviceLocationId,
          customerId: serviceLocation.customerId,
          token: token,
        }),
      }
    );

    const json = await res.json();
    if (!json.success) {
      toast.warn(json.message);
    } else {
      toast.success("Service location has been deleted!");
    }
  };

  const handleEditEmail = (value) => {
    setEmail(value);
    db.collection("Customers").doc(selectedCustomerData.customerId).update({
      customerEmailAddress: value,
    });
  };

  const handleEditName = (value, type) => {
    if (type === "first") {
      setFirstName(value);
      db.collection("Customers").doc(selectedCustomerData.customerId).update({
        customerFirstName: value,
      });
    } else {
      setLastName(value);
      db.collection("Customers").doc(selectedCustomerData.customerId).update({
        customerLastName: value,
      });
    }
  };

  function numericStringMask(str, mask) {
    if (!mask) return str;

    const numeric = str.replaceAll(/[^\d]/g, "");

    let idx = 0;

    const formated = mask.split("").map((el) => {
      if (el === "#") {
        el = numeric[idx];
        idx++;
      }
      return el;
    });

    return formated.join("");
  }

  const handleToggleNotification = async (notificationId) => {
    // need to add or remove notification from businessNotificationSettings in firebase

    if (customerNotificationSettings.includes(notificationId)) {
      const newSettings = customerNotificationSettings.filter(
        (setting) => setting !== notificationId
      );
      setCustomerNotificationSettings(newSettings);
      // update firebase
      await db
        .collection("Customers")
        .doc(selectedCustomerData.customerId)
        .update({
          notificationSettings: newSettings,
        });
    } else {
      const newSettings = [...customerNotificationSettings, notificationId];
      await db
        .collection("Customers")
        .doc(selectedCustomerData.customerId)
        .update({
          notificationSettings: newSettings,
        });
      setCustomerNotificationSettings(newSettings);
    }
  };

  return !serviceLocationProfileDisplay ? (
    <>
      <div className="min-h-full">
        <main className="py-10 p-44">
          {/* Page header */}
          <div className="max-w-3xl mx-auto px-4 sm:px-6 md:flex md:items-center md:justify-between md:space-x-5 lg:max-w-full lg:px-8">
            <div className="flex items-center space-x-5">
              <div className="flex-shrink-0">
                <div className="relative">
                  <BiUser className="h-10 w-10 rounded-full" />
                  <span
                    className="absolute inset-0 shadow-inner rounded-full"
                    aria-hidden="true"
                  />
                </div>
              </div>
              <div>
                <div className="text-2xl font-bold text-gray-900 flex">
                  <AutosizeInput
                    placeholder="First Name"
                    inputStyle={{
                      color: "black",
                      borderWidth: 2,
                      borderColor: "#D1D5DB",
                      borderRadius: 5,
                      paddingLeft: 4,
                      paddingRight: 4,
                    }}
                    value={firstName.replace(/(^\w{1})|(\s+\w{1})/g, (letter) =>
                      letter.toUpperCase()
                    )}
                    onChange={(change) =>
                      handleEditName(change.target.value, "first")
                    }
                  />
                  <AutosizeInput
                    placeholder="Last Name"
                    inputStyle={{
                      color: "black",
                      borderWidth: 2,
                      borderColor: "#D1D5DB",
                      borderRadius: 5,
                      paddingLeft: 4,
                      paddingRight: 4,
                      marginLeft: 5,
                    }}
                    value={lastName.replace(/(^\w{1})|(\s+\w{1})/g, (letter) =>
                      letter.toUpperCase()
                    )}
                    onChange={(change) =>
                      handleEditName(change.target.value, "last")
                    }
                  />
                </div>
                {selectedCustomerData.isBusinessCustomer && <div>Business</div>}
                <p className="text-sm font-medium text-gray-500">
                  Has been a customer since{" "}
                  {dayjs(
                    firebase.auth().currentUser.metadata.creationTime
                  ).format("MM/DD/YYYY")}
                  .
                </p>
              </div>
            </div>
            <div className="mt-6 flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-reverse sm:space-y-0 sm:space-x-3 md:mt-0 md:flex-row md:space-x-3">
              <CustomerAddButton
                businessId={selectedCustomerData.businessIds[0]}
                handleAddNewToCustomer={handleAddNewToCustomer}
              />
            </div>
          </div>

          <div className="mt-8 max-w-3xl mx-auto gap-6 sm:px-6 lg:max-w-full lg:grid-flow-col-dense lg:grid-cols-3">
            <div className="space-y-6 lg:col-start-1 lg:col-span-2">
              {/* Description list*/}
              <section aria-labelledby="applicant-information-title">
                <div className="bg-white shadow sm:rounded-lg">
                  <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                    <dl className="flex">
                      <div className="w-1/3">
                        <dt className="text-sm font-medium text-gray-500 flex">
                          Phone Number{" "}
                          {selectedCustomerData.customerLandline && (
                            <div className="ml-1 text-red-500">
                              (Unable to text this number)
                            </div>
                          )}
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900">
                          {selectedCustomerData.pending ? (
                            <input
                              className="block w-2/3 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm border-gray-300 border-2 h-10 pl-2"
                              value={numericStringMask(
                                customerPhoneNumber,
                                "(###) ###-####"
                              )}
                              onChange={(e) => {
                                const updatedPhoneNumber = e.target.value;

                                const unuformatedPhoneNumber =
                                  updatedPhoneNumber.replace(/[\D]/g, "");

                                setCustomerPhoneNumber(updatedPhoneNumber);

                                db.collection("Customers")
                                  .doc(selectedCustomerData.customerId)
                                  .update({
                                    customerPhoneNumber: unuformatedPhoneNumber,
                                    customerLandline: false,
                                  });
                              }}
                            />
                          ) : (
                            <div className="block w-2/3 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm border-gray-300 border-2 h-10 pl-2 flex items-center">
                              {numericStringMask(
                                selectedCustomerData.customerPhoneNumber,
                                "(###) ###-####"
                              )}
                            </div>
                          )}
                        </dd>
                      </div>
                      {selectedCustomerData?.isBusinessCustomer && (
                        <div className="w-1/3">
                          <dt className="text-sm font-medium text-gray-500 flex">
                            Secondary Phone Number{" "}
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900">
                            <input
                              className="block w-2/3 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm border-gray-300 border-2 h-10 pl-2"
                              value={numericStringMask(
                                customerSecondaryPhoneNumber,
                                "(###) ###-####"
                              )}
                              onChange={(e) => {
                                const updatedPhoneNumber = e.target.value;

                                const unuformatedPhoneNumber =
                                  updatedPhoneNumber.replace(/[\D]/g, "");

                                setCustomerSecondaryPhoneNumber(
                                  updatedPhoneNumber
                                );

                                db.collection("Customers")
                                  .doc(selectedCustomerData.customerId)
                                  .update({
                                    customerSecondaryPhoneNumber:
                                      unuformatedPhoneNumber,
                                  });
                              }}
                            />
                          </dd>
                        </div>
                      )}
                      <div className="w-1/3">
                        <dt className="text-sm font-medium text-gray-500">
                          Email Address
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900">
                          <input
                            className="block w-2/3 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm border-gray-300 border-2 h-10 pl-2"
                            onChange={(change) =>
                              handleEditEmail(change.target.value)
                            }
                            value={email}
                          />
                        </dd>
                      </div>
                    </dl>
                  </div>
                </div>
              </section>

              <div className="divide-y divide-gray-200 mt-10">
                <section aria-labelledby="notes-title">
                  <div className="bg-white shadow sm:rounded-lg sm:overflow-hidden border-gray-200 border-2">
                    <div className="divide-y divide-gray-200">
                      <div className="px-4 py-5 sm:px-6 bg-gray-50">
                        <h2
                          id="notes-title"
                          className="text-lg font-medium text-gray-900"
                        >
                          Customer Notes
                        </h2>
                      </div>
                      <div className="px-4 py-6 sm:px-6 max-h-80 overflow-y-scroll">
                        <ul role="list" className="space-y-8">
                          {notes.map((note) => (
                            <li key={note.id}>
                              <div className="flex space-x-3">
                                <div className="flex-shrink-0">
                                  <GoNote
                                    color="4F46E5"
                                    className="h-10 w-10"
                                  />
                                </div>
                                <div>
                                  <div className="text-sm">
                                    <div className="font-medium text-gray-900">
                                      {note.noteFrom === ""
                                        ? "Employee"
                                        : note.noteFrom}
                                    </div>
                                  </div>
                                  <div className="mt-1 text-sm text-gray-700">
                                    <p>{note.noteBody}</p>
                                  </div>
                                  <div className="mt-2 text-sm space-x-2">
                                    <span className="text-gray-500 font-medium">
                                      {new Date(
                                        note.noteWhen.seconds
                                          ? note.noteWhen.seconds * 1000
                                          : note.noteWhen
                                      ).toDateString()}
                                    </span>{" "}
                                  </div>
                                </div>
                              </div>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                    <div className="bg-gray-50 px-4 py-6 sm:px-6">
                      <div className="flex space-x-3">
                        <div className="min-w-0 flex-1">
                          <div>
                            <textarea
                              id="comment"
                              name="comment"
                              rows={3}
                              className="shadow-sm block w-full focus:ring-blue-500 focus:border-blue-500 sm:text-sm border border-gray-300 rounded-md"
                              placeholder="Add a note"
                              defaultValue={""}
                              value={newNoteText}
                              onChange={(change) =>
                                setNewNoteText(change.target.value)
                              }
                            />
                          </div>
                          <div className="mt-3 flex items-center justify-end">
                            <button
                              onClick={() => handleAddNote()}
                              type="submit"
                              className="inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                            >
                              Add Note
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>

              <section aria-labelledby="notes-title">
                <div className="divide-y divide-gray-200">
                  <ServiceLocations
                    handleViewServiceLocationClick={
                      handleViewServiceLocationClick
                    }
                    serviceLocations={serviceLocations}
                    handleDeleteServiceLocation={handleDeleteServiceLocation}
                  />
                </div>
                <div className="divide-y divide-gray-200 mt-10">
                  <Jobs businessId={props.businessId} jobs={jobs} />
                </div>

                <div className="divide-y divide-gray-200 mt-10">
                  <div className="px-4 sm:px-6 lg:px-0">
                    <div className="mt-8 flex flex-col">
                      <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                          <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                            <table className="min-w-full divide-y divide-gray-300">
                              <thead className="bg-gray-50">
                                <tr>
                                  <th
                                    scope="col"
                                    className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                                  >
                                    Notification Settings
                                  </th>
                                </tr>
                              </thead>
                              <tbody className="divide-y divide-gray-200 bg-white customer-profile-tbody">
                                <div className="grid grid-cols-4 gap-4 mt-4 p-6">
                                  {allNotifications.map((notification) => (
                                    <div key={notification.id}>
                                      <label className="inline-flex items-center">
                                        <input
                                          type="checkbox"
                                          className="form-checkbox"
                                          checked={customerNotificationSettings.includes(
                                            notification.id
                                          )}
                                          onChange={() =>
                                            handleToggleNotification(
                                              notification.id
                                            )
                                          }
                                        />
                                        <span className="ml-2 text-sm text-gray-700">
                                          {notification.name}
                                        </span>
                                      </label>
                                    </div>
                                  ))}
                                </div>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </main>
        <CustomerAddJob
          open={addJobDisplay}
          setOpen={setAddJobDisplay}
          businessId={props.businessId}
          serviceLocations={serviceLocations}
          selectedCustomer={selectedCustomerData}
        />
        <CustomerAddServiceLocation
          open={addServiceLocationDisplay}
          setOpen={setAddServiceLocationDisplay}
          businessId={props.businessId}
          serviceLocations={serviceLocations}
          selectedCustomer={selectedCustomerData}
        />
      </div>
    </>
  ) : (
    <ServiceLocationProfile
      jobs={jobs}
      businessId={props.businessId}
      selectedServiceLocation={selectedServiceLocation}
      selectedCustomer={selectedCustomerData}
      handleAddToBreadcrumb={props.handleAddToBreadcrumb}
    />
  );
}
