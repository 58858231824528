import { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import dayjs from "dayjs";
import { toast } from "react-toastify";
import Geocoder from "react-native-geocoding";
import { Rings } from "react-loader-spinner";

import { generateBusinessId } from "../../Tools/generateBusinessId";
import SelectCustomerSearch from "../../Jobs/SelectCustomerSearch";
import SelectServiceLocation from "../../Jobs/SelectServiceLocation";
import SelectJobSearch from "./SelectJobSearch";
import CheckBox from "../../GlobalComponents/CheckBox";

export default function AddStop(props) {
  const [open, setOpen] = useState(false);
  const createMode = props.mode;
  // Mode to determine if user is assigning to existing job or creating a new one
  const [mode, setMode] = useState("existingJob");

  // Stop fields
  const [stopTitle, setStopTitle] = useState("");
  const [stopDescription, setStopDescription] = useState("");
  const [showStopDescriptionInput, setShowStopDescriptionInput] =
    useState(false);

  // Existing job selection
  const [jobs, setJobs] = useState([]);
  const [selectedJob, setSelectedJob] = useState(null);

  // New job fields
  const [jobTitle, setJobTitle] = useState("");
  const [jobDescription, setJobDescription] = useState("");
  const [showJobDescriptionInput, setShowJobDescriptionInput] = useState(false);

  // Customer & service location for new job
  const [customers, setCustomers] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [serviceLocations, setServiceLocations] = useState([]);
  const [selectedServiceLocation, setSelectedServiceLocation] = useState(null);

  const [loading, setLoading] = useState(false);
  const db = firebase.firestore();

  const [stopDate, setStopDate] = useState();

  const selectedBusiness = props.selectedBusiness; // from parent

  // Load component
  useEffect(() => {
    setOpen(props.open);

    if (props.open && selectedBusiness) {
      // Fetch all jobs (for existing job assignment)
      db.collection("Businesses")
        .doc(selectedBusiness)
        .collection("Jobs")
        .get()
        .then((snapshot) => {
          const jobList = snapshot.docs.map((doc) => doc.data());
          setJobs(jobList);
        });

      // Fetch customers (for new job creation)
      db.collection("Customers")
        .where("businessIds", "array-contains", selectedBusiness)
        .get()
        .then((snapshot) => {
          const customerList = snapshot.docs.map((doc) => doc.data());
          setCustomers(customerList);
        });
    }
  }, [props.open, selectedBusiness, db]);

  // When a customer is selected for new job, fetch their service locations
  useEffect(() => {
    if (selectedCustomer && selectedBusiness) {
      db.collection("Customers")
        .doc(selectedCustomer.customerId)
        .collection("ServiceLocations")
        .where("businessId", "==", selectedBusiness)
        .get()
        .then((snapshot) => {
          const locations = snapshot.docs.map((doc) => doc.data());
          setServiceLocations(locations);
        });
    } else {
      setServiceLocations([]);
      setSelectedServiceLocation(null);
    }
  }, [selectedCustomer, selectedBusiness, db]);

  // Reset all fields & close panel
  const handleClose = () => {
    setOpen(false);
    props.setOpen(false);

    // Reset states
    setMode("existingJob");
    setStopTitle("");
    setStopDescription("");
    setShowStopDescriptionInput(false);

    setSelectedJob(null);
    setJobs([]);

    setJobTitle("");
    setJobDescription("");
    setShowJobDescriptionInput(false);

    setCustomers([]);
    setSelectedCustomer(null);
    setServiceLocations([]);
    setSelectedServiceLocation(null);

    setLoading(false);
  };

  const handleCreateScheduledStop = async (selectedJob) => {
    Geocoder.from(selectedJob.serviceLocation.address)
      .then(async (json) => {
        console.log(json.results[0].geometry.location);

        const selectedEmployee = props.selectedDayViewEmployee;

        console.log(selectedEmployee);

        const newStopId = generateBusinessId();
        const routeQuery = await db
          .collection("Businesses")
          .doc(selectedBusiness)
          .collection("Routes")
          .where("active", "==", true)
          .get();

        const routeId = routeQuery.docs[0].id;
        const stopData = {
          dayOfTheWeek: dayjs(stopDate).format("dddd"),
          frequency: "once",
          startOn: dayjs(stopDate).format("MM/DD/YYYY"),
          stopAfter: "no end",
          name: stopTitle,
          address: selectedJob.serviceLocation.address,
          stopId: newStopId,
          routeId: routeId,
          serviceLocationId: selectedJob.serviceLocation.serviceLocationId,
          completedDates: [],
          contractor: {
            id: selectedEmployee.uid,
            name: selectedEmployee.name,
            profilePhoto: selectedEmployee.photo,
          },
          customerData: {
            name:
              selectedJob.customerData.customerFirstName +
              " " +
              selectedJob.customerData.customerLastName,
            customerId: selectedJob.customerData.customerId,
            businessId: props.selectedBusiness,
          },
          businessId: props.selectedBusiness,
          stopNickname: stopTitle,
          location: json.results[0].geometry.location,
          stopTemplateId: "none",
          stopCustomerDescription: stopTitle,
          stopWorkerDescription: stopDescription,
          stopDate: dayjs(stopDate).format("MM/DD/YYYY"),
          type: "Job Stop",
          messageId: "none",
          jobId: selectedJob.jobId,
        };

        console.log(stopData);

        const routeData = {
          frequency: "once",
          name: stopTitle,
          address: selectedJob.serviceLocation.address,
          stopObject: stopData,
          routeId: stopData.routeId,
          jobId: selectedJob.jobId,
          serviceLocationId: selectedJob.serviceLocation.serviceLocationId,
          type: "stop",
          completed: false,
          stopId: stopData.stopId,
          contractor: {
            id: selectedEmployee.uid,
            name: selectedEmployee.name,
            profilePhoto: selectedEmployee.photo,
          },
          location: json.results[0].geometry.location,
          customerData: {
            name:
              selectedJob.customerData.customerFirstName +
              " " +
              selectedJob.customerData.customerLastName,
            customerId: selectedJob.customerData.customerId,
            businessId: props.selectedBusiness,
          },
        };

        console.log(routeData);

        db.collection("Businesses")
          .doc(selectedBusiness)
          .collection("Workers")
          .doc(selectedEmployee.uid)
          .collection("Route")
          .doc("JobStops")
          .set(
            {
              route: firebase.firestore.FieldValue.arrayUnion(routeData),
              checkDistanceTime: true,
            },
            { merge: true }
          );

        await db
          .collection("Businesses")
          .doc(selectedBusiness)
          .collection("Workers")
          .doc(selectedEmployee.uid)
          .update({ lastUpdated: dayjs().unix() });

        db.collection("Businesses")
          .doc(selectedBusiness)
          .collection("Jobs")
          .doc(selectedJob.jobId)
          .update({
            stops: firebase.firestore.FieldValue.arrayUnion(stopData),
            status: "scheduled",
          })
          .then(async () => {
            // add to customer service location

            await db
              .collection("Customers")
              .doc(selectedJob.customerData.customerId)
              .collection("ServiceLocations")
              .doc(selectedJob.serviceLocation.serviceLocationId)
              .update({
                routes: firebase.firestore.FieldValue.arrayUnion(stopData),
              });

            toast.success("Stop created!");
            handleClose();
          });
      })
      .catch((e) => console.log(e));
  };

  // Validate input and add stop
  const handleAddStop = async () => {
    // Validate stop fields
    if (!stopTitle.trim()) {
      toast.warn("Stop title is required!");
      return;
    }
    if (showStopDescriptionInput && !stopDescription.trim()) {
      toast.warn("Stop description cannot be empty if enabled!");
      return;
    }

    // If we are assigning to existing job
    if (mode === "existingJob") {
      if (!selectedJob) {
        toast.warn(
          "You must select an existing job or switch to create a new one!"
        );
        return;
      }

      if (createMode === "unscheduled") {
        // Create the stop object
        const newStopId = generateBusinessId();
        const stopObject = {
          stopDate: "none",
          stopTitle: stopTitle, // assuming stopTitle holds the stop name
          stopDescription:
            stopDescription.trim().length > 0 ? stopDescription : stopTitle,
          stopTech: "none",
          scheduledJob: false,
          jobId: selectedJob.jobId,
          stopId: newStopId,
          customer: {
            customerName:
              selectedJob.customerData.customerFirstName +
              " " +
              selectedJob.customerData.customerLastName,
            customerId: selectedJob.customerData.customerId,
          },
          stopServiceLocation: selectedJob.serviceLocation,
          stopServiceLocationCoordinates: await Geocoder.from(
            selectedJob.serviceLocation.address
          ).then((json) => json.results[0].geometry.location),
        };

        console.log(stopObject);

        try {
          setLoading(true);

          // Add to job's unscheduled stops
          await db
            .collection("Businesses")
            .doc(selectedBusiness)
            .collection("Jobs")
            .doc(selectedJob.jobId)
            .update({
              unscheduledStops:
                firebase.firestore.FieldValue.arrayUnion(stopObject),
            });

          // Optional: Send notification or do additional logic
          const notificationId = generateBusinessId();
          await db
            .collection("Businesses")
            .doc(selectedBusiness)
            .collection("Notifications")
            .doc(notificationId)
            .set({
              id: notificationId,
              body: `A new unscheduled stop (“${stopTitle}”) was added to job “${selectedJob.jobTitle}”.`,
              date: dayjs().format("MM/DD/YYYY"),
              time: dayjs().format("hh:mm A"),
            });

          toast.success("Unscheduled stop added to existing job!");
          handleClose();
        } catch (err) {
          console.error(err);
          toast.error("Error adding stop to existing job.");
        } finally {
          setLoading(false);
        }
      } else {
        // Create the scheduled stop object
        handleCreateScheduledStop(selectedJob);
      }
    }

    // If we are creating a new job on the fly
    if (mode === "newJob") {
      // Validate new job fields
      if (!jobTitle.trim()) {
        toast.warn("New job title is required!");
        return;
      }
      if (showJobDescriptionInput && !jobDescription.trim()) {
        toast.warn("New job description cannot be empty if enabled!");
        return;
      }
      if (!selectedCustomer) {
        toast.warn("You must select a customer for the new job!");
        return;
      }
      if (!selectedServiceLocation) {
        toast.warn("You must select a service location for the new job!");
        return;
      }

      try {
        setLoading(true);

        // Create new job in Firestore
        const newJobId = generateBusinessId();
        const businessDoc = await db
          .collection("Businesses")
          .doc(selectedBusiness)
          .get();
        const businessData = businessDoc.data();

        const newJobData = {
          jobId: newJobId,
          jobTitle: jobTitle,
          stopDescription:
            jobDescription.trim().length > 0 ? stopDescription : jobTitle,
          status: "new",
          customerId: selectedCustomer.customerId,
          customerData: selectedCustomer,
          serviceLocation: selectedServiceLocation,
          createdDate: dayjs().format("MM/DD/YYYY"),
          stops: [],
          unscheduledStops: [],
          notes: [],
          estimates: [],
          invoices: [],
          pics: [],
          messages: [],
          businessId: selectedBusiness,
          businessName: businessData?.name || "",
        };

        await db
          .collection("Businesses")
          .doc(selectedBusiness)
          .collection("Jobs")
          .doc(newJobId)
          .set(newJobData);

        console.log(`newJobId: ${newJobId}`);

        // Now create the stop object

        if (createMode === "unscheduled") {
          const newStopId = generateBusinessId();
          const stopObject = {
            stopDate: "none",
            stopTitle: stopTitle,
            stopDescription:
              stopDescription.trim().length > 0 ? stopDescription : stopTitle,
            stopTech: "none",
            scheduledJob: false,
            jobId: newJobId,
            stopId: newStopId,
            customer: {
              customerName:
                selectedCustomer.customerFirstName +
                " " +
                selectedCustomer.customerLastName,
              customerId: selectedCustomer.customerId,
            },
            stopServiceLocation: selectedServiceLocation,
            stopServiceLocationCoordinates: await Geocoder.from(
              selectedServiceLocation.address
            ).then((json) => {
              return json.results[0].geometry.location;
            }),
          };
          // Add this unscheduled stop to the newly created job
          await db
            .collection("Businesses")
            .doc(selectedBusiness)
            .collection("Jobs")
            .doc(newJobId)
            .update({
              unscheduledStops:
                firebase.firestore.FieldValue.arrayUnion(stopObject),
            });

          const notificationId = generateBusinessId();
          await db
            .collection("Businesses")
            .doc(selectedBusiness)
            .collection("Notifications")
            .doc(notificationId)
            .set({
              id: notificationId,
              body: `A new job (“${jobTitle}”) was created with an unscheduled stop (“${stopTitle}”).`,
              date: dayjs().format("MM/DD/YYYY"),
              time: dayjs().format("hh:mm A"),
            });

          toast.success("New job created and unscheduled stop added!");
          handleClose();
        } else {
          // Create the scheduled stop object
          handleCreateScheduledStop(newJobData);
        }
      } catch (err) {
        console.error(err);
        toast.error("Error creating new job or adding stop.");
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={() => null}>
        {/* Overlay */}
        <div className="fixed inset-0" />

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            {/* Slide-in panel */}
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-300 sm:duration-500"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-300 sm:duration-500"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                  <div className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
                    {/* Header */}
                    <div className="h-0 flex-1 overflow-y-auto">
                      <div className="bg-indigo-700 py-6 px-4 sm:px-6">
                        <div className="flex items-center justify-between">
                          <Dialog.Title className="text-lg font-medium text-white">
                            {createMode === "unscheduled"
                              ? "Add Unscheduled Stop"
                              : "Add Scheduled Stop"}
                          </Dialog.Title>
                          <div className="ml-3 flex h-7 items-center">
                            <button
                              type="button"
                              className="rounded-md bg-indigo-700 text-indigo-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                              onClick={handleClose}
                            >
                              <span className="sr-only">Close panel</span>
                              <XIcon className="h-6 w-6" aria-hidden="true" />
                            </button>
                          </div>
                        </div>
                        <div className="mt-1">
                          <p className="text-sm text-indigo-300">
                            Create an{" "}
                            {createMode === "unscheduled"
                              ? "unscheduled"
                              : "scheduled"}{" "}
                            stop by assigning it to an existing job or by
                            creating a new job.
                          </p>
                        </div>
                      </div>

                      {/* Body */}
                      <div className="flex flex-1 flex-col justify-between">
                        <div className="divide-y divide-gray-200 px-4 sm:px-6">
                          <div className="space-y-6 pt-6 pb-5">
                            {/* Mode Selection */}
                            <div>
                              <label className="block text-sm font-medium text-gray-900">
                                Assign to Existing Job or Create New Job?
                              </label>
                              <div className="mt-2 flex gap-4">
                                <div className="flex items-center">
                                  <input
                                    id="existingJob"
                                    name="jobMode"
                                    type="radio"
                                    checked={mode === "existingJob"}
                                    onChange={() => setMode("existingJob")}
                                    className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                  />
                                  <label
                                    htmlFor="existingJob"
                                    className="ml-2 block text-sm text-gray-700"
                                  >
                                    Existing
                                  </label>
                                </div>
                                <div className="flex items-center">
                                  <input
                                    id="newJob"
                                    name="jobMode"
                                    type="radio"
                                    checked={mode === "newJob"}
                                    onChange={() => setMode("newJob")}
                                    className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                  />
                                  <label
                                    htmlFor="newJob"
                                    className="ml-2 block text-sm text-gray-700"
                                  >
                                    New
                                  </label>
                                </div>
                              </div>
                            </div>

                            {/* Existing Job Selection */}
                            {mode === "existingJob" && (
                              <div>
                                <label className="block text-sm font-medium text-gray-900">
                                  Select Existing Job
                                </label>
                                <div className="mt-1">
                                  {/* Replace SelectJobSearch with your own job selector if needed */}
                                  <SelectJobSearch
                                    jobs={jobs}
                                    setSelectedJob={setSelectedJob}
                                  />
                                </div>
                              </div>
                            )}

                            {/* New Job Creation Fields */}
                            {mode === "newJob" && (
                              <>
                                <div>
                                  <label className="block text-sm font-medium text-gray-900">
                                    New Job Title
                                  </label>
                                  <div className="mt-1">
                                    <input
                                      onChange={(e) =>
                                        setJobTitle(e.target.value)
                                      }
                                      type="text"
                                      className="block w-full rounded-md border-2 border-gray-300 h-10 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                      value={jobTitle}
                                    />
                                  </div>
                                </div>

                                <div>
                                  <div className="flex items-center">
                                    <label className="block text-sm font-medium text-gray-900">
                                      New Job Description
                                    </label>
                                    <div className="ml-auto">
                                      <CheckBox
                                        onChange={() =>
                                          setShowJobDescriptionInput(
                                            !showJobDescriptionInput
                                          )
                                        }
                                        checked={showJobDescriptionInput}
                                      />
                                    </div>
                                  </div>
                                  {showJobDescriptionInput && (
                                    <div className="mt-1">
                                      <input
                                        onChange={(e) =>
                                          setJobDescription(e.target.value)
                                        }
                                        type="text"
                                        className="block w-full rounded-md border-2 border-gray-300 h-10 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                        value={jobDescription}
                                      />
                                    </div>
                                  )}
                                </div>

                                <div>
                                  <label className="block text-sm font-medium text-gray-900">
                                    Customer
                                  </label>
                                  <div className="mt-1">
                                    <SelectCustomerSearch
                                      customers={customers}
                                      setSelectedCustomer={setSelectedCustomer}
                                    />
                                  </div>
                                </div>

                                <div>
                                  <label className="block text-sm font-medium text-gray-900">
                                    Service Location
                                  </label>
                                  <div className="mt-1">
                                    <SelectServiceLocation
                                      serviceLocations={serviceLocations}
                                      setSelectedServiceLocation={
                                        setSelectedServiceLocation
                                      }
                                    />
                                  </div>
                                </div>
                              </>
                            )}

                            {/* Stop Fields */}
                            <div>
                              <label className="block text-sm font-medium text-gray-900">
                                Stop Title
                              </label>
                              <div className="mt-1">
                                <input
                                  onChange={(e) => setStopTitle(e.target.value)}
                                  type="text"
                                  className="block w-full rounded-md border-2 border-gray-300 h-10 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                  value={stopTitle}
                                />
                              </div>
                            </div>

                            <div>
                              <div className="flex items-center">
                                <label className="block text-sm font-medium text-gray-900">
                                  Stop Description
                                </label>
                                <div className="ml-auto">
                                  <CheckBox
                                    onChange={() =>
                                      setShowStopDescriptionInput(
                                        !showStopDescriptionInput
                                      )
                                    }
                                    checked={showStopDescriptionInput}
                                  />
                                </div>
                              </div>
                              {showStopDescriptionInput && (
                                <div className="mt-1">
                                  <input
                                    onChange={(e) =>
                                      setStopDescription(e.target.value)
                                    }
                                    type="text"
                                    className="block w-full rounded-md border-2 border-gray-300 h-10 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                    value={stopDescription}
                                  />
                                </div>
                              )}
                            </div>
                            {createMode !== "unscheduled" && (
                              <div>
                                <label
                                  htmlFor="description"
                                  className="block text-sm font-medium text-gray-900"
                                >
                                  {" "}
                                  Stop Date{" "}
                                </label>
                                <div className="mt-1">
                                  <input
                                    onChange={(change) =>
                                      setStopDate(change.target.value)
                                    }
                                    type="date"
                                    className="block w-full rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm border-gray-300 border-2 h-10"
                                  />
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* Footer Buttons */}
                    <div className="flex flex-shrink-0 justify-end px-4 py-4">
                      <button
                        type="button"
                        className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                        onClick={handleClose}
                      >
                        Cancel
                      </button>
                      <button
                        onClick={handleAddStop}
                        className="ml-4 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      >
                        {loading ? (
                          <div className="flex items-center">
                            <span className="mr-2">Loading</span>
                            <Rings
                              height={22}
                              width={22}
                              color="white"
                              ariaLabel="loading"
                            />
                          </div>
                        ) : (
                          "Add Stop"
                        )}
                      </button>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
