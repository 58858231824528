import { Fragment, useEffect, useState } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { SelectorIcon } from "@heroicons/react/solid";
import firebase from "firebase/compat/app";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function SelectAssignedEmployees(props) {
  const db = firebase.firestore();
  const [selected, setSelected] = useState(props.selectedEmployee || []);

  useEffect(() => {
    if (props.selectedEmployee) {
      setSelected(props.selectedEmployee);
    }
  }, [props.selectedEmployee]);

  const handleToggle = (option) => {
    const exists = selected.find((emp) => emp.name === option.name);
    let newSelected;
    if (exists) {
      newSelected = selected.filter((emp) => emp.name !== option.name);
      if (props.jobData) {
        db.collection("Businesses")
          .doc(props.jobData.businessId)
          .collection("Jobs")
          .doc(props.jobData.jobId)
          .update({
            assignedEmployees: newSelected,
          });
      }
    } else {
      newSelected = [...selected, option];
      if (props.jobData) {
        db.collection("Businesses")
          .doc(props.jobData.businessId)
          .collection("Jobs")
          .doc(props.jobData.jobId)
          .update({
            assignedEmployees: newSelected,
          });
      }
    }
    setSelected(newSelected);
    props.setSelectedEmployee(newSelected);
  };

  const displayText =
    selected.length === 0
      ? "None"
      : selected.map((emp) => emp.name).join(". ") + ".";

  const options = props.allEmployees;

  return (
    <Listbox value={selected} onChange={() => {}}>
      {({ open }) => (
        <>
          <div className="mt-1 relative">
            <Listbox.Button className="block w-full rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm border-gray-300 border-2 h-10 text-left pl-2">
              <span className="block truncate">{displayText}</span>
              <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                <SelectorIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                {options.map((option) => {
                  const isSelected = selected.find(
                    (emp) => emp.name === option.name
                  );
                  return (
                    <Listbox.Option
                      key={option.name}
                      onClick={() => handleToggle(option)}
                      className={({ active }) =>
                        classNames(
                          active ? "text-white bg-indigo-600" : "text-gray-900",
                          "cursor-pointer select-none relative py-2 pl-3 pr-9"
                        )
                      }
                      value={option}
                    >
                      {({ active }) => (
                        <div className="flex items-center">
                          <input
                            type="checkbox"
                            checked={!!isSelected}
                            readOnly
                            className="h-4 w-4 text-indigo-600 border-gray-300 rounded"
                          />
                          <span
                            className={classNames(
                              isSelected ? "font-semibold" : "font-normal",
                              "ml-2 block truncate"
                            )}
                          >
                            {option.name}
                          </span>
                        </div>
                      )}
                    </Listbox.Option>
                  );
                })}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
}
