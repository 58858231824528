import React, { useState, useEffect } from "react";
import firebase from "firebase/compat/app";
import dayjs from "dayjs";

const ServiceLocationSubscriptionCard = (props) => {
  const [stopTemplate, setStopTemplate] = useState();
  const [routeData, setRouteData] = useState();
  const [scheduledSubscriptions, setScheduledSubscriptions] = useState();
  const [subscriptionPrice, setSubscriptionPrice] = useState(
    props.subscriptionDetails.subscriptionPrice
  );

  const db = firebase.firestore();

  const handleGetData = async () => {
    console.log("RUNNING");
    console.log(props.subscriptionDetails);
    const recStopTemplateDoc = await db
      .collection("Businesses")
      .doc(props.businessId)
      .collection("RecurringStopTemplates")
      .doc(props.subscriptionDetails.connectedStopTemplateId)
      .get();

    const recStopTemplateData = recStopTemplateDoc.data();
    setStopTemplate(recStopTemplateData);

    const routeDocQuery = await db
      .collection("Businesses")
      .doc(props.businessId)
      .collection("Routes")
      .where("active", "==", true)
      .get();

    const routeDocId = routeDocQuery.docs[0].id;

    const routeServiceLocationDoc = await db
      .collection("Businesses")
      .doc(props.businessId)
      .collection("Routes")
      .doc(routeDocId)
      .collection("ServiceLocations")
      .doc(
        props.serviceLocation.serviceLocationId +
          props.subscriptionDetails.subscriptionStopId
      )
      .get();

    if (routeServiceLocationDoc.exists) {
      const serviceLocationRoutes = routeServiceLocationDoc.data().routes;

      const stopInfo = serviceLocationRoutes.find((a) =>
        a.recurringStopType
          ? a.subscriptionStopId ===
            props.subscriptionDetails.subscriptionStopId
          : false
      );

      if (stopInfo) {
        setRouteData(stopInfo);
      } else {
        setRouteData(null);
      }
    }

    const customerServiceLocationDoc = await db
      .collection("Customers")
      .doc(props.serviceLocation.customerId)
      .collection("ServiceLocations")
      .doc(props.serviceLocation.serviceLocationId)
      .get();

    const scheduledSubscription =
      customerServiceLocationDoc.data().scheduledSubscription;

    if (scheduledSubscription) {
      setScheduledSubscriptions(scheduledSubscription);
    }
  };

  useEffect(() => {
    // need to get stop template info, as well as route info.
    handleGetData();
  }, [props.reloadCards]);

  const pause = () => {
    props.handlePauseSubscription(props.subscriptionDetails);
  };

  const handleDelete = () => {
    props.handleDeleteSubscription(
      props.subscriptionDetails.subscriptionId,
      props.subscriptionDetails.subscriptionStopId
    );
  };

  const resume = () => {
    props.handleResumeSubscription(
      props.subscriptionDetails.subscriptionId,
      props.subscriptionDetails.subscriptionStopId
    );
  };

  const handleView = () => {
    props.handleViewSubscription(props.subscriptionDetails);
  };

  const handlePriceChange = async (e) => {
    setSubscriptionPrice(e.target.value);
    const doc = await db
      .collection("Customers")
      .doc(props.serviceLocation.customerId)
      .collection("ServiceLocations")
      .doc(props.serviceLocation.serviceLocationId)
      .get();

    const data = doc.data();

    let subscriptions = data.subscriptions;

    let subBeingViewed = subscriptions.findIndex(
      (a) => a.subscriptionId === props.subscriptionDetails.subscriptionId
    );

    if (subBeingViewed) {
      subscriptions[subBeingViewed].subscriptionPrice = e.target.value;

      await db
        .collection("Customers")
        .doc(props.serviceLocation.customerId)
        .collection("ServiceLocations")
        .doc(props.serviceLocation.serviceLocationId)
        .update({
          subscriptions: subscriptions,
        });
    }
  };

  return (
    <tr key={props.subscriptionDetails.subscriptionId}>
      {props.subscriptionDetails.subscriptionTitle && (
        <div style={{ display: "flex", alignItems: "center" }}>
          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6 pr-20">
            <div className="flex items-center">
              <div className="ml-1" style={{ maxWidth: 120, minWidth: 120 }}>
                <div className="font-medium text-gray-900">
                  {props.subscriptionDetails.subscriptionTitle.replace(
                    /(^\w{1})|(\s+\w{1})/g,
                    (letter) => letter.toUpperCase()
                  )}
                </div>
                <div className="font-medium text-gray-500">Reocurring Stop</div>
              </div>
            </div>
          </td>
          <td
            style={{ maxWidth: 80, minWidth: 80 }}
            className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 ml-[6%]"
          >
            {routeData && (
              <div className="text-gray-900 text-left">
                {routeData.employeeName.replace(
                  /(^\w{1})|(\s+\w{1})/g,
                  (letter) => letter.toUpperCase()
                )}
              </div>
            )}
            {!routeData && (
              <div className="text-gray-900 text-left">--------------</div>
            )}
          </td>
          <td
            style={{ maxWidth: 120, minWidth: 120 }}
            className="relative whitespace-nowrap py-4 pl-3 pr-4 text-sm font-medium sm:pr-6 ml-[3%]"
          >
            {routeData && (
              <div className="text-gray-900 text-left">
                {routeData.day.replace(/(^\w{1})|(\s+\w{1})/g, (letter) =>
                  letter.toUpperCase()
                )}
              </div>
            )}
            {!routeData && (
              <div className="text-gray-900 text-left">--------------</div>
            )}
          </td>
          <td
            style={{ maxWidth: 40, minWidth: 40 }}
            className="relative whitespace-nowrap py-4 pl-3 pr-4 text-sm font-medium sm:pr-6 ml-[1%]"
          >
            {stopTemplate && (
              <div className="text-gray-900 text-left">
                {stopTemplate.stopServiceInterval}
              </div>
            )}
          </td>
          <td
            style={{ maxWidth: 40, minWidth: 40 }}
            className="relative whitespace-nowrap py-4 pl-3 pr-4 text-sm font-medium sm:pr-6 ml-[6%]"
          >
            $
            <input
              type="text"
              value={subscriptionPrice}
              onChange={handlePriceChange}
              style={{ width: "50px" }}
              className="text-gray-900 text-left"
            />
          </td>
          <td
            style={{
              maxWidth:
                scheduledSubscriptions &&
                scheduledSubscriptions.sub.subscriptionId ===
                  props.subscriptionDetails.subscriptionId
                  ? 140
                  : 60,
              minWidth:
                scheduledSubscriptions &&
                scheduledSubscriptions.sub.subscriptionId ===
                  props.subscriptionDetails.subscriptionId
                  ? 140
                  : 60,
            }}
            className="relative whitespace-nowrap py-4 pl-3 pr-4 text-sm font-medium sm:pr-6 ml-[4%]"
          >
            {props.paused &&
              scheduledSubscriptions &&
              scheduledSubscriptions.sub.subscriptionId !==
                props.subscriptionDetails.subscriptionId && (
                <div className="text-[red] text-left">Paused</div>
              )}

            {props.paused && !scheduledSubscriptions && (
              <div className="text-[red] text-left">Paused</div>
            )}

            {!props.paused && (
              <div className="text-[green] text-left">Active</div>
            )}

            {scheduledSubscriptions &&
              scheduledSubscriptions.sub.subscriptionId ===
                props.subscriptionDetails.subscriptionId && (
                <div className="text-[green] text-left">
                  Scheduled{" - "}
                  {dayjs(scheduledSubscriptions.startDate).format("MM/DD/YYYY")}
                </div>
              )}
          </td>
          <td
            style={{ maxWidth: 40, minWidth: 40 }}
            className="relative whitespace-nowrap py-4 pl-3 pr-4 text-sm font-medium sm:pr-6 ml-[2%]"
          >
            {scheduledSubscriptions &&
              scheduledSubscriptions.sub.subscriptionId ===
                props.subscriptionDetails.subscriptionId && (
                <div
                  onClick={() =>
                    props.cancelScheduled(
                      props.subscriptionDetails.subscriptionId
                    )
                  }
                  className="text-[red] text-left cursor-pointer"
                >
                  Cancel
                </div>
              )}

            {!props.paused && (
              <div
                onClick={pause}
                className="text-[blue] text-left cursor-pointer"
              >
                Pause
              </div>
            )}

            {props.paused &&
              scheduledSubscriptions &&
              scheduledSubscriptions.sub.subscriptionId !==
                props.subscriptionDetails.subscriptionId && (
                <div
                  onClick={resume}
                  className="text-[blue] text-left cursor-pointer"
                >
                  Resume
                </div>
              )}

            {props.paused && !scheduledSubscriptions && (
              <div
                onClick={resume}
                className="text-[blue] text-left cursor-pointer"
              >
                Resume
              </div>
            )}
          </td>
          {props.paused &&
            (!scheduledSubscriptions ||
              (scheduledSubscriptions &&
                scheduledSubscriptions.sub.subscriptionId !==
                  props.subscriptionDetails.subscriptionId)) && (
              <td
                className="relative whitespace-nowrap py-4 pl-6 pr-4 text-sm font-medium sm:pr-6 ml-12"
                style={{ minWidth: "100px" }}
              >
                <div className="flex items-center justify-start space-x-2">
                  <div
                    onClick={handleDelete}
                    className="text-[blue] cursor-pointer"
                  >
                    Delete
                  </div>
                </div>
              </td>
            )}
          <td
            className="relative whitespace-nowrap py-4 pr-4 text-sm font-medium sm:pr-6"
            style={{
              minWidth: "100px",
              marginLeft:
                props.paused &&
                (!scheduledSubscriptions ||
                  (scheduledSubscriptions &&
                    scheduledSubscriptions.sub.subscriptionId !==
                      props.subscriptionDetails.subscriptionId))
                  ? 14
                  : 60,
            }}
          >
            {!props.paused && (
              <div className="flex items-center justify-start">
                <div
                  onClick={handleView}
                  className="text-[blue] cursor-pointer"
                >
                  View
                </div>
              </div>
            )}
          </td>
        </div>
      )}
    </tr>
  );
};

export default ServiceLocationSubscriptionCard;
