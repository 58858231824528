import React, { useEffect, useState } from "react";
import firebase from "firebase/compat/app";
import { toast } from "react-toastify";
import dayjs from "dayjs";

export default function EmployeeProfile(props) {
  const selectedEmployeeData = props.selectedEmployeeData;
  const [title, setTitle] = useState(selectedEmployeeData.title);
  const [payRate, setPayRate] = useState(selectedEmployeeData.payRate);
  const [phoneNumber, setPhoneNumber] = useState(
    numericStringMask(selectedEmployeeData.phone, "(###) ### - ####")
  );
  const [payType, setPayType] = useState(selectedEmployeeData.payMethod);
  const [permissions, setPermissions] = useState([]);
  const [employeeStartDate, setEmployeeStartDate] = useState();
  const db = firebase.firestore();
  const [employeePhoneUnformatted, setEmployeePhoneUnformatted] = useState("");
  const [prevValue, setPrevValue] = useState("");

  // NEW: State for the Messages Number field
  const [messageAreaCode, setMessageAreaCode] = useState("");
  const [availableNumbers, setAvailableNumbers] = useState([]);

  const handleSendPasswordResetEmail = () => {
    firebase
      .auth()
      .sendPasswordResetEmail(props.selectedEmployeeData.email)
      .then(() => {
        toast.success("Password reset email sent!");
      })
      .catch((e) => toast.warn(JSON.stringify(e)));
  };

  const handleTitleChange = (title) => {
    setTitle(title);
    db.collection("Businesses")
      .doc(props.selectedEmployeeData.businessId)
      .collection("Workers")
      .doc(props.selectedEmployeeData.uid)
      .update({
        title: title,
      })
      .catch((e) => toast.warn(JSON.stringify(e)));
  };

  const handlePayRateChange = (newPayRate) => {
    setPayRate(newPayRate);
    db.collection("Businesses")
      .doc(props.selectedEmployeeData.businessId)
      .collection("Workers")
      .doc(props.selectedEmployeeData.uid)
      .update({
        payRate: newPayRate,
      })
      .catch((e) => toast.warn(JSON.stringify(e)));
  };

  const handlePhoneNumberChange = (newPhoneNumber) => {
    const unformmatedNumber = newPhoneNumber.replaceAll(/[^\d]/g, "");
    setEmployeePhoneUnformatted(unformmatedNumber);

    // Check if the user is deleting characters
    if (newPhoneNumber.length < prevValue.length) {
      setPhoneNumber(newPhoneNumber);
      db.collection("Businesses")
        .doc(props.selectedEmployeeData.businessId)
        .collection("Workers")
        .doc(props.selectedEmployeeData.uid)
        .update({
          phone: unformmatedNumber,
        })
        .catch((e) => toast.warn(JSON.stringify(e)));
    } else {
      let formattedNumber = numericStringMask(
        newPhoneNumber,
        "(###) ### - ####"
      );
      console.log(formattedNumber);
      setPhoneNumber(formattedNumber);
      db.collection("Businesses")
        .doc(props.selectedEmployeeData.businessId)
        .collection("Workers")
        .doc(props.selectedEmployeeData.uid)
        .update({
          phone: unformmatedNumber,
        })
        .catch((e) => toast.warn(JSON.stringify(e)));
    }

    // Update the previous value state
    setPrevValue(newPhoneNumber);
  };

  const handlePayTypeChange = (newPayType) => {
    setPayType(newPayType);
    db.collection("Businesses")
      .doc(props.selectedEmployeeData.businessId)
      .collection("Workers")
      .doc(props.selectedEmployeeData.uid)
      .update({
        payMethod: newPayType,
      })
      .catch((e) => toast.warn(JSON.stringify(e)));
  };

  const handlePermissionChange = async (permission) => {
    setPermissions((prevPermissions) =>
      prevPermissions.includes(permission)
        ? prevPermissions.filter((perm) => perm !== permission)
        : [...prevPermissions, permission]
    );

    // add the updated array to Workers database in the permissions field
    await db
      .collection("Businesses")
      .doc(props.selectedEmployeeData.businessId)
      .collection("Workers")
      .doc(props.selectedEmployeeData.uid)
      .update({
        permissions: permissions,
      })
      .catch((e) => {
        toast.warn(JSON.stringify(e));
      });
  };

  // NEW: Function to search available phone numbers by area code
  const handleSearchNumbers = async () => {
    if (!messageAreaCode) return;
    try {
      const res = await fetch(
        `https://us-central1-symbri-production.cloudfunctions.net/searchPhoneNumbers?areaCode=${messageAreaCode}`
      );
      if (!res.ok) {
        throw new Error("Search failed");
      }
      const data = await res.json();
      setAvailableNumbers(data);
    } catch (error) {
      console.error("Error searching phone numbers:", error);
    }
  };

  // NEW: Function to call purchasePhoneNumber when a number is selected
  const handleSelectPhoneNumber = async (selectedNumber) => {
    try {
      const res = await fetch(
        "https://us-central1-symbri-production.cloudfunctions.net/purchasePhoneNumber",
        {
          method: "POST",
          body: JSON.stringify({
            phoneNumber: selectedNumber,
            businessId: selectedEmployeeData.businessId,
            employeeId: selectedEmployeeData.uid,
          }),
        }
      );
      if (!res.ok) {
        throw new Error("Purchase failed");
      }
      const data = await res.json();
      console.log("Purchased number:", data);
    } catch (error) {
      console.error("Error purchasing phone number:", error);
    }
  };

  const allPermissions = [
    "Use of the website CRM",
    "Add Employees",
    "Delete Employees",
    "Create Jobs",
    "Delete Jobs",
    "Sign up new customer",
    "Send an offer to new customers",
    "Message Leads",
    "Move scheduled Stops",
    "Create Chemicals",
    "Delete Chemicals",
    "Create Subscriptions",
    "Delete Subscriptions",
    "Edit Subscriptions",
    "Make Notifications",
    "Edit Active Routes",
    "Edit Recurring Routes",
    "Create New Recurring Routes",
    "Create Issues",
    "Delete Issues",
    "Edit Issues",
    "Message Customers",
  ];

  useEffect(() => {
    if (selectedEmployeeData.permissions) {
      setPermissions(selectedEmployeeData.permissions);
    }
  }, [selectedEmployeeData.permissions]);

  useEffect(() => {
    getEmployeeDate();
  }, []);

  const getEmployeeDate = async () => {
    // getEmployeeStartDate
    const res = await fetch(
      "https://us-central1-symbri-production.cloudfunctions.net/getEmployeeStartDate",
      {
        method: "POST",
        body: JSON.stringify({
          uid: selectedEmployeeData.uid,
        }),
      }
    );
    const json = await res.json();

    console.log(json);

    if (!json.error) {
      setEmployeeStartDate(json.creationTime);
    }
  };

  function numericStringMask(str, mask) {
    if (!mask) return str;

    const numeric = str.replaceAll(/[^\d]/g, "");

    let idx = 0;

    const formated = mask.split("").map((el) => {
      if (el === "#") {
        el = numeric[idx];
        idx++;
      }
      return el;
    });

    return formated.join("");
  }

  return (
    <>
      <div className="min-h-full">
        <main className="py-10 p-44">
          {/* Page header */}
          <div className="max-w-3xl mx-auto px-4 sm:px-6 md:flex md:items-center md:justify-between md:space-x-5 lg:max-w-full lg:px-8">
            <div className="flex items-center space-x-5">
              <div className="flex-shrink-0">
                <div className="relative">
                  <img
                    className="h-16 w-16 rounded-full"
                    src={selectedEmployeeData.profilePhoto}
                    alt=""
                  />
                  <span
                    className="absolute inset-0 shadow-inner rounded-full"
                    aria-hidden="true"
                  />
                </div>
              </div>
              <div>
                <h1 className="text-2xl font-bold text-gray-900">
                  {selectedEmployeeData.name.replace(
                    /(^\w{1})|(\s+\w{1})/g,
                    (letter) => letter.toUpperCase()
                  )}
                </h1>
                <p className="text-sm font-medium text-gray-500">
                  Has been an employee since{" "}
                  {employeeStartDate
                    ? dayjs(employeeStartDate).format("MM/DD/YYYY")
                    : ""}
                  .
                </p>
              </div>
            </div>
            <div
              onClick={handleSendPasswordResetEmail}
              style={{
                backgroundColor: "#4F46E5",
                width: 200,
                height: 40,
                borderRadius: 5,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
              }}
            >
              <div style={{ color: "white" }}>Send Password Reset</div>
            </div>
          </div>

          <div className="mt-8 max-w-3xl mx-auto gap-6 sm:px-6 lg:max-w-full lg:grid-flow-col-dense lg:grid-cols-3">
            <div className="space-y-6 lg:col-start-1 lg:col-span-2">
              {/* Description list */}
              <section aria-labelledby="applicant-information-title">
                <div className="bg-white shadow sm:rounded-lg">
                  <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                    <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                      <div className="sm:col-span-1">
                        <dt className="text-sm font-medium text-gray-500">
                          Phone Number
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900">
                          <input
                            type="tel"
                            value={phoneNumber}
                            onChange={(e) =>
                              handlePhoneNumberChange(e.target.value)
                            }
                            style={{
                              width: 200,
                              height: 30,
                              border: "1px solid #d9d9d9",
                              borderRadius: 3,
                            }}
                          />
                        </dd>
                      </div>

                      <div className="sm:col-span-1">
                        <dt className="text-sm font-medium text-gray-500">
                          Email Address
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900">
                          {selectedEmployeeData.email
                            ? selectedEmployeeData.email.toString()
                            : ""}
                        </dd>
                      </div>
                      <div className="sm:col-span-1">
                        <dt className="text-sm font-medium text-gray-500">
                          Pay Rate
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900">
                          <input
                            type="number"
                            value={payRate}
                            onChange={(e) =>
                              handlePayRateChange(e.target.value)
                            }
                            style={{
                              width: 200,
                              height: 30,
                              border: "1px solid #d9d9d9",
                              borderRadius: 3,
                            }}
                          />
                        </dd>
                      </div>
                      <div className="sm:col-span-1">
                        <dt className="text-sm font-medium text-gray-500">
                          Pay Type
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900">
                          <select
                            value={payType}
                            onChange={(e) =>
                              handlePayTypeChange(e.target.value)
                            }
                            style={{
                              width: 200,
                              height: 30,
                              border: "1px solid #d9d9d9",
                              borderRadius: 3,
                            }}
                          >
                            <option value="Hourly">Hourly</option>
                            <option value="Salary">Salary</option>
                          </select>
                        </dd>
                      </div>

                      {/* hiding this till next week */}
                      <div className="sm:col-span-1 hidden">
                        <dt className="text-sm font-medium text-gray-500">
                          Messages Number
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900">
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <input
                              type="text"
                              placeholder="Enter area code"
                              value={messageAreaCode}
                              onChange={(e) =>
                                setMessageAreaCode(e.target.value)
                              }
                              style={{
                                width: 150,
                                height: 30,
                                border: "1px solid #d9d9d9",
                                borderRadius: 3,
                              }}
                            />
                            <button
                              onClick={handleSearchNumbers}
                              style={{
                                marginLeft: 5,
                                cursor: "pointer",
                                border: "none",
                                background: "none",
                                fontSize: 18,
                              }}
                              title="Search"
                            >
                              🔍
                            </button>
                          </div>
                          {availableNumbers.length > 0 && (
                            <select
                              onChange={(e) =>
                                handleSelectPhoneNumber(e.target.value)
                              }
                              style={{
                                width: 200,
                                height: 30,
                                marginTop: 5,
                                border: "1px solid #d9d9d9",
                                borderRadius: 3,
                              }}
                            >
                              <option value="">Select a phone number</option>
                              {availableNumbers.map((num) => (
                                <option
                                  key={num.phoneNumber}
                                  value={num.phoneNumber}
                                >
                                  {num.phoneNumber}
                                </option>
                              ))}
                            </select>
                          )}
                        </dd>
                      </div>

                      <div className="sm:col-span-1">
                        <dt className="text-sm font-medium text-gray-500">
                          Title
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900">
                          <input
                            value={title}
                            onChange={(change) =>
                              handleTitleChange(change.target.value)
                            }
                            style={{
                              width: 200,
                              height: 30,
                              border: "1px solid #d9d9d9",
                              borderRadius: 3,
                            }}
                          />
                        </dd>
                      </div>
                    </dl>
                  </div>
                </div>
              </section>

              {/* Permissions Section */}
              <section aria-labelledby="permissions-title">
                <div className="bg-white shadow sm:rounded-lg mt-6">
                  <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                    <h2 className="text-lg font-medium text-gray-900">
                      Employee Permissions
                    </h2>
                    <div className="grid grid-cols-4 gap-4 mt-4">
                      {allPermissions.map((permission) => (
                        <div key={permission}>
                          <label className="inline-flex items-center">
                            <input
                              type="checkbox"
                              className="form-checkbox"
                              disabled={
                                selectedEmployeeData.userType === "owner"
                              }
                              checked={permissions.includes(permission)}
                              onChange={() =>
                                handlePermissionChange(permission)
                              }
                            />
                            <span className="ml-2 text-sm text-gray-700">
                              {permission}
                            </span>
                          </label>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </main>
      </div>
    </>
  );
}
