import { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import dayjs from "dayjs";

export default function ViewDetailsOneTime(props) {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(props.open);

    console.log(props.viewDetailsData);
  }, [props.open]);

  const handleClose = () => {
    props.setOpen(false);
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={() => console.log()}>
        <div className="fixed inset-0" />

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-300 sm:duration-500"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-300 sm:duration-500"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                  <div className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
                    <div className="h-0 flex-1 overflow-y-auto">
                      <div className="bg-black py-6 px-4 sm:px-6">
                        <div className="flex items-center justify-between">
                          {props.viewDetailsData &&
                            props.viewDetailsData.customer && (
                              <Dialog.Title className="text-lg font-medium text-white">
                                {props.viewDetailsData.customer.customerName
                                  ? props.viewDetailsData.customer.customerName.replace(
                                      /(^\w{1})|(\s+\w{1})/g,
                                      (letter) => letter.toUpperCase()
                                    )
                                  : props.viewDetailsData.customer.name.replace(
                                      /(^\w{1})|(\s+\w{1})/g,
                                      (letter) => letter.toUpperCase()
                                    )}
                              </Dialog.Title>
                            )}
                          {props.viewDetailsData &&
                            props.viewDetailsData.stopObject && (
                              <Dialog.Title className="text-lg font-medium text-white">
                                {props.viewDetailsData.stopObject.customerData.name.replace(
                                  /(^\w{1})|(\s+\w{1})/g,
                                  (letter) => letter.toUpperCase()
                                )}
                              </Dialog.Title>
                            )}
                          {props.viewDetailsData &&
                            props.viewDetailsData.unassignedStopObject && (
                              <Dialog.Title className="text-lg font-medium text-white">
                                {props.viewDetailsData.unassignedStopObject
                                  .customer &&
                                  props.viewDetailsData.unassignedStopObject.customer.customerName.replace(
                                    /(^\w{1})|(\s+\w{1})/g,
                                    (letter) => letter.toUpperCase()
                                  )}
                              </Dialog.Title>
                            )}

                          <div className="ml-3 flex h-7 items-center">
                            <button
                              type="button"
                              className="rounded-md bg-white text-black focus:outline-none focus:ring-2 focus:ring-white"
                              onClick={() => handleClose()}
                            >
                              <span className="sr-only">Close panel</span>
                              <XIcon className="h-6 w-6" aria-hidden="true" />
                            </button>
                          </div>
                        </div>
                        <div className="mt-1">
                          {props.viewDetailsData &&
                            props.viewDetailsData.stopServiceLocation && (
                              <p className="text-sm text-white">
                                {
                                  props.viewDetailsData.stopServiceLocation.address.split(
                                    ","
                                  )[0]
                                }
                              </p>
                            )}
                          {props.viewDetailsData &&
                            props.viewDetailsData.address && (
                              <p className="text-sm text-white">
                                {props.viewDetailsData.address.split(",")[0]}
                              </p>
                            )}
                        </div>
                      </div>
                      <div className="flex flex-1 flex-col justify-between">
                        <div className="divide-y divide-gray-200 px-4 sm:px-6">
                          {props.viewDetailsData &&
                            (props.viewDetailsData.assigned ||
                              props.viewDetailsData.contractor) &&
                            props.viewDetailsData.type === "stop" && (
                              <div className="space-y-6 pt-6 pb-5">
                                <div>
                                  <label
                                    htmlFor="project-name"
                                    className="block text-sm font-medium text-gray-900"
                                  >
                                    Stop Title
                                  </label>
                                  <div className="mt-1">
                                    <input
                                      disabled
                                      value={props.viewDetailsData.name}
                                      type="text"
                                      className="block w-full rounded-md border-gray-300 border-2 h-10 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                    />
                                  </div>
                                </div>
                                <div>
                                  <label
                                    htmlFor="project-name"
                                    className="block text-sm font-medium text-gray-900"
                                  >
                                    Stop Description
                                  </label>
                                  <div className="mt-1">
                                    <input
                                      disabled
                                      value={
                                        props.viewDetailsData.stopObject
                                          ? props.viewDetailsData.stopObject
                                              .stopCustomerDescription
                                          : props.viewDetailsData
                                              .unassignedStopObject
                                          ? props.viewDetailsData
                                              .unassignedStopObject
                                              .stopDescription
                                          : ""
                                      }
                                      type="text"
                                      className="block w-full rounded-md border-gray-300 border-2 h-10 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                    />
                                  </div>
                                </div>

                                <div className="flex flex-column">
                                  <div>
                                    <div className="block text-sm font-medium text-gray-900">
                                      Date of Stop
                                    </div>
                                    <div className="mt-1 text-sm flex flex-row">
                                      <div>
                                        {props.viewDetailsData.stopObject
                                          ? props.viewDetailsData.stopObject
                                              .stopDate
                                          : "---"}
                                      </div>
                                      <div className="ml-2">
                                        {props.viewDetailsData.stopObject
                                          ? dayjs(
                                              props.viewDetailsData.stopObject
                                                .stopDate
                                            ).format("ddd")
                                          : "---"}
                                      </div>
                                    </div>
                                  </div>

                                  <div className="ml-auto mr-2">
                                    <div className="block text-sm font-medium text-gray-900">
                                      Assigned Employee
                                    </div>
                                    <div className="mt-1 text-sm flex flex-row">
                                      {props.viewDetailsData.contractor ? (
                                        <div>
                                          {props.viewDetailsData.contractor.name.replace(
                                            /(^\w{1})|(\s+\w{1})/g,
                                            (letter) => letter.toUpperCase()
                                          )}
                                        </div>
                                      ) : (
                                        <div>
                                          {props.viewDetailsData.stopObject.contractor.name.replace(
                                            /(^\w{1})|(\s+\w{1})/g,
                                            (letter) => letter.toUpperCase()
                                          )}
                                        </div>
                                      )}

                                      {props.viewDetailsData
                                        .unassignedStopObject && <div>---</div>}
                                    </div>
                                  </div>
                                </div>

                                {props.viewDetailsData?.stopObject?.completedDates.includes(
                                  props.stopDate
                                ) &&
                                  !props.viewDetailsData
                                    .unassignedStopObject && (
                                    <div className="flex flex-column">
                                      <div
                                        onClick={() =>
                                          props.handleOpenReportJob(
                                            props.viewDetailsData
                                          )
                                        }
                                        style={{
                                          color: "blue",
                                          borderBottom: "1px solid blue",
                                          cursor: "pointer",
                                        }}
                                      >
                                        View Stop Report
                                      </div>
                                    </div>
                                  )}
                                {!props.viewDetailsData.stopObject.completedDates.includes(
                                  props.stopDate
                                ) &&
                                  !props.viewDetailsData
                                    .unassignedStopObject && (
                                    <div className="flex flex-column">
                                      <div
                                        onClick={() =>
                                          props.handleOpenReschedule(
                                            props.viewDetailsData
                                          )
                                        }
                                        style={{
                                          color: "blue",
                                          borderBottom: "1px solid blue",
                                          cursor: "pointer",
                                        }}
                                      >
                                        Move Stop
                                      </div>
                                    </div>
                                  )}
                                {!props.viewDetailsData.stopObject.completedDates.includes(
                                  props.stopDate
                                ) &&
                                  !props.viewDetailsData
                                    .unassignedStopObject && (
                                    <div className="flex flex-column">
                                      <div
                                        onClick={() =>
                                          props.handleCompleteOneTimeStop(
                                            props.viewDetailsData,
                                            props.viewDetailsData.stopObject
                                              .stopDate,
                                            props.viewDetailsData.employeeUid ||
                                              props.viewDetailsData.contractor
                                                .uid
                                          )
                                        }
                                        style={{
                                          color: "blue",
                                          borderBottom: "1px solid blue",
                                          cursor: "pointer",
                                        }}
                                      >
                                        Complete Stop
                                      </div>
                                    </div>
                                  )}

                                <div className="flex flex-column">
                                  <div
                                    onClick={() =>
                                      props.handleViewJob(props.viewDetailsData)
                                    }
                                    style={{
                                      color: "blue",
                                      borderBottom: "1px solid blue",
                                      cursor: "pointer",
                                    }}
                                  >
                                    View Job
                                  </div>
                                </div>
                              </div>
                            )}
                          {props.viewDetailsData &&
                            props.viewDetailsData.stopServiceLocation && (
                              <div className="space-y-6 pt-6 pb-5">
                                <div>
                                  <label
                                    htmlFor="project-name"
                                    className="block text-sm font-medium text-gray-900"
                                  >
                                    Stop Title
                                  </label>
                                  <div className="mt-1">
                                    <input
                                      disabled
                                      value={
                                        props.viewDetailsData.stopTitle ||
                                        props.viewDetailsData
                                          .unassignedStopObject.stopTitle
                                      }
                                      type="text"
                                      className="block w-full rounded-md border-gray-300 border-2 h-10 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                    />
                                  </div>
                                </div>
                                <div>
                                  <label
                                    htmlFor="project-name"
                                    className="block text-sm font-medium text-gray-900"
                                  >
                                    Stop Description
                                  </label>
                                  <div className="mt-1">
                                    <input
                                      disabled
                                      value={
                                        props.viewDetailsData.stopDescription ||
                                        props.viewDetailsData
                                          .unassignedStopObject.stopDescription
                                      }
                                      type="text"
                                      className="block w-full rounded-md border-gray-300 border-2 h-10 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                    />
                                  </div>
                                </div>

                                <div className="flex flex-column">
                                  <div>
                                    <div className="block text-sm font-medium text-gray-900">
                                      Date of Stop
                                    </div>
                                    <div className="mt-1 text-sm flex flex-row">
                                      <div>---</div>
                                      <div className="ml-2">---</div>
                                    </div>
                                  </div>

                                  <div className="ml-auto mr-2">
                                    <div className="block text-sm font-medium text-gray-900">
                                      Assigned Employee
                                    </div>
                                    <div className="mt-1 text-sm flex flex-row">
                                      <div>---</div>
                                    </div>
                                  </div>
                                </div>
                                {props.viewDetailsData.stopObject &&
                                  !props.viewDetailsData.stopObject.completedDates.includes(
                                    props.stopDate
                                  ) &&
                                  !props.viewDetailsData
                                    .stopServiceLocation && (
                                    <div className="flex flex-column">
                                      <div
                                        onClick={() =>
                                          props.handleOpenReschedule(
                                            props.viewDetailsData
                                          )
                                        }
                                        style={{
                                          color: "blue",
                                          borderBottom: "1px solid blue",
                                          cursor: "pointer",
                                        }}
                                      >
                                        Move Stop
                                      </div>
                                    </div>
                                  )}
                                {props.viewDetailsData.stopObject &&
                                  !props.viewDetailsData.stopObject.completedDates.includes(
                                    props.stopDate
                                  ) &&
                                  !props.viewDetailsData
                                    .stopServiceLocation && (
                                    <div className="flex flex-column">
                                      <div
                                        onClick={() =>
                                          props.handleCompleteOneTimeStop(
                                            props.viewDetailsData,
                                            props.viewDetailsData.stopObject
                                              .stopDate,
                                            props.viewDetailsData.employeeUid ||
                                              props.viewDetailsData.contractor
                                                .uid
                                          )
                                        }
                                        style={{
                                          color: "blue",
                                          borderBottom: "1px solid blue",
                                          cursor: "pointer",
                                        }}
                                      >
                                        Complete Stop
                                      </div>
                                    </div>
                                  )}
                                {props.viewDetailsData.stopServiceLocation && (
                                  <div className="flex flex-column">
                                    <div
                                      onClick={() =>
                                        props.handleOpenSchedule(
                                          props.viewDetailsData
                                        )
                                      }
                                      style={{
                                        color: "blue",
                                        borderBottom: "1px solid blue",
                                        cursor: "pointer",
                                      }}
                                    >
                                      Schedule Stop
                                    </div>
                                  </div>
                                )}
                              </div>
                            )}
                        </div>
                      </div>
                    </div>
                    {props.viewDetailsData &&
                      !props.viewDetailsData.stopServiceLocation && (
                        <div className="flex flex-shrink-0 justify-start px-4 py-4">
                          <button
                            type="button"
                            className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                            onClick={() =>
                              props.handleViewDay(
                                props.dayData[
                                  props.viewDetailsData.contractor
                                    ? props.viewDetailsData.contractor.uid
                                    : props.viewDetailsData.stopObject
                                        .contractor.uid
                                ],
                                props.viewDetailsData.contractor
                                  ? props.viewDetailsData.contractor
                                  : props.viewDetailsData.stopObject.contractor,
                                props.viewDetailsData.completedDates
                              )
                            }
                          >
                            View Route
                          </button>
                        </div>
                      )}
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
