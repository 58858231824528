import { Fragment, useEffect, useState, useRef } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import firebase from "firebase/compat/app";
import AutoComplete from "react-google-autocomplete";
import Geocoder from "react-native-geocoding";
import { generateBusinessId } from "../Tools/generateBusinessId";
import { toast } from "react-toastify";
import SelectSubscription from "./SelectSubscription";
import SelectAgressivePets from "./SelectAgressivePets";
import { Rings } from "react-loader-spinner";

export default function AddCustomer(props) {
  const db = firebase.firestore();
  const [open, setOpen] = useState(false);
  const [customerFirstName, setCustomerFirstName] = useState("");
  const [customerLastName, setCustomerLastName] = useState("");
  const [customerHomeAddress, setCustomerHomeAddress] = useState("");
  const [customerEmailAddress, setCustomerEmailAddress] = useState("");
  const [customerPhoneNumber, setCustomerPhoneNumber] = useState("");
  const [subscriptions, setSubscriptions] = useState([]);
  const [addedSubscriptions, setAddedSubscriptions] = useState({});
  const [pets, setPets] = useState("");
  const [accessCode, setAccessCode] = useState("");
  const [prevPhoneNumber, setPrevPhoneNumber] = useState("");
  const [isBusiness, setIsBusiness] = useState(false);
  const userPermissions = props.userPermissions;
  const [loading, setLoading] = useState(false);
  const [customerSecondaryPhoneNumber, setCustomerSecondaryPhoneNumber] =
    useState("");
  const [prevSecondaryPhoneNumber, setPrevSecondaryPhoneNumber] = useState("");

  useEffect(() => {
    setOpen(props.open);
    if (props.open) {
      db.collection("Businesses")
        .doc(props.businessId)
        .collection("Subscriptions")
        .get()
        .then((snapshot) => {
          let subs = [];
          for (let index = 0; index < snapshot.docs.length; index++) {
            const doc = snapshot.docs[index].data();
            subs.push(doc);
          }

          setSubscriptions(subs);
        });
    }
  }, [props.open]);

  function numericStringMask(str, mask) {
    if (!mask) return str;

    const numeric = str.replaceAll(/[^\d]/g, "");

    let idx = 0;

    const formated = mask.split("").map((el) => {
      if (el === "#") {
        el = numeric[idx];
        idx++;
      }
      return el;
    });

    console.log(formated.join(""));

    return formated.join("");
  }

  const handleClose = () => {
    props.setOpen(false);
    setCustomerFirstName("");
    setCustomerLastName("");
    setCustomerHomeAddress("");
    setCustomerEmailAddress("");
    setCustomerPhoneNumber("");
    setSubscriptions([]);
    setAddedSubscriptions({});
    setPets("");
    setAccessCode("");
    setPrevPhoneNumber("");
  };

  const handleAddCustomerClick = () => {
    if (!userPermissions.includes("Sign up new customer")) {
      toast.warn("You do not have permission to complete this action.");
      return;
    }
    if (customerFirstName === "" || customerFirstName.trim().length === 0) {
      toast.warn("Customer first name field is required!");
      return;
    } else if (
      customerLastName === "" ||
      customerLastName.trim().length === 0
    ) {
      if (!isBusiness) {
        toast.warn("Customer last name field is required!");
        return;
      }
    } else if (customerHomeAddress === "") {
      // need to also validate number format
      toast.warn("Customer home address field is required!");
      return;
    }

    setLoading(true);

    const customerId = generateBusinessId();
    firebase
      .auth()
      .currentUser.getIdToken()
      .then((token) => {
        let subToSend = addedSubscriptions;
        addedSubscriptions.paused = false;
        fetch(
          "https://us-central1-symbri-production.cloudfunctions.net/handleAddCustomer",
          {
            method: "POST",
            body: JSON.stringify({
              props: props,
              subscription: subToSend,
              customerId: customerId,
              customerFirstName: customerFirstName,
              customerLastName: customerLastName,
              customerPhoneNumber: customerPhoneNumber.replace(/[^\d]/g, ""),
              customerSecondaryPhoneNumber: customerSecondaryPhoneNumber,
              isBusinessCustomer: isBusiness,
              customerHomeAddress: customerHomeAddress,
              customerEmailAddress: customerEmailAddress,
              token: token,
              accessCode: accessCode,
              pets: pets,
            }),
          }
        )
          .then((response) => response.json())
          .then((data) => {
            console.log(data);

            if (data.error) {
              setLoading(false);
              return toast.warn(data.error);
            }

            // if succcess
            setLoading(false);
            handleClose();

            toast.success(
              "Customer created! A text message has been sent to the customer inviting them!"
            );
          });
      });
  };

  const handleCustomerAddress = (value) => {
    console.log(value);
    Geocoder.from(value.formatted_address)
      .then((json) => {
        var location = json.results[0].geometry.location;
        setCustomerHomeAddress({
          address: value.formatted_address,
          coordinates: location,
        });
      })
      .catch((e) => console.log(e));
  };

  const handlePhoneNumberInput = (value) => {
    // Check if the user is deleting characters
    if (value.length < prevPhoneNumber.length) {
      setCustomerPhoneNumber(value);
    } else {
      const formattedNumber = numericStringMask(value, "(###) ### - ####");
      setCustomerPhoneNumber(formattedNumber);
    }

    // Update the previous value state
    setPrevPhoneNumber(value);
  };

  const handleSecondaryPhoneNumberInput = (value) => {
    // Check if the user is deleting characters
    if (value.length < prevSecondaryPhoneNumber.length) {
      setCustomerSecondaryPhoneNumber(value);
    } else {
      const formattedNumber = numericStringMask(value, "(###) ### - ####");
      setCustomerSecondaryPhoneNumber(formattedNumber);
    }

    // Update the previous value state
    setPrevSecondaryPhoneNumber(value);
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={() => console.log()}>
        <div className="fixed inset-0" />

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-300 sm:duration-500"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-300 sm:duration-500"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                  <div className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
                    <div className="h-0 flex-1 overflow-y-auto">
                      <div className="bg-indigo-700 py-6 px-4 sm:px-6">
                        <div className="flex items-center justify-between">
                          <Dialog.Title className="text-lg font-medium text-white">
                            {" "}
                            New Customer{" "}
                          </Dialog.Title>
                          <div className="ml-3 flex h-7 items-center">
                            <button
                              type="button"
                              className="rounded-md bg-indigo-700 text-indigo-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                              onClick={() => handleClose()}
                            >
                              <span className="sr-only">Close panel</span>
                              <XIcon className="h-6 w-6" aria-hidden="true" />
                            </button>
                          </div>
                        </div>
                        <div className="mt-1">
                          <p className="text-sm text-indigo-300">
                            Get started by filling in the information below to
                            add a new customer.
                          </p>
                        </div>
                      </div>
                      <div className="flex flex-1 flex-col justify-between">
                        <div className="divide-y divide-gray-200 px-4 sm:px-6">
                          <div className="space-y-6 pt-6 pb-5">
                            <div className="flex items-center">
                              <label
                                htmlFor="isBusiness"
                                className="block text-sm font-medium text-gray-900 ml-auto"
                              >
                                Is this a business?
                              </label>
                              <div className="mt-1 ml-2">
                                <input
                                  type="checkbox"
                                  id="isBusiness"
                                  name="isBusiness"
                                  className="h-5 w-5 text-indigo-600 border-gray-300 rounded"
                                  checked={isBusiness}
                                  onChange={(e) =>
                                    setIsBusiness(e.target.checked)
                                  }
                                />
                              </div>
                            </div>
                            {isBusiness && (
                              <div style={{ marginTop: -8 }}>
                                <label
                                  htmlFor="description"
                                  className="block text-sm font-medium text-gray-900"
                                >
                                  Business Name
                                </label>
                                <div className="mt-1">
                                  <input
                                    onChange={(change) =>
                                      setCustomerFirstName(change.target.value)
                                    }
                                    type="text"
                                    className="block w-full rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm border-gray-300 border-2 h-10"
                                  />
                                </div>
                              </div>
                            )}

                            {!isBusiness && (
                              <div style={{ marginTop: -8 }}>
                                <label
                                  htmlFor="description"
                                  className="block text-sm font-medium text-gray-900"
                                >
                                  {" "}
                                  First Name{" "}
                                </label>
                                <div className="mt-1">
                                  <input
                                    onChange={(change) =>
                                      setCustomerFirstName(change.target.value)
                                    }
                                    type="text"
                                    className="block w-full rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm border-gray-300 border-2 h-10"
                                  />
                                </div>
                              </div>
                            )}
                            {!isBusiness && (
                              <div>
                                <label
                                  htmlFor="description"
                                  className="block text-sm font-medium text-gray-900"
                                >
                                  {" "}
                                  Last Name{" "}
                                </label>
                                <div className="mt-1">
                                  <input
                                    onChange={(change) =>
                                      setCustomerLastName(change.target.value)
                                    }
                                    type="text"
                                    className="block w-full rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm border-gray-300 border-2 h-10"
                                  />
                                </div>
                              </div>
                            )}
                            <div>
                              <label
                                htmlFor="description"
                                className="block text-sm font-medium text-gray-900"
                              >
                                {" "}
                                Phone Number{" "}
                              </label>
                              <div className="mt-1">
                                <input
                                  value={customerPhoneNumber}
                                  onChange={(change) =>
                                    handlePhoneNumberInput(change.target.value)
                                  }
                                  text="text"
                                  className="block w-full rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm border-gray-300 border-2 h-10"
                                />
                              </div>
                            </div>
                            {isBusiness && (
                              <div>
                                <label
                                  htmlFor="description"
                                  className="block text-sm font-medium text-gray-900"
                                >
                                  {" "}
                                  Secondary Phone Number{" "}
                                </label>
                                <div className="mt-1">
                                  <input
                                    value={customerSecondaryPhoneNumber}
                                    onChange={(change) =>
                                      handleSecondaryPhoneNumberInput(
                                        change.target.value
                                      )
                                    }
                                    text="text"
                                    className="block w-full rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm border-gray-300 border-2 h-10"
                                  />
                                </div>
                              </div>
                            )}
                            <div>
                              <label
                                htmlFor="description"
                                className="block text-sm font-medium text-gray-900"
                              >
                                {" "}
                                Email Address{" "}
                              </label>
                              <div className="mt-1">
                                <input
                                  value={customerEmailAddress}
                                  onChange={(change) =>
                                    setCustomerEmailAddress(change.target.value)
                                  }
                                  text="text"
                                  className="block w-full rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm border-gray-300 border-2 h-10"
                                />
                              </div>
                            </div>
                            <div>
                              <label
                                htmlFor="description"
                                className="block text-sm font-medium text-gray-900"
                              >
                                {" "}
                                Pool Address{" "}
                              </label>
                              <div className="mt-1">
                                <AutoComplete
                                  options={{
                                    types: ["address"],
                                  }}
                                  className="block w-full rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm border-gray-300 border-2 h-10"
                                  apiKey={
                                    process.env.REACT_APP_GOOGLE_MAPS_API_TOKEN
                                  }
                                  onPlaceSelected={(value) =>
                                    handleCustomerAddress(value)
                                  }
                                />
                              </div>
                            </div>
                            <div>
                              <label
                                htmlFor="description"
                                className="block text-sm font-medium text-gray-900"
                              >
                                {" "}
                                Subscription{" "}
                              </label>
                              <div className="mt-1">
                                <SelectSubscription
                                  businessId={props.businessId}
                                  setAddedSubscriptions={setAddedSubscriptions}
                                  subscriptions={subscriptions}
                                />
                              </div>
                            </div>

                            <div>
                              <label
                                htmlFor="description"
                                className="block text-sm font-medium text-gray-900"
                              >
                                {" "}
                                Access Code{" "}
                              </label>
                              <div className="mt-1">
                                <input
                                  value={accessCode}
                                  onChange={(change) =>
                                    setAccessCode(change.target.value)
                                  }
                                  text="text"
                                  className="block w-full rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm border-gray-300 border-2 h-10"
                                />
                              </div>
                            </div>
                            <div>
                              <label
                                htmlFor="description"
                                className="block text-sm font-medium text-gray-900"
                              >
                                {" "}
                                Aggressive Pets{" "}
                              </label>
                              <div className="mt-1">
                                <SelectAgressivePets
                                  businessId={props.businessId}
                                  setPets={setPets}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        style={{
                          fontSize: 10,
                          width: "100%",
                          justifyContent: "center",
                          display: "flex",
                        }}
                      >
                        <div style={{ width: "88%", marginTop: 12 }}>
                          Pursuant to the regulations set forth by the Telephone
                          Consumer Protection Act (TCPA), it is imperative that
                          written consent is obtained from your clientele before
                          engaging in any form of text messaging. By clicking
                          the add button below, you are explicitly acknowledging
                          and affirming your utilization of the Symbri software
                          for the purpose of transmitting text messages to your
                          customers, while affirming further that you possess
                          the requisite prior consent to engage in such textual
                          communication.
                        </div>
                      </div>
                    </div>

                    <div className="flex flex-shrink-0 justify-end px-4 py-4">
                      <button
                        type="button"
                        className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                        onClick={() => handleClose(false)}
                      >
                        Cancel
                      </button>
                      <button
                        onClick={handleAddCustomerClick}
                        className="ml-4 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      >
                        {loading ? (
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <div>Loading </div>
                            <Rings
                              height={22}
                              width={22}
                              color="white"
                              arialLabel="loading-indicator"
                            />
                          </div>
                        ) : (
                          "Add"
                        )}
                      </button>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
