import React from "react";
import { FaCheckCircle, FaClock, FaRegTrashAlt } from "react-icons/fa";
import { HiOutlinePencil } from "react-icons/hi";
import { MdPlace } from "react-icons/md";
import firebase from "firebase/compat/app";
import dayjs from "dayjs";
import { IoIosAlert } from "react-icons/io";

const JobStopsNew = (props) => {
  const stops = props.jobStops || [];
  const db = firebase.firestore();
  const searchTerm = "";

  const viewReport = async (stop) => {
    console.log(stop);

    const querySnapshot = await db
      .collection("Customers")
      .doc(stop.customerData.customerId)
      .collection("PoolReports")
      .where("businessId", "==", stop.businessId)
      .where("reportJobId", "==", stop.jobId)
      .get();

    const reports = querySnapshot.docs.map((doc) => doc.data());
    console.log(dayjs.unix(reports[0].reportDate.seconds).format("MM/DD/YYYY"));
    console.log(stop.stopDate);
    const exactReport = reports.find(
      (report) =>
        (stop.tempStopId ? stop.tempStopId : stop.stopId) ===
          report.jobStopId ||
        dayjs.unix(report.reportDate.seconds).format("MM/DD/YYYY") ===
          stop.stopDate
    );

    console.log(exactReport);

    if (exactReport) {
      props.handleViewReport(exactReport);
    }
  };

  return (
    <div
      style={{
        width: "100%",
        alignItems: "center",
        backgroundColor: "white",
      }}
    >
      {/* Stops List */}
      <div style={{ width: "100%", height: "60%", overflowY: "auto" }}>
        {stops
          .filter((a) =>
            searchTerm.length === 0
              ? true
              : a.stopNickname.toLowerCase().includes(searchTerm.toLowerCase())
          )
          .map((value, index) => (
            <div
              key={value.stopId}
              style={{
                border: "2px solid #D9D9D9",
                width: "100%",
                height: "90px",
                marginTop: index === 0 ? "0px" : "12px",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                padding: "6px",
                borderRadius: "10px",
              }}
            >
              <div style={{ marginLeft: "2%" }}>
                {value.completedDates && value.completedDates.length !== 0 && (
                  <FaCheckCircle color="green" size={24} />
                )}
                {value.stopDate === "none" && <IoIosAlert size={30} />}
                {!value.completedDates ||
                  (value.completedDates.length === 0 && <FaClock size={24} />)}
              </div>
              <div style={{ marginLeft: "4%" }}>
                {value.stopDate === "none" && (
                  <p
                    style={{ marginTop: "4px", fontWeight: "500", margin: "0" }}
                  >
                    Unscheduled
                  </p>
                )}
                {value.stopDate !== "none" && (
                  <p
                    style={{ marginTop: "4px", fontWeight: "500", margin: "0" }}
                  >
                    {value.contractor?.name?.replace(
                      /(^\w{1})|(\s+\w{1})/g,
                      (letter) => letter.toUpperCase()
                    )}
                  </p>
                )}

                <p style={{ marginTop: "4px", fontWeight: "500", margin: "0" }}>
                  {value.stopDate}
                </p>

                <p style={{ marginTop: "4px", fontWeight: "500", margin: "0" }}>
                  {props.jobData.serviceLocation.address.split(",")[0]}
                </p>
              </div>
              {(value.completedDates?.length === 0 ||
                !value.completedDates) && (
                <div className="flex ml-auto mr-4 cursor-pointer">
                  <div onClick={() => props.handleEditStopClick(value)}>
                    Edit Stop
                  </div>

                  {false && (
                    <FaRegTrashAlt
                      onClick={() => props.handleDeleteJobStop(value)}
                      className="ml-3 cursor-pointer"
                      size={24}
                    />
                  )}
                </div>
              )}
              {value.completedDates && value.completedDates.length !== 0 && (
                <div
                  onClick={() => viewReport(value)}
                  className="flex ml-auto mr-4 cursor-pointer"
                >
                  View Report
                </div>
              )}
            </div>
          ))}
      </div>
    </div>
  );
};

export default JobStopsNew;
